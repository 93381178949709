import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  margin: 4px 16px;

  main,
  aside {
    display: flex;
    flex: 1;

    .card {
      width: 100%;
    }

    .card-footer,
    .card-body {
      font-family: 'Roboto';
    }

    .cardBody {
      padding: 16px 0;
    }
  }

  aside {
    display: flex;
    gap: 10px;
  }
`

export const HeaderModal = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    background-color: transparent;
  }
`

export const CardBody = styled.div`
  display: flex;

  cursor: zoom-in;

  justify-content: space-between;

  gap: 5px;

  .image {
    display: flex;
    flex-direction: column;

    border: 1px solid #000;

    .image-title {
      margin: 0;
      border-bottom: 1px solid #000;

      padding: 2px;

      font-weight: bold;
    }

    img {
      height: 280px;
      width: 280px;

      object-fit: cover;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 8px 8px 8px;

  margin-left: auto;
`

export const LinkStyled = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline !important;
  }
`

export const CardContainer = styled.div`
  flex: 1;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  border-radius: 5px;

  padding: 10px;

  display: flex;
  flex-direction: column;

  .cardHeader {
    display: flex;

    position: relative;

    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #ddd !important;

    padding-bottom: 10px !important;

    button {
      background-color: transparent;
    }
  }

  .cardFooter {
    border-top: 1px solid #ddd;

    padding-top: 10px;
  }

  .cardBody {
    flex: 1;
  }
`

export const Map = styled.div.attrs({className: 'col-map-rota', id: 'map-rota'})`
  width: 100%;
  height: 100%;
`
