import styled from 'styled-components'

export const Container = styled.button`
  display: none;
  visibility: hidden;
  scale: 0;
  justify-content: center;
  height: 35px;

  span {
    font-family: Inter, Helvetica, 'sans-serif';
  }

  gap: 5px;
  align-items: center;

  color: white;

  text-transform: uppercase;

  padding: 0 8px;

  border-radius: 5px;
  border: none;

  svg {
    font-size: 25px;
    color: white;
  }

  @keyframes fade-in {
    100% {
      scale: 1;
    }
  }

  @keyframes fade-out {
    0% {
      scale: 1;
    }
    100% {
      scale: 0;
    }
  }

  &.show {
    display: flex;
    visibility: visible;
    scale: 1;
  }

  &.fade-out {
    display: flex;
    visibility: visible;
    animation: fade-out 0.15s ease-in forwards;
  }

  &.fade-in {
    display: flex;
    visibility: visible;

    animation: fade-in 0.15s ease-in forwards;
  }

  &:hover {
    transition-duration: 0.15s;
    filter: contrast(120%);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 5px 10px white;
  }

  &:active:after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    border-radius: 5px;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
  }

  &:active {
    top: 1px;
  }
`
