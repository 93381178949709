import clsx from 'clsx'
import {ButtonHTMLAttributes} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import Icon from './Icon'

function Button({
  color = 'light-primary',
  w100,
  loading,
  icon,
  iconSize,
  iconType,
  fill = true,
  tooltip,
  className,
  children,
  ...rest
}: ButtonProps) {
  const intl = useIntl()

  function renderButton() {
    return (
      <button
        type='submit'
        id='kt_sign_in_submit'
        className={clsx(
          `btn btn-sm`,
          {'btn-icon': !children},
          {[`btn-color-${color} btn-active-light-${color}`]: !fill},
          {[`btn-${color}`]: fill},
          {'w-100': w100},
          {className}
        )}
        {...rest}
      >
        {!loading && (
          <>
            {Boolean(icon) && <Icon icon={icon!} iconSize={iconSize} iconType={iconType} />}
            <span className='indicator-label'>{children}</span>
          </>
        )}
        {loading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {intl.formatMessage({id: 'GENERAL.LOADING.TEXT'})}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    )
  }

  if (tooltip) {
    return (
      <OverlayTrigger placement='top' overlay={<Tooltip>{tooltip}</Tooltip>}>
        {renderButton()}
      </OverlayTrigger>
    )
  } else {
    return renderButton()
  }
}

export default Button

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  color?: string
  w100?: boolean
  fill?: boolean
  tooltip?: string
  icon?: string
  iconSize?: string
  iconType?: 'duotone' | 'solid' | 'outline'
  className?: string
}
