import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #364150;
  width: 100%;
  padding: 10px;

  .services {
    color: white;
    display: flex;

    gap: 20px;

    .service {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;

    button {
      display: flex;
      align-items: center;
    }
  }
`

export const Textarea = styled.textarea`
  width: 100%;
  min-height: 80px;

  border: none;
  border-radius: 5px;
  outline: none;

  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;

  font-family: Roboto, Poppins, sans-serif !important;

  margin-top: 10px;
  padding: 6px;
`
