import {useEffect, useState} from 'react'

import {useSpring, useInView} from '@react-spring/web'
import {AnimatedTableRow} from './styles'

const TableRow = ({children, item, onClick, ...rest}: any) => {
  const [show, setShow] = useState(false)

  const [ref, inView] = useInView()

  const fadeAnimation = {
    opacity: show ? 1 : 0,
    scale: show ? 1 : 0,
    // borderWidth: show ? 1 : 0,
    config: {
      tension: 300,
      duration: 250,
    },
  }

  const styles = useSpring(fadeAnimation)

  useEffect(() => {
    if (inView) {
      setShow(true)
    }
  }, [inView])

  return (
    <AnimatedTableRow
      ref={ref}
      onClick={() => {
        onClick(item)
      }}
      style={styles}
      {...rest}
    >
      {children}
    </AnimatedTableRow>
  )
}

export default TableRow
