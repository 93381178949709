import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  .container-body {
    padding-top: 16px;
    padding-left: 16px;
  }
`

export const ContainerHeader = styled.div`
  display: flex;
  align-items: flex-end;

  width: 100%;

  border-bottom: 1px solid black;

  padding: 10px;

  position: relative;

  .icon {
    position: absolute;
    left: -20px;
    bottom: -16.9px;

    transform: rotate(-90deg);
  }

  span {
    font-family: Inter;
    font-size: 18px;
  }

  button {
    background-color: transparent;
    margin-left: auto;
  }
`
