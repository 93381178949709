/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useAlert} from 'src/app/components/Alert/Alert'
import Filtros from 'src/app/model/Usuario/Filtros'
import {IUser} from 'src/app/model/Usuario/Type'
import {ForgetPassword} from 'src/app/repositories/Login'
import {
  GetUserQuantitative,
  GetUsersData,
  IUserCreationSelectInfos,
  ImportFileUsuario,
  getUsers,
  toogleUserActiveStatus,
} from 'src/app/repositories/User'
import CardsWidget from '../../../components/CardsWidget'
import ImportModal, {errorMessage} from '../../../components/ImportModal'
import ModalAlert from '../../../components/ModalAlert'
import UsuarioModel from '../../../model/Usuario/Usuario'
import UserTable from './components/UserTable'
import { IFilter } from 'src/app/model/Generic/Type'

const IMPORT_EXCEL_MODEL = [
  {
    sheetLabel: 'Usuários',
    sheetName: 'modelo_usuario',
    values: {
      'NOME COMPLETO': '******',
      EMAIL: 'exemplo@email.com',
      'GRUPO DE ACESSO': 'COORDENADOR/PESQUISADOR/GESTOR/RH',
      TELEFONE: 'DDD 00000-0000',
      CPF: '000.000.000-00',
      RG: '00.000.000-0',
      'DATA EMISSAO RG': '00/00/0000',
      'ORGAO EMISSOR RG': '******',
      'DATA DE NASCIMENTO': '00/00/0000',
      NACIONALIDADE: '******',
      'ESTADO CIVIL': 'Casado(a)/Divorciado(a)/Separado(a)/Solteiro(a)/União estável',
      GENERO: 'Feminino/Masculino',
      CEP: '00000000-000',
      BAIRRO: '******',
      CIDADE: '******',
      UF: '**',
      LOGRADOURO: '******',
      NUMERO: '******',
      COMPLEMENTO: '******',
      'CODIGO BANCO': '******',
      'TIPO CONTA': 'corrente/pagamento/poupança/salario',
      AGENCIA: '******',
      CONTA: '******',
      'CHAVE PIX': '******',
    },
  },
  {
    sheetLabel: 'Cidades Custo',
    sheetName: 'cidades_custo',
    values: {
      CIDADE: '******',
      UF: '**',
      VALOR: '0.00',
      'CPF DO PESQUISADOR': '000.000.000-00',
      'CPF/CNPJ CENTRO DE CUSTO': '******',
    },
  },
]

const empytFiltros: GetUsersData = {
  situacao: null,
  perfil: null,
  pesquisadorPendente: false,
  numeroPagina: 1,
  tamanhoPagina: 5,
}
const empytFilters = {
  label: 'Selecione uma opção',
  value: null,
}

export interface IModalAlert {
  title: string
  content: string
  action: () => void
}

export interface OptionInput {
  label: string | null
  value: number | string | null
}

export function Usuario() {
  const [users, setUsers] = useState<UsuarioModel[]>([])
  const [userCreationSelectInfos, setUserCreationSelectInfos] = useState<IUserCreationSelectInfos>(
    {} as IUserCreationSelectInfos
  )

  const [filtros, setFiltros] = useState<GetUsersData>(empytFiltros)

  const [searchParams, setSearchParams] = useSearchParams()
  const pageSize =
    searchParams.get('pageSize') !== null ? parseInt(searchParams.get('pageSize')!) : 5
  const pageNumber =
    searchParams.get('pageNumber') !== null ? parseInt(searchParams.get('pageNumber')!) : 1
  const [pagesTotal, setPagesTotal] = useState(0)

  const [total, setTotal] = useState(0)

  const [showModalImport, setShowModalImport] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [modalAlert, setModalAlert] = useState<IModalAlert | undefined>(undefined)

  const [situationFilterOptions, setSituationFilterOptions] = useState<IFilter[]>([] as IFilter[])
  const [perfilFilterOptions, setPerfilFilterOptions] = useState<IFilter[]>([] as IFilter[])

  const [situationFilter, setSituationFilter] = useState<OptionInput>(empytFilters)
  const [perfilFilter, setPerfilFilter] = useState<OptionInput>(empytFilters)
  const [isSearchingPending, setIsSearchingPending] = useState(false)

  const [pendingUserInfo, setPendingUserInfo] = useState<Metric[]>([])
  const [quantityUserInfo, setQuantityPendingUserInfo] = useState<Metric[]>([])

  function applyFilters() {
    const filtro = new Filtros({
      situacao: situationFilter.value,
      perfil: perfilFilter.value,
    })

    setFiltros({
      perfil: isSearchingPending ? null : filtro.perfil,
      situacao: filtro.situacao,
      pesquisadorPendente: isSearchingPending,
      numeroPagina: pageNumber,
      tamanhoPagina: pageSize,
    })

    searchParams.set('pageNumber', '1')
    setSearchParams(searchParams, {replace: false})
  }

  const cleanFilters = () => {
    setIsSearchingPending(false)
    setFiltros(empytFiltros)
    setSituationFilter(empytFilters)
    setPerfilFilter(empytFilters)
  }

  function handleToggleUserActive(row: UsuarioModel) {
    const action = async () => {
      setIsLoading(true)
      try {
        await toogleUserActiveStatus(row.codigo, row?.statusNome === 'ATIVO' ? false : true)

        confirmSuccess(`Usuário ${row.nome} atualizado com sucesso`)

        getUsuarios()
      } catch (error: any) {
        confirmError(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    setModalAlert({
      title: `${row?.statusNome === 'ATIVO' ? 'Inativar' : 'Ativar'} usuário`,
      content: `Deseja realmente ${
        row?.statusNome === 'ATIVO' ? 'inativar' : 'ativar'
      } o usuário: ${row ? row.nome : ''}?`,
      action,
    })
  }

  function sendPassword(row: UsuarioModel) {
    const action = async () => {
      setIsLoading(true)
      try {
        await ForgetPassword(row.email)

        confirmSuccess(`Foi enviado um e-mail para ${row.email} com sua senha temporária.`)
      } catch (error: any) {
        confirmError(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    setModalAlert({
      title: 'Aviso!',
      content: `Deseja reenviar uma senha temporária para o usuário ${row.nome}? `,
      action,
    })
  }

  function confirmSuccess(message: string) {
    setModalAlert(undefined)
    useAlert(message, 'Success')
  }

  function confirmError(message: string) {
    setModalAlert(undefined)
    useAlert(message, 'Error')
  }

  const handleGetData = async (filtros: GetUsersData) => {
    try {
      setIsLoading(true)
      const res = await getUsers(filtros)

      if (res) {
        const {usuarios, perfis, status, userCreationSelectInfos, totalDePaginas} = res

        setUserCreationSelectInfos(userCreationSelectInfos)
        setPagesTotal(totalDePaginas)
        setUsers(usuarios)

        setSituationFilterOptions(
          status.map((item) => {
            return {label: item.nome, value: item.codigo}
          })
        )

        setPerfilFilterOptions(
          perfis.map((item) => {
            return {label: item.nome, value: item.codigo}
          })
        )
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      setIsLoading(false)
    }
  }

  const getUsuarios = (o?: GetUsersData) => {
    if (o) {
      handleGetData(o)
    } else {
      const o = filtros

      handleGetData(o)
    }
  }

  const handlerHeaderColumnErrors = (erros: any) => {
    let err: errorMessage = []

    if (erros?.cidades_custo?.length > 0) {
      const preFix = 'As seguintes colunas na aba de cidades_custo não existem '
      const columns = erros.cidades_custo

      err.push({columns, preFix})
    }
    if (erros?.modelo_usuario?.length > 0) {
      const preFix = 'As seguintes colunas na aba de modelo_usuario não existem '
      const columns = erros.modelo_usuario

      err.push({columns, preFix})
    }

    return err
  }

  const onImportFile = async (file: File) => {
    try {
      const res = await ImportFileUsuario(file, false)

      if (res) {
        const {excelError, excelSucces, headersError} = res

        const excel = excelSucces as IUser[]

        if (excel.length > 0) {
          getUsuarios(empytFiltros)
          useAlert(`${excel.length} Usuários criados com sucesso`, 'Success')
        }

        const userColumns = [
          {name: 'NOME', accessor: 'nome', color: 'green'},
          {name: 'EMAIL', accessor: 'email'},
          {name: 'GRUPO DE ACESSO', accessor: 'perfil'},
          {name: 'TELEFONE', accessor: 'telefone'},
          {name: 'CPF', accessor: 'cpf'},
          {name: 'RG', accessor: 'rg'},
          {name: 'DATA EMISSAO RG', accessor: 'dataEmissao'},
          {name: 'ORGAO EMISSOR RG', accessor: 'orgaoEmissor'},
          {name: 'DATA DE NASCIMENTO', accessor: 'dataNascimento'},
          {name: 'NACIONALIDADE', accessor: 'nacionalidade'},
          {name: 'ESTADO CIVIL', accessor: 'estadoCivil'},
          {name: 'GENERO', accessor: 'genero'},
          {name: 'CEP', accessor: 'cep'},
          {name: 'BAIRRO', accessor: 'bairro'},
          {name: 'CIDADE', accessor: 'cidade'},
          {name: 'UF', accessor: 'uf'},
          {name: 'LOGRADOURO', accessor: 'logradouro'},
          {name: 'NUMERO', accessor: 'numero'},
          {name: 'COMPLEMENTO', accessor: 'complemento'},
          {name: 'CODIGO BANCO', accessor: 'codigoBanco'},
          {name: 'TIPO CONTA', accessor: 'tipoConta'},
          {name: 'AGENCIA', accessor: 'agencia'},
          {name: 'CONTA', accessor: 'conta'},
          {name: 'CHAVE PIX', accessor: 'chavePix'},
        ]

        const cityColumns = [
          {name: 'Cidade', accessor: 'cidade'},
          {name: 'UF', accessor: 'uf'},
          {name: 'Valor', accessor: 'valor'},
          {name: 'CPF DO PESQUISADOR', accessor: 'cpfPesquisador'},
          {name: 'CPF/CNPJ CENTRO DE CUSTO', accessor: 'cpfOuCnpjCentro'},
        ]

        const errorsMessages = handlerHeaderColumnErrors(headersError)

        return {
          columns: [
            {
              name: 'Usuários',
              data: userColumns,
            },
            {
              name: 'Cidades Custo',
              data: cityColumns,
            },
          ],
          excelSuccess: {Usuários: excelError.usuarios, 'Cidades Custo': excelError.cidadesValor},
          errorsMessages,
        }
      }
    } catch (error: any) {
      useAlert(
        `Houve um erro ao processar esse excel, por favor verifique se ele segue o modelo_usuario.`,
        'Error'
      )
    }

    return {
      columns: [],
      excelSuccess: {},
      errorsMessages: [],
    }
  }

  const getUserQuantitative = async () => {
    try {
      const res = await GetUserQuantitative()

      if (res) {
        const {
          pesquisadoresConcluidos,
          pesquisadoresPendentes,
          ativos,
          bloqueados,
          cancelados,
          expirados,
          total,
        } = res

        setPendingUserInfo([
          {label: 'Concluídos', value: pesquisadoresConcluidos},
          {label: 'Pendentes', value: pesquisadoresPendentes},
        ])

        setQuantityPendingUserInfo([
          {label: 'Ativos', value: ativos},
          {label: 'Cancelados', value: cancelados},
          {label: 'Bloqueados', value: bloqueados},
          {label: 'Expirados', value: expirados},
        ])

        setTotal(total)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  useEffect(() => {
    getUsuarios()
  }, [filtros])

  useEffect(() => {
    setFiltros((oldState) => ({
      ...oldState,
      numeroPagina: pageNumber,
      tamanhoPagina: pageSize,
    }))
  }, [searchParams])

  useEffect(() => {
    getUserQuantitative()
  }, [users])

  return (
    <>
      <ModalAlert
        isLoading={isLoading}
        title={modalAlert?.title}
        content={modalAlert?.content}
        onConfirm={modalAlert?.action}
        onShow={Boolean(modalAlert)}
        onHide={() => setModalAlert(undefined)}
      />

      <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
        {quantityUserInfo.length > 0 && (
          <div className='d-flex justify-content-start gap-10'>
            <CardsWidget
              title='Usuários'
              countTotal={total}
              itens={quantityUserInfo}
              bgColor='light-primary'
              textColor='primary'
            />
          </div>
        )}

        {pendingUserInfo.length > 0 && (
          <div className='d-flex justify-content-start gap-10'>
            <CardsWidget
              title='Pesquisadores pendentes'
              countTotal={pendingUserInfo[1].value}
              itens={pendingUserInfo}
              bgColor='light-danger'
              textColor='danger'
              colors={['#008FFB', '#F1416C']}
            />
          </div>
        )}
      </div>

      <div className={'card'}>
        <div className='card-body py-3'>
          <UserTable
            pagesTotal={pagesTotal}
            isLoading={isLoading}
            getUserQuantitative={getUserQuantitative}
            userCreationSelectInfos={userCreationSelectInfos}
            getUsuarios={getUsuarios}
            users={users}
            setUsers={setUsers}
            setOpenModalImport={setShowModalImport}
            handleToggleUserActive={handleToggleUserActive}
            applyFilters={applyFilters}
            cleanFilters={cleanFilters}
            sendPassword={sendPassword}
            filters={[
              {
                label: 'Situação',
                options: situationFilterOptions,
                value: situationFilter,
                setSelectedOption: setSituationFilter,
              },
              {
                label: 'Perfil',
                options: perfilFilterOptions,
                value: perfilFilter,
                setSelectedOption: setPerfilFilter,
                disabled: isSearchingPending,
              },
              {
                type: 'switch',
                label: 'Somente pesquisadores pendente',
                value: isSearchingPending,
                setSelectedOption: setIsSearchingPending,
              },
            ]}
          />
        </div>
      </div>

      {showModalImport && (
        <ImportModal
          title='Upload Novos Usuários'
          modelFileName='modelo_usuario'
          excelModel={IMPORT_EXCEL_MODEL}
          onClose={() => setShowModalImport(false)}
          onImportFile={onImportFile}
          sheets={['Usuários', 'Cidades Custo']}
          instructions={[
            'Os campos “GRUPO DE ACESSO”, “NOME COMPLETO” e “EMAIL”  são obrigatórios;',
            'O campo telefone deve conter o DDD + número;',
            'Não é permitido a reutilização de e-mail, ou seja, um e-mail por usuário;',
            'As colunas devem seguir o mesmo padrão do arquivo exemplo “modelo_usuario.xlsx“. Adição de novas colunas serão ignoradas.',
          ]}
        />
      )}
    </>
  )
}

export type Metric = {
  label: string
  value: number
}
