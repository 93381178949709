/* eslint-disable react-hooks/exhaustive-deps */
import {Dispatch, RefObject, SetStateAction, useEffect, useMemo, useState} from 'react'

import {Checkbox} from '@mui/material'

import {
  Column,
  ColumnFilter,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import {rankItem} from '@tanstack/match-sorter-utils'

import {FaArrowLeftLong, FaArrowRightLong} from 'react-icons/fa6'
import {TableFilterPontoColeta} from 'src/app/pages/planejamento-execucao/rota/components/EditRouteModal/components/AddPontoColetaTable'
import {
  PaginationButtonStyled,
  SearchInput,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderWrapper,
} from './styles'

const TableMaterial = ({
  rows,
  selectedRows,
  isSelected,
  onClick,
  onClickAll,
  headCells,
  isSelectable = true,
  onClickRemove = () => {},
  inputRef = null,
  filledFilters,
  setCustomFilters,
  pageIndex,
  setPageIndex,
  totalPages,
}: Props) => {
  const [data, setData] = useState(rows)
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const generateTableFields = () => {
    const fields: any[] = []

    headCells.map((item) => {
      return fields.push({
        accessorKey: item.id,
        header: item.label,
        cell: (info: any) => info.getValue(),
        footer: (props: any) => props.column.id,
      })
    })

    return fields
  }

  const columns = useMemo(
    () => [
      {
        header: 'header',
        columns: generateTableFields(),
      },
    ],
    []
  )

  const fuzzyFilter = (row: any, columnId: any, value: any, addMeta: any) => {
    const itemRank = rankItem(row.getValue(columnId), value)

    addMeta({
      itemRank,
    })

    return itemRank.passed
  }

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    manualPagination: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  })

  const hasFilters = table.getState().columnFilters.length > 0

  const numSelected = selectedRows.length

  const onSelectAllClick = onClickAll

  const checkedAll =
    (hasFilters && numSelected === Object.values(table.getRowModel().rowsById).length) ||
    (!hasFilters && data.length === numSelected)

  const formatRows = () => {
    const newRows: any[] = []

    Object.values(table.getRowModel().rowsById).map((item) => newRows.push(item.original))

    if (hasFilters) {
      return newRows
    } else {
      return rows
    }
  }

  useEffect(() => {
    setData(rows)
  }, [rows])

  return (
    <div
      style={{
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
      }}
    >
      <TableHeader>
        <span>{numSelected} Selecionado(s)</span>
      </TableHeader>
      <div className='h-2' />
      <TableContainer>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            if (headerGroup.headers[0].column.id === 'header') {
              return null
            }
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  if (index === 0) {
                    return (
                      <th style={{cursor: 'pointer'}} key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <TableHeaderWrapper>
                            {isSelectable && (
                              <Checkbox
                                inputRef={inputRef}
                                data-test='selectAll'
                                checked={checkedAll}
                                onChange={(e) => onSelectAllClick(e, formatRows())}
                                inputProps={{
                                  'aria-label': 'select all desserts',
                                }}
                              />
                            )}

                            <div style={{width: '100%'}}>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                                {{
                                  asc: '🔼',
                                  desc: ' 🔽',
                                }[header.column.getIsSorted() as string] ?? ' 💡'}
                              </div>
                              {header.column.getCanFilter() ? (
                                <div>
                                  <TableFilter
                                    column={header.column}
                                    table={table}
                                    filledFilters={filledFilters}
                                    setFiltersCustom={setCustomFilters}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </TableHeaderWrapper>
                        )}
                      </th>
                    )
                  }

                  return (
                    <th style={{cursor: 'pointer'}} key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? ' 💡'}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <TableFilter
                                column={header.column}
                                table={table}
                                filledFilters={filledFilters}
                                setFiltersCustom={setCustomFilters}
                              />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            )
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
            const isItemSelected = isSelected(row.original)

            const labelId = `enhanced-table-checkbox-${index}`

            return (
              <tr
                data-test='table-list'
                style={{
                  backgroundColor: isItemSelected ? '#EEF7FE' : '#fff',
                  cursor: 'pointer',
                  padding: 0,
                }}
                className='row-td'
                key={row.id}
                onClick={(e) => onClick(row.original)}
              >
                {isSelectable && (
                  <Checkbox
                    key={index}
                    color='primary'
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                )}

                {row.getVisibleCells().map((cell) => {
                  return (
                    <>
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    </>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </TableContainer>
      <TableFooter>
        <div className='content'>
          <span className='flex items-center gap-1'>
            <strong>{totalPages === 0 ? '0 de 0' : `${pageIndex + 1} de ${totalPages}`}</strong>
          </span>

          <div>
            <PaginationButtonStyled
              disabled={pageIndex <= 0}
              onClick={() => setPageIndex((oldState) => oldState - 1)}
            >
              <FaArrowLeftLong style={{fontSize: '22px'}} />
            </PaginationButtonStyled>
            <PaginationButtonStyled
              disabled={pageIndex + 1 >= totalPages}
              onClick={() => setPageIndex((oldState) => oldState + 1)}
            >
              <FaArrowRightLong style={{fontSize: '22px'}} />
            </PaginationButtonStyled>
          </div>
        </div>
      </TableFooter>
    </div>
  )
}

function DebouncedInput({value: initialValue, onChange, debounce = 500, ...props}: any) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <SearchInput {...props} value={value} onChange={(e: any) => setValue(e.target.value)} />
}

function TableFilter({
  column,
  table,
  filledFilters = [],
  setFiltersCustom,
}: {
  column: Column<any>
  table: any
  filledFilters?: FilledFilter[]
  setFiltersCustom: Dispatch<SetStateAction<TableFilterPontoColeta>>
}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  const [filterValue, setFilterValue] = useState(() => {
    const foundItem = filledFilters.find((item) => column.id === item.id)
    return foundItem?.value
  })

  const columnFilterValue = column.getFilterValue() as string

  const sortedUniqueValues: any[] = useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return (
    <div style={{position: 'relative'}}>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={columnFilterValue || filterValue}
        onChange={(value: any) => {
          column.setFilterValue(value)
          setFiltersCustom((o) => ({...o, [column.id]: value}))
        }}
        placeholder={`Procurar... (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      {(columnFilterValue || filterValue) && (
        <span
          onClick={() => {
            column.setFilterValue('')
            setFilterValue('')
            setFiltersCustom((o) => ({...o, [column.id]: null}))
          }}
          style={{position: 'absolute', right: 10, bottom: 10}}
        >
          ❌
        </span>
      )}
    </div>
  )
}

type Props = {
  rows: any[]
  selectedRows: any[]
  isSelected: (item: any) => boolean
  onClick: (item: any) => void
  onClickAll: (item: any, list: any[]) => void
  headCells: HeadCells[]
  isSelectable?: boolean
  onClickRemove?: () => void
  inputRef?: RefObject<HTMLInputElement> | null
  filledFilters?: FilledFilter[]
  setCustomFilters: Dispatch<SetStateAction<TableFilterPontoColeta>>
  pageIndex: number
  setPageIndex: Dispatch<SetStateAction<number>>
  totalPages: number
}

export type FilledFilter = {
  id: string
  value: string | null
}

export type HeadCells = {
  id: string
  numeric: boolean
  disablePadding: boolean
  label: string
}

export default TableMaterial
