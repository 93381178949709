import Button from 'src/app/components/Button'
import Label from 'src/app/components/Label'
import {Select} from 'src/app/components/Select/Select'
import Switch from 'src/app/components/Form/Switch'
import {TableFilters} from 'src/app/components/TablePainel/table-painel'
import InputText from 'src/app/components/Form/InputText'

type Props = {
  cleanFilters: () => void
  applyFilters: () => void
  filter: TableFilters[]
}

const UserFilter = ({cleanFilters, applyFilters, filter}: Props) => {
  return (
    <>
      {filter.map((item) => {
        if (item.type === 'switch')
          return (
            <div className='pb-4'>
              <Switch
                checked={Boolean(item.value)}
                //@ts-ignore
                onChange={(e) => item.setSelectedOption(e.target.checked)}
              >
                {item.label}
              </Switch>
            </div>
          )

        if (item.type === 'text')
          return (
            <div className='pb-4'>
              <InputText
                height={80}
                placeholder={item.placeholder}
                label={item.label}
                //@ts-ignore
                value={item.value}
                //@ts-ignore
                onChange={(e) => item.setSelectedOption(e.target.value)}
              />
            </div>
          )

        return (
          <div className='pb-4'>
            <Label>{item.label}</Label>
            <Select
              isMulti={item.isMulti || false}
              setSelectedOption={item.setSelectedOption}
              value={
                item.disabled
                  ? {label: item.disabledLabel || 'Pesquisador', value: item.disabledValue || null}
                  : item.value
              }
              options={item.options}
              isDisabled={item.disabled}
            />
          </div>
        )
      })}
      <div className='d-flex gap-5'>
        <Button w100 onClick={applyFilters}>
          Filtrar
        </Button>
        <Button w100 onClick={cleanFilters}>
          Limpar
        </Button>
      </div>
    </>
  )
}

export default UserFilter
