import StatusTag from 'src/app/components/StatusTag'
import {formatCPF} from 'src/app/utils/Formats'
import {IRoute} from './Types'

export default class RotaModel {
  private _codigo: number
  private _nome: string
  private _ativo: boolean

  private _dataCriacao?: string
  private _dataExpiracao: string
  private _dataInicio: string
  private _horaFim: string
  private _horaInicio: string

  private _pendencia: number
  private _antecedencia: number
  private _intervalo: number
  private _frequencia: number

  private _cidade: string
  private _uf: string

  private _nomePesquisador: string
  private _cpfPesquisador: string

  private _contagemPontosColeta: number

  private _domingo: boolean
  private _segunda: boolean
  private _terca: boolean
  private _quarta: boolean
  private _quinta: boolean
  private _sexta: boolean
  private _sabado: boolean

  private _temPendencia: boolean
  private _temPendenciaSincronizacao: boolean

  constructor({
    codigo,
    nome,
    ativo,
    dataCriacao,
    antecedencia,
    intervalo,
    cidade,
    contagemPontosColeta,
    dataExpiracao,
    dataInicio,
    frequencia,
    horaFim,
    horaInicio,
    pendencia,
    nomePesquisador,
    cpfPesquisador,
    uf,
    domingo,
    quarta,
    quinta,
    sabado,
    segunda,
    sexta,
    terca,
    temPendencia,
    temPendenciaSincronizacao,
  }: IRoute) {
    this._codigo = codigo
    this._nome = nome
    this._ativo = ativo

    this._dataCriacao = dataCriacao
    this._dataExpiracao = dataExpiracao
    this._dataInicio = dataInicio
    this._horaFim = horaFim
    this._horaInicio = horaInicio

    this._antecedencia = antecedencia
    this._pendencia = pendencia
    this._intervalo = intervalo
    this._frequencia = frequencia

    this._cidade = cidade
    this._uf = uf

    this._contagemPontosColeta = contagemPontosColeta

    this._nomePesquisador = nomePesquisador
    this._cpfPesquisador = cpfPesquisador

    this._domingo = domingo
    this._segunda = segunda
    this._terca = terca
    this._quarta = quarta
    this._quinta = quinta
    this._sexta = sexta
    this._sabado = sabado

    this._temPendencia = temPendencia
    this._temPendenciaSincronizacao = temPendenciaSincronizacao
  }

  get codigo() {
    return this._codigo
  }

  get nome() {
    return this._nome
  }

  get nomePesquisador() {
    return this._nomePesquisador
  }

  get cpfPesquisador() {
    return formatCPF(this._cpfPesquisador)
  }

  get frequencia() {
    return this._frequencia
  }

  get intervalo() {
    return this._intervalo
  }

  get dataInicio() {
    return this._dataInicio
  }
  get dataCriacao() {
    return this._dataCriacao
  }
  get dataExpiracao() {
    return this._dataExpiracao
  }
  get horaFim() {
    return this._horaFim
  }

  get horaInicio() {
    return this._horaInicio
  }

  get contagemPontosColeta() {
    return this._contagemPontosColeta
  }

  get ativo() {
    return this._ativo
  }

  get status() {
    return this._ativo ? 'Ativo' : 'Inativo'
  }

  get antecedencia() {
    return this._antecedencia
  }

  get pendencia() {
    return this._pendencia
  }

  get uf() {
    return this._uf
  }

  get cidade() {
    return this._cidade
  }

  get cidadeUf() {
    return `${this._cidade}/${this._uf}`
  }

  get temPendencia() {
    return this._temPendencia
  }

  get temPendenciaSincronizacao() {
    return this._temPendenciaSincronizacao ? 'Sim' : 'Não'
  }

  get agendaDaSemana() {
    const days = []

    if (this._domingo) days.push('Dom')
    if (this._segunda) days.push('Seg')
    if (this._terca) days.push('Ter')
    if (this._quarta) days.push('Qua')
    if (this._quinta) days.push('Qui')
    if (this._sexta) days.push('Sex')
    if (this._sabado) days.push('Sab')

    return days.join('/')
  }

  //adicionar funcionalidade de clicar para abrir o modal de erros da sincronizacao
  get statusPendencia() {
    return (
      <div style={{display: 'flex', gap: 5}}>
        {this._temPendencia && <StatusTag name='Pesquisador' />}

        {this._temPendenciaSincronizacao && <StatusTag name='Erro Sincronização' type='danger' />}

        {!this._temPendencia && !this._temPendenciaSincronizacao && (
          <StatusTag type='info' name='Concluído' />
        )}
      </div>
    )
  }
}
