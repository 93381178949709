/* eslint-disable react-hooks/rules-of-hooks */
import React, {useState} from 'react'

import CardHeader from '../CardHeader'
import ImageModal from './components/ImageModal'

import {getImageFromBucket} from 'src/app/utils/API'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'

import {Container, DistanceText, InfoQA} from './styles'
import {useService} from 'src/app/hooks/useService'
import {UpdateOccurrencePhoto} from 'src/app/repositories/Atendimentos'
import {useAlert} from 'src/app/components/Alert/Alert'
import {FotoColeta} from 'src/app/model/Validacao/Type'

import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import ImagePlaceholder from '../../../../../../assets/imagens/placeholder.jpg'
import {formatCnpj} from 'src/app/utils/Formats'
import AnimatedRadio from 'src/app/components/AnimatedRadio'

type CardProps = {
  data?: ValidacaoModel
  children: React.ReactNode
  title: string
  className?: string
  cnpj?: string
  numeroAnp?: string
  hasSubImage?: boolean
  isQA?: boolean
  isPP?: boolean
  isPR?: boolean
  showDistance?: boolean
  distance?: number
  foto: FotoColeta
  fotoHomo?: string
  currentOcorrenciaFoto?: string | number | undefined
  handleOnClickChangeOcorrencia?(value: string | number): void
  currentSituacaoConstatada?: string | number | undefined
  handleOnClickChangeSituacaoConstatada?(value: string | number): void
  setIsWarning: any
}

const Card = ({
  children,
  title,
  className,
  hasSubImage = false,
  isQA = false,
  isPR = false,
  isPP = false,
  showDistance = true,
  distance = 0,
  cnpj = '',
  numeroAnp = '',
  foto,
  data,
  fotoHomo,
  setIsWarning,
  currentOcorrenciaFoto,
  currentSituacaoConstatada,
  handleOnClickChangeSituacaoConstatada,
}: CardProps) => {
  const [showImageModal, setShowImageModal] = useState(false)
  const [isHomologation, setIsHomologation] = useState(false)

  const handleCloseModal = () => setShowImageModal(false)
  const handleOpenModal = () => setShowImageModal(true)

  const {servicesInfo} = useService()
  const [currentValue, setCurrentValue] = useState<string | number>(currentOcorrenciaFoto || '')
  const ocorrenciaFotoQa = servicesInfo?.ocorrenciasFotos.filter(
    (ocorrencia) => ocorrencia.siglaFoto === 'QA'
  )[0]

  async function handleOnClick(value: string | number) {
    let o: any = {
      codigoFotoAtendimento: foto.codigo,
      codigoOcorrenciaSelecionada: value as number,
    }

    if (value === currentValue) {
      setCurrentValue('')
      o = {
        codigoFotoAtendimento: foto.codigo,
        codigoOcorenciaSelecionada: null,
      }
    } else {
      setCurrentValue(value)
    }

    try {
      const res = await UpdateOccurrencePhoto<string>(o)

      if (res) {
        useAlert(res, 'Success')
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  return (
    <Container className={className}>
      <CardHeader
        showDistance={showDistance}
        title={title}
        isPR={isPR}
        isQA={isQA}
        isPP={isPP}
        data={data}
        foto={foto}
        setIsWarning={setIsWarning}
        currentSituacaoConstatada={currentSituacaoConstatada}
        handleOnClickChangeSituacaoConstatada={handleOnClickChangeSituacaoConstatada}
      />
      {isQA && (
        <InfoQA>
          <div className='firstRowQA'>
            <div>
              <span className='key'>número anp: </span>
              <span className='value'>{numeroAnp}</span>
            </div>
            <div>
              <span className='key'>cnpj: </span>
              <span className='value'>{formatCnpj(cnpj)}</span>
            </div>
          </div>
          {ocorrenciaFotoQa && (
            <div className='secondRowQA'>
              <AnimatedRadio
                label={ocorrenciaFotoQa.valor}
                value={ocorrenciaFotoQa.codigo}
                currentValue={currentValue}
                onClick={() => handleOnClick(ocorrenciaFotoQa.codigo)}
              />
            </div>
          )}
        </InfoQA>
      )}
      {showImageModal && !isHomologation && (
        <ImageModal
          titleFirstFoto={
            isPR
              ? 'Foto PR (Atual)'
              : isQA
              ? 'Foto QA (Atual)'
              : isPP
              ? 'Foto PP (Atual)'
              : 'Sem Titulo'
          }
          firstFoto={getImageFromBucket(foto?.fileName)}
          onclose={handleCloseModal}
        />
      )}
      {showImageModal && isHomologation && (
        <ImageModal
          titleFirstFoto='Foto PR (Atual)'
          firstFoto={getImageFromBucket(foto?.fileName)}
          titleSecondFoto='Foto PR (Homologada)'
          secondFoto={getImageFromBucket(fotoHomo!)}
          onclose={handleCloseModal}
        />
      )}

      <div className='img'>
        <div className='distance'>
          <p>Distância</p>
          <DistanceText value={distance}>{distance.toFixed(0)} M</DistanceText>
        </div>
        {hasSubImage && (
          <div className='float-img'>
            <img
              onClick={() => {
                if (fotoHomo) {
                  setIsHomologation(true)
                  handleOpenModal()
                }
              }}
              src={getImageFromBucket(fotoHomo!)}
              alt=''
            />
          </div>
        )}
        <div className='image'>
          <LazyLoadImage
            onClick={() => {
              handleOpenModal()
              setIsHomologation(false)
            }}
            src={
              getImageFromBucket(foto?.fileName) ||
              'https://www.russorizio.com/wp-content/uploads/2016/07/ef3-placeholder-image.jpg'
            }
            placeholderSrc={ImagePlaceholder}
            alt=''
            effect='blur'
          />
        </div>
      </div>

      {children}
    </Container>
  )
}

export default Card
