import styled, {css} from 'styled-components'

type ContainerProps = {
  show: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .line-name {
    font-size: 22px;
    color: #2b2b2b;
    font-family: Poppins;

    margin-left: 20px;
    margin-right: 20px;
  }

  .line {
    flex: 1;

    height: 1px;

    /* background-color: #2b2b2b; */
    border: 1px dashed #2b2b2b;
  }

  .table-container {
    max-height: 354px;
    overflow-y: auto;

    margin-top: 10px;
  }

  .title {
    font-size: 18px;
  }
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  background-color: #fff;

  width: 90%;

  margin: 0 auto;

  main {
    display: flex;
    flex-direction: column;

    gap: 10px;

    padding: 16px;

    width: 100%;

    ${({show}) =>
      show
        ? css`
            overflow: inital;
          `
        : css`
            overflow: hidden;
          `}
  }

  header {
    display: flex;
    align-items: center;

    width: 100%;

    cursor: pointer;

    padding: 16px;

    height: 50px;

    .accordion-arrow {
      margin-left: auto;
    }

    svg {
      z-index: 0 !important;
    }

    p {
      margin: 0;
      margin-left: 8px;
    }
  }

  .checkbox {
    font-family: Poppins;
    font-size: 16px;

    margin-left: 5px;
  }

  .box-container {
    display: flex;
    flex: 2;
    flex-direction: column;

    justify-content: center;
  }
`
