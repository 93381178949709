import { handlerResult } from '../utils/API';
import { ExclusaoModel } from '../model/ManutencaoExclusao/ExclusaoModel';
import { 
    IDetailFotografia,
    IDetailManutencaoExclusao, 
    IExclusao, 
    IFiltersSolicitacao, 
    ISolicitacaoRastreacao
} from '../model/ManutencaoExclusao/Type';
import api from '../config';

const serialize = (item: IExclusao) => {
    return new ExclusaoModel(item);
}

const serializeSolicitacoes = (itens: IExclusao[]) => {
    let model = []

    for (let item of itens) {
        model.push(serialize(item))
    }

    return model
}

export async function GetSolicitacaoExclusao(filters: IFiltersSolicitacao) {
    const response = await api.post('manutencaoDeExclusao/listar', filters);
    const { total, solicitacoes, pesquisadores, statusExclusao } = await handlerResult(response.data);

    return {
        total,
        solicitacoes: serializeSolicitacoes(solicitacoes),
        pesquisadores,
        statusExclusao
    } as IDetailManutencaoExclusao;
}

export async function GetFotosHomologacao(codigo: number) {
    const response = await api.post('manutencaoDeExclusao/exibirHomologacao', { codigoAtendimento: codigo });
    const { fotoPanoramica, fotoHomologada } = await handlerResult(response.data);

    return {
        panoramica: fotoPanoramica,
        homologado: fotoHomologada
    } as IDetailFotografia;
}

export async function UpdateDeletePostoBase(codigos: number[]) {
    const response = await api.post('manutencaoDeExclusao/atualizarParaRemovido', { codigoSolicitacoes: codigos });
    const { SolicitacoesAtualizadas, SolicitacoesNaoAtualizadas } = await handlerResult(response.data);

    return {
        SolicitacoesAtualizadas, 
        SolicitacoesNaoAtualizadas
    } as ISolicitacaoRastreacao;
}

export async function UncheckSolicitacaoPosto(codigos: number[]) {
    const response = await api.post('manutencaoDeExclusao/atualizarParaDesmarcado', { codigoSolicitacoes: codigos });
    const { SolicitacoesAtualizadas, SolicitacoesNaoAtualizadas } = await handlerResult(response.data);

    return {
        SolicitacoesAtualizadas, 
        SolicitacoesNaoAtualizadas
    } as ISolicitacaoRastreacao;
}