import * as Yup from 'yup'
import {IntlShape} from 'react-intl'

export const codigoValidation = (intl: IntlShape) =>
  Yup.string()
    .min(3, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH'}, {name: 'Código', min: '3'}))
    .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Código'}))
    .test('valor é um número', 'Digite um valor numérico', (value) => {
      const numericValue = Number(value)

      if (value !== undefined) {
        return !isNaN(numericValue)
      } else {
        return true
      }
    })

export const nomeValidation = (intl: IntlShape) =>
  Yup.string().required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Nome'}))

export const execucaoValidation = (intl: IntlShape) =>
  Yup.object().shape({
    label: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Execução'})
    ),
  })

export const ufValidation = (intl: IntlShape) =>
  Yup.object().shape({
    label: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'UF'})
    ),
    value: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'UF'})
    ),
  })

export const cidadeValidation = (intl: IntlShape) =>
  Yup.object().shape({
    label: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Cidade'})
    ),
    value: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Cidade'})
    ),
  })
