import {useState} from 'react'

import {Link} from 'react-router-dom'

import * as Yup from 'yup'
import {useFormik} from 'formik'

import {useIntl} from 'react-intl'

import {ForgetPassword} from 'src/app/repositories/Login'
import Button from 'src/app/components/Button'
import Form from 'src/app/components/Form'
import InputText from 'src/app/components/Form/InputText'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'AUTH.VALIDATION.EMAIL'}))
      .min(3, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH'}, {name: 'Email', min: '3'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Email', max: '50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Email'})),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const response = await ForgetPassword(values.email)
        setStatus({success: true, message: response})
      } catch (error: any) {
        setStatus({success: false, message: error.message})
        setSubmitting(false)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <Form
      title='Esqueceu sua senha ?'
      subtitle={intl.formatMessage({id: 'AUTH.FORGOT.DESC'})}
      formik={formik}
    >
      <InputText
        label='Email'
        placeholder='Digite seu email...'
        type='email'
        formik={{
          formikProp: 'email',
          formikValues: formik,
        }}
      />

      <div style={{gap: 20}} className='d-flex flex-wrap justify-content-center pb-lg-0 mt-10'>
        <Button disabled={formik.isSubmitting || !formik.isValid} loading={loading} color='primary'>
          Enviar
        </Button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Voltar para login
          </button>
        </Link>
      </div>
    </Form>
  )
}
