import styled from 'styled-components'

export const Container = styled.div`
  width: 600px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`

export const TitleContainer = styled.div`
  padding: 8px;
  border-bottom: 1px solid #e1e1e1;
  padding: 24px;
`

export const DatePickerContainer = styled.div`
  padding: 24px;
  display: flex;
  justify-content: space-between;

  button {
    border-radius: 4px;
    padding: 8px;
  }
`
