import React, {ReactNode, SetStateAction, Dispatch} from 'react'

import {Container, ContainerHeader} from './styles'
import {ArrowDownIcon, OpenEyeIcon, CloseEyeIcon} from 'src/app/utils/Icons'

const ContainerCard = ({title, isBlur = false, children, setHidden, hidden}: Props) => {
  return (
    <Container>
      <ContainerHeader>
        <span className='icon'>
          <ArrowDownIcon size={30} />
        </span>
        <span>{title}</span>

        {isBlur && setHidden && (
          <button onClick={() => setHidden((oldState) => !oldState)}>
            {hidden ? <OpenEyeIcon size={30} /> : <CloseEyeIcon size={30} />}
          </button>
        )}
      </ContainerHeader>
      <div className='container-body'>{children}</div>
    </Container>
  )
}

type Props = {
  title: string
  isBlur?: boolean
  children: ReactNode
  hidden?: any
  setHidden?: Dispatch<SetStateAction<boolean>>
}

export default ContainerCard
