import AnimatedModal from 'src/app/components/AnimatedModal'
import TablePainel from 'src/app/components/TablePainel'
import {TableColumn, TableExportProps} from 'src/app/components/TablePainel/table-painel'
import {Container} from './style'
import {IDetailUsuarioMonitoramento} from 'src/app/repositories/Monitoramento'

export default function DetailUserModal({data, closeModal}: Props) {
  const tableColumns: TableColumn[] = [
    {
      name: 'Código Atendimento',
      accessor: 'codigoAtendimento',
      hidden: true,
    },
    {
      name: 'Código Triad',
      accessor: 'codigoTriad',
    },
    {
      name: 'Fonte',
      accessor: 'fonte',
    },
    {
      name: 'Codigo Externo',
      accessor: 'codigoExterno',
    },
    {
      name: 'Data Execução',
      accessor: 'dataExecucao',
    },
    {
      name: 'Data Liberação',
      accessor: 'dataValidacao',
    },
    {
      name: 'UF',
      accessor: 'uf',
    },
    {
      name: 'Status',
      accessor: 'status',
    },
  ]

  const tableExports: TableExportProps = {
    pdf: {
      fileName: 'liberacao_por_assistente',
    },
    excel: {
      fileName: 'liberacao_por_assistente',
      sheetName: 'liberacao_por_assistente',
    },
    csv: {
      fileName: 'liberacao_por_assistente',
    },
  }

  const TableTitle = () => {
    return (
      <div>
        <p>
          Assistente: <span className='text-danger'>{data?.nomeAssistente}</span>
        </p>
        <p>
          Atendimentos Avaliados: <span className='text-danger'>{data?.totalAtendimentos}</span>
        </p>
      </div>
    )
  }

  return (
    <AnimatedModal
      headerTitle='Detalhe assistente'
      containerStyle={{
        width: '1200px',
        maxHeight: '90%',
        height: 'fit-content',
        padding: '16px',
      }}
      onClose={closeModal}
      hasFooter={false}
    >
      <Container>
        <TablePainel
          customTitle={<TableTitle />}
          data={data!.atendimentosValidados}
          columns={tableColumns}
          tableExportProps={tableExports}
          paginate={true}
          createDataButton={false}
        />
      </Container>
    </AnimatedModal>
  )
}

interface Props {
  data: IDetailUsuarioMonitoramento | undefined
  closeModal: () => void
}
