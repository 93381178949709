import moment from "moment";

export function Convert_DDMMYYYY_to_YYYYMMDD( strDate: string ) {
  if (strDate === null) return null;
  const arr = strDate.split('/');
  if (arr.length !== 3) return null;
  return `${arr[2]}-${arr[1]}-${arr[0]}`;
}

export function Convert_YYYYMMDD_to_DDMMYYYY( strDate: string ) {
  if(!strDate) return '';

  try {
      return moment(strDate).format("DD/MM/YYYY");   
  } catch (error) {
      return strDate;
  }
}

export function GetDateNow() {
  const dataAtual = new Date();
  const ano = dataAtual.getFullYear();
  const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
  const dia = String(dataAtual.getDate()).padStart(2, '0');
  const dataFormatada = `${ano}-${mes}-${dia}`;
  return dataFormatada; 
}

export function ConvertDateToStr(date: Date) {
  const ano = date.getFullYear();
  const mes = String(date.getMonth() + 1).padStart(2, '0');
  const dia = String(date.getDate()).padStart(2, '0');
  const dataFormatada = `${ano}-${mes}-${dia}`;
  return dataFormatada;
}