import styled from 'styled-components'
import {animated} from '@react-spring/web'

export const Container = styled(animated.div)`
  display: flex;
  margin: 0 auto;

  width: 100%;
  height: 100%;
  max-width: 1400px;

  gap: 10px;
  padding: 4px 10px;

  overflow: hidden;

  position: relative;

  .animated-div {
    display: flex;

    position: absolute;

    width: 100%;
    height: 100%;

    will-change: transform, opacity;
    -webkit-user-select: none;
    user-select: none;

    .esquerda,
    .direita {
      height: 100%;

      flex: 1;
    }

    .esquerda {
      display: flex;

      background-color: #f4f6fa;

      padding: 10px;
      padding-left: 50px;

      /* width: 60%; */

      img {
        width: 100%;

        object-fit: contain;
      }
    }
    .direita {
      /* width: 40%; */
      padding: 10px;
      padding-right: 50px;

      background-color: #ff3;
    }
  }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    background-color: transparent;
  }
`
