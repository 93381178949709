import {ReactNode} from 'react'
import Button, {ButtonProps} from './Button'

type Props = {
  title?: string
  label?: string
  buttonProps?: ButtonProps
  children: ReactNode
}

export default function Menu({title, label, buttonProps, children}: Props) {
  return (
    <div className='dropdown'>
      <Button {...buttonProps} data-bs-toggle='dropdown' aria-expanded='false'>
        {label}
      </Button>
      <ul className='dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-250px'>
        {title && (
          <>
            <li className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>{title}</div>
            </li>
            <div className='separator mb-3 opacity-75'></div>
          </>
        )}
        {children}
      </ul>
    </div>
  )
}
