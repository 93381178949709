import {useState, useEffect} from 'react'

import {useSpring, useInView} from '@react-spring/web'

import HomologacaoModal from './components/HomologacaoModal'

import {ListAltIcon, MoreOutlineIcon} from 'src/app/utils/Icons'

import {Actions, Container} from './styles'

import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import QAModal from './components/QAModal'
import DuplicateModal from './components/DuplicateModal'
import ChangeModal from './components/ChangeModal'
import {FotoColeta} from 'src/app/model/Validacao/Type'

import {Tooltip as ReactTooltip} from 'react-tooltip'

type CardHeaderProps = {
  title: string
  showDistance?: boolean
  distance?: number
  isPR?: boolean
  isQA?: boolean
  isPP?: boolean
  data?: ValidacaoModel
  foto?: FotoColeta
  setIsWarning: any
  currentSituacaoConstatada?: string | number | undefined
  handleOnClickChangeSituacaoConstatada?(value: string | number): void
}

const CardHeader = ({
  title,
  distance = 0,
  isPR,
  isQA,
  data,
  foto,
  isPP,
  setIsWarning,
  currentSituacaoConstatada,
  handleOnClickChangeSituacaoConstatada,
}: CardHeaderProps) => {
  const isWarning = distance > 300
  const [showHomologacaoModal, setShowHomologacaoModal] = useState(false)
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const [showChangeModal, setShowChangeModal] = useState(false)
  const [showQAModal, setShowQAModal] = useState(false)
  const [ref, isInView] = useInView({
    rootMargin: '-45% 0px -45% 0px',
  })
  const [showActions, setShowActions] = useState(isInView)
  const [runAnimation, setRunAnimation] = useState(false)

  const scaleAnimation = {
    opacity: runAnimation ? 1 : 0,
    config: {
      tension: 300,
      duration: 150,
    },
  }

  const animatedStyle = useSpring(scaleAnimation)

  const handleOpenDuplicateModal = () => {
    handleCloseActions()
    setShowDuplicateModal(true)
  }

  const handleOpenChangeModal = () => {
    handleCloseActions()
    setShowChangeModal(true)
  }

  const handleCloseDuplicateModal = () => setShowDuplicateModal(false)

  const handleCloseChangeModal = () => setShowChangeModal(false)

  const handleOpenActions = () => {
    setRunAnimation((oldState) => !oldState)
    setShowActions(true)
  }

  const handleCloseActions = () => {
    setRunAnimation(false)

    setTimeout(() => {
      setShowActions(false)
    }, 150)
  }

  function useOutside(ref: React.RefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleCloseActions()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutside(ref)

  useEffect(() => {
    setShowActions(isInView)
  }, [isInView])

  return (
    <Container isWarning={isWarning}>
      {showHomologacaoModal && (
        <HomologacaoModal data={data} setShowHomologacaoModal={setShowHomologacaoModal} />
      )}

      {showQAModal && (
        <QAModal
          data={data!}
          setShowQAModal={setShowQAModal}
          foto={foto!.fileName}
          setIsWarning={setIsWarning}
          currentSituacaoConstatada={currentSituacaoConstatada}
          handleOnClickChangeSituacaoConstatada={handleOnClickChangeSituacaoConstatada}
        />
      )}

      {showDuplicateModal && (
        <DuplicateModal
          data={data!}
          codigoFoto={foto!.codigo}
          onClose={handleCloseDuplicateModal}
          setIsWarning={setIsWarning!}
        />
      )}

      {showChangeModal && (
        <ChangeModal
          sigla={isPP ? 'PP' : isQA ? 'QA' : 'PR'}
          data={data!}
          onClose={handleCloseChangeModal}
        />
      )}

      <div className='header'>
        <div className='header-row'>
          {(isPR || isQA) && (
            <ListAltIcon
              onClick={() => {
                if (isPR) {
                  setShowHomologacaoModal(true)
                }
                if (isQA) {
                  setShowQAModal(true)
                }
              }}
              size={20}
              data-tooltip-id={`${isQA ? 'my-tooltip-6' : isPR ? 'my-tooltip-7' : ''}`}
            />
          )}

          <span>{title}</span>

          <MoreOutlineIcon onClick={handleOpenActions} size={20} data-tooltip-id='my-tooltip-8' />

          {showActions && (
            <Actions orientation='right' ref={ref} style={animatedStyle}>
              <p onClick={handleOpenDuplicateModal}>Duplicar</p>
              <p onClick={handleOpenChangeModal}>Trocar</p>
            </Actions>
          )}
        </div>
      </div>
      <ReactTooltip id='my-tooltip-6' place='top-end' content='Detalhes do quadro de aviso' />
      <ReactTooltip id='my-tooltip-7' place='top-end' content='Análise AT. Homologado' />
      <ReactTooltip id='my-tooltip-8' place='top-end' content='Duplicar/Trocar Foto' />
    </Container>
  )
}

export default CardHeader
