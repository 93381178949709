import styled, { css } from 'styled-components'

type SituationButtonProps = {
  active: boolean
}

type ButtonFilterProps = {
  hasFilter: boolean
}

export const Container = styled.div`
  display: flex;
  width: 100%;

  padding: 10px;
  position: relative;

  justify-content: space-between;
  align-items: center;
  gap: 20px;

  background-color: #364150;

  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    :hover{
    color: white;
    background-color: #009ef7;
    border-color: #009ef7;
  }

  }

  aside {
    display: flex;
    align-items: center;
    gap: 20px;

    .filter {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      border: none;
      outline: none;
      padding: 0 6px;
      font-size: 20px;
      background-color: transparent;
    }

    @media (max-width: 1400px) {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
`

export const ButtonFilterStyled = styled.button<ButtonFilterProps>`
  background: transparent;
  font-size: 23px;
  border-radius: 6px;
  background-color: white;
  transition: all 0.4s;

  ${(props) =>
    props.hasFilter &&
    css`
      background-color: #2196f3;
      box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
    `}
    &:hover{
      
        color: white;
        background: #009ef7;
        background-color: #009ef7;
        border-color: #009ef7;

        > *{
          color:white !important;
        }

    }
   
`

export const SituationButton = styled.button<SituationButtonProps>`
  padding: 6px;

  transition: all 0.4s;

  border-radius: 5px;

  font-family: 'Inter';

  background-color: #fff;
  border-color: #fff;
  outline: none;

  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  span {
    color: white;
    font-family: 'Inter';
    font-weight: bold;

    background-color: #2196f3;

    border-radius: 20px;
    padding: 1px 5px;

    font-size: 10px;
  }

  ${({ active }) =>
    active &&
    css`
      color: white;
      background-color: #2196f3;
      border-color: #2196f3;

      box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
      span {
        color: #2b2b2b;
        background-color: #fff;
      }
    `}
`
