/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, useRef } from 'react'

import ColorButton from 'src/app/components/ColorButton'
import AutoGLP from './components/AutoGLP'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import ServiceActionModal, { IServiceActionModalConfig } from './components/ServiceActionModal'

import { Container } from './styles'
import clsx from 'clsx'
import { useAlert } from 'src/app/components/Alert/Alert'
import { useService } from 'src/app/hooks/useService'
import ErrorMessage from './components/ErrorMessage'
import { ValidateServices, UpdatePayment } from 'src/app/repositories/Atendimentos'
import { formatCnpj } from 'src/app/utils/Formats'

type Props = {
  RenderSelect?: () => JSX.Element
  viewOnly?: boolean
  data: ValidacaoModel
  onClick: (e: any, data: any) => void
  handleIsSelected: (data: any) => boolean
  handleIsApproved: (data: any) => boolean
}

const Service = ({
  data,
  onClick,
  handleIsSelected,
  handleIsApproved,
  viewOnly = false,
  RenderSelect,
}: Props) => {
  const {
    setServices: setAtendimentos,
    currentSituation,
    getQuantitative,
    services: atendimentos,
    selectedAtendimentos,
    getData,
    setSelectedAtendimentos,
  } = useService()
  const [width, setWidth] = useState(0)
  const [slide, setSlide] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [action, setAction] = useState<'aprovar' | 'reprovar'>('aprovar')

  const [serviceActionModalConfig, setServiceActionModalConfig] =
    useState<IServiceActionModalConfig>({} as IServiceActionModalConfig)
  const [showContent, setShowContent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isSelected = handleIsSelected(data.codigoAtendimento)
  const isHandleApproved = handleIsApproved(data.codigoAtendimento)

  const [statusPayment, setStatusPayment] = useState(data.pagamento ? data.pagamento.aprovado : false)

  const isPre = data.statusAtendimento === 'PRE-REPROVADO'
  const isApproved = data.statusAtendimento === 'APROVADO'
  const isDisapproved = data.statusAtendimento === 'REPROVADO'
  const isWarning = data.ocorrencias.length > 0
  const isDivergence = data.divergencia
    ? data.divergencia.tipoExecucao ||
    data.divergencia.horarioExecucaoInicial ||
    data.divergencia.horarioExecucaoFinal ||
    data.divergencia.dataSincronizacao ||
    data.divergencia.dataInicioFim
    : false

  const inAnimation = (config: IServiceActionModalConfig) => {
    setShowModal(true)
    setWidth(100)
    setServiceActionModalConfig(config)

    setShowContent(true)
  }

  const outAnimation = () => {
    setWidth(0)
    setShowContent(false)

    setTimeout(() => {
      setShowModal(false)
    }, 500)
  }

  const renderIconBandeiraPosto = (bandeiraPosto: string | undefined) => {
    switch (bandeiraPosto) {
      case 'IPIRANGA':
        return 'media/bandeiras/ipiranga.ico'
      case 'SHELL':
        return 'media/bandeiras/shell.ico'
      case 'BR':
        return 'media/bandeiras/br.ico'
      case 'ALE':
        return 'media/bandeiras/ale.ico'
      case 'TOTAL':
        return 'media/bandeiras/total.ico'
      case 'RAÍZEN' || 'RAIZEN':
        return 'media/bandeiras/shell.ico'
      case 'VIBRA ENERGIA':
        return 'media/bandeiras/br.ico'
      default:
        return 'media/bandeiras/branca.ico'
    }
  }

  const handleApproveDisapprove = async (
    codigo: number,
    approvedReason: string | undefined,
    disapprovedReason: string | undefined,
    isActive: boolean,
    action: 'aprovar' | 'reprovar'
  ) => {
    if (
      (!isActive && disapprovedReason === 'Selecione o motivo da reprovação do pagamento') ||
      disapprovedReason === '' ||
      !disapprovedReason
    ) {
      useAlert('Selecione o motivo da reprovação do pagamento', 'Error')
    } else {
      if (isActive && (approvedReason === undefined || approvedReason === '')) {
        useAlert('Justifique a liberação do pagamento', 'Error')
      }
      else {
        try {
          setIsLoading(true)
          const {
            atendimentosAtualizados,
            atendimentosNaoAtualizados,
          }: {
            atendimentosAtualizados: number[]
            atendimentosNaoAtualizados: { codigo: number; nome: string }[]
          } =
            action === 'aprovar'
              ? await ValidateServices({
                codigosAtendimentos: [codigo],
                observacao: isActive
                  ? approvedReason === ''
                    ? null
                    : approvedReason
                  : disapprovedReason,
                pagar: isActive,
                isAprovar: true,
              })
              : await ValidateServices({
                codigosAtendimentos: [codigo],
                observacao: isActive
                  ? approvedReason === ''
                    ? null
                    : approvedReason
                  : disapprovedReason,
                pagar: isActive,
                isAprovar: false,
              })

          const errorList = atendimentosNaoAtualizados

          if (errorList.length > 0) {
            const errorCods = errorList.map((item) => item.codigo)

            setAtendimentos((oldState) => {
              const newState = oldState.map((item) => {
                if (errorCods.includes(item.codigoAtendimento)) {
                  const index = errorList.findIndex((i) => i.codigo === item.codigoAtendimento)
                  item.error = errorList[index].nome
                }
                return item
              })

              return newState
            })

            useAlert(
              `Atendimento não foi atualizado, por favor, verifique no atendimento o motivo.`,
              'Error'
            )
          }

          if (atendimentosAtualizados.length > 0) {
            if (atendimentos.length - selectedAtendimentos.length <= 4) {
              getData()
            } else {
              getQuantitative()
              setAtendimentos((oldState) => {
                return oldState.filter((item) => {
                  if (!atendimentosAtualizados.includes(item.codigoAtendimento)) {
                    return item
                  }
                })
              })
            }

            setSlide(true)

            setSelectedAtendimentos((oldState) => {
              return oldState.filter((item) => {
                if (!atendimentosAtualizados.includes(item.codigoAtendimento)) {
                  return item
                }
              })
            })

            useAlert('Atendimento atualizado com sucesso', 'Success')
          }
        } catch (error: any) {
          useAlert(error.message, 'Error')
        } finally {
          setShowModal(false)
          setIsLoading(false)
          setShowContent(false)
        }
      }
    }
  }

  const handleChangePayment = async (
    codigo: number,
    approvedReason: string | undefined,
    disapprovedReason: string | undefined,
    isActive: boolean
  ) => {
    if (
      (!isActive && disapprovedReason === 'Selecione o motivo da reprovação do pagamento') ||
      disapprovedReason === '' ||
      !disapprovedReason
    ) {
      useAlert('Selecione o motivo da reprovação do pagamento', 'Error')
    } else {
      if (isActive && (approvedReason === undefined || approvedReason === '')) {
        useAlert('Justifique a liberação do pagamento', 'Error')
      }
      else {
        try {
          await UpdatePayment({
            codigoAtendimento: codigo,
            observacao: isActive
              ? approvedReason === ''
                ? null
                : approvedReason
              : disapprovedReason,
            pagar: isActive,
          })

          useAlert('Pagamento atualizado com sucesso', 'Success')
        } catch (error: any) {
          useAlert(error.message, 'Error')
        } finally {
          setStatusPayment(!statusPayment)
          outAnimation()
        }

      }
    }
  }

  const approveConfig = {
    color: 'rgba(185, 227, 204, 0.4)',
    title: { message: 'Confirmar aprovação do atendimento?', color: '#22E27B' },
    handleSuccess: handleApproveDisapprove,
    active: true,
  }

  const disapproveConfig = {
    color: 'rgba(235, 190, 192, 0.4)',
    title: { message: 'Confirmar reprovação do atendimento?', color: '#DB3461' },
    handleSuccess: handleApproveDisapprove,
    active: false,
  }

  const paymentConfig = {
    color: 'rgba(248, 156, 50, 0.4)',
    title: { message: 'Pagamento Pesquisador', color: 'rgb(248, 156, 50)' },
    handleSuccess: handleChangePayment,
    hasInfo: false,
    active: !statusPayment,
  }

  const handleMultipleApprove = () => {
    setShowModal(true)
    setWidth(100)
    setServiceActionModalConfig(approveConfig)
  }

  useEffect(() => {
    if (isHandleApproved) {
      handleMultipleApprove()
    } else {
      setShowModal(false)
      setIsLoading(false)
    }
  }, [isHandleApproved])

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setShowModal(false)
  }, [currentSituation])

  return (
    <>
      <Container
        className={`${clsx({
          slide,
        })}`}
        isApproved={isApproved}
        isDivergence={isPre && isDivergence}
        isSelected={isSelected}
        isWarning={isWarning}
        isDisapproved={isDisapproved}
        ref={containerRef}
      >
        {showModal && (
          <ServiceActionModal
            width={width}
            handleClose={outAnimation}
            data={data}
            config={serviceActionModalConfig}
            showContent={showContent}
            loading={isLoading}
            containerRef={containerRef}
            action={action}
          />
        )}

        <div className='header'>
          <div className='info'>
            <img
              width={32}
              height={32}
              src={renderIconBandeiraPosto(data.pontoColeta.bandeiraAuto)}
              alt=''
            />
            <span className='title'>{data.pontoColeta.razaoSocial}</span>
            <span>{formatCnpj(data.pontoColeta.cnpj)}</span>
          </div>
          {!viewOnly && (
            <div className='buttons'>
              {(isApproved || isDisapproved) && (
                <ColorButton
                  onClick={() => inAnimation(paymentConfig)}
                  contentContainerStyle={{ width: 130 }}
                  className='show'
                  role={statusPayment ? 'payment_reproved' : 'payment_approved'}
                  title={statusPayment ? 'Negar PG' : 'Liberar PG'}
                />
              )}
              {(isApproved || isDisapproved) && (
                <ColorButton
                  onClick={() => {
                    if (isApproved) {
                      setAction('reprovar')
                      inAnimation(disapproveConfig)
                    } else if (isDisapproved) {
                      setAction('aprovar')
                      inAnimation(approveConfig)
                    }
                  }}
                  contentContainerStyle={{ width: 130 }}
                  className='show'
                  role={isApproved ? 'default_dislike' : 'default_like'}
                  title={isApproved ? 'Reprovar' : 'Aprovar'}
                />
              )}

              {!isApproved && !isDisapproved && (
                <>
                  <ColorButton
                    onClick={() => {
                      setAction('aprovar')
                      inAnimation(approveConfig)
                    }}
                    contentContainerStyle={{ width: 130 }}
                    className='show'
                    role='like'
                    title='Aprovar'
                  />
                  <ColorButton
                    onClick={() => {
                      setAction('reprovar')
                      inAnimation(disapproveConfig)
                    }}
                    contentContainerStyle={{ width: 130 }}
                    className='show'
                    role='dislike'
                    title='Reprovar'
                  />
                </>
              )}
              <ColorButton
                contentContainerStyle={{ width: 130 }}
                className='show'
                checked={isSelected}
                onClick={(e) => onClick(e, data)}
                role='check'
                title={isSelected ? 'Selecionado' : 'Selecionar'}
              />
            </div>
          )}
          {RenderSelect && <RenderSelect />}
        </div>
        <ErrorMessage
          handleClose={() => {
            setAtendimentos((oldState) => {
              const newState = oldState.map((item) => {
                if (item.codigoAtendimento === data.codigoAtendimento) {
                  item.error = undefined
                }
                return item
              })

              return newState
            })
          }}
          message={data.error}
          show={Boolean(data.error)}
        />
        <div style={{ pointerEvents: viewOnly ? 'none' : 'all' }} className={`body`}>
          <AutoGLP data={data} viewOnly={viewOnly} />
        </div>
      </Container>
    </>
  )
}

export default Service
