/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import {useRef, useState} from 'react'

import styled from 'styled-components'

import {ExcelCidadeValor, ExcelUsuario} from '../repositories/User'
import {saveExcelFile} from '../utils/Files'
import Accordion from './Accordion'
import {useAlert} from './Alert/Alert'
import AnimatedModal from './AnimatedModal'
import BulletList from './BulletList'
import Button from './Button'
import Switch from './Form/Switch'
import TablePainel from './TablePainel'
import {TableColumn} from './TablePainel/table-painel'

type responseMessage = {
  message: string
  type: 'error' | 'text'
}

export default function ImportModal({
  title,
  instructions,
  modelFileName,
  excelModel,
  onClose,
  onImportFile,
  sheets,
  update,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>(null)
  const [data, setData] = useState<any | null>(null)
  const [errorsOn, setErrorsOn] = useState<any>({})
  const [responseMessage, setResponseMessage] = useState<responseMessage | undefined>(undefined)
  const [isUpdate, setIsUpdate] = useState(false)
  const [columns, setColumns] = useState<ImportColumns>([])
  const [currentSheet, setCurrentSheet] = useState(sheets[0])
  const [columnHeaderErrors, setColumnHeaderErrors] = useState<errorMessage>([])
  const [loading, setLoading] = useState(false)

  const handleChangeCurrentSheet = (value: string) => setCurrentSheet(value)

  function checkForErros(array: any[]) {
    // Percorre cada objeto no array excelUsuario
    for (let i = 0; i < array.length; i++) {
      // Verifica se o array de errors tem comprimento maior que 0
      if (Object.entries(array[i].errors).length > 0) {
        return true // Se houver erros, retorna true
      }
    }
    return false // Se nenhum erro for encontrado, retorna false
  }

  async function carregarDadosValidacao(file: File) {
    setColumnHeaderErrors([])
    setColumns([])
    setData(null)
    setResponseMessage(undefined)

    try {
      setLoading(true)
      const res = await onImportFile(file)
      const {columns, excelSuccess, errorsMessages} = res as importFileResponse

      if (errorsMessages.length === 0) {
        for (let i = 0; i < columns.length; i++) {
          const col = columns[i]

          const hasError = checkForErros(excelSuccess[col.name])

          setErrorsOn((oldState: any) => {
            return {...oldState, [col.name]: hasError}
          })

          if (hasError) {
            setResponseMessage({
              message: 'Passe o mouse em cima dos itens em vermelho para uma descrição do erro',
              type: 'error',
            })

            setColumns(columns)

            setData(excelSuccess)
          }
        }
      } else {
        setColumnHeaderErrors(errorsMessages)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
      setData(null)
      setFile(null)
    } finally {
      setLoading(false)
      //@ts-ignore
      fileInputRef.current.value = null
    }
  }

  function readFile(e: React.ChangeEvent<HTMLInputElement>) {
    function checkfile(sender: File) {
      var validExts = ['.xlsx']
      var fileExt = sender.name

      fileExt = fileExt.substring(fileExt.lastIndexOf('.'))

      if (validExts.indexOf(fileExt) < 0) {
        useAlert(
          `Arquivo inválido, somente aceito arquivos do tipo ${validExts.toString()}`,
          'Error'
        )
        return false
      }
      return true
    }

    let files = e.target.files
    const fl = files?.item(0)

    if (fl && checkfile(fl)) {
      setFile(fl)
      carregarDadosValidacao(fl)
    }
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleDownloadModelExcel = () => {
    const sheetData = excelModel.map((item) => {
      return {
        sheetName: item.sheetName,
        data: [item.values],
      }
    })

    saveExcelFile(sheetData, modelFileName)
  }

  const closeModal = () => {
    setResponseMessage(undefined)
    setData(null)
    setFile(null)
    onClose()
  }

  const getColorButton = (item: string) => {
    if (errorsOn[item] && currentSheet === item) return 'danger'

    if (errorsOn[item] && currentSheet !== item) return 'light-danger'

    if (currentSheet === item) return 'info'

    return 'light-info'
  }

  const getData = () => {
    return data[currentSheet] || []
  }

  const getColumn = () => {
    const aaa = excelModel.filter((item) => item.sheetLabel === currentSheet)
    const col = columns.filter((item) => item.name === aaa[0].sheetLabel)
    return col[0].data
  }

  return (
    <>
      <AnimatedModal
        onClose={closeModal}
        containerStyle={{maxWidth: 1500}}
        headerTitle={title}
        hasFooter={false}
      >
        <Container>
          <span className='text-muted mt-1 fw-semibold fs-5'>
            Para uma importação correta baixe o arquivo {modelFileName}.xlsx no link ao lado e o
            preencha:{' '}
            <a href='#' onClick={handleDownloadModelExcel} className='link-primary'>
              {modelFileName}.xlsx
            </a>
          </span>
          <div className='my-6'>
            {Boolean(instructions) && (
              <Accordion className='mw-50' title='Instruções'>
                <BulletList items={instructions!} />
              </Accordion>
            )}
          </div>
          <div className='card border pb-6' style={{alignItems: 'center'}}>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Detalhes do Arquivo</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>{file?.name}</span>
                {responseMessage && (
                  <span
                    className={`${
                      responseMessage.type === 'error' ? 'text-danger' : 'card-label'
                    } mt-1 fw-semibold fs-2`}
                  >
                    {responseMessage.message}
                  </span>
                )}
              </h3>
              <div className='d-flex flex-stack gap-2'></div>
            </div>
            <div className='card-body py-3'>
              <div className='d-flex flex-stack'>
                <div className='d-flex gap-4'>
                  <div className='card-toolbar'>
                    <label htmlFor='upload-file'>
                      <input
                        data-test='upload-file'
                        ref={fileInputRef}
                        id='upload-file'
                        type='file'
                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        style={{display: 'none'}}
                        onChange={readFile}
                      />
                      <Button
                        data-test='bImportacao'
                        color='primary'
                        icon='plus'
                        onClick={handleButtonClick}
                      >
                        Escolher Arquivo
                      </Button>
                    </label>
                  </div>
                </div>
                {update && (
                  <div className='card-toolbar'>
                    <Switch checked={isUpdate} onChange={(e) => setIsUpdate(e.target.checked)}>
                      Atualizar registro(s) existente(s) na base de dados.
                    </Switch>
                  </div>
                )}
              </div>

              {!file && <span className='text-muted'>Nenhum arquivo selecionado</span>}
            </div>
          </div>
          <div>
            <div
              className='pt-8'
              style={{backgroundColor: 'white', padding: '10px', borderRadius: '5px'}}
            >
              {columnHeaderErrors.length > 0 &&
                columnHeaderErrors.map((item) => (
                  <>
                    <p className='card-label  fs-3 mb-1'>{item.preFix}</p>
                    <ul>
                      {item.columns.map((item) => (
                        <li className='card-label fw-bold fs-3 mb-1 text-danger'>{item}</li>
                      ))}
                    </ul>
                  </>
                ))}
              {data && columns.length > 0 && (
                <>
                  <div className='d-flex gap-3'>
                    {sheets.map((item) => (
                      <Button
                        fill
                        color={getColorButton(item)}
                        onClick={() => handleChangeCurrentSheet(item)}
                      >
                        {item}
                      </Button>
                    ))}
                  </div>

                  <TablePainel
                    bordered
                    tableTitle={''}
                    columns={getColumn()}
                    data={getData()}
                    createDataButton={false}
                    importDataButton={false}
                    searchable={false}
                    exportButton={false}
                    hideColumnsButton={false}
                  />
                </>
              )}
            </div>
          </div>
        </Container>

        {loading && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
              top: 0,
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-xl align-middle ms-2'></span>
            </span>
          </div>
        )}
      </AnimatedModal>
    </>
  )
}

export type ImportColumns = {name: string; data: TableColumn[]}[]
export type ImportExcelSuccess = any

interface Props {
  title: string
  modelFileName: string
  instructions?: string[]
  excelModel: excelModel[]
  sheets: string[]
  onClose: () => void
  onImportFile: (file: File) => Promise<importFileResponse | undefined>
  update?: boolean
}

type importFileResponse = {
  columns: ImportColumns
  excelSuccess: ImportExcelSuccess
  errorsMessages: errorMessage
}

export type excelModel = {
  sheetName: string
  sheetLabel: string
  values: any
}

export type errorMessage = {
  preFix: string
  columns: string[]
}[]

export type TableData = {
  users: ExcelUsuario[]
  cities: ExcelCidadeValor[]
} | null

export type SheetColumns = {
  userColumns: TableColumn[]
  cityColumns: TableColumn[]
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 1% 3% 0 3%;

  .msg-row {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    font-size: 14px;
    font-family: 'Poppins';

    .label {
      font-weight: 600;
      color: #5c5c5c;
      text-transform: uppercase;
    }
  }
`
