/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import YupPassword from 'yup-password'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'

import Button from 'src/app/components/Button'
import Form from 'src/app/components/Form'
import InputText from 'src/app/components/Form/InputText'
import Link from 'src/app/components/Link'
import {useAuth} from 'src/app/hooks/useAuth'
import {LogIn} from 'src/app/repositories/Login'
import {encryptMessage} from 'src/app/utils/Encrypt'

const initialValues = {
  email: '',
  password: '',
}

YupPassword(Yup)

export function Login() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const {setCurrentUser, saveAuth} = useAuth()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'AUTH.VALIDATION.EMAIL'}))
      .min(3, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH'}, {name: 'Email', min: '3'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Email', max: '50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Email'})),
    password: Yup.string()
      .min(6, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH'}, {name: 'Senha', min: '6'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Senha', max: '50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Senha'}))
      .minLowercase(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINLOWERCASE'}, {name: 'Senha'}))
      .minUppercase(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINUPPERCASE'}, {name: 'Senha'}))
      .minNumbers(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINNUMBERS'}, {name: 'Senha'}))
      .minSymbols(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINSYMBOLS'}, {name: 'Senha'})),
  })

  let publicKey =
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCz1zqQHtHvKczHh58ePiRNgOyiHEx6lZDPlvwBTaHmkNlQyyJ06SIlMU1pmGKxILjT7n06nxG7LlFVUN5MkW/jwF39/+drkHM5B0kh+hPQygFjRq81yxvLwolt+Vq7h+CTU0Z1wkFABcTeQQldZkJlTpyx0c3+jq0o47wIFjq5fwIDAQAB'

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const encryptPassword = encryptMessage(values.password, publicKey)

        const data = {
          email: values.email.trim(),
          senha: encryptPassword,
        }

        const {token, usuario} = await LogIn(data)

        setCurrentUser(usuario)
        saveAuth(token, usuario)
      } catch (error: any) {
        saveAuth(undefined, undefined)
        setStatus({success: false, message: error.message})
        setSubmitting(false)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <Form title={intl.formatMessage({id: 'AUTH.GENERAL.SIGNIN'})} formik={formik}>
      <InputText
        data-test='email'
        label={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
        placeholder='Digite seu email'
        type='email'
        formik={{
          formikProp: 'email',
          formikValues: formik,
        }}
      />

      <InputText
        data-test='senha'
        label={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
        type='password'
        formik={{
          formikProp: 'password',
          formikValues: formik,
        }}
      />

      <div className='d-flex flex-stack mb-8 mt-8'>
        <div />

        <Link navigateTo='/auth/forgot-password' color='primary'>
          {intl.formatMessage({id: 'AUTH.GENERAL.FORGOT_BUTTON'})}
        </Link>
      </div>

      <div className='d-grid mb-10'>
        <Button
          data-test='submit-login'
          disabled={formik.isSubmitting || !formik.isValid}
          loading={loading}
          color='primary'
        >
          {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
        </Button>
      </div>
    </Form>
  )
}
