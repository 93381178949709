import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import Validacao from '../pages/avaliacao-pesquisa/validacao/Validacao'
import {BaseOperacional} from '../pages/planejamento-execucao/base-operacional/BaseOperacional'
import {Usuario} from '../pages/planejamento-execucao/usuario/Usuarios'
import {RedefinePassword} from '../modules/auth/components/RedefinePassword'
import {useAuth} from '../hooks/useAuth'
import CentroCusto from '../pages/planejamento-execucao/CentroCusto'
import Rota from '../pages/planejamento-execucao/rota'
import ColetaiApi from '../pages/configuracao/ColetaiApi'
import ManutencaoExclusao from '../pages/planejamento-execucao/manutencao-exclusao/ManutencaoExclusao'
import Monitoramento from '../pages/liberacoes/Monitoramento'
import Exportacao from '../pages/liberacoes/exportacao'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path='auth/*'
          element={
            <Navigate to={currentUser!.primeiroAcesso ? '/redefine-password' : '/validacao'} />
          }
        />
        {/* Pages */}
        <Route path='validacao' element={<Validacao />} />
        <Route path='coletaiApi' element={<ColetaiApi />} />
        <Route path='redefine-password' element={<RedefinePassword />} />
        <Route path='base_operacional' element={<BaseOperacional />} />
        <Route path='centro_custo' element={<CentroCusto />} />
        <Route path='liberacao_por_assistente' element={<Monitoramento />} />
        <Route path='exportacao' element={<Exportacao />} />
        <Route path='usuario' element={<Usuario />} />
        <Route path='rota' element={<Rota />} />
        <Route path='manutencao_exclusao' element={<ManutencaoExclusao />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
