import api from '../config'
import ApiConfigurationModel from '../model/ApiConfiguration/ApiConfiguration'
import {IApiConfiguration} from '../model/ApiConfiguration/Type'
import {handlerResult} from '../utils/API'

const serializeApiConfiguration = (value: IApiConfiguration) => {
  const model = new ApiConfigurationModel(value)
  return model
}

export async function GetApiConfiguration() {
  try {
    const response = await api.post('api/configuracao', {apiId: 'triad-user-api'})
    const dataResponse = handlerResult(response.data)
    const model = serializeApiConfiguration(dataResponse)
    return model
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.data.message)
    }
  }
}
