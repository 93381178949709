import {ICenterCost} from './type'

export default class CenterCostModel {
  private _codigo: number
  private _nomeFantasia: string
  private _razaoSocial: string
  private _nomeProprietario: string
  private _email: string
  private _telefone: string
  private _cpfOuCnpj: string
  private _totalPesquisadores: string

  constructor({
    codigo,
    nomeFantasia,
    razaoSocial,
    nomeProprietario,
    email,
    telefone,
    cpfOuCnpj,
    totalPesquisadores,
  }: ICenterCost) {
    this._codigo = codigo
    this._nomeFantasia = nomeFantasia
    this._razaoSocial = razaoSocial
    this._nomeProprietario = nomeProprietario
    this._email = email
    this._telefone = telefone
    this._cpfOuCnpj = cpfOuCnpj
    this._totalPesquisadores = totalPesquisadores
  }

  get codigo() {
    return this._codigo
  }

  get nomeFantasia() {
    return this._nomeFantasia
  }

  get razaoSocial() {
    return this._razaoSocial
  }

  get nomeProprietario() {
    return this._nomeProprietario
  }

  get email() {
    return this._email
  }

  get telefone() {
    return this._telefone
  }
  set telefone(value: string) {
    this._telefone = value
  }

  get cpfOuCnpj() {
    return this._cpfOuCnpj
  }
  set cpfOuCnpj(value: string) {
    this._cpfOuCnpj = value
  }

  get totalPesquisadores() {
    return this._totalPesquisadores
  }
}
