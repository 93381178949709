import {useState} from 'react'

import AnimatedModal from 'src/app/components/AnimatedModal'
import {Container} from './styles'
import ContainerCard from './components/ContainerCard'
import UsuarioModel from 'src/app/model/Usuario/Usuario'
import KeyValue from './components/KeyValue'
import {Table} from '../../../CentroCusto/components/CostCenterBase/styles'
import TableRow from '../../../CentroCusto/components/CostCenterBase/components/TableRow'
import {currencyFormatterBRL, formatCEP, formatCPF, formatPhoneNumber} from 'src/app/utils/Formats'

const UserViewInfoModal = ({onClose, userInfo}: Props) => {
  const [hidden, setHidden] = useState(true)
  const isPesquisador = userInfo.perfil.value === 'PESQUISADOR'

  return (
    <AnimatedModal
      headerTitle='Dados Usuário'
      footerTitle='Salvar'
      containerStyle={{padding: 32, width: 690}}
      onClose={onClose}
    >
      <Container>
        <ContainerCard title='Cadastro'>
          <>
            <div className='photo-info'>
              <div className='image'>
                <img src={userInfo.imagemUrl} alt='' />
              </div>
              <div className=''>
                <KeyValue label='Nome' value={userInfo.nome} />
                <KeyValue label='Grupo' value={userInfo.perfil.label} />
                <KeyValue label='Email' value={userInfo.email} />
                <KeyValue
                  label='Telefone'
                  value={formatPhoneNumber(userInfo.telefone) || 'Não preenchido'}
                />
              </div>
            </div>
            <div className='info-row'>
              <KeyValue label='CPF' value={formatCPF(userInfo.cpf) || 'Não preenchido'} />
              <KeyValue
                label='RG'
                value={
                  userInfo.rg
                    ? `${userInfo.rg} - ${userInfo.dataEmissaoRg} - ${userInfo.orgaoEmissorRg}`
                    : 'Não preenchido'
                }
              />
            </div>
            <div className='info-row'>
              {' '}
              <KeyValue label='Data Nasc.' value={userInfo.dataNascimento || 'Não preenchido'} />
              <KeyValue label='Nacionalidade' value={userInfo.nacionalidade || 'Não preenchido'} />
            </div>
            <div className='info-row'>
              {' '}
              <KeyValue
                label='Estado Civil'
                value={userInfo.estadoCivil.label || 'Não preenchido'}
              />
              <KeyValue label='Gênero' value={userInfo.genero.label || 'Não preenchido'} />
            </div>
          </>
        </ContainerCard>
        <ContainerCard title='Endereço'>
          <div className='info-row'>
            <KeyValue label='CEP' value={formatCEP(userInfo.cep) || 'Não preenchido'} />
            <KeyValue label='Bairro' value={userInfo.bairro || 'Não preenchido'} />
            <KeyValue
              label='Cidade/UF'
              value={
                userInfo.uf.label ? `${userInfo.cidade}/${userInfo.uf.label}` : 'Não preenchido'
              }
            />
          </div>
          <div className='info-row'>
            {' '}
            <KeyValue label='Logradouro' value={userInfo.logradouro || 'Não preenchido'} />
            <KeyValue label='Nº' value={userInfo.numero || 'Não preenchido'} />
          </div>
          <div className='info-row'>
            {' '}
            <KeyValue label='Complemento' value={userInfo.complemento || 'Não preenchido'} />
          </div>
        </ContainerCard>

        <ContainerCard hidden={hidden} setHidden={setHidden} title='Dados Bancários' isBlur>
          <div className='info-row'>
            <KeyValue
              label='Banco'
              isBlur={hidden}
              value={`${userInfo.banco.label || 'Não preenchido'}`}
            />
          </div>
          <div className='info-row'>
            <KeyValue
              label='Chave Pix'
              isBlur={hidden}
              value={`${userInfo.chavePix || 'Não preenchido'}`}
            />
            <KeyValue
              label='Agência'
              isBlur={hidden}
              value={`${userInfo.agencia || 'Não preenchido'}`}
            />
            <KeyValue
              label='Conta'
              isBlur={hidden}
              value={`${userInfo.conta || 'Não preenchido'}`}
            />
          </div>
        </ContainerCard>
        {isPesquisador && (
          <ContainerCard title='Cidade/UF Custo'>
            <Table>
              <tr>
                <th>Cidade/UF</th>
                <th>Valor</th>
                <th>Centro Custo</th>
              </tr>

              {userInfo.cidadesValor.map((item) => (
                <TableRow item={item} onClick={() => {}}>
                  <td>{`${item.cidade}/${item.uf}`}</td>
                  <td>{currencyFormatterBRL(item.valor)}</td>
                  <td>{item.nomeCentroDeCusto}</td>
                </TableRow>
              ))}
            </Table>
          </ContainerCard>
        )}
      </Container>
    </AnimatedModal>
  )
}

type Props = {
  onClose: () => void
  userInfo: UsuarioModel
}

export default UserViewInfoModal
