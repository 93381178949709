import styled from 'styled-components'

export const Container = styled.div`
  border: 1px dashed #d9dbdd !important;
  width: 100% !important;

  background-color: #fafafa !important;

  .btn-remove {
    padding: 5px;
    background: #e7505a;
    color: #fff;
    font-size: 1em;
    border: none;
    margin: 0px;
    width: 100%;

    margin-top: 8px0;
  }

  span {
    background-color: #edf5fd;

    padding: 5px 20px !important;

    border-radius: 5px;

    color: #4fa2ec;

    font-family: 'Poppins', sans-serif;
    font-weight: bold !important;
  }

  img {
    width: 100%;
    max-height: 200px;

    object-fit: contain;
  }

  input {
    visibility: hidden;
    display: none;
  }
`

export const ContainerButton = styled.button`
  border: none;

  background-color: #fafafa !important;

  display: flex;
  flex-direction: column;

  height: 200px !important;
  width: 100%;
  align-items: center !important;
  justify-content: center !important;
`
