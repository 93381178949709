import styled from 'styled-components'

export const MsgContainer = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #fafafa;

  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

  padding: 20px 40px;

  overflow: auto;

  input.form-check-input {
    background-color: rgba(235, 190, 192, 1);
  }

  input.form-check-input:checked {
    background-color: rgba(185, 227, 204, 1);
  }

  .msg-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .msg-row {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      font-size: 14px;
      font-family: 'Poppins';

      .label {
        font-weight: 600;
        color: #5c5c5c;
        text-transform: uppercase;
      }
    }
  }

  .msg-header {
    display: flex;
    justify-content: center;

    padding: 20px;

    border-radius: 5px;

    text-align: center;
    color: white;
    font-weight: bold;

    span {
      color: ${({color}) => color || '#1b1b1b'};
      letter-spacing: 5px;
      text-transform: uppercase;
      font-weight: 300;
      font-family: 'Poppins';
      font-size: 18px;
    }

    button {
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: transparent;
    }
  }

  .msg-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding: 5px;
    margin-top: 6px;
  }
`
