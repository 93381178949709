import { IAtendimentoLiberado } from 'src/app/repositories/Monitoramento'
import { BadgeSecondary } from './style'

export class DetalheMonitoramentoModel {
  private _codigoAtendimento: number
  private _codigoTriad: string
  private _dataExecucao: string
  private _dataValidacao: string
  private _status: string
  private _uf: string
  private _fonte: string
  private _codigoExterno: string

  constructor({
    codigoAtendimento,
    codigoTriad,
    dataExecucao,
    dataValidacao,
    status,
    uf,
    fonte,
    codigoExterno,
  }: IAtendimentoLiberado) {
    this._codigoAtendimento = codigoAtendimento
    this._codigoTriad = codigoTriad
    this._dataExecucao = dataExecucao
    this._dataValidacao = dataValidacao
    this._status = status
    this._uf = uf
    this._fonte = fonte
    this._codigoExterno = codigoExterno
  }

  get codigoAtendimento() {
    return this._codigoAtendimento
  }
  get codigoTriad() {
    return this._codigoTriad
  }
  get dataExecucao() {
    return this._dataExecucao
  }
  get dataValidacao() {
    return this._dataValidacao
  }
  get status() {
    return <BadgeSecondary status={this._status}>{this._status}</BadgeSecondary>
  }
  get uf() {
    return this._uf
  }
  get fonte() {
    return this._fonte
  }
  get codigoExterno() {
    return this._codigoExterno
  }
}
