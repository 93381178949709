/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {IconType} from 'react-icons/lib'

type Props = {
  to?: string
  img?: string
  action?: () => void
  title: string
  iconName?: string
  fontIcon?: string
  hasBullet?: boolean
  Icon?: IconType
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  iconName,
  fontIcon,
  hasBullet = false,
  action,
  Icon,
  img,
  ...rest
}) => {
  const {pathname} = useLocation()
  const isActive = (to && checkIsActive(pathname, to)) || false
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item'>
      {to && (
        <Link {...rest} className={clsx('menu-link without-sub', {active: isActive})} to={to}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {iconName && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <KTIcon iconName={iconName} className='fs-2' />
            </span>
          )}
          {Icon && (
            <span className='menu-icon'>
              {' '}
              <Icon size={19.5} />
            </span>
          )}
          {img && (
            <span className='menu-icon'>
              {' '}
              <img
                width={19}
                height={19}
                style={{borderRadius: 20}}
                src={`data:image/png;base64,${img}`}
                alt='avatar do usuário'
              />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title'>{title}</span>
        </Link>
      )}

      {action && (
        <a className={clsx('menu-link without-sub')} onClick={action}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {iconName && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <KTIcon iconName={iconName} className='fs-2' />
            </span>
          )}
          {Icon && (
            <span className='menu-icon'>
              {' '}
              <Icon size={19.5} />
            </span>
          )}
          {img && (
            <span className='menu-icon'>
              {' '}
              <img
                width={19}
                height={19}
                style={{borderRadius: 20}}
                src={`data:image/png;base64,${img}`}
                alt='avatar do usuário'
              />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title'>{title}</span>
        </a>
      )}

      {children}
    </div>
  )
}

export {SidebarMenuItem}
