import {IApiConfiguration} from './Type'

export default class ApiConfigurationModel {
  private _id: string
  private _key: string
  private _email: string
  private _codigoPerfil: number
  private _codigoProjeto: number

  constructor({id, key, email, codigoPerfil, codigoProjeto}: IApiConfiguration) {
    this._id = id
    this._key = key
    this._email = email
    this._codigoPerfil = codigoPerfil
    this._codigoProjeto = codigoProjeto
  }

  get id() {
    return this._id
  }
  get key() {
    return this._key
  }
  get email() {
    return this._email
  }
  get codigoPerfil() {
    return this._codigoPerfil
  }
  get codigoProjeto() {
    return this._codigoProjeto
  }
}
