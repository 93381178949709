/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {memo, useEffect, useState} from 'react'
import DateRangePicker from 'rsuite/DateRangePicker'
import {FilterIcon} from 'src/app/utils/Icons'
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'

import {Container, SituationButton, ButtonFilterStyled} from './styles'
import AnimatedInput from 'src/app/components/AnimatedInput'
import {Situation} from 'src/app/contexts/ServiceContext'
import {useService} from 'src/app/hooks/useService'
import Button from 'src/app/components/Button'
import ImportModal, {excelModel, errorMessage} from 'src/app/components/ImportModal'
import {useAlert} from 'src/app/components/Alert/Alert'
import {ImportRevalidationServices} from 'src/app/repositories/Atendimentos'

type HeaderContainerProps = {
  setActiveFilter: React.Dispatch<React.SetStateAction<boolean>>
  setCanShowButtons: React.Dispatch<React.SetStateAction<boolean>>
}

const IMPORT_EXCEL_MODEL: excelModel[] = [
  {
    sheetLabel: 'Atendimento',
    sheetName: 'atendimento',
    values: {
      CNPJ: '99.999.999/9999-99',
      'DATA DA COLETA': 'dd/MM/yyyy',
      TIPO: 'AUTO/GLP',
    },
  },
]

function HeaderContainer({
  setActiveFilter,

  setCanShowButtons,
}: HeaderContainerProps) {
  const {
    servicesPerDate,
    currentSituation,
    setCurrentSituation,
    setSelectedAtendimentos,
    filtersCount,
    searchFilter,
    setSearchFilter,
    setDateRange,
    setPagination,
    getData,
    dataInicioDefault,
    dataFimDefault,
    hasFilterApplied,
  } = useService()

  const [dateOne, setDateOne] = useState<Date>()
  const [dateTwo, setDateTwo] = useState<Date>()
  const [showModalImport, setShowModalImport] = useState(false)

  const handleOpenModalImport = () => {
    setShowModalImport(true)
  }

  const handleCloseModalImport = () => {
    setShowModalImport(false)
  }

  const datesAndQuantitatives =
    (servicesPerDate &&
      (currentSituation === 'PD'
        ? servicesPerDate.pendentes
        : currentSituation === 'PR'
        ? servicesPerDate.preReprovados
        : currentSituation === 'AP'
        ? servicesPerDate.aprovados
        : servicesPerDate.reprovados)) ||
    []

  const dates =
    (servicesPerDate &&
      (currentSituation === 'PD'
        ? Object.keys(servicesPerDate.pendentes)
        : currentSituation === 'PR'
        ? Object.keys(servicesPerDate.preReprovados)
        : currentSituation === 'AP'
        ? Object.keys(servicesPerDate.aprovados)
        : Object.keys(servicesPerDate.reprovados))) ||
    []

  const handleChangeSituation = (situation: Situation) => {
    setCanShowButtons(false)
    setCurrentSituation(situation)
    setSelectedAtendimentos([])
    setPagination(1)
  }

  const newDates = dates.map((item) => {
    let day = new Date(item)

    day.setDate(day.getDate() + 1)

    return day
  })

  const setCorrectDateTitle = () => {
    let message = ''

    if (dateOne && dateTwo) {
      const dOne = format(dateOne, 'dd/MM', {locale: ptBR})
      const dTwo = format(dateTwo, 'dd/MM', {locale: ptBR})

      if (dOne === dTwo) {
        message = `Resultados do dia ${dOne}`
      } else {
        message = `Resultados entre ${dOne} e ${dTwo}`
      }
    }

    return message
  }

  const handlerHeaderColumnErrors = (erros: any, name: string = 'pontos_de_coleta') => {
    let err: errorMessage = []

    if (erros.atendimentos.length > 0) {
      const preFix = 'As seguintes colunas na aba de rotas não existem '
      const columns = erros.atendimentos

      err.push({columns, preFix})
    }

    return err
  }

  const onImportFile = async (file: File) => {
    try {
      const res = await ImportRevalidationServices(file)

      const {excelError: excelerror, excelSucces, headersError} = res!

      const excel = excelSucces
      const excelError = excelerror

      if (excel.length === 0 && excelError.length === 0) {
        handleCloseModalImport()

        if (currentSituation === 'RV') {
          getData()
        } else {
          handleChangeSituation('RV')
        }
        useAlert(`Atendimentos importados com sucesso.`, 'Success')
      }

      if (excel.length > 0 && excelError.length === 0) {
        handleCloseModalImport()
        return {
          columns: [],
          excelSuccess: {},
          errorsMessages: [],
        }
      }

      if (excelError && excelError.length > 0) {
        let base = excelError

        const errorsMessages = handlerHeaderColumnErrors(headersError)

        useAlert(`Houve erro em ${excelError.length} atendimentos`, 'Error')

        const data = [
          {name: 'CNPJ', accessor: 'cnpj'},
          {name: 'DATA DA COLETA', accessor: 'dataColeta'},
          {name: 'TIPO', accessor: 'tipo'},
        ]

        return {
          columns: [
            {
              name: 'Atendimento',
              data,
            },
          ],
          excelSuccess: {Atendimento: base},
          errorsMessages,
        }
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }

    return {
      columns: [],
      excelSuccess: {},
      errorsMessages: [],
    }
  }

  useEffect(() => {
    if (dateOne && dateTwo) {
      const datasNoIntervalo = []

      for (const data of newDates) {
        if (data >= dateOne && data <= dateTwo) {
          datasNoIntervalo.push(data)
        }
      }

      datasNoIntervalo.push(dateTwo)

      let c = 0

      datasNoIntervalo.map((item) => {
        const f = format(item, 'yyyy-MM-dd', {locale: ptBR})

        if (datesAndQuantitatives[f]) {
          c += datesAndQuantitatives[f]
        }

        // setCount(c)

        return f
      })
    }
  }, [dateTwo])

  const situationsButton = [
    {
      onClick: () => handleChangeSituation('PD'),
      count: filtersCount.pendentes,
      label: 'Pendentes',
      active: currentSituation === 'PD',
    },
    {
      onClick: () => handleChangeSituation('PR'),
      count: filtersCount.preReprovados,
      label: 'Pré-Reprovados',
      active: currentSituation === 'PR',
    },
    {
      onClick: () => handleChangeSituation('AP'),
      count: filtersCount.aprovados,
      label: 'Aprovados',
      active: currentSituation === 'AP',
    },
    {
      onClick: () => handleChangeSituation('RE'),
      count: filtersCount.reprovados,
      label: 'Reprovados',
      active: currentSituation === 'RE',
    },
    {
      onClick: () => handleChangeSituation('RV'),
      count: filtersCount.revalidacao,
      label: 'Revalidar',
      active: currentSituation === 'RV',
    },
  ]

  return (
    <Container>
      <div data-test='header' className='buttons'>
        {situationsButton.map((item, index) => (
          <SituationButton key={String(index)} onClick={item.onClick} active={item.active}>
            {item.label} <span>{item.count}</span>
          </SituationButton>
        ))}
      </div>
      <aside>
        <Button icon='file-down' onClick={handleOpenModalImport}>
          Importar Revalidação
        </Button>
        <AnimatedInput
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          titulo='CNPJ'
          placeholder='CNPJ do posto'
        />
        <DateRangePicker
          showOneCalendar
          placeholder='Filtrar por período'
          format='dd-MM-yyyy'
          editable
          // renderTitle={() => <span>{count} resultados</span>}
          renderValue={(value) => {
            if (value) {
              setDateOne(value[0])
              setDateTwo(value[1])
              return <span>{setCorrectDateTitle()} </span>
            }
          }}
          shouldDisableDate={(dateParam) => {
            return false
          }}
          placement='autoHorizontalStart'
          onClean={() => setDateRange({dataFim: dataFimDefault(), dataInicio: dataInicioDefault()})}
          onChange={(value: any) => {
            if (value) {
              const dataInicio = format(value[0], 'yyyy/MM/dd', {locale: ptBR})
              const dataFim = format(value[1], 'yyyy/MM/dd', {locale: ptBR})

              setDateRange({dataInicio, dataFim})
            }
          }}
        />

        <ButtonFilterStyled hasFilter={hasFilterApplied()} onClick={() => setActiveFilter(true)}>
          <FilterIcon color={hasFilterApplied() ? 'white' : '#727272'} />
        </ButtonFilterStyled>
      </aside>

      {showModalImport && (
        <ImportModal
          title='upload atendimentos revalidação'
          modelFileName='modelo_revalidação'
          excelModel={IMPORT_EXCEL_MODEL}
          onClose={handleCloseModalImport}
          onImportFile={onImportFile}
          sheets={['Atendimento']}
          instructions={[
            'Os campos “CNPJ”, “DATA COLETA” e “TIPO”  são obrigatórios;',
            'CNPJ formatado com os seus 14 dígitos (os caracteres são opcionais)',
            'A DATA COLETA deve estar no formato dd/mm/yyyy',
          ]}
        />
      )}
    </Container>
  )
}

export default memo(HeaderContainer)
