/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
import {useState, useEffect} from 'react'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'

import AnimatedModal from 'src/app/components/AnimatedModal'

import {BankIcon, BuildingIcon} from 'src/app/utils/Icons'

import AccordionCollapseItem from '../AccordionCollapseItem'
import {Select} from 'src/app/components/Select/Select'
import PhoneInput from 'react-phone-input-2'
import Label from 'src/app/components/Label'
import InputText from 'src/app/components/Form/InputText'
import {ResearchersData} from '../CostCenterBase'

import {CloseIcon} from 'src/app/utils/Icons'
import {ModalHeader} from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/CardHeader/components/ChangeModal/styles'

import {Container, ContainerBox} from './styles'
import {
  CreateCostCenter,
  ICostCenterResponse,
  UpdateCostCenter,
} from 'src/app/repositories/CenterCost'
import {useAlert} from 'src/app/components/Alert/Alert'
import Footer from './components/Footer'
import {ICenterCost} from 'src/app/model/CenterCost/type'
import IsResearcherMoldal from './components/IsResearcherMoldal'
import {CentroCustoData} from 'src/app/contexts/CostCenterContext'
import {
  bancoValidation,
  cpfCnpjValidation,
  emailValidation,
  razaoSocialValidation,
  proprietarioValidation,
  tipoContaValidation,
  contaValidation,
  agenciaValidation,
} from './validations'
import {formatCPF, formatCnpj, formatPhoneNumber} from 'src/app/utils/Formats'
import {GetActiveResearches} from 'src/app/repositories/Researcher'

const defaultOption = {label: 'Selecione uma opção', value: null}

const CostCenterCreationModal = ({
  onClose,
  isEditing,
  setData,
  centerCostInfo,
  data,
  callback,
}: Props) => {
  const [showIsResearcherMoldal, setShowIsResearcherMoldal] = useState(false)

  const {bancos, contasBancarias} = data

  const bancosList: typeof bancos = [defaultOption, ...bancos]
  const contasBancariasList: typeof contasBancarias = [defaultOption, ...contasBancarias]

  const initialValues = {
    agencia: centerCostInfo?.dadoBancario?.agencia || '',
    nomeFantasia: centerCostInfo?.centroDeCusto?.nomeFantasia || '',
    proprietario: centerCostInfo?.centroDeCusto?.nomeProprietario || '',
    email: centerCostInfo?.centroDeCusto?.email || '',
    cpfCnpj: centerCostInfo?.centroDeCusto?.cpfOuCnpj || '',
    razaoSocial: centerCostInfo?.centroDeCusto?.razaoSocial || '',
    telefone: centerCostInfo?.centroDeCusto?.telefone || '',
    pix: centerCostInfo?.dadoBancario?.chavePix || '',
    conta: centerCostInfo?.dadoBancario?.conta || '',
    tipoConta: {
      label: centerCostInfo?.dadoBancario?.tipoConta?.nome || defaultOption.label,
      value: centerCostInfo?.dadoBancario?.tipoConta?.codigo || defaultOption.value,
    },
    banco: {
      label: centerCostInfo?.dadoBancario?.banco?.nome || defaultOption.label,
      value: centerCostInfo?.dadoBancario?.banco?.codigo || defaultOption.value,
    },
  }

  const handleCloseShowIsResearcherMoldal = () => setShowIsResearcherMoldal(false)

  const handleOpenShowIsResearcherMoldal = () => setShowIsResearcherMoldal(true)

  const intl = useIntl()
  const [researchers, setResearchers] = useState<ResearchersData[]>([])
  const [rawResearchers, setRawResearchers] = useState<ActiveResearcers[]>([])

  const validationSchema = Yup.object().shape({
    proprietario: proprietarioValidation(intl),
    cpfCnpj: cpfCnpjValidation(intl),
    razaoSocial: razaoSocialValidation(intl),
    email: emailValidation(intl),
    agencia: agenciaValidation(intl),
    conta: contaValidation(intl),
    tipoConta: tipoContaValidation(intl),
    banco: bancoValidation(intl),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const {
        agencia,
        conta,
        cpfCnpj,
        email,
        nomeFantasia,
        pix,
        proprietario,
        razaoSocial,
        telefone,
        tipoConta,
        banco,
      } = values

      try {
        const o: any = {
          agenciaBanco: agencia === '' ? null : agencia,
          chavePix: pix === '' ? null : pix,
          codigoBanco: banco.value === defaultOption.value ? null : banco.value,
          codigoTipoContaBanco: tipoConta.value === defaultOption.value ? null : tipoConta.value,
          contaBanco: conta === '' ? null : conta,
          cpfOuCnpj: cpfCnpj
            .replaceAll(',', '')
            .replaceAll('-', '')
            .replaceAll('.', '')
            .replaceAll('/', ''),
          email,
          razaoSocial,
          nomeFantasia: nomeFantasia === '' ? null : nomeFantasia,
          nomeProprietario: proprietario,
          telefone: telefone === '' ? null : telefone,
        }

        if (!isEditing) {
          const res = await CreateCostCenter<ICenterCost>(o)

          if (res) {
            setData((oldState) => {
              const stateCopy = oldState

              stateCopy.centrosDeCusto.unshift(res)

              return stateCopy
            })
            await getData()
            onClose()
            useAlert('Centro de Custo criado com sucesso!', 'Success')
          }
        } else {
          o.codigo = centerCostInfo.centroDeCusto.codigo

          const res = await UpdateCostCenter<ICenterCost>(o)

          if (res) {
            setData((oldState) => {
              const stateCopy = oldState

              stateCopy.centrosDeCusto = stateCopy.centrosDeCusto.map((item) => {
                if (item.codigo === res.codigo) {
                  item = res
                }
                if (item.telefone) {
                  item.telefone = formatPhoneNumber(item.telefone)
                }

                if (item.cpfOuCnpj.length === 11) {
                  item.cpfOuCnpj = formatCPF(item.cpfOuCnpj)
                } else {
                  item.cpfOuCnpj = formatCnpj(item.cpfOuCnpj)
                }

                return item
              })

              return stateCopy
            })
            await getData()
            onClose()
            useAlert('Centro de Custo alterado com sucesso!', 'Success')
          }
        }

        if (callback) callback()
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    },
  })

  const Header = ({onClick}: any) => {
    return (
      <ModalHeader>
        <span>{isEditing ? 'Editar Centro de Custo' : 'Criar Centro de Custo'}</span>
        <button onClick={onClick}>
          <CloseIcon size={20} fill='#666666' />
        </button>
      </ModalHeader>
    )
  }

  const handleSetInputValue = async (field: fields, value: string) => {
    await formik.setFieldValue(field, value)
  }

  async function getData() {
    try {
      const res = await GetActiveResearches<ActiveResearcers[]>()

      const o: ResearchersData[] = []

      if (res) {
        res.map((item) => {
          let index = -1

          if (centerCostInfo.pesquisadores && centerCostInfo.pesquisadores.length > 0)
            index = centerCostInfo.pesquisadores.findIndex((i) => i.codigo === item.codigo)

          o.push({
            checked: index === -1 ? false : true,
            cpf: item.cpf || '000',
            nome: item.nome,
            codigo: item.codigo,
          })
        })

        setRawResearchers(res)

        setResearchers(o)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AnimatedModal
      containerStyle={{padding: 16}}
      onClose={onClose}
      header={<Header />}
      footer={<Footer isEditing={isEditing} formik={formik} />}
    >
      <Container>
        <AccordionCollapseItem height={323} title='Cadastro' Icon={BuildingIcon} initOpen>
          <div className='container-row'>
            <ContainerBox>
              <InputText
                height={80}
                required
                placeholder='Digite a Razão Social'
                label='Razão Social'
                formik={{
                  formikProp: 'razaoSocial',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
            <ContainerBox>
              <InputText
                placeholder='Digite o Nome Fantasia'
                height={80}
                label='Nome Fantasia'
                formik={{
                  formikProp: 'nomeFantasia',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
          </div>
          <div className='container-row'>
            <ContainerBox>
              <InputText
                required
                height={80}
                link={{
                  text: ' É pesquisador?',
                  action: handleOpenShowIsResearcherMoldal,
                }}
                label='Proprietário'
                placeholder='Digite o nome do Proprietário'
                formik={{
                  formikProp: 'proprietario',
                  formikValues: formik,
                }}
              />
            </ContainerBox>

            <ContainerBox>
              <InputText
                required
                height={80}
                placeholder='Digite o CPF ou CNPJ'
                label='CPF/CNPJ'
                formik={{
                  formikProp: 'cpfCnpj',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
          </div>
          <div className='container-row'>
            <ContainerBox>
              <InputText
                required
                height={80}
                placeholder='Digite o email'
                label='E-mail'
                formik={{
                  formikProp: 'email',
                  formikValues: formik,
                }}
              />
            </ContainerBox>

            <ContainerBox>
              <Label>Telefone</Label>
              <PhoneInput
                disableCountryCode
                country={'br'}
                specialLabel=''
                placeholder='99999-9999'
                inputClass='bordered'
                inputStyle={{height: 43.56, width: '100%'}}
                {...formik.getFieldProps('telefone')}
                onChange={(value: any) => {
                  formik.setFieldValue('telefone', value)
                }}
              />
              {formik && formik.touched['telefone'] && formik.errors['telefone'] && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors['telefone']}</span>
                </div>
              )}
            </ContainerBox>
          </div>
        </AccordionCollapseItem>
        <AccordionCollapseItem height={224} title='Dados Bancários' Icon={BankIcon}>
          <div className='container-row'>
            <ContainerBox>
              <Select
                label='Banco'
                error={Boolean(formik.errors['banco'])}
                options={bancosList}
                formik={{
                  formikProp: 'banco',
                  formikValues: formik,
                }}
              />
              {formik && formik.touched['banco'] && formik.errors['banco'] && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors['banco'].value}</span>
                </div>
              )}
            </ContainerBox>

            <ContainerBox>
              <Select
                label='Tipo Conta'
                error={Boolean(formik.errors['tipoConta'])}
                options={contasBancariasList}
                formik={{
                  formikProp: 'tipoConta',
                  formikValues: formik,
                }}
              />
              {formik && formik.touched['tipoConta'] && formik.errors['tipoConta'] && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors['tipoConta'].value}</span>
                </div>
              )}
            </ContainerBox>
          </div>

          <div className='container-row'>
            <ContainerBox>
              <InputText
                type='number'
                label='Agência'
                formik={{
                  formikProp: 'agencia',
                  formikValues: formik,
                }}
              />
            </ContainerBox>

            <ContainerBox>
              <InputText
                type='number'
                label='Conta'
                formik={{
                  formikProp: 'conta',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
            <ContainerBox>
              <InputText
                placeholder='Digite a chave pix'
                label='Chave pix'
                formik={{
                  formikProp: 'pix',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
          </div>
        </AccordionCollapseItem>
      </Container>

      {showIsResearcherMoldal && (
        <IsResearcherMoldal
          researchers={researchers}
          rawResearchers={rawResearchers}
          onClose={handleCloseShowIsResearcherMoldal}
          handleSetInputValue={handleSetInputValue}
        />
      )}
    </AnimatedModal>
  )
}

export type ActiveResearcers = {
  codigo: string
  nome: string
  numeroTelefone: string
  cpf: string
  email: string
}

type Props = {
  onClose: () => void
  callback?: () => void
  isEditing: boolean
  setData: React.Dispatch<React.SetStateAction<CentroCustoData>>
  centerCostInfo: ICostCenterResponse
  data: CentroCustoData
  getData: () => void
}

export type fields =
  | 'agencia'
  | 'nomeFantasia'
  | 'proprietario'
  | 'email'
  | 'cpfCnpj'
  | 'razaoSocial'
  | 'telefone'
  | 'pix'
  | 'conta'

export default CostCenterCreationModal
