import {Container} from './styles'

import {CloseIconOutline, WarningIcon} from 'src/app/utils/Icons'

type Props = {
  message: string | undefined
  handleClose?: () => void
  show: boolean
}

const ErrorMessage = ({message, handleClose, show = false}: Props) => {
  if (!show) return <></>

  return (
    <Container>
      <div>
        <WarningIcon color='#ee7171' size={20} />
        <span className='message'>{message}</span>
      </div>
      <span className='close' onClick={handleClose}>
        <CloseIconOutline color='#ee7171' size={20} />
      </span>
    </Container>
  )
}

export default ErrorMessage
