import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1% 1.7% 0 3%;

  width: 100%;
`
