import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import { WithChildren } from 'src/_metronic/helpers';
import { ExclusaoModel } from "../model/ManutencaoExclusao/ExclusaoModel";
import { IFiltersSolicitacao } from "../model/ManutencaoExclusao/Type";
import { GetDateNow } from "../utils/Date";

interface SolicitacaoContextProps {
    solicitacao: ExclusaoModel[]
    setSolicitacao: Dispatch<SetStateAction<ExclusaoModel[]>>
    filters: IFiltersSolicitacao,
    setFilters: Dispatch<SetStateAction<IFiltersSolicitacao>>
}

export const ManutencaoSolicitacaoContext = createContext<SolicitacaoContextProps | null>(null);

export const ManutencaoSolicitacaoContextProvider: FC<WithChildren> = ({children}) => {
    //data atual
    const setDefaultDate = GetDateNow();
    const status = "PENDENTE";

    const [solicitacao, setSolicitacao] = useState<ExclusaoModel[]>([]);
    const [filters, setFilters] = useState<IFiltersSolicitacao>({
        dataInicio: setDefaultDate,
        dataFim: setDefaultDate,
        status: status,
        codigoPesquisador: null
    });

    return (
        <ManutencaoSolicitacaoContext.Provider
            value={{ solicitacao, setSolicitacao, filters, setFilters }}
        >
            {children}
        </ManutencaoSolicitacaoContext.Provider>
    )
}