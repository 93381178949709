/* eslint-disable react/jsx-no-target-blank */
import {useAuth} from 'src/app/hooks/useAuth'
import {SidebarMenuItem} from './SidebarMenuItem'
import {
  LogOutIcon,
  ConfigurationIcon,
  RouteIcon,
  MapMarkedIcon,
  ApiIcon,
  PerformanceIcon,
  ExportFileIcon,
} from 'src/app/utils/Icons'
import {FaWrench} from 'react-icons/fa6'

const SidebarMenuMain = () => {
  const {currentUser, logout} = useAuth()
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Avaliação de Pesquisa
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/validacao'
        iconName='questionnaire-tablet'
        title='Validação'
        fontIcon='bi-ui-radios'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Planejamento de execução
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to='/centro_custo'
        iconName='finance-calculator'
        title='Centro de custo'
        fontIcon='bi-diagram-3-fill'
      />
      <SidebarMenuItem
        to='/usuario'
        iconName='profile-user'
        title='Usuário'
        fontIcon='bi-profile-user-fill'
      />
      <SidebarMenuItem to='/base_operacional' Icon={MapMarkedIcon} title='Base Operacional' />
      <SidebarMenuItem data-test='aRota' to='/rota' Icon={RouteIcon} title='Rota' />
      <SidebarMenuItem to='/manutencao_exclusao' Icon={FaWrench} title='Manutenção de Exclusão' />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Liberações</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/liberacao_por_assistente'
        title='Avaliações por Assistentes'
        Icon={PerformanceIcon}
      />

      <SidebarMenuItem to='/exportacao' title='Exportação' Icon={ExportFileIcon} />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Configuração</span>
        </div>
      </div>
      <SidebarMenuItem to='/coletaiApi' Icon={ApiIcon} title='Coletaí API' />
      <div
        style={{
          marginTop: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
        className='divisor'
      >
        <SidebarMenuItem
          action={() => {}}
          img={currentUser?.imagem}
          title={currentUser!.nome}
          fontIcon='bi-profile-user-fill'
        />
        <SidebarMenuItem
          action={() => {}}
          Icon={ConfigurationIcon}
          title='Configurações'
          fontIcon='bi-profile-user-fill'
        />
        <SidebarMenuItem
          action={logout}
          title='Sair'
          fontIcon='bi-profile-user-fill'
          Icon={LogOutIcon}
        />
      </div>
    </>
  )
}

export {SidebarMenuMain}
