import TablePainel from "src/app/components/TablePainel";
import { TableColumn, TableExportProps, TableRowAction } from "src/app/components/TablePainel/table-painel";
import { ExclusaoModel } from 'src/app/model/ManutencaoExclusao/ExclusaoModel';

export default function ExclusaoTable({
    data, 
    getData, 
    isLoading, 
    setAtendimentoHomologacao, 
    deletePostoDaBase, 
    markOffPosto, 
    rowSelect, 
    setRowSelect
}: Props) {
    const PENDENTE = "PENDENTE";
    const tableColumns: TableColumn[] = [
        {
            name: "Código Triad",
            accessor: "identificadorPonto"
        },
        {
            name: "CNPJ",
            accessor: "cnpj"
        },
        {
            name: "Tipo Execução",
            accessor: "tipoExecucao"
        },
        {
            name: "Ciclo",
            accessor: "rota"
        },
        {
            name: "Cidade",
            accessor: "cidade"
        },
        {
            name: "UF",
            accessor: "uf"
        },
        {
            name: "Pesquisador",
            accessor: "pesquisador"
        }, 
        {
            name: "Data",
            accessor: "dataSolicitacao"
        },
        {
            name: "Solicitante",
            accessor: "solicitante"
        },
        {
            name: "Motivo",
            accessor: "motivoSolicitacao"
        },
        {
            name: "Código Atendimento",
            accessor: "codigoExterno"
        },
        {
            name: "Fonte",
            accessor: "fonte"
        },
        {
            name: "Situação",
            accessor: "situacaoSolicitacao"
        },
        {
            name: "Data Remoção",
            accessor: "remocaoSolicitacao"
        },
        {
            name: "Removido Por",
            accessor: "usuarioResponsavelRemocao"
        }
    ];
    const tableRowsActions: TableRowAction[] = [
        {
            label: 'Exibir PR',
            icon: 'eye',
            onClick: (row: ExclusaoModel) => setAtendimentoHomologacao(row.codigoAtendimento),
        },
        {
            label: 'Excluir posto',
            icon: 'trash',
            onClick: (row: ExclusaoModel) => deletePostoDaBase( [row.codigo] ),
            condition: (row: ExclusaoModel) => row.situacaoSolicitacao === PENDENTE
        },
        {
            label: 'Desmarcar posto',
            icon: 'notepad-edit',
            onClick: (row: ExclusaoModel) => markOffPosto( [row.codigo] ),
            condition: (row: ExclusaoModel) => row.situacaoSolicitacao === PENDENTE
        }
    ];
    const tableExports: TableExportProps = {
        pdf: {
            fileName: 'manutencao_exclusao_base',
          },
          excel: {
            fileName: 'manutencao_exclusao_base',
            sheetName: 'base',
          },
          csv: {
            fileName: 'manutencao_exclusao_base',
          }
    };

    const conditionDisabledSelect = (id: any) => {
        let item = data.filter(item => item.codigo == id)[0];
        return item.situacaoSolicitacao !== PENDENTE;
    }

    return (
        <TablePainel
            tableTitle='Tabela Solicitações'
            data={data}
            getData={getData} 
            columns={tableColumns}
            rowActions={tableRowsActions}
            tableExportProps={tableExports}
            isLoading={isLoading}
            paginate={false}
            createDataButton={false}
            isCheckbox={true}
            rowSelect={rowSelect}
            setRowSelect={setRowSelect}
            conditionDisabledSelect={conditionDisabledSelect}
        />
    )
}

interface Props {
    data: ExclusaoModel[]
    getData: () => void
    isLoading?: boolean
    setAtendimentoHomologacao: (codigoAtendimento: number) => void 
    deletePostoDaBase: (codigos: number[]) => void
    markOffPosto: (codigos: number[]) => void,
    rowSelect: string[],
    setRowSelect: (codigos: string[]) => void
}