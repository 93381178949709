import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  background-color: #ffecec;

  border: 3px solid #ee7171;
  border-radius: 5px;

  padding: 4px;
  margin: 8px 16px -8px 16px;

  div {
    display: flex;
    align-items: flex-end;
    gap: 10px;

    width: 100%;
  }

  .close {
    cursor: pointer;
  }

  .message {
    font-weight: bold;
    font-family: Poppins;
    font-size: 13px;
    color: #ee7171;
  }
`
