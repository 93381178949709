/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {useRef, useState} from 'react'

import TablePainel from 'src/app/components/TablePainel'

import {
  TableColumn,
  TableExportProps,
  TableFilters,
  TableRowAction,
} from 'src/app/components/TablePainel/table-painel'
import EditRouteModal from '../EditRouteModal'
import RouteCreationModal from '../RouteCreationModal'

import {useAlert} from 'src/app/components/Alert/Alert'
import ImportModal, {errorMessage} from 'src/app/components/ImportModal'
import ModalAlert from 'src/app/components/ModalAlert'
import NotificationHub, {AddFunction} from 'src/app/components/NotificationHub'
import {useRoute} from 'src/app/hooks/useRoute'
import RotaModel from 'src/app/model/Rota/Rota'
import {IRoute} from 'src/app/model/Rota/Types'
import {
  DetailRouteResponse,
  DownloadRouteXlsx,
  GetRouteDetail,
  ListErrorsFromSyncronizationRoute,
  RouteResponse,
  SyncAllRoutesWithColetaiApi,
  SyncRouteWithColetaiApi,
  UpdateRouteStatus,
  UpdateRouteStatusData,
  ValidacaoImportFileRouteHeadersError,
  ValidacaoImportPlanejamentoRoute,
  ValidacaoImportacaoMultipla,
  serializeListRoute,
} from 'src/app/repositories/Route'
import {SheetData, saveExcelFile} from 'src/app/utils/Files'
import { IModalAlert} from '../../../usuario/Usuarios'

import Modal from 'src/app/components/Modal'
import {IErroSincronization} from 'src/app/model/ErrorSincronization/type'
import { IFilter } from 'src/app/model/Generic/Type'

const IMPORT_EXCEL_MODEL_PONTOS = [
  {
    sheetLabel: 'Pontos Rota',
    sheetName: 'pontos_rotas',
    values: {
      'NOME ROTA': '',
      'IDENTIFICADOR PONTO': '',
    },
  },
]

const IMPORT_EXCEL_MODEL_ROTAS = [
  {
    sheetLabel: 'Rotas',
    sheetName: 'rota',
    values: {
      'NOME ROTA': '',
      CIDADE: '',
      'CPF PESQUISADOR': '',
      SEGUNDA: '',
      TERÇA: '',
      QUARTA: '',
      QUINTA: '',
      SEXTA: '',
      SABADO: '',
      DOMINGO: '',
      'DATA INICIO': '00/00/0000',
      'DATA EXPIRAÇÃO': '',
      INTERVALO: 1,
      ANTECEDENCIA: 0,
      PENDENCIA: 0,
    },
  },
]

const RouteTable = ({
  filters,
  getRoutes,
  routes,
  applyFilters,
  cleanFilters,
  ufOptions,
  pagesTotal,
}: Props) => {
  const {updateRoutes, setRouteDetail, updateMultipleRoutes} = useRoute()

  const [showRouteCreationModal, setShowRouteCreationModal] = useState(false)
  const [showRoutePlanningModal, setShowRoutePlanningModal] = useState(false)
  const [ShowRoutesImportModal, setShowRoutesImportModal] = useState(false)
  const [showModalSyncError, setShowModalSyncError] = useState(false)
  const [showEditRouteModal, setShowEditRouteModal] = useState(false)

  const [modalAlert, setModalAlert] = useState<IModalAlert | undefined>(undefined)
  const [dataErrorSync, setDataErrorSync] = useState<IErroSincronization[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleGetRouteDetail = async (codigo: number) => {
    try {
      const res = await GetRouteDetail(codigo)

      if (res) {
        setRouteDetail(res)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const handleDownloadRouteXlsx = async (nome: string, codigo: number) => {
    try {
      const res = await DownloadRouteXlsx(codigo)

      if (res) {
        const dataRota = [
          {
            CIDADE: res.cidadeRota,
            'CPF PESQUISADOR': res.cpfPesquisador,
            'DATA INICIO': res.dataInicio,
            'DATA EXPIRAÇÃO': res.dataExpiracao,
            SEGUNDA: res.segunda,
            TERÇA: res.terca,
            QUARTA: res.quarta,
            QUINTA: res.quinta,
            SEXTA: res.sexta,
            SABADO: res.sabado,
            DOMINGO: res.domingo,
            INTERVALO: res.intervalo,
            ANTECEDENCIA: res.antecedencia,
            PENDENCIA: res.pendencia,
          },
        ]

        const dataPontosDeColeta = res.identificadoresPontosDeColeta.map((item) => ({
          'IDENTIFICADOR PONTO': item,
        }))

        const sheetData: SheetData[] = [
          {
            sheetName: 'rota',
            data: dataRota,
          },
          {
            sheetName: 'pontos_de_coleta',
            data:
              dataPontosDeColeta.length > 0 ? dataPontosDeColeta : [{'IDENTIFICADOR PONTO': ''}],
          },
        ]

        saveExcelFile(sheetData, `${nome}.xlsx`)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const handleSyncronizeRouteOnColetai = async (codigoRota: number) => {
    try {
      const res = await SyncRouteWithColetaiApi(codigoRota)
      if (res) {
        updateRoutes(res)
        useAlert(`Rota ${res.nome} sincronizada com o Coletaí`, 'Success')
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const handleSyncronizeAllRoutesOnColetai = async () => {
    try {
      setIsLoading(true)
      const res = await SyncAllRoutesWithColetaiApi()
      if (res) {
        updateMultipleRoutes(res)
        useAlert('Rotas Sincronizadas Com o coletai Api', 'Success')
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleListErrorSincronizationRoute = async (codigoRota: number) => {
    try {
      const res = await ListErrorsFromSyncronizationRoute(codigoRota)

      if (res) {
        setDataErrorSync(res)
        setShowModalSyncError(true)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const handleUpdateRouteStatus = (row: RotaModel) => {
    const action = async () => {
      setIsLoading(true)
      try {
        const o: UpdateRouteStatusData = {
          ativo: row?.status === 'Ativo' ? false : true,
          codigo: row.codigo,
        }
        const res = await UpdateRouteStatus(o)

        if (res) {
          updateRoutes(res)

          useAlert(`Rota ${res.nome} atualizada com sucesso`, 'Success')
        }
      } catch (error: any) {
        useAlert(error.message, 'Error')
      } finally {
        setIsLoading(false)
        setModalAlert(undefined)
      }
    }

    let content = `Confirma a ativação da rota ${row.nome}?`

    if (row.status === 'Ativo')
      content = `Confirma a inativação da rota ${row.nome}? Esta ação removerá o pesquisador da rota especificada. Se esta for a única rota no planejamento do pesquisador, ele também será inativado. Deseja continuar?`

    setModalAlert({
      title: `${row.status === 'Ativo' ? 'Inativar' : 'Ativar'} rota`,
      content,
      action,
    })
  }

  const columns: TableColumn[] = [
    {name: 'Status Pendência', accessor: 'statusPendencia'},
    {name: 'Nome', accessor: 'nome'},
    {name: 'Cidade/UF', accessor: 'cidadeUf'},
    {name: 'Nome do Pesquisador', accessor: 'nomePesquisador'},
    {name: 'CPF do pesquisador', accessor: 'cpfPesquisador'},
    {name: 'Situação', accessor: 'status'},
    {name: 'Agenda da semana', accessor: 'agendaDaSemana'},
    {name: 'Intervalo', accessor: 'intervalo'},
    {name: 'Frequência', accessor: 'frequencia'},
    {name: 'Antecedência', accessor: 'antecedencia'},
    {name: 'Pendência', accessor: 'pendencia'},
    {name: 'Data Início ', accessor: 'dataInicio'},
    {name: 'Data Expiração', accessor: 'dataExpiracao', hidden: true},
    {name: 'Qtd. Pontos de coleta', accessor: 'contagemPontosColeta'},
    {name: 'Pendencia Sincronização', accessor: 'temPendenciaSincronizacao', hidden: true},
  ]

  const actions: TableRowAction[] = [
    {
      label: 'Editar',
      condition: (row: RotaModel) => true,
      icon: 'pencil',
      onClick: (row: RotaModel) => {
        throwTableAction('Editar', row)
      },
    },
    {
      label: 'Baixar',
      icon: 'cloud-download',
      onClick: (row: RotaModel) => throwTableAction('Baixar', row),
      condition: (row: RotaModel) => true,
    },
    {
      label: 'Ativar',
      icon: 'check',
      condition: (row: RotaModel) => row.status === 'Inativo',
      onClick: (row: RotaModel) => throwTableAction('Ativar', row),
    },
    {
      label: 'Inativar',
      icon: 'check',
      condition: (row: RotaModel) => row.status === 'Ativo',
      onClick: (row: RotaModel) => throwTableAction('Inativar', row),
    },
    {
      label: 'Sincronizar',
      icon: 'arrows-circle',
      condition: (row: RotaModel) => row.temPendenciaSincronizacao === 'Sim',
      onClick: (row: RotaModel) => throwTableAction('Sincronizar', row),
    },
    {
      label: 'Erros Sincronizacao',
      icon: 'burger-menu',
      condition: (row: RotaModel) => row.temPendenciaSincronizacao === 'Sim',
      onClick: (row: RotaModel) => throwTableAction('Erros Sincronização', row),
    },
  ]
  const tableExportProps: TableExportProps = {
    pdf: {
      fileName: 'tabela_rota',
    },
    excel: {
      fileName: 'tabela_rota',
      sheetName: 'tabela_rota',
    },
    csv: {
      fileName: 'tabela_rota',
    },
  }

  const throwTableAction = async (
    action:
      | 'Editar'
      | 'Ativar'
      | 'Inativar'
      | 'Reenviar Senha'
      | 'Baixar'
      | 'Sincronizar'
      | 'Erros Sincronização',
    row: RotaModel
  ) => {
    switch (action) {
      case 'Ativar':
        handleUpdateRouteStatus(row)
        break
      case 'Inativar':
        handleUpdateRouteStatus(row)
        break
      case 'Editar':
        // setIsEditing(true)
        await handleGetRouteDetail(row.codigo)
        handleOpenEditRouteModal()
        break
      case 'Reenviar Senha':
        // sendPassword(row)
        break
      case 'Sincronizar':
        await handleSyncronizeRouteOnColetai(row.codigo)
        break
      case 'Baixar':
        await handleDownloadRouteXlsx(row.nome, row.codigo)
        break
      case 'Erros Sincronização':
        await handleListErrorSincronizationRoute(row.codigo)
        break
      default:
        break
    }
  }

  const handleCloseRouteCreationModal = () => {
    setShowRouteCreationModal(false)
  }

  const handleOpenShowRouteCreationModal = () => setShowRouteCreationModal(true)

  const handleOpenRoutesImportModal = () => setShowRoutesImportModal(true)
  const handleCloseRoutesImportModal = () => setShowRoutesImportModal(false)

  const handleOpenShowRoutePlanningModal = () => setShowRoutePlanningModal(true)
  const handleCloseShowRoutePlanningModal = () => setShowRoutePlanningModal(false)

  const handleOpenEditRouteModal = () => {
    setShowEditRouteModal(true)
  }

  const handleCloseEditRouteModal = () => {
    setShowEditRouteModal(false)
    setRouteDetail({} as DetailRouteResponse)
  }

  const openEditRouteModalAfterCreateRoute = async (codigo: number) => {
    await handleGetRouteDetail(codigo)
    handleOpenEditRouteModal()
  }

  const handlerHeaderColumnErrors = (
    erros: ValidacaoImportFileRouteHeadersError,
    name: string = 'pontos_de_coleta'
  ) => {
    let err: errorMessage = []

    if (erros.rotas.length > 0) {
      const preFix = 'As seguintes colunas na aba de rotas não existem '
      const columns = erros.rotas

      err.push({columns, preFix})
    }
    if (erros.pontos_de_coleta.length > 0) {
      const preFix = `As seguintes colunas na aba de ${name} não existem`
      const columns = erros.pontos_de_coleta

      err.push({columns, preFix})
    }

    return err
  }

  const onImportPlanning = async (file: File) => {
    try {
      const res = await ValidacaoImportPlanejamentoRoute(file)

      const {excelError: excelerror, excelSucces, headersError} = res!

      const excel = excelSucces as IRoute[]
      const excelError = excelerror as RouteResponse[]

      if (excel.length > 0) {
        handleSyncronizeAllRoutesOnColetai()
        updateMultipleRoutes(serializeListRoute(excel))
        useAlert(`${excel.length} Planejamentos alterados com sucesso.`, 'Success')
      }

      if (excel.length > 0 && excelError.length === 0) {
        handleCloseShowRoutePlanningModal()
        return {
          columns: [],
          excelSuccess: {},
          errorsMessages: [],
        }
      }

      if (excelError) {
        let rota = excelError

        const rotaColumns = [
          {name: 'NOME ROTA', accessor: 'nomeRota'},
          {name: 'CIDADE', accessor: 'cidadeRota'},
          {name: 'CPF PESQUISADOR', accessor: 'cpfPesquisador'},
          {name: 'DOM', accessor: 'domingo'},
          {name: 'SEG', accessor: 'segunda'},
          {name: 'TER', accessor: 'terca'},
          {name: 'QUA', accessor: 'quarta'},
          {name: 'QUI', accessor: 'quinta'},
          {name: 'SEX', accessor: 'sexta'},
          {name: 'SAB', accessor: 'sabado'},
          {name: 'DATA INICIO', accessor: 'dataInicio'},
          {name: 'DATA EXPIRACAO', accessor: 'dataExpiracao'},
          {name: 'INTERVALO', accessor: 'intervalo'},
          {name: 'FREQUENCIA', accessor: 'frequencia'},
          {name: 'DIA ANTECEDENCIA', accessor: 'antecedencia'},
          {name: 'DIA PENDENCIA', accessor: 'pendencia'},
        ]

        const errorsMessages = handlerHeaderColumnErrors(headersError)

        useAlert(`Houve erro em ${excelError.length} planejamentos`, 'Error')

        return {
          columns: [
            {
              name: 'Rotas',
              data: rotaColumns,
            },
          ],
          excelSuccess: {Rotas: rota},
          errorsMessages,
        }
      }

      const errorsMessages = handlerHeaderColumnErrors(headersError)

      return {
        columns: [],
        excelSuccess: {},
        errorsMessages,
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const onImportMultiple = async (file: File) => {
    try {
      const res = await ValidacaoImportacaoMultipla(file)

      if (res) {
        const {excelError, excelSucces, headersError} = res

        const excel = excelSucces as IRoute[]

        if (excel.length > 0) {
          handleSyncronizeAllRoutesOnColetai()
          updateMultipleRoutes(serializeListRoute(excel))
          useAlert(`${excel.length} Postos atualizados com sucesso`, 'Success')
        }

        if (excel.length > 0 && excelError.length === 0) {
          handleCloseRoutesImportModal()
          return {
            columns: [],
            excelSuccess: {},
            errorsMessages: [],
          }
        }

        const pontos_rota = excelError

        const pontosRotaColumns = [
          {name: 'NOME ROTA', accessor: 'nomeRota'},
          {name: 'IDENTIFICADOR PONTO', accessor: 'identificadorPonto'},
        ]

        const errorsMessages = handlerHeaderColumnErrors(headersError, 'pontos_rota')

        const nomesRotasUnicos = new Set()

        excelError.forEach((item) => {
          nomesRotasUnicos.add(item.nomeRota)
        })

        useAlert(`Houve erro em ${nomesRotasUnicos.size} rotas`, 'Error')

        return {
          columns: [
            {
              name: 'Pontos Rota',
              data: pontosRotaColumns,
            },
          ],
          excelSuccess: {'Pontos Rota': pontos_rota},
          errorsMessages,
        }
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }

    return {
      columns: [],
      excelSuccess: {},
      errorsMessages: [],
    }
  }

  const ref = useRef<null | AddFunction>(null)

  return (
    <>
      {showEditRouteModal && (
        <EditRouteModal
          ufOptions={ufOptions}
          handleDownloadRouteXlsx={handleDownloadRouteXlsx}
          onClose={handleCloseEditRouteModal}
          setIsLoading={setIsLoading}
          setModalAlert={setModalAlert}
        />
      )}
      {showRoutePlanningModal && (
        <ImportModal
          sheets={['Rotas']}
          title={`upload planejamento rotas`}
          modelFileName='exportacao_rotas_planejamento'
          onClose={handleCloseShowRoutePlanningModal}
          onImportFile={onImportPlanning}
          excelModel={IMPORT_EXCEL_MODEL_ROTAS}
          instructions={[
            'Os campos "ROTA", "CIDADE" e "DATA INICIO" são obrigatórios',
            'A "FREQUÊNCIA" e os dias  da semana marcados com "x" devem ser quantitivamente iguais',
          ]}
        />
      )}

      {ShowRoutesImportModal && (
        <ImportModal
          sheets={['Pontos Rota']}
          title={`upload postos rotas`}
          modelFileName='exportacao_rotas_postos'
          onClose={handleCloseRoutesImportModal}
          onImportFile={onImportMultiple}
          excelModel={IMPORT_EXCEL_MODEL_PONTOS}
          instructions={[
            'Os campos "ROTA" e "CODIGO POSTO"  são obrigatórios',
            'A ordem dos postos no arquivo importa, logo, ordenação na rota depende de como os postos serão ordenados de cima para baixo no excel',
            'Fique atento para não duplicar os postos na mesma rota',
          ]}
        />
      )}

      {showRouteCreationModal && (
        <RouteCreationModal
          callback={openEditRouteModalAfterCreateRoute}
          ufOptions={ufOptions}
          onClose={handleCloseRouteCreationModal}
        />
      )}

      {showModalSyncError && (
        <Modal
          title={{message: `Erros da sincronização da rota: ${dataErrorSync[0]?.nomeRota}`}}
          open={showModalSyncError}
          handleClose={() => setShowModalSyncError(false)}
        >
          <ul>
            {dataErrorSync?.map((error, index) => (
              <li
                key={index}
                className='bg-light-primary'
                style={{
                  borderRadius: '8px',
                  padding: '16px',
                  listStyle: 'none',
                  marginTop: '24px',
                }}
              >
                <span className='bullet bg-primary me-2 mb-1'></span>
                <b>{error.detalheErro}</b>
                <br />
                <span>Data da sincronização: {error.dataSincronizacao}</span>
              </li>
            ))}
          </ul>
        </Modal>
      )}

      <ModalAlert
        isLoading={isLoading}
        title={modalAlert?.title}
        content={modalAlert?.content}
        onConfirm={modalAlert?.action}
        onShow={Boolean(modalAlert)}
        onHide={() => setModalAlert(undefined)}
      />

      <NotificationHub
        children={(add: AddFunction) => {
          ref.current = add
        }}
        onClick={async (codigo) => {
          await handleGetRouteDetail(codigo)
          handleOpenEditRouteModal()
        }}
      />

      <TablePainel
        pagesTotal={pagesTotal}
        isLoading={isLoading}
        tableTitle='Tabela de Rotas'
        columns={columns}
        data={routes}
        rowActions={actions}
        tableExportProps={tableExportProps}
        onCreateData={handleOpenShowRouteCreationModal}
        // onImportData={handleOpenModalIndividualImport}
        getData={getRoutes}
        filters={filters}
        applyFilters={applyFilters}
        cleanFilters={cleanFilters}
        onImportPlanning={handleOpenShowRoutePlanningModal}
        importPlanningButton
        importPostButton
        onImportPosts={handleOpenRoutesImportModal}
        buttons={[
          {
            icon: 'add-item',
            label: 'Sincronizar',
            onClick: () => {
              handleSyncronizeAllRoutesOnColetai()
            },
          },
        ]}
      />
    </>
  )
}

type Props = {
  pagesTotal: number
  isLoading: boolean
  routes: RotaModel[]
  getRoutes: () => void
  cleanFilters: () => void
  applyFilters: () => void
  filters: TableFilters[]
  ufOptions: IFilter[]
}

export default RouteTable
