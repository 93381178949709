import styled, {css} from 'styled-components'

type BadgeProps = {
  status: string
}

export const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  max-width: 1000px;
  width: 100%;
`

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  background-color: #ff6f71;
  padding: 4px 8px;
  border-radius: 8px;
  width: fit-content;

  span {
    color: white;
    font-weight: bold;
    font-size: 14px;
  }

  :nth-child(2) {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 0 4px;
    background-color: #33c3e6;
  }
`

export const BadgeSecondary = styled.div<BadgeProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 8px;
  width: fit-content;
  color: white;
  font-weight: bold;
  font-size: 14px;

  ${({status}) => css`
    background-color: ${status === 'APROVADO' ? '#04AA6D' : '#ff6f71'};
  `}
`
