import {useState} from 'react'
/* eslint-disable react-hooks/rules-of-hooks */
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'

import AnimatedModal from 'src/app/components/AnimatedModal'
import InputText from 'src/app/components/Form/InputText'
import {Select} from 'src/app/components/Select/Select'
import {ContainerBox} from '../../../CentroCusto/components/CostCenterCreationModal/styles'
import {optionsValue} from '../../../usuario/components/UserCreationModal/validations'

import {IinitialValues, OptionType, Props} from './routeCreationModal'

import {Container} from './styles'
import {
  codigoValidation,
  cidadeValidation,
  execucaoValidation,
  nomeValidation,
  ufValidation,
} from './routeCreationModalValidations'
import {CreateRoute, GetNextCodeRoute} from 'src/app/repositories/Route'
import {useAlert} from 'src/app/components/Alert/Alert'
import {useRoute} from 'src/app/hooks/useRoute'
import {GetMunicipiosPorEstado} from 'src/app/repositories/Location'

const RouteCreationModal = ({onClose, ufOptions, callback}: Props) => {
  const intl = useIntl()
  const {setRoutes} = useRoute()
  const [cidadeOptions, setCidadeOptions] = useState<
    {
      value: string
      label: string
    }[]
  >([])
  const [nextCodeRoute, setNextCodeRoute] = useState<string>('000')

  const initialValues = {
    nome: '',
    codigo: '',
    uf: optionsValue,
    cidade: optionsValue,
    execucao: optionsValue,
  }

  const validationSchema = Yup.object().shape({
    codigo: codigoValidation(intl),
    nome: nomeValidation(intl),
    cidade: cidadeValidation(intl),
    execucao: execucaoValidation(intl),
    uf: ufValidation(intl),
  })

  const formik = useFormik<IinitialValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const {nome, cidade, uf} = values

      try {
        const res = await CreateRoute({uf: String(uf.value), cidade: String(cidade.value), nome})

        if (res) {
          setRoutes((oldState) => [res, ...oldState])
          callback(res.codigo)
          onClose()
        }
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    },
  })

  const getMunicipiosPorEstado = async (uf: string) => {
    if (uf && uf !== null) {
      try {
        const res = await GetMunicipiosPorEstado(String(uf))

        if (res) setCidadeOptions(res)
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    }
  }

  const getNextCodeRoute = async (uf: string, tipoExecuxao: string) => {
    if (uf && tipoExecuxao) {
      try {
        const res = await GetNextCodeRoute(uf, tipoExecuxao)
        if (res) setNextCodeRoute(res)
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    }
  }

  return (
    <AnimatedModal
      formik={formik}
      containerStyle={{maxWidth: 1000, height: 'auto', overflowY: 'initial'}}
      footerTitle='Criar'
      headerTitle='Criar Rota'
      onClose={onClose}
    >
      <Container>
        <div className='row'>
          <ContainerBox>
            <ContainerBox>
              <Select
                dataTest='oUF'
                hideExclusion
                onChange={async (newValue) => {
                  const value = newValue as OptionType
                  await formik.setFieldValue('uf', value)

                  const exe = await formik.getFieldProps('execucao').value.value
                  const cod = await formik.getFieldProps('codigo').value
                  const uf = value.value

                  await formik.setFieldValue('nome', `${uf}${exe}${cod}`)

                  await getMunicipiosPorEstado(uf as string)
                  await getNextCodeRoute(uf + '', exe + '')
                }}
                error={Boolean(formik.errors['uf'])}
                options={ufOptions}
                formik={{
                  formikProp: 'uf',
                  formikValues: formik,
                }}
                label='UF'
              />
              {formik.errors['uf'] && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors['uf'].label}</span>
                </div>
              )}
            </ContainerBox>
          </ContainerBox>
          <ContainerBox flex={3}>
            <Select
              dataTest='oExecucao'
              hideExclusion
              onChange={async (newValue) => {
                const value = newValue as OptionType
                await formik.setFieldValue('execucao', value)

                const exe = value.value
                const cod = await formik.getFieldProps('codigo').value
                const uf = await formik.getFieldProps('uf').value.value

                await formik.setFieldValue('nome', `${uf}${exe}${cod}`)
                await getNextCodeRoute(uf + '', exe + '')
              }}
              error={Boolean(formik.errors['execucao'])}
              options={[
                {value: 'Z', label: 'Z - AUTO/GLP'},
                {value: 'Y', label: 'Y - GLP'},
                {value: 'X', label: 'X - AUTO'},
                {value: '', label: 'AUTO TODOS CLIENTE'},
              ]}
              formik={{
                formikProp: 'execucao',
                formikValues: formik,
              }}
              label='Execução'
            />
            {formik.errors['execucao'] && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors['execucao'].label}</span>
              </div>
            )}
          </ContainerBox>
          <ContainerBox>
            <InputText
              data-test='codigoExc'
              placeholder={nextCodeRoute}
              height={80}
              maxLength={3}
              label='Código'
              formik={{
                formikProp: 'codigo',
                formikValues: formik,
              }}
              onChange={async (newValue) => {
                const value = newValue.target.value
                await formik.setFieldValue('codigo', value)

                const exe = await formik.getFieldProps('execucao').value.value
                const cod = value
                const uf = await formik.getFieldProps('uf').value.value

                await formik.setFieldValue('nome', `${uf}${exe}${cod}`)
              }}
            />
          </ContainerBox>
          <ContainerBox flex={2}>
            <InputText
              height={80}
              label='Nome'
              disabled
              formik={{
                formikProp: 'nome',
                formikValues: formik,
              }}
            />
          </ContainerBox>
        </div>

        <ContainerBox>
          {formik.getFieldProps('uf').value.value && (
            <>
              <Select
                dataTest='oCidade'
                options={cidadeOptions}
                error={Boolean(formik.errors['cidade'])}
                formik={{
                  formikProp: 'cidade',
                  formikValues: formik,
                }}
                label='Cidade Referência'
              />
              {formik.errors['cidade'] && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors['cidade'].label}</span>
                </div>
              )}
            </>
          )}
        </ContainerBox>
      </Container>
    </AnimatedModal>
  )
}

export default RouteCreationModal
