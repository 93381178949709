/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {useEffect, useState} from 'react'
import OperationalTable from './components/OperationalTable'
import {CardsWidget20} from './components/CardWidget'
import {OptionInput} from '../usuario/Usuarios'
import {GetOperationalBase, GetOperationalBaseData} from 'src/app/repositories/OperationalBase'
import {useAlert} from 'src/app/components/Alert/Alert'
import {TableColumn} from 'src/app/components/TablePainel/table-painel'
import StatusTag from 'src/app/components/StatusTag'
import {useSearchParams} from 'react-router-dom'
import { IFilter } from 'src/app/model/Generic/Type'

const empytFilter = {
  label: 'Selecione uma opção',
  value: null,
}

const empytFilters = {
  cidadeUf: null,
  codigoExterno: null,
  pedidoCliente: null,
  pesquisador: null,
  temAnotacao: false,
  postosNaoHomologados: false,
  numeroPagina: 1,
  tamanhoPagina: 5,
}

export function BaseOperacional() {
  const [searchParams, setSearchParams] = useSearchParams()
  const pageSize =
    searchParams.get('pageSize') !== null ? parseInt(searchParams.get('pageSize')!) : 5
  const pageNumber =
    searchParams.get('pageNumber') !== null ? parseInt(searchParams.get('pageNumber')!) : 1
  const [pagesTotal, setPagesTotal] = useState(0)

  const [orderFilter, setOrderFilter] = useState<OptionInput | null>(empytFilter)
  const [cityUfFilter, setCityUfFilter] = useState<OptionInput | null>(empytFilter)
  const [researcherFilter, setResearcherFilter] = useState<OptionInput | null>(empytFilter)

  const [isLoading, setIsLoading] = useState(true)

  const [quantitatives, setQuantitatives] = useState({total: 0, pending: 0})

  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    cidadesUf: [],
    pedidosClientes: [],
    pesquisadores: [],
  })

  const [isHomologated, setIsHomologated] = useState(false)
  const [isNote, setIsNote] = useState(false)

  const [codFilter, setCodFilter] = useState('')

  const [data, setData] = useState<any[]>([])
  const [columns, setColumns] = useState<TableColumn[]>([])

  useEffect(() => {
    getData()
  }, [searchParams])

  const cleanFilters = async () => {
    setOrderFilter(empytFilter)
    setCityUfFilter(empytFilter)
    setResearcherFilter(empytFilter)

    setIsHomologated(false)
    setIsNote(false)

    setCodFilter('')

    getData(empytFilters)
  }

  const applyFilters = () => {
    getData()
    searchParams.set('pageNumber', '1')
    setSearchParams(searchParams, {replace: false})
  }

  const handleGetData = async (o: GetOperationalBaseData) => {
    try {
      setIsLoading(true)

      const res = await GetOperationalBase(o)

      if (res) {
        const {basePontos, dataTableColumns, filters, quantitativos, totalDePaginas} = res
        dataTableColumns.unshift({name: 'Status Pendência', accessor: 'statusPendencia'})

        setPagesTotal(totalDePaginas)
        setQuantitatives({total: quantitativos.total, pending: quantitativos.totalSemRota})
        setData(
          basePontos.map((item) => {
            if (item.rotas === '') {
              item.statusPendencia = (
                <div style={{display: 'flex', gap: 5, flexDirection: 'column'}}>
                  <StatusTag name='Rota' />
                </div>
              )
            } else {
              item.statusPendencia = (
                <div style={{display: 'flex', gap: 5, flexDirection: 'column'}}>
                  <StatusTag name='Concluído' type='info' />
                </div>
              )
            }
            return item
          })
        )
        setColumns(dataTableColumns)
        setFilterOptions({
          cidadesUf: filters.cidadesUf,
          pedidosClientes: filters.pedidosClientes,
          pesquisadores: filters.pesquisadores,
        })
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      setIsLoading(false)
    }
  }

  const getData = async (o?: GetOperationalBaseData) => {
    if (o) {
      await handleGetData(o)
    } else {
      const o: GetOperationalBaseData = {
        cidadeUf: (cityUfFilter?.value as string) || null,
        codigoExterno: codFilter || null,
        pedidoCliente: (orderFilter?.value as number) || null,
        pesquisador: (researcherFilter?.value as string) || null,
        temAnotacao: isNote,
        postosNaoHomologados: isHomologated,
        numeroPagina: pageNumber,
        tamanhoPagina: pageSize,
      }

      await handleGetData(o)
    }
  }

  return (
    <>
      <CardsWidget20
        className='w-md-25 mb-5 mb-xl-10'
        description='Pontos de coleta na base'
        color='#F1416C'
        pendingCount={quantitatives.pending}
        total={quantitatives.total}
        // img={toAbsoluteUrl('/media/patterns/vector-1.png')}
      />
      <div className={'card'}>
        <div className='card-body py-3'>
          <OperationalTable
            pagesTotal={pagesTotal}
            isLoading={isLoading}
            // ufOptions={ufOptions}
            cleanFilters={cleanFilters}
            columns={columns}
            applyFilters={applyFilters}
            filters={[
              {
                label: 'Pedido Cliente',
                options: filterOptions.pedidosClientes,
                value: orderFilter,
                setSelectedOption: setOrderFilter,
              },

              {
                label: 'Código Cliente',
                value: codFilter,
                setSelectedOption: setCodFilter,
                type: 'text',
                placeholder: 'Digite um código...',
              },
              {
                label: 'Cidade - UF',
                options: filterOptions.cidadesUf,
                value: cityUfFilter,
                setSelectedOption: setCityUfFilter,
              },
              {
                label: 'Pesquisador',
                options: filterOptions.pesquisadores,
                value: researcherFilter,
                setSelectedOption: setResearcherFilter,
              },
              {
                type: 'switch',
                label: 'Postos não homologados',
                value: isHomologated,
                setSelectedOption: setIsHomologated,
              },
              {
                type: 'switch',
                label: 'Postos com Anotações',
                value: isNote,
                setSelectedOption: setIsNote,
              },
            ]}
            getData={getData}
            data={data}
            setData={setData}
          />
        </div>
      </div>
    </>
  )
}

type FilterOptions = {
  cidadesUf: IFilter[]
  pedidosClientes: IFilter[]
  pesquisadores: IFilter[]
}
