/* eslint-disable react-hooks/rules-of-hooks */
import {Dispatch, SetStateAction, useState} from 'react'
import TablePainel from 'src/app/components/TablePainel'

import {
  TableColumn,
  TableExportProps,
  TableRowAction,
} from 'src/app/components/TablePainel/table-painel'
import UsuarioModel from 'src/app/model/Usuario/Usuario'
import UserCreationModal from '../UserCreationModal'
import {IUserCreationSelectInfos, getUserDetail} from 'src/app/repositories/User'
import {useAlert} from 'src/app/components/Alert/Alert'
import UserViewInfoModal from '../UserViewInfoModal'

type Props = {
  pagesTotal: number
  isLoading: boolean
  getUserQuantitative: () => void
  users: UsuarioModel[]
  setUsers: Dispatch<SetStateAction<UsuarioModel[]>>
  userCreationSelectInfos: IUserCreationSelectInfos
  cleanFilters: () => void
  applyFilters: () => void
  getUsuarios: () => void
  setOpenModalImport: Dispatch<SetStateAction<boolean>>
  handleToggleUserActive: (row: UsuarioModel) => void
  sendPassword: (row: UsuarioModel) => void
  filters: any
}

const UserTable = ({
  users,
  setOpenModalImport,
  handleToggleUserActive,
  sendPassword,
  getUsuarios,
  filters,
  applyFilters,
  cleanFilters,
  userCreationSelectInfos,
  setUsers,
  getUserQuantitative,
  isLoading,
  pagesTotal,
}: Props) => {
  const [showUserCreationModal, setShowUserCreationModal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [userInfo, setUserInfo] = useState<UsuarioModel>({} as UsuarioModel)

  const [showUserViewInfoModal, setShowUserViewInfoModal] = useState(false)

  const handleOpenShowUserViewInfoModal = () => setShowUserViewInfoModal(true)
  const handleCloseShowUserViewInfoModal = () => setShowUserViewInfoModal(false)

  const columns: TableColumn[] = [
    {name: 'Status Pendência', accessor: 'statusPendencia'},
    {
      name: 'Foto',
      accessor: 'imagem',
    },
    {
      name: 'Nome Completo',
      accessor: 'nome',
    },
    {
      name: 'Perfil',
      accessor: 'perfil.label',
    },
    {
      name: 'E-mail',
      accessor: 'email',
    },

    {
      name: 'Situacao',
      accessor: 'statusNome',
    },
    {
      name: 'Quantidade de Acessos',
      accessor: 'quantidadeAcessos',
    },
    {
      name: 'Número de tentativas de Acessos',
      accessor: 'quantidadeTentativas',
    },
    {
      name: 'Data último acesso',
      accessor: 'dataUltimoAcesso',
    },
  ]

  const actions: TableRowAction[] = [
    {
      label: 'Editar',
      condition: (_row) => true,
      icon: 'pencil',
      onClick: (row: UsuarioModel) => throwTableAction('Editar', row),
    },
    {
      label: 'Visualizar',
      icon: 'eye',
      onClick: (row: UsuarioModel) => throwTableAction('Visualizar', row),
      condition: (row: UsuarioModel) => true,
    },
    {
      label: 'Ativar',
      icon: 'check',
      onClick: (row: UsuarioModel) => throwTableAction('Ativar', row),
      condition: (row: UsuarioModel) => row.statusNome === 'CANCELADO',
    },
    {
      label: 'Inativar',
      icon: 'check',
      onClick: (row: UsuarioModel) => throwTableAction('Inativar', row),
      condition: (row: UsuarioModel) => row.statusNome === 'ATIVO',
    },
    {
      label: 'Reenviar Senha',
      icon: 'cross',
      onClick: (row: UsuarioModel) => throwTableAction('Reenviar Senha', row),
      condition: (row: UsuarioModel) =>
        row.statusNome === 'ATIVO' ||
        row.statusNome === 'BLOQUEADO' ||
        row.statusNome === 'EXPIRADO',
    },
  ]

  const tableExportProps: TableExportProps = {
    pdf: {
      fileName: 'usuario',
    },
    excel: {
      fileName: 'usuario',
      sheetName: 'usuario',
    },
    csv: {
      fileName: 'usuario',
    },
  }

  const throwTableAction = async (
    action: 'Editar' | 'Ativar' | 'Inativar' | 'Reenviar Senha' | 'Visualizar',
    row: UsuarioModel
  ) => {
    switch (action) {
      case 'Ativar':
        handleToggleUserActive(row)
        break
      case 'Inativar':
        handleToggleUserActive(row)
        break
      case 'Editar':
        setIsEditing(true)
        await handleGetUserDetail(row)
        handleOpenShowUserCreationModal()
        break
      case 'Reenviar Senha':
        sendPassword(row)
        break
      case 'Visualizar':
        await handleGetUserDetail(row)
        handleOpenShowUserViewInfoModal()
        break
      default:
        break
    }
  }

  const handleGetUserDetail = async (row: UsuarioModel) => {
    try {
      const res = await getUserDetail(row.codigo)

      if (res) {
        setUserInfo(res)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const handleCloseShowUserCreationModal = () => {
    setShowUserCreationModal(false)
    setUserInfo({} as UsuarioModel)
    setIsEditing(false)
  }

  const handleOpenShowUserCreationModal = () => {
    setShowUserCreationModal(true)
  }

  return (
    <>
      {showUserViewInfoModal && (
        <UserViewInfoModal userInfo={userInfo} onClose={handleCloseShowUserViewInfoModal} />
      )}
      {showUserCreationModal && (
        <UserCreationModal
          getUserQuantitative={getUserQuantitative}
          isEditing={isEditing}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          userCreationSelectInfos={userCreationSelectInfos}
          onClose={handleCloseShowUserCreationModal}
          setUsers={setUsers}
        />
      )}

      <TablePainel
        pagesTotal={pagesTotal}
        isLoading={isLoading}
        tableTitle='Tabela de Usuários'
        columns={columns}
        data={users}
        rowActions={actions}
        tableExportProps={tableExportProps}
        onCreateData={handleOpenShowUserCreationModal}
        onImportData={() => setOpenModalImport(true)}
        getData={getUsuarios}
        filters={filters}
        importDataButton
        applyFilters={applyFilters}
        cleanFilters={cleanFilters}
      />
    </>
  )
}

export default UserTable
