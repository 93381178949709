import {getImageFromBucket} from 'src/app/utils/API'
import {ItemPhotoContainer} from '../../styles'

type Props = {
  handleOnClick(value: string | number, descricao: string, siglaFoto: string): void
  currentValue: string | number
  item: any
}

const ItemPhoto = ({handleOnClick, currentValue, item}: Props) => {
  return (
    <ItemPhotoContainer
      onClick={() => handleOnClick(item.codigo, item.descricao, item.siglaFoto)}
      selected={currentValue === item.codigo}
    >
      <img src={getImageFromBucket(item.fileName)} alt='foto' />

      <span>{item.descricao}</span>
    </ItemPhotoContainer>
  )
}

export default ItemPhoto
