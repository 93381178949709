import Button from './Button'
import Icon from './Icon'
import {Modal} from 'react-bootstrap'

export default function ModalAlert({
  title,
  content,
  onShow,
  onHide,
  onConfirm,
  danger,
  isLoading = false,
}: Props) {
  return (
    <Modal show={onShow} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className='justify-content-center flex-column gap-4'>
        <Icon iconSize='7' icon='information-2' iconColor={danger ? 'danger' : 'warning'} />
        <Modal.Title className='fs-2'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-8 text-center fs-4'>{content}</Modal.Body>
      <Modal.Footer className='justify-content-center'>
        <Button color={danger ? 'danger' : 'warning'} fill={false} onClick={onHide}>
          Fechar
        </Button>
        <Button
          data-test='bModalAlert'
          loading={isLoading}
          color={danger ? 'danger' : 'warning'}
          onClick={onConfirm}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

interface Props {
  title?: string
  content?: string
  icon?: string
  danger?: boolean
  onConfirm?: () => void
  onCancel?: () => void
  onHide?: () => void
  onShow?: boolean
  isLoading?: boolean
}
