import React, {useRef, useState, useEffect} from 'react'
import {useSpring, a} from '@react-spring/web'
import useMeasure from 'react-use-measure'
import {Title, Frame, Content, toggle} from './styles'
import {CloseIcon, PlusIcon} from 'src/app/utils/Icons'

function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => void (ref.current = value), [value])
  return ref.current
}

export const Tree = React.memo<
  React.HTMLAttributes<HTMLDivElement> & {
    defaultOpen?: boolean
    name: string | JSX.Element
  }
>(({children, name, style, defaultOpen = false, onClick}) => {
  const [isOpen, setOpen] = useState(defaultOpen)
  const previous = usePrevious(isOpen)
  const [ref, {height: viewHeight}] = useMeasure()
  const {height, opacity, y} = useSpring({
    from: {height: 0, opacity: 0, y: 0},
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      y: isOpen ? 0 : 20,
    },
  })

  const Icon = isOpen ? CloseIcon : PlusIcon

  return (
    <Frame>
      <div
        className='frame-header'
        onClick={(e) => {
          setOpen(!isOpen)
          onClick && onClick(e)
        }}
      >
        {children && <Icon style={{...toggle, opacity: children ? 1 : 0.3}} />}

        <Title style={style}>{name}</Title>
      </div>

      <Content
        style={{
          opacity,
          height: isOpen && previous === isOpen ? 'auto' : height,
        }}
      >
        <a.div ref={ref} style={{y}} children={children} />
      </Content>
    </Frame>
  )
})
