import Checkbox from 'src/app/components/Form/Checkbox'
import {IGetToggleHiddenProps} from '../..'
import {ColumnInstance, TableToggleHideAllColumnProps} from 'react-table'
import {ChangeEvent} from 'react'

// import { Container } from './styles';

const ShowHideComponent = ({
  allColumns,
  getToggleHideAllColumnsProps,
  setHiddenColumns,
  hiddenColumns,
}: Props) => {
  const handleToggleAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (getToggleHideAllColumnsProps().checked) {
      return setHiddenColumns(hiddenColumns)
    }

    // @ts-ignore
    return getToggleHideAllColumnsProps().onChange(e)
  }

  return (
    <div className='container mb-5' style={{overflowY: 'auto', maxHeight: 500}}>
      <div className='mx-5 my-3'>
        {/* @ts-ignore */}
        <Checkbox
          label='Mostrar todas ocultas'
          {...getToggleHideAllColumnsProps()}
          onChange={(e) => handleToggleAll(e)}
        />
      </div>

      {allColumns
        .filter((c) => Boolean(c.Header))
        .map((column, index) => {
          const gthp = column.getToggleHiddenProps() as IGetToggleHiddenProps
          const columnName = column.Header!.toString()

          return (
            <div key={String(index)} className='mx-5 my-3'>
              {/* @ts-ignore */}
              <Checkbox keyHtmlFor={columnName} label={columnName} {...gthp} />
            </div>
          )
        })}
    </div>
  )
}

export default ShowHideComponent

type Props = {
  allColumns: ColumnInstance<object>[]
  hiddenColumns: string[]
  getToggleHideAllColumnsProps: (
    props?: Partial<TableToggleHideAllColumnProps> | undefined
  ) => TableToggleHideAllColumnProps
  setHiddenColumns: (param: string[]) => void
}
