/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import AnimatedModal from 'src/app/components/AnimatedModal'
import ModalCard from 'src/app/components/ModalCard'
import {Select} from 'src/app/components/Select/Select'
import Info from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/CardHeader/components/QAModal/components/Info'
import {ResearchersData} from '../../../CostCenterBase'

import {Container, Footer} from './styles'

import {ActiveResearcers, fields} from '../..'
import Button from 'src/app/components/Button'

const initialData = [
  {label: 'Nome Completo', value: 'Selecione um proprietário'},
  {label: 'CPF', value: 'Selecione um proprietário'},
  {label: 'E-mail', value: 'Selecione um proprietário'},
  {label: 'Telefone', value: 'Selecione um proprietário'},
]

const IsResearcherMoldal = ({onClose, researchers, rawResearchers, handleSetInputValue}: Props) => {
  const [researcherData, setResearcherData] = useState<SelectProps[]>(initialData)
  const [currentResearcherID, setCurrentResearcherID] = useState<SelectProps>({} as SelectProps)
  const researchersList = researchers.map((item) => {
    return {value: item.codigo, label: item.nome}
  })

  useEffect(() => {
    const currentID = currentResearcherID.value
    const index = rawResearchers.findIndex((item) => item.codigo === currentID)

    if (index !== -1) {
      setResearcherData([
        {label: 'Nome Completo', value: rawResearchers[index].nome || 'Não preenchido'},
        {label: 'CPF', value: rawResearchers[index].cpf || 'Não preenchido'},
        {label: 'E-mail', value: rawResearchers[index].email || 'Não preenchido'},
        {label: 'Telefone', value: rawResearchers[index].numeroTelefone || 'Não preenchido'},
      ])
    }
  }, [currentResearcherID])

  return (
    <AnimatedModal
      containerStyle={{
        height: 'auto',
        width: '50%',
        padding: 16,
      }}
      onClose={onClose}
      footer={
        <Footer>
          <Button
            onClick={async () => {
              if (researcherData[0].value !== 'Selecione um proprietário') {
                await handleSetInputValue(
                  'proprietario',
                  researcherData[0].value === 'Não preenchido' ? '' : researcherData[0].value
                )
                await handleSetInputValue(
                  'cpfCnpj',
                  researcherData[1].value === 'Não preenchido' ? '' : researcherData[1].value
                )
                await handleSetInputValue(
                  'email',
                  researcherData[2].value === 'Não preenchido' ? '' : researcherData[2].value
                )
                await handleSetInputValue(
                  'telefone',
                  researcherData[3].value === 'Não preenchido' ? '' : researcherData[3].value
                )
              }
            }}
            color='primary'
          >
            OK
          </Button>
        </Footer>
      }
    >
      <Container>
        <ModalCard
          title='Selecionar proprietário'
          contentContainerStyle={{height: 'auto', flex: 0}}
          style={{marginTop: 10}}
        >
          <Select
            label='Nome-CPF'
            options={researchersList}
            setSelectedOption={setCurrentResearcherID}
          />
        </ModalCard>

        <ModalCard
          title='Dados'
          style={{marginTop: 10}}
          contentContainerStyle={{height: 'auto', flex: 0}}
        >
          {researcherData.map((item) => (
            <Info label={item.label} value={item.value} key={item.label} />
          ))}
        </ModalCard>
      </Container>
    </AnimatedModal>
  )
}

type Props = {
  onClose: () => void
  researchers: ResearchersData[]
  rawResearchers: ActiveResearcers[]

  handleSetInputValue: (field: fields, value: string) => Promise<void>
}

type SelectProps = {label: string; value: string}

export default IsResearcherMoldal
