import styled from 'styled-components'
import {animated} from '@react-spring/web'

export const Message = styled(animated.div)`
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  width: 40ch;

  margin-top: 10px;

  color: white;

  .header {
    color: white;
    opacity: 0.9;
    padding: 12px 22px;
    font-size: 1em;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    overflow: hidden;
    height: auto;
    border-radius: 3px;
    background: #445159;

    cursor: pointer;
  }

  @media (max-width: 680px) {
    width: 100%;
  }
`

export const Content = styled(animated.div)`
  color: white;
  will-change: transform, opacity, height;

  width: 95%;
  margin-left: auto;
`

export const Button = styled.button`
  cursor: pointer;
  pointer-events: all;
  outline: 0;
  border: none;
  background: transparent;
  display: flex;
  align-self: flex-end;
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding-bottom: 14px;
  color: rgba(255, 255, 255, 0.5);
  :hover {
    color: rgba(255, 255, 255, 0.6);
  }
`
