/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
import { useRef, useState } from 'react'

import clsx from 'clsx'

import ColorButton from 'src/app/components/ColorButton'

import { Container, Textarea } from './styles'
import { useService } from 'src/app/hooks/useService'
import { ValidateServices } from 'src/app/repositories/Atendimentos'
import { useAlert } from 'src/app/components/Alert/Alert'
import Switch from 'src/app/components/Form/Switch'
import Selecao from 'src/app/components/Selecao'
import { selectOptions } from '../Service/components/ServiceActionModal'
import Modal from 'src/app/components/Modal'

type SubHeaderContainerProps = {
  setCanShowButtons: React.Dispatch<React.SetStateAction<boolean>>
  canShowButtons: boolean
}

const SubHeaderContainer = ({ setCanShowButtons, canShowButtons }: SubHeaderContainerProps) => {
  const {
    setSelectedAtendimentos,
    selectedAtendimentos,
    setSelectedApprovedAtendimentos,
    services: atendimentos,
    setServices,
    currentSituation,
    getQuantitative,
    getData,
  } = useService()

  const [showModal, setShowModal] = useState(false)

  const [isActive, setIsActive] = useState(true)

  const [approvedReason, setApprovedReason] = useState('')
  const [disapprovedReason, setDisapprovedReason] = useState(
    'Selecione o motivo da reprovação do pagamento'
  )

  const [action, setAction] = useState<'aprovar' | 'reprovar'>('aprovar')

  const checkRef = useRef<HTMLInputElement>(null)

  const numSelected = selectedAtendimentos.length
  const checkedAll = numSelected === atendimentos.length

  const handleSelectAllClick = () => {
    if (!checkRef.current?.checked) {
      checkRef.current!.checked = true
      setSelectedAtendimentos(atendimentos)

      return
    }

    checkRef.current.checked = false

    setSelectedAtendimentos([])
    setCanShowButtons(true)
  }

  const handleSelectApproveDisapproveAll = async () => {
    if (
      (!isActive && disapprovedReason === 'Selecione o motivo da reprovação do pagamento') ||
      disapprovedReason === '' ||
      !disapprovedReason
    ) {
      useAlert('Selecione o motivo da reprovação do pagamento', 'Error')
    } else {
      if (isActive && (approvedReason === undefined || approvedReason === '')) {
        useAlert('Justifique a liberação do pagamento', 'Error')
      }
      else {
        try {
          const codigos = selectedAtendimentos.map((item) => item.codigoAtendimento)

          setSelectedApprovedAtendimentos(selectedAtendimentos)

          const {
            atendimentosAtualizados,
            atendimentosNaoAtualizados,
          }: {
            atendimentosAtualizados: number[]
            atendimentosNaoAtualizados: { codigo: number; nome: string }[]
          } =
            action === 'aprovar'
              ? await ValidateServices({
                codigosAtendimentos: codigos,
                observacao: isActive
                  ? approvedReason === ''
                    ? null
                    : approvedReason
                  : disapprovedReason,
                pagar: isActive,
                isAprovar: true,
              })
              : await ValidateServices({
                codigosAtendimentos: codigos,
                observacao: isActive
                  ? approvedReason === ''
                    ? null
                    : approvedReason
                  : disapprovedReason,
                pagar: isActive,
                isAprovar: false,
              })

          setSelectedApprovedAtendimentos([])

          const errorList = atendimentosNaoAtualizados

          if (errorList.length > 0) {
            const errorCods = errorList.map((item) => item.codigo)

            setServices((oldState) => {
              const newState = oldState.map((item) => {
                if (errorCods.includes(item.codigoAtendimento)) {
                  const index = errorList.findIndex((i) => i.codigo === item.codigoAtendimento)
                  item.error = errorList[index].nome
                }
                return item
              })

              return newState
            })

            useAlert(
              `${errorList.length} atendimentos não foram atualizados, por favor, verifique no atendimento o motivo.`,
              'Error'
            )
          }

          if (atendimentosAtualizados.length > 0) {
            if (atendimentos.length - selectedAtendimentos.length <= 4) {
              getData()
            } else {
              getQuantitative()
              setServices((oldState) => {
                return oldState.filter((item) => {
                  if (!atendimentosAtualizados.includes(item.codigoAtendimento)) {
                    return item
                  }
                })
              })
            }

            setSelectedAtendimentos((oldState) => {
              return oldState.filter((item) => {
                if (!atendimentosAtualizados.includes(item.codigoAtendimento)) {
                  return item
                }
              })
            })

            useAlert(
              `${atendimentosAtualizados.length} atendimentos foram atualizados com sucesso!`,
              'Success'
            )
          }
        } catch (error: any) {
          setSelectedApprovedAtendimentos([])
          useAlert(error.message, 'Error')
        } finally {
          closeModal()
        }
      }
    }
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  return (
    <>
      <Container>
        <Modal
          title={{
            message: `Deseja ${action} todos os atendimentos selecionados?`,
            color: action === 'reprovar' ? '#db3461' : '#22E27B',
          }}
          open={showModal}
          footer
          handleClose={closeModal}
          onConfirm={() => {
            handleSelectApproveDisapproveAll()
          }}
        >
          <Switch
            className='text-success'
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          >
            Pagamento Liberado?
          </Switch>

          {isActive ? (
            <Textarea
              //@ts-ignore
              onChange={(e) => setApprovedReason(e.target.value)}
              value={approvedReason}
              placeholder='Observação de pagamento'
            ></Textarea>
          ) : (
            <Selecao
              contentStyle={{ width: '100%' }}
              titulo={'Motivo da reprovação'}
              opcoes={selectOptions}
              setValue={setDisapprovedReason}
              value={disapprovedReason}
            />
          )}
        </Modal>
        <div className='buttons'>
          {currentSituation !== 'RE' && (
            <ColorButton
              onClick={() => {
                setAction('reprovar')
                openModal()
              }}
              className={`${clsx({
                'fade-in ': numSelected > 0,
                'fade-out': numSelected === 0 && canShowButtons,
              })}`}
              role='dislike'
              title={`Reprovar Selecionados (${numSelected})`}
            />
          )}

          {currentSituation !== 'AP' && (
            <ColorButton
              onClick={() => {
                setAction('aprovar')
                openModal()
              }}
              className={`${clsx({
                'fade-in ': numSelected > 0,
                'fade-out': numSelected === 0 && canShowButtons,
              })}`}
              role='like'
              title={`Aprovar Selecionados (${numSelected})`}
            />
          )}

          <ColorButton
            className='show'
            onClick={handleSelectAllClick}
            inputRef={checkRef}
            role='check'
            title='Selecionar Todos'
            checked={checkedAll}
          />
        </div>
        <div style={{ display: 'flex', order: '-1' }}>
          <div className='services'>
            <div className='service'>
              <span className='value'>{atendimentos.length}</span>
              <span className='name'>Atendimento(s) exibido(s)</span>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default SubHeaderContainer
