import styled from 'styled-components'

type LineProps = {
  width: number
  index: number
  color: string
  textColor: string
}

type ContainerPros = {
  height: number | undefined
}

export const Container = styled.div<ContainerPros>`
  display: flex;
  flex-direction: column;

  position: absolute;
  width: 100%;
  height: ${({height}) => height}px;
`
export const Line = styled.div<LineProps>`
  z-index: ${({index}) => (index === 3 ? 3 : 2)};
  flex: 1;
  width: ${({width}) => width}%;
  position: relative;

  @keyframes fadeIn {
    from {
      scale: 0;
      opacity: 0;
    }

    to {
      opacity: 1;
      scale: 1;
    }
  }

  .msg-container {
    display: flex;
    flex-direction: column;

    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);

    background-color: #fafafa;

    min-height: 300px;

    border-radius: 5px;

    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

    width: 100%;
    max-width: 600px;

    margin: 0 auto;
    padding: 20px 40px;

    animation: fadeIn 0.4s;

    input.form-check-input {
      background-color: rgba(235, 190, 192, 1);
    }

    input.form-check-input:checked {
      background-color: rgba(185, 227, 204, 1);
    }
  }

  .msg-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    textarea {
      width: 100%;
      min-height: 80px;

      border: none;
      border-radius: 5px;
      outline: none;

      box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;

      font-family: Roboto, Poppins, sans-serif !important;

      margin-top: 10px;
      padding: 6px;
    }

    .msg-row {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      font-size: 14px;
      font-family: 'Poppins';

      .label {
        font-weight: 600;
        color: #5c5c5c;
        text-transform: uppercase;
      }
    }
  }
  .msg-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  .msg-header {
    display: flex;
    justify-content: center;

    padding: 20px;

    border-radius: 5px;

    text-align: center;
    color: white;
    font-weight: bold;

    span {
      color: ${({textColor}) => textColor};
      letter-spacing: 5px;
      text-transform: uppercase;
      font-weight: 300;
      font-family: 'Poppins';
      font-size: 18px;
    }

    button {
      background-color: transparent;
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  transition: all ${({index}) => 0.2 / ((index + 2) / 10)}s;

  background-color: ${({color}) => color};
`
