/* eslint-disable react-hooks/rules-of-hooks */
import {createContext, ReactNode, useState, SetStateAction, Dispatch} from 'react'
import ValidacaoModel from '../model/Validacao/Validacao'
import {
  GetService,
  GetServicesQuantitative,
  GetServicesQuantitativeResponse,
} from '../repositories/Atendimentos'
import {ISigla, PrecosColeta} from '../model/Validacao/Type'
import {useAlert} from '../components/Alert/Alert'
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'

export type ServicePerDate = {
  pendentes: any
  aprovados: any
  reprovados: any
  preReprovados: any
}

export type situacoesConstatadas = {
  codigo: number
  valor: string
}

export type ocorrenciasFotos = situacoesConstatadas & {
  siglaFoto: ISigla
}

export type ServicesInfo = {
  situacoesConstatadas: situacoesConstatadas[]
  ocorrenciasFotos: ocorrenciasFotos[]
}

export type Situation = 'PD' | 'AP' | 'RE' | 'PR' | 'RV'
type DataRange = {dataInicio: null | string; dataFim: null | string}

type ContextType = {
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>

  selectedAtendimentos: ValidacaoModel[]
  selectedApprovedAtendimentos: ValidacaoModel[]
  services: ValidacaoModel[]
  setServices: Dispatch<SetStateAction<ValidacaoModel[]>>
  setSelectedAtendimentos: Dispatch<SetStateAction<ValidacaoModel[]>>
  setSelectedApprovedAtendimentos: Dispatch<SetStateAction<ValidacaoModel[]>>
  filtersCount: GetServicesQuantitativeResponse
  setFiltersCount: Dispatch<SetStateAction<GetServicesQuantitativeResponse>>

  pagination: number
  setPagination: Dispatch<SetStateAction<number>>
  statesFilter: never[]
  setStatesFilter: Dispatch<SetStateAction<never[]>>
  groupsFilter: never[]
  setGroupsFilter: Dispatch<SetStateAction<never[]>>
  researchersFilter: never[]
  setResearchersFilter: Dispatch<SetStateAction<never[]>>
  clientsFilter: never[]
  setClientsFilter: Dispatch<SetStateAction<never[]>>
  formsFilter: never[]
  setFormFilters: Dispatch<SetStateAction<never[]>>

  searchFilter: string
  setSearchFilter: Dispatch<SetStateAction<string>>
  servicesPerDate: ServicePerDate | undefined
  setServicesPerDate: Dispatch<SetStateAction<ServicePerDate | undefined>>
  states: never[]
  setStates: Dispatch<SetStateAction<never[]>>
  groups: never[]
  setGroups: Dispatch<SetStateAction<never[]>>
  researchers: never[]
  setResearchers: Dispatch<SetStateAction<never[]>>
  clients: never[]
  setClients: Dispatch<SetStateAction<never[]>>
  forms: never[]
  setForms: Dispatch<SetStateAction<never[]>>
  statusPayment: string | null
  setStatusPayment: Dispatch<SetStateAction<string | null>>
  dateRange: DataRange
  setDateRange: Dispatch<SetStateAction<DataRange>>
  hasFilterApplied(): boolean

  getQuantitative: () => Promise<void>
  getInfiniteData: () => Promise<void>
  getData: () => Promise<void>

  currentSituation: Situation
  setCurrentSituation: Dispatch<SetStateAction<Situation>>

  setServicesInfo: Dispatch<SetStateAction<ServicesInfo | undefined>>
  servicesInfo: ServicesInfo | undefined

  dataInicioDefault(): string
  dataFimDefault(): string
  updatePrecoColetaService(
    codigoAtendimento: number,
    precoColeta: PrecosColeta,
    indexOf: number
  ): void
}

type ContextProviderProps = {
  children: ReactNode
}

export const ServiceContext = createContext({} as ContextType)

export function ServiceContextProvider(props: ContextProviderProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [currentSituation, setCurrentSituation] = useState<Situation>('PD')

  const [services, setServices] = useState<ValidacaoModel[]>([])
  const [servicesInfo, setServicesInfo] = useState<ServicesInfo | undefined>(undefined)

  const [selectedAtendimentos, setSelectedAtendimentos] = useState<ValidacaoModel[]>([])
  const [selectedApprovedAtendimentos, setSelectedApprovedAtendimentos] = useState<
    ValidacaoModel[]
  >([])

  const [filtersCount, setFiltersCount] = useState({
    pendentes: 0,
    aprovados: 0,
    reprovados: 0,
    preReprovados: 0,
    revalidacao: 0,
  })

  const [pagination, setPagination] = useState(1)
  const [statesFilter, setStatesFilter] = useState([])
  const [groupsFilter, setGroupsFilter] = useState([])
  const [researchersFilter, setResearchersFilter] = useState([])
  const [clientsFilter, setClientsFilter] = useState([])
  const [formsFilter, setFormFilters] = useState([])

  const [searchFilter, setSearchFilter] = useState('')

  const [servicesPerDate, setServicesPerDate] = useState<ServicePerDate>()

  const [states, setStates] = useState([])
  const [groups, setGroups] = useState([])
  const [researchers, setResearchers] = useState([])
  const [clients, setClients] = useState([])
  const [forms, setForms] = useState([])
  const [statusPayment, setStatusPayment] = useState<string | null>(null)
  const [dateRange, setDateRange] = useState<DataRange>({
    dataInicio: dataInicioDefault(),
    dataFim: dataFimDefault(),
  })

  function dataInicioDefault() {
    const seteDiasAtras = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
    return format(seteDiasAtras, 'yyyy/MM/dd', {locale: ptBR})
  }

  function dataFimDefault() {
    return format(Date.now(), 'yyyy/MM/dd', {locale: ptBR})
  }

  function updatePrecoColetaService(
    codigoAtendimento: number,
    precoColeta: PrecosColeta,
    indexOf: number
  ) {
    setServices((oldState) => {
      return oldState.map((item) => {
        if (item.codigoAtendimento === codigoAtendimento) {
          item.precosColeta[indexOf] = precoColeta
        }

        return item
      })
    })
  }

  function hasFilterApplied() {
    if (
      clients.length > 0 ||
      forms.length > 0 ||
      groups.length > 0 ||
      states.length > 0 ||
      researchers.length > 0 ||
      statusPayment
    ) {
      return true
    } else return false
  }

  async function getQuantitative() {
    //@ts-ignore
    const res = await GetServicesQuantitative(dateRange.dataInicio, dateRange.dataFim)

    if (res) {
      const {pendentes, preReprovados, reprovados, aprovados, revalidacao} = res

      setFiltersCount({
        pendentes,
        aprovados,
        reprovados,
        preReprovados,
        revalidacao,
      })
    }
  }

  async function getInfiniteData() {
    const getCodigo = (array: {codigo: number; nome: string}[]) => {
      return array.map((item) => item.codigo)
    }

    const formatDataService = (name: string) => {
      return {
        situacao: name,
        numeroPagina: pagination,
        clientes: getCodigo(clients),
        formularios: getCodigo(forms),
        grupos: groups,
        ufs: states,
        pesquisadores: getCodigo(researchers),
        dataInicio: dateRange.dataInicio,
        dataFim: dateRange.dataFim,
        cnpjPonto: searchFilter,
        statusPagamento: statusPayment,
      }
    }

    const pendingData = formatDataService(currentSituation)

    try {
      // setIsLoading(true)

      const {atendimentos, filtros, informacoesAtendimentos} = await GetService(pendingData)

      setServices((oldState) => [...oldState, ...atendimentos])

      setServicesInfo(informacoesAtendimentos)

      setPagination((oldState) => oldState + 1)

      getQuantitative()

      setStatesFilter(filtros.estados)
      setResearchersFilter(filtros.pesquisadores)
      setClientsFilter(filtros.clientes)
      setFormFilters(filtros.formularios)
      setGroupsFilter(filtros.regioes)
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      // setIsLoading(false)
    }
  }

  async function getData() {
    const getCodigo = (array: {codigo: number; nome: string}[]) => {
      return array.map((item) => item.codigo)
    }

    const formatDataService = (name: string) => {
      return {
        situacao: name,
        numeroPagina: 0,
        clientes: getCodigo(clients),
        formularios: getCodigo(forms),
        grupos: groups,
        ufs: states,
        pesquisadores: getCodigo(researchers),
        dataInicio: dateRange.dataInicio,
        dataFim: dateRange.dataFim,
        cnpjPonto: searchFilter,
        statusPagamento: statusPayment,
      }
    }

    const pendingData = formatDataService(currentSituation)

    try {
      setIsLoading(true)

      const {atendimentos, filtros, informacoesAtendimentos} = await GetService(pendingData)

      setServices(atendimentos)

      setServicesInfo(informacoesAtendimentos)

      getQuantitative()

      setStatesFilter(filtros.estados)
      setResearchersFilter(filtros.pesquisadores)
      setClientsFilter(filtros.clientes)
      setFormFilters(filtros.formularios)
      setGroupsFilter(filtros.regioes)
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ServiceContext.Provider
      value={{
        selectedAtendimentos,
        setSelectedAtendimentos,
        selectedApprovedAtendimentos,
        setSelectedApprovedAtendimentos,
        filtersCount,
        setFiltersCount,
        pagination,
        setPagination,
        statesFilter,
        setStatesFilter,
        groupsFilter,
        setGroupsFilter,
        researchersFilter,
        setResearchersFilter,
        clientsFilter,
        setClientsFilter,
        formsFilter,
        setFormFilters,
        searchFilter,
        setSearchFilter,
        servicesPerDate,
        setServicesPerDate,
        states,
        setStates,
        groups,
        setGroups,
        researchers,
        setResearchers,
        clients,
        setClients,
        forms,
        setForms,
        statusPayment,
        setStatusPayment,
        hasFilterApplied,
        dateRange,
        setDateRange,
        getInfiniteData,
        currentSituation,
        setCurrentSituation,
        services,
        setServices,
        getData,
        getQuantitative,
        isLoading,
        setIsLoading,
        servicesInfo,
        updatePrecoColetaService,
        setServicesInfo,
        dataInicioDefault,
        dataFimDefault,
      }}
    >
      {props.children}
    </ServiceContext.Provider>
  )
}
