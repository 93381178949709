import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  flex-direction: column;

  textarea {
    margin-bottom: 10px;
    padding: 8px;

    border-radius: 5px;
    border: none;
    border: 1px solid #1b1b1b;

    background-color: #fff;
  }

  textarea {
    background-color: #f6f9ff;
    cursor: not-allowed;
  }
`
