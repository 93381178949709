/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {Dispatch, SetStateAction, useEffect, useState} from 'react'

import {useSpring, useInView} from '@react-spring/web'

import {AnimatedTableRow} from '../../../CentroCusto/components/CostCenterBase/components/TableRow/styles'

import {DeleteIcon, PencilIcon} from 'src/app/utils/Icons'
import {IModalAlert} from '../../../usuario/Usuarios'
import {useAlert} from 'src/app/components/Alert/Alert'
import {GetOrdersResponse} from 'src/app/repositories/OperationalBase'

// import { Container } from './styles';

const TableRow = ({item, setData, handleFillOrders, setModalAlert, data}: Props) => {
  const [show, setShow] = useState(false)

  const [ref, inView] = useInView()

  const fadeAnimation = {
    opacity: show ? 1 : 0,
    scale: show ? 1 : 0,
    // borderWidth: show ? 1 : 0,
    config: {
      tension: 300,
      duration: 250,
    },
  }

  const styles = useSpring(fadeAnimation)

  useEffect(() => {
    if (inView) {
      setShow(true)
    }
  }, [inView])

  const handleDeleteUserCostCenter = (codigo: number | string) => {
    const action = () => {
      setData((oldState) => {
        return oldState.filter((item) => item.codigoCliente !== codigo)
      })

      setModalAlert(undefined)
    }

    if (data.length > 1) {
      setModalAlert({
        title: 'Aviso!',
        content: `Deseja realmente excluir esse centro de custo? `,
        action,
      })
    } else {
      useAlert('Necessário pelo menos um cliente vinculado nesta edição.', 'Info')
    }
  }

  return (
    <AnimatedTableRow style={styles} ref={ref} key={item.codigoCliente}>
      <td>{item.nomeCliente}</td>
      <td>{item.status}</td>
      <td> {item.codigoExterno}</td>
      <td>
        <div className='buttons'>
          <button onClick={() => handleDeleteUserCostCenter(item.codigoCliente!)}>
            <DeleteIcon size={20} color='#FF4560' />
          </button>
          <button onClick={() => handleFillOrders(item)}>
            <PencilIcon size={20} color='#009EF7' />
          </button>
        </div>
      </td>
    </AnimatedTableRow>
  )
}

type Props = {
  setData: Dispatch<SetStateAction<GetOrdersResponse[]>>
  data: GetOrdersResponse[]
  handleFillOrders: (item: GetOrdersResponse) => void
  item: GetOrdersResponse
  setModalAlert: Dispatch<SetStateAction<IModalAlert | undefined>>
}

export default TableRow
