import styled, {css} from 'styled-components'

type BoxProps = {
  flex: number
}

type ContainerProps = {
  hasSolicitacaoExclusao: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: fit-content;

  background-color: #fff;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

  border-radius: 5px;

  padding: 5px;

  .header {
    display: flex;
    border: none;
    padding: 0 !important;

    margin: -5px -5px 5px -5px;

    .header-row span {
      display: flex;
      flex: 1;

      justify-content: center;

      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      background-color: #384752;
      color: white;

      padding: 5px;

      font-family: 'Inter';
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .header-row {
    display: flex;
    align-items: center;

    width: 100%;

    svg {
      margin: 0 10px;
      cursor: pointer;
    }
  }

  .container-row {
    display: flex;

    align-items: center;
    justify-content: center;
    gap: 8px;

    margin-bottom: 5px;

    &.column {
      flex-direction: column;

      gap: 0px;

      button {
        margin: 0;
      }
    }

    .drop {
      background-color: #384752;
      width: 100%;
      color: #fff;

      p {
        margin: 0;
        font-family: Poppins;
        font-weight: bold;

        margin-left: 10px;
      }
    }

    .checkbox {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      width: 100%;

      padding: 10px;

      label {
        font-family: Inter;
        font-size: 14px;
        cursor: pointer;
      }

      ${({hasSolicitacaoExclusao}) =>
        hasSolicitacaoExclusao &&
        css`
          border: 3px solid #f89c32;

          label {
            color: #f89c32;
          }
        `}
    }
  }

  .red {
    background-color: #f3565d;
    color: white !important;
  }

  .pulse {
    animation: redPulse 2s infinite;
  }

  @keyframes redPulse {
    0% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(243, 86, 93, 0.7);
    }

    70% {
      /* transform: scale(1); */
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  @keyframes orangePulse {
    0% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(248, 156, 50, 0.7);
    }

    70% {
      /* transform: scale(1); */
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`

export const Box = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  flex: ${({flex}) => flex};
  gap: 4px;
  height: 26px;

  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled {
    padding: 0 !important;
  }

  padding: 0 5px;

  border: 1px solid #e8e8e8;

  .bold {
    font-weight: bold;
  }
`
