/* eslint-disable react-hooks/rules-of-hooks */
import {useState} from 'react'

import AnimatedModal from 'src/app/components/AnimatedModal'
import Button from 'src/app/components/Button'
import AnimatedRadio from 'src/app/components/AnimatedRadio'

import {Container, FooterModal} from './styles'
import {
  DataUpdateSituationExclusion,
  UpdateSituationExclusion,
} from 'src/app/repositories/Atendimentos'
import {useAlert} from 'src/app/components/Alert/Alert'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import {ISolicitacaoExclusao} from 'src/app/model/Validacao/Type'
import {useService} from 'src/app/hooks/useService'

type Props = {
  data: ValidacaoModel
  onClose: () => void
}

const ExclusionModal = ({onClose, data}: Props) => {
  const items = ['Sem instalação', 'Não funciona', 'Outros']

  const haveReason = data.solicitacaoExclusao?.motivo

  const reasonIsOthers = haveReason && haveReason !== items[0] && haveReason !== items[1]

  const [currentValue, setCurrentValue] = useState<string | number>(
    reasonIsOthers ? items[2] : haveReason || ''
  )
  const [reason, setReason] = useState(reasonIsOthers ? haveReason : '')

  const [removeSolicitacao, setRemoveSolicitacao] = useState<boolean>(haveReason ? false : true)

  const {setServices} = useService()

  const isOthers = currentValue === 'Outros'

  const handleOnClick = (value: string | number) => {
    setCurrentValue(value)
  }

  const handleUpdateSituationExclusion = async (action: 'create' | 'cancelar') => {
    let o: DataUpdateSituationExclusion = {
      cancelar: false,
      codigoSolicitacao: data.solicitacaoExclusao?.codigo || null,
      codigoAtendimento: data.codigoAtendimento,
      motivo: (!isOthers ? currentValue : reason) as string,
    }

    if (action === 'cancelar') {
      o = {
        ...o,
        cancelar: true,
      }
    }

    try {
      const res = await UpdateSituationExclusion<ISolicitacaoExclusao | null>(o)

      onClose()
      useAlert('Solicitação atualizada com sucesso', 'Success')

      if (res) {
        setServices((oldState) => {
          return oldState.map((item) => {
            if (item.codigoAtendimento === data.codigoAtendimento) {
              item.solicitacaoExclusao = res
            }
            return item
          })
        })
      } else {
        setServices((oldState) => {
          return oldState.map((item) => {
            if (item.codigoAtendimento === data.codigoAtendimento) {
              item.solicitacaoExclusao = null
            }
            return item
          })
        })
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const Footer = () => {
    return (
      <FooterModal removeSolicitacao={removeSolicitacao}>
        <Button
          color='danger'
          disabled={removeSolicitacao}
          onClick={() => handleUpdateSituationExclusion('cancelar')}
        >
          Remover Solicitação
        </Button>
        <Button color='primary' onClick={() => handleUpdateSituationExclusion('create')}>
          Confirmar Solicitação
        </Button>
      </FooterModal>
    )
  }

  return (
    <AnimatedModal
      containerStyle={{height: 'auto', width: 400}}
      headerTitle='Motivo'
      footer={<Footer />}
      onClose={onClose}
    >
      <Container isOthers={isOthers}>
        {items.map((item) => (
          <AnimatedRadio
            label={item}
            value={item}
            currentValue={currentValue}
            onClick={handleOnClick}
          />
        ))}
        <textarea
          disabled={!isOthers}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder='Digite o motivo...'
        ></textarea>
      </Container>
    </AnimatedModal>
  )
}

export default ExclusionModal
