/* eslint-disable react-hooks/rules-of-hooks */

import { Dispatch, SetStateAction } from 'react'

import {
  CheckIcon,
  ExclamationIcon,
  HashtagIcon,
  PencilIcon,
  QuestionIcon,
  UserAltIcon,
  WorldPinIcon,
  Search,
  FormIcon,
  RoadIcon,
  CheckCircle,
  UserCheck,
  RegClock,
} from 'src/app/utils/Icons'

import { useSpring, animated } from '@react-spring/web'

import { Tooltip as ReactTooltip } from 'react-tooltip'

import IconButton from './components/IconButton'

import ValidacaoModel from 'src/app/model/Validacao/Validacao'

import { Container, Box } from './styles'

import 'react-tooltip/dist/react-tooltip.css'
import Modal from 'src/app/components/Modal'
import { useState } from 'react'
import ObservationItem from './components/ObservationItem'
import { DataUpdate, UpdateObservation } from 'src/app/repositories/Atendimentos'
import { useAlert } from 'src/app/components/Alert/Alert'
import Anotacoes from 'src/app/components/Anotacoes'
import AlternativePhotosModal from './components/AlternativePhotosModal'
import ExclusionModal from './components/ExclusionModal'
import { BiChevronUp } from 'react-icons/bi'
import AnotacaoModel from 'src/app/model/Anotacao/Anotacao'
import { useService } from 'src/app/hooks/useService'
import {
  CreateNote,
  DataUpdateNote,
  DeleteNote,
  GetNotes,
  UpdateNote,
} from 'src/app/repositories/Anotacao'

type Props = {
  viewOnly: boolean
  data: ValidacaoModel
  canEdit: boolean
  isWarning: boolean
  setIsWarning: Dispatch<SetStateAction<boolean>>
}

const ServiceData = ({ data, canEdit, isWarning, setIsWarning, viewOnly }: Props) => {
  const [isToggled, setToggle] = useState(false)
  const hasObservations = data.observacao
  const hasSolicitacaoExclusao = Boolean(data.solicitacaoExclusao)
  const observations: string[] = hasObservations ? Object.values(hasObservations) : ['', '', '']
  const hasOccurrence = data.ocorrencias.length > 0

  const isPre = data.statusAtendimento === 'PRE-REPROVADO'
  const isApr = data.statusAtendimento === 'APROVADO'
  const isRep = data.statusAtendimento === 'REPROVADO'

  const isDivergenciaTipoExecucao = data.divergencia?.tipoExecucao
  const isDivergenciaDataInicioFim = data.divergencia?.dataInicioFim
  const isDivergenciaDataSincronizacao = data.divergencia?.dataSincronizacao
  const isDivergenciaHorarioExecucaoInicial = data.divergencia?.horarioExecucaoInicial
  const isDivergenciaHorarioExecucaoFinal = data.divergencia?.horarioExecucaoFinal

  const [showObservationModal, setShowObservationModal] = useState(false)
  const [showNotesModal, setShowNotesModal] = useState(false)
  const [showAlternativePhotosModal, setShowAlternativePhotosModal] = useState(false)
  const [showExclusionModal, setShowExclusionModal] = useState(false)

  const [dataUpdate, setDataUpdate] = useState<DataUpdate>({
    codigoAtendimento: data.codigoAtendimento,
    observacaoUm: observations[1],
    observacaoDois: observations[2],
  } as DataUpdate)

  const menuAppear = useSpring({
    transform: isToggled ? 'translate3D(0,0,0)' : 'translate3D(0,-40px,0)',
    opacity: isToggled ? 1 : 0,
  })

  const closeModal = () => {
    setDataUpdate((oldState) => {
      return { ...oldState, observacaoUm: observations[1], observacaoDois: observations[2] }
    })
    setShowObservationModal(false)
  }

  const handleOpenAlternativePhotosModal = () => {
    setShowAlternativePhotosModal(true)
  }
  const handleCloseAlternativePhotosModal = () => {
    setShowAlternativePhotosModal(false)
  }

  const openModal = () => {
    setShowObservationModal(true)
  }

  const openNotesModal = () => {
    setShowNotesModal(true)
    getAnotacoes()
  }

  const handleCloseExclusionModal = () => {
    setShowExclusionModal(false)
  }
  const handleOpenExclusionModal = () => setShowExclusionModal(true)

  const ExclusionCheckbox = () => {
    return (
      <div className='checkbox' onClick={handleOpenExclusionModal}>
        <input
          checked={hasSolicitacaoExclusao}
          type='checkbox'
          id={'isChecked'}
          value={'isChecked'}
        />
        <label htmlFor={'isChecked'}>Solicitação de exclusão</label>
      </div>
    )
  }

  const { codigo: pontoCodigo } = data.pontoColeta
  const { setServices } = useService()

  const [codigo, setCodigo] = useState(0)
  const [anotacoes, setAnotacoes] = useState<AnotacaoModel[]>([])

  function handleGetNameForm(formulario: string) {
    switch (formulario) {
      case 'PA':
        return 'AUTO'
      case 'PG':
        return 'GLP'
    }
  }

  const handleCreateNote = async (mensagemAnotacao: string) => {
    try {
      const response = await CreateNote({
        codigoPontoColeta: pontoCodigo,
        descricao: mensagemAnotacao,
      })
      setAnotacoes((oldState) => {
        return [...oldState, response]
      })
      setServices((oldState) => {
        return oldState.map((item) => {
          if (item.codigoAtendimento === data.codigoAtendimento) {
            item.temAnotacao = true
          }
          return item
        })
      })
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const handleDeleteNote = async () => {
    const index = anotacoes.findIndex((item) => item.codigo === codigo)
    const currentItem = anotacoes[index]

    try {
      setAnotacoes(anotacoes.filter((item) => item.codigo !== currentItem.codigo))

      await DeleteNote(codigo)

      useAlert('Anotação excluida com sucesso', 'Success')

      setServices((oldState) => {
        return oldState.map((item) => {
          if (item.codigoAtendimento === data.codigoAtendimento && anotacoes.length - 1 === 0) {
            item.temAnotacao = false
          }

          return item
        })
      })
    } catch (error: any) {
      useAlert(error.message, 'Error')

      setAnotacoes((oldState) => [...oldState, currentItem])
    }
  }

  const handleUpdateNote = async (DataUpdateNote: DataUpdateNote) => {
    try {
      let editAnotacao = await UpdateNote(DataUpdateNote)

      const updateList = anotacoes.map((anotacao: AnotacaoModel) =>
        anotacao.codigo === editAnotacao.codigo ? editAnotacao : anotacao
      )
      setAnotacoes(updateList)

      useAlert('Anotação atualizada com sucesso', 'Success')
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const getAnotacoes = async () => {
    try {
      let anotacoes = await GetNotes(pontoCodigo)
      setAnotacoes(anotacoes)
    } catch (error) {
      useAlert('Não foi possível listar as anotações.', 'Error')
    }
  }

  return (
    <Container hasSolicitacaoExclusao={hasSolicitacaoExclusao} className='four'>
      {showExclusionModal && <ExclusionModal data={data} onClose={handleCloseExclusionModal} />}
      <Modal
        title={{ message: 'Observações' }}
        open={showObservationModal}
        handleClose={closeModal}
        footer
        onConfirm={async () => {
          try {
            if (dataUpdate.observacaoUm === '') dataUpdate.observacaoUm = null
            if (dataUpdate.observacaoDois === '') dataUpdate.observacaoDois = null

            await UpdateObservation(dataUpdate)

            setShowObservationModal(false)
          } catch (error: any) {
            useAlert(error.message, 'Error')
          }
        }}
        confirmText='Salvar'
      >
        <ObservationItem isTextArea label='Observações da ocorrência' value={observations[0]} />
        <ObservationItem
          value={dataUpdate.observacaoUm ?? undefined}
          onChange={(e) =>
            setDataUpdate((oldState) => {
              return { ...oldState, observacaoUm: e.target.value ?? null }
            })
          }
          label='Observação gestora 1'
        />
        <ObservationItem
          value={dataUpdate.observacaoDois ?? undefined}
          onChange={(e) =>
            setDataUpdate((oldState) => {
              return { ...oldState, observacaoDois: e.target.value ?? null }
            })
          }
          label='Observação gestora 2'
        />
      </Modal>

      <div className='header'>
        <div className='header-row'>
          <span>Dados do atendimento</span>
        </div>
      </div>

      {!viewOnly && (
        <div className='container-row'>
          <ExclusionCheckbox />
        </div>
      )}

      <div className='container-row'>
        <Box flex={2}>
          <WorldPinIcon size={15} />
          <span className='bold'>{`${data.pontoColeta.cidade} - ${data.pontoColeta.uf}`}</span>
        </Box>
        <Box flex={1}>
          <HashtagIcon size={15} />
          <span className='bold'>Fonte:</span>
          <span>{data.origem}</span>
        </Box>
      </div>

      <div className='container-row'>
        <Box flex={1}>
          <HashtagIcon size={15} />
          <span className='bold'>Atend: </span>
          <span>{data.codigoAtendimento}</span>
        </Box>
        <Box flex={1}>
          <WorldPinIcon size={15} />
          <span className='bold'>Cód.Triad: </span>
          <span>{data.pontoColeta.identificador}</span>
        </Box>
      </div>
      <div className='container-row'>
        <Box flex={1}>
          <RoadIcon size={15} />
          <span className='bold'>Rota: </span>
          <span>{data.rota}</span>
        </Box>
      </div>
      {isPre && isDivergenciaTipoExecucao && (
        <div data-tooltip-id='my-tooltip-2' className='container-row'>
          <Box className='red pulse' flex={2}>
            <FormIcon size={15} />
            <span className='bold'>Form: </span>
            <span>{handleGetNameForm(data.formulario)}</span>
          </Box>
          <Box className='red pulse' flex={3}>
            <WorldPinIcon size={15} />
            <span className='bold'>T.Exec: </span>
            <span>{data.pontoColeta.tipoExecucao}</span>
          </Box>
        </div>
      )}

      <div className='container-row'>
        <Box flex={1}>
          <Search size={15} />
          <span className='bold'>Pesq: </span>
          <span>{data.pesquisador.nome}</span>
        </Box>
      </div>

      {isPre && isDivergenciaHorarioExecucaoInicial && (
        <div data-tooltip-id='my-tooltip-3' className='container-row pulse red'>
          <Box flex={1}>
            <RegClock size={15} />

            <span className='bold'>Data Inicio: </span>
            <span>{data.inicioColeta.dataColeta}</span>
          </Box>
        </div>
      )}
      {isPre && isDivergenciaHorarioExecucaoFinal && (
        <div data-tooltip-id='my-tooltip-4' className='container-row pulse red'>
          <Box flex={1}>
            <RegClock size={15} />

            <span className='bold'>Data Fim: </span>
            <span>{data.fimColeta.dataColeta}</span>
          </Box>
        </div>
      )}

      {isPre &&
        isDivergenciaDataInicioFim &&
        !isDivergenciaHorarioExecucaoInicial &&
        !isDivergenciaHorarioExecucaoFinal && (
          <div data-tooltip-id='my-tooltip-1' className='container-row'>
            <Box className='red pulse' flex={1}>
              <RegClock size={15} />
              <span className='bold'>Data Início: </span>
              <span>{data.inicioColeta.dataColeta}</span>
            </Box>
            <Box className='red pulse' flex={1}>
              <RegClock size={15} />
              <span className='bold'>Data Fim: </span>
              <span>{data.fimColeta.dataColeta}</span>
            </Box>
          </div>
        )}

      {isPre && isDivergenciaDataSincronizacao && (
        <>
          {!isDivergenciaHorarioExecucaoFinal && !isDivergenciaDataInicioFim && (
            <div data-tooltip-id='my-tooltip-5' className='container-row pulse red'>
              <Box flex={1}>
                <RegClock size={15} />

                <span className='bold'>Data Fim: </span>
                <span>{data.fimColeta.dataColeta}</span>
              </Box>
            </div>
          )}
          <div data-tooltip-id='my-tooltip-5' className='container-row pulse red'>
            <Box flex={1}>
              <RegClock size={15} />

              <span className='bold'>Data Sincronização: </span>
              <span>{data.dataSincronizacao}</span>
            </Box>
          </div>
        </>
      )}

      <div className='container-row'>
        <Box flex={1}>
          <RegClock size={15} />

          <span className='bold'>Data Coleta: </span>
          <span>{data.dataColeta}</span>
        </Box>
      </div>

      {(isApr || isRep) && (
        <div className='container-row'>
          <Box flex={1}>
            <UserCheck size={15} />
            <span className='bold'>Gestor(a): </span>
            <span>{data.nomeUsuarioValidacao}</span>
          </Box>
          <Box flex={1}>
            <CheckCircle size={15} />
            <span className='bold'>Data Validação: </span>
            <span>{data.dataValidacao}</span>
          </Box>
        </div>
      )
      }

      <div>
        {showNotesModal && (
          <Anotacoes
            showAnotacoes={showNotesModal}
            setShowAnotacoes={setShowNotesModal}
            handleCreateNote={handleCreateNote}
            handleDeleteNote={handleDeleteNote}
            setCodigo={setCodigo}
            handleUpdateNote={handleUpdateNote}
            anotacoes={anotacoes}
          />
        )}

        <div className='container-row mt-5'>
          <IconButton
            onClick={openModal}
            empty={Boolean(hasObservations) && !viewOnly}
            text='Observações'
            Icon={ExclamationIcon}
            style={{ flex: 1 }}
            disabled={viewOnly}
          />
          <IconButton
            empty={data.temAnotacao && !viewOnly}
            onClick={openNotesModal}
            style={{ flex: 1 }}
            text='Anotações'
            Icon={PencilIcon}
            disabled={viewOnly}
          />
          <IconButton
            disabled={!isWarning || viewOnly}
            empty={isWarning && !viewOnly}
            text={isWarning ? 'Análise fotos adicionais' : 'Sem fotos adicionais'}
            Icon={CheckIcon}
            onClick={handleOpenAlternativePhotosModal}
          />
        </div>

        {showAlternativePhotosModal && (
          <AlternativePhotosModal
            data={data}
            onClose={handleCloseAlternativePhotosModal}
            setIsWarning={setIsWarning}
          ></AlternativePhotosModal>
        )}

        <div className='container-row column'>
          <IconButton
            disabled={!hasOccurrence || viewOnly}
            empty={hasOccurrence && !viewOnly}
            style={{ flex: 1 }}
            text='Ocorrências'
            Icon={QuestionIcon}
            Indicator={BiChevronUp}
            onClick={() => setToggle(!isToggled)}
            isToggled={isToggled}
          />

          <animated.div style={menuAppear} className='drop'>
            {isToggled ? data.ocorrencias.map((item) => <p>{item.descricao}</p>) : null}
          </animated.div>
        </div>
      </div>

      <ReactTooltip
        id='my-tooltip-1'
        place='right-start'
        content='Data Início e Fim estão divergentes.'
      />
      <ReactTooltip
        id='my-tooltip-2'
        place='right-start'
        content='Atendimento feito no formulário errado.'
      />
      <ReactTooltip
        id='my-tooltip-3'
        place='right-start'
        content='Atendimento feito antes das 6h.'
      />
      <ReactTooltip
        id='my-tooltip-4'
        place='right-start'
        content='Atendimento feito após às 20h.'
      />
      <ReactTooltip
        id='my-tooltip-5'
        place='right-start'
        content='Atendimento sincronizado fora do tempo estimado.'
      />
    </Container>
  )
}

export default ServiceData
