/* eslint-disable react-hooks/rules-of-hooks */
import {useEffect, useState} from 'react'

import {SearchIcon} from 'src/app/utils/Icons'

import {Container, Table} from './styles'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import {PrecosColeta} from 'src/app/model/Validacao/Type'
import {GetPayments} from 'src/app/repositories/Atendimentos'
import TableRow from './components/TableRow'
import {useAlert} from 'src/app/components/Alert/Alert'
import LastServicesModal from './components/LastServicesModal'

type Props = {
  data: ValidacaoModel
  canEdit: boolean
  showPayments?: boolean
  prices?: PrecosColeta[]
  sigla?: string
}

export type Payment = {
  codigo: number
  valor: string
  identificador: string
}

const PriceScore = ({data, canEdit, showPayments = false, prices, sigla}: Props) => {
  const [payments, setPayments] = useState<Payment[]>([])
  const [showLastServicesModal, setShowLastServicesModal] = useState(false)

  const handleOpenLastServicesModal = () => setShowLastServicesModal(true)
  const handleCloseLastServicesModal = () => setShowLastServicesModal(false)

  const showColumn =
    (data?.ultimoAtendimento && data.ultimoAtendimento?.precosPesquisa?.length > 0) || false

  useEffect(() => {
    async function getData() {
      try {
        const res = await GetPayments()

        setPayments(res)
      } catch (error: any) {
        useAlert(error.message, 'Error', () => {}, 8, 'payment')
      }
    }

    getData()
  }, [])

  return (
    <Container>
      {showLastServicesModal && (
        <LastServicesModal data={data} onClose={handleCloseLastServicesModal} />
      )}
      <Table>
        <tr className='title'>
          <th>Combustível</th>
          {showColumn && <th> Últ. coleta 06/06/2023</th>}

          <th>Coleta atual</th>
          {showPayments && <th>Forma de pagamento</th>}
        </tr>

        {prices
          ? prices.map((item: PrecosColeta, index) => {
              return (
                <TableRow
                  index={index}
                  item={item}
                  data={data}
                  showColumn={showColumn}
                  canEdit={canEdit}
                  payments={payments}
                  showPayments={showPayments}
                  sigla={sigla}
                />
              )
            })
          : data.precosColeta.map((item: PrecosColeta, index) => {
              return (
                <TableRow
                  index={index}
                  item={item}
                  data={data}
                  showColumn={showColumn}
                  canEdit={canEdit}
                  payments={payments}
                  showPayments={showPayments}
                />
              )
            })}
      </Table>

      {showColumn && canEdit && (
        <button onClick={handleOpenLastServicesModal}>
          <SearchIcon /> Analisar atendimento {data.ultimoAtendimento?.data}
        </button>
      )}
    </Container>
  )
}

export default PriceScore
