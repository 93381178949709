import styled from 'styled-components'

type ContainerProps = {
  selected: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;

  justify-content: space-between;
  align-items: center;

  transition: background-color 0.6s;

  background-color: ${({selected}) => (selected ? '#F89C32' : '#fff')};

  color: ${({selected}) => (selected ? '#fff' : '#2b2b2b')};

  button {
    background-color: transparent;

    transition: all 0.2s;
    filter: brightness(120%);

    &:hover {
      filter: brightness(100%);
    }
  }

  .info {
    display: flex;
    flex: 1;
    align-items: center;

    .drag-container {
      flex: 1;
      cursor: move;
    }

    .id {
      font-weight: bold;
    }

    .order {
      width: 32px;
      height: 32px;

      margin-right: 10px;

      text-align: center;

      outline: none;

      color: #2b2b2b;
    }

    div {
      display: flex;
      flex-direction: column;

      justify-content: center;

      p {
        margin: 0 !important;
      }
    }
  }

  padding: 8px;
`
