import api from '../config'

import Erro from '../model/Erro/Erro'
import {handlerResult} from '../utils/API'

type DataLogon = {
  email: string
  senha: string
}
type DataRedefinePassword = {
  email: string
  senha: string
  confirmacaoSenha: string
}

export async function LogIn(dataLogIn: DataLogon) {
  try {
    const response = await api.post('auth', dataLogIn)

    const dataResponse = await handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function ForgetPassword(email: string) {
  try {
    const response = await api.post('auth/esqueciSenha', {email})
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function RedefinePassword(data: DataRedefinePassword) {
  try {
    const response = await api.post('auth/redefinirSenha', data)
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}
