import styled, {css} from 'styled-components'

type ContainerProps = {
  empty: boolean
  isToggled?: boolean
}

export const Container = styled.button<ContainerProps>`
  background-color: #5e6278;

  cursor: pointer !important;

  transition: all 0.2s;
  filter: brightness(120%);

  &:hover {
    filter: brightness(100%);
  }

  ${({isToggled}) =>
    isToggled
      ? css`
          border-radius: 5px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : css`
          border-radius: 5px;
        `}

  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;

  width: 100%;

  padding: 8px;

  margin-bottom: 10px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  /* transform: scale(1); */

  ${({empty, isToggled}) =>
    empty && !isToggled
      ? css`
          background-color: #f89c32;
          animation: orangePulse 2s infinite;
        `
      : empty &&
        isToggled &&
        css`
          background-color: #f89c32;
        `}

  &:disabled {
    background-color: #919597;

    cursor: not-allowed !important;
  }
`
