import { LiHTMLAttributes } from "react"

export default function BulletList({ bulletColor = 'primary', items, className, ...rest }: Props) {
  return <>
    {items.map((e, i) => <li key={i} className="d-flex align-items-center py-2" {...rest}>
      <span className={`bullet bullet-vertical bg-${bulletColor} me-5 ${className}`}></span>
      {e}
    </li>)}
  </>
}

interface Props extends LiHTMLAttributes<HTMLLIElement> {
  items: string[]
  bulletColor?: string
  className?: string
}