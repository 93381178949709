import styled from 'styled-components'

type ContainerBoxProps = {
  flex?: number
}

export const Container = styled.div`
  display: flex;

  flex-direction: column;

  width: 100%;

  gap: 4px;

  .container-row {
    display: flex;
    gap: 0 5px;

    width: 100%;

    &.column {
      flex-direction: column;
    }
  }
`

export const ContainerBox = styled.div<ContainerBoxProps>`
  display: flex;
  flex-direction: column;

  flex: ${({flex = 1}) => flex};

  .add {
    background-color: transparent;

    padding-top: 14px;
  }
`
