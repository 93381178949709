import {Dispatch, SetStateAction, useState} from 'react'
import AnotacaoModel from 'src/app/model/Anotacao/Anotacao'
import {CardBody} from './styles'
import Button from '../Button'
import {DataUpdateNote} from 'src/app/repositories/Anotacao'
import {useAuth} from 'src/app/hooks/useAuth'

type Props = {
  anotacao: AnotacaoModel
  setShowRemovalAlert: Dispatch<SetStateAction<boolean>>
  setCodigo: Dispatch<SetStateAction<number>>
  handleUpdateNote: (DataUpdateNote: DataUpdateNote) => Promise<void>
}
const NoteItem = ({anotacao, setShowRemovalAlert, setCodigo, handleUpdateNote}: Props) => {
  const [bodyMessage, setBodyMessage] = useState(anotacao?.descricao || '')
  const [isUpdating, setIsUpdating] = useState(false)

  const {currentUser} = useAuth()

  return (
    <div className='card card-custom shadow-sm mb-2' key={String(anotacao.codigo)}>
      <div className='card-header'>
        <h3 className='card-title'>{anotacao.nomeUsuario}</h3>
        {currentUser!.codigo === anotacao.codigoUsuario && (
          <div className='card-toolbar'>
            <div className='p-1'>
              <button
                className='btn btn-icon btn-light-primary'
                onClick={() => setIsUpdating((oldState) => !oldState)}
              >
                <i className='bi bi-pencil-square'></i>
              </button>
            </div>
            <button
              className='btn btn-icon btn-light-danger'
              onClick={() => {
                setCodigo(anotacao.codigo)
                setShowRemovalAlert(true)
              }}
            >
              <i className='bi bi-x fs-1'></i>
            </button>
          </div>
        )}
      </div>
      <CardBody className='card-body'>
        <textarea
          value={bodyMessage}
          onChange={(e) => setBodyMessage(e.target.value)}
          disabled={!isUpdating}
        />
        {isUpdating && (
          <Button
            onClick={() => {
              handleUpdateNote({codigo: anotacao.codigo, descricao: bodyMessage})
              setIsUpdating(false)
            }}
          >
            Salvar
          </Button>
        )}
      </CardBody>
      <div className='card-footer'>
        <p className='mb-0'>{`Data de Criação: ${anotacao.dataCriacao}`}</p>
        {anotacao.dataUltimaAlteracao && (
          <p className='mb-0'>{`Data de Atualização: ${anotacao.dataUltimaAlteracao}`}</p>
        )}
      </div>
    </div>
  )
}

export default NoteItem
