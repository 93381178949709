import {Container} from './styles'

const StatusTag = (props: Props) => {
  const {type = 'danger'} = props

  const schema: SchemaProps = {
    danger: {
      color: '#f64e60',
      background: '#ffe2e5',
    },
    warning: {
      color: '#F2A65E',
      background: 'rgba(242,166,94,0.18)',
    },
    info: {
      color: '#008FFB',
      background: '#F1FAFF',
    },
  }
  return (
    <Container style={{height: 'fit-content', width: '120px'}} schema={schema[type]}>
      <span>{props.name}</span>
    </Container>
  )
}

export default StatusTag

type Props = {
  name: string
  type?: 'danger' | 'warning' | 'info'
}

type SchemaProps = {
  danger: {color: string; background: string}
  warning: {color: string; background: string}
  info: {color: string; background: string}
}
