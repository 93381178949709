import styled from 'styled-components'

export const Container = styled.div`
  header {
    padding: 8px;
    width: 100%;

    border-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    display: flex;

    align-items: center;
    justify-content: space-between;

    background-color: #2f2f2f;

    color: #fff;
    font-weight: bold;
    font-family: Poppins;

    button {
      background-color: #0095e8;
      border-radius: 15px;
      color: white;

      padding: 4px 8px;

      font-weight: 400;
      font-family: Poppins;

      transition: all 0.2s;
      filter: brightness(120%);

      &:hover {
        filter: brightness(100%);
      }
    }
  }

  .main {
    padding: 16px;

    border: 1px solid rgba(47, 47, 47, 0.3);

    border-radius: 5px;

    border-top-right-radius: 0;
    border-top-left-radius: 0;

    border-top-width: 0;
  }
`
