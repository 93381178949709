/* eslint-disable react-hooks/rules-of-hooks */
// import { Container } from './styles';

import AnimatedModal from 'src/app/components/AnimatedModal'
import {Select} from 'src/app/components/Select/Select'
import {Container} from './styles'
import ModalAlert from 'src/app/components/ModalAlert'
import {useEffect, useState} from 'react'
import {IModalAlert} from '../../../usuario/Usuarios'
import {GetClients, RemoveClient, removeClientData} from 'src/app/repositories/OperationalBase'
import {useAlert} from 'src/app/components/Alert/Alert'

const RemoveClientModal = ({onClose, getTableData}: Props) => {
  const [modalAlert, setModalAlert] = useState<IModalAlert | undefined>(undefined)
  const [clients, setClients] = useState([])
  const [client, setClient] = useState<{label: string; value: number}>()

  const getData = async () => {
    try {
      const res = await GetClients()

      if (res) setClients(res)
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AnimatedModal
      headerTitle='Remover Cliente'
      onClose={onClose}
      containerStyle={{height: 400, width: 800}}
      onSubmit={() => {
        const action = async () => {
          try {
            if (client) {
              const o: removeClientData = {
                codigoCliente: client.value,
              }
              const res = await RemoveClient(o)

              useAlert(res, 'Success')
              setModalAlert(undefined)
              getTableData()
              onClose()
            }
          } catch (error: any) {
            useAlert(error.message, 'Error')
          }
        }
        setModalAlert({
          title: 'Aviso!',
          content: 'Ao confirmar essa ação seu evento é irreversível. Deseja continuar?',
          action,
        })
      }}
    >
      <Container>
        <Select label='Nome:' options={clients} onChange={(e: any) => setClient(e)} />

        <div className='obs'>
          <span className='fs-6 fw-bolder text-dark'>Observação: </span>
          <span>
            Ao remover o cliente os pontos de coleta exclusivos serão removidos da base operacional.
          </span>
        </div>
      </Container>

      <ModalAlert
        isLoading={false}
        title={modalAlert?.title}
        content={modalAlert?.content}
        onConfirm={modalAlert?.action}
        onShow={Boolean(modalAlert)}
        onHide={() => setModalAlert(undefined)}
      />
    </AnimatedModal>
  )
}

export default RemoveClientModal

type Props = {
  onClose: () => void
  getTableData: () => void
}
