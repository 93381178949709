import api from '../config'

import Erro from '../model/Erro/Erro'
import {handlerResult} from '../utils/API'
import {stringArrayToSelectOptions} from '../utils/Formats'

// const serializeListMunicipios = (response: any) => {
//   const model: string[] = []

//   for (const municipio of response) {
//     model.push(municipio.nome)
//   }

//   return model
// }

// export async function GetMunicipiosPorEstado(siglaUF: string) {
//   try {
//     const response = await api.get(
//       `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${siglaUF}/municipios`
//     )

//     return serializeListMunicipios(response.data)
//   } catch (error: any) {
//     if (error.response) {
//       throw new Erro(error.response.data.data.message)
//     }
//   }
// }

export async function GetUFs() {
  try {
    const response = await api.get('localidade/listarUfs')

    const responseData: string[] = handlerResult(response.data)

    return stringArrayToSelectOptions(responseData)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetMunicipiosPorEstado(uf: string) {
  try {
    const response = await api.post('localidade/listarMunicipiosPorUf', {uf})
    const responseData: string[] = handlerResult(response.data)

    return stringArrayToSelectOptions(responseData)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}
