import styled from 'styled-components'

export const CardContainer = styled.div`
  flex: 1;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  border-radius: 5px;

  padding: 10px;

  display: flex;
  flex-direction: column;

  .cardHeader {
    display: flex;

    position: relative;

    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #ddd;

    padding-bottom: 10px;

    button {
      background-color: transparent;
    }
  }

  .cardFooter {
    border-top: 1px solid #ddd;

    padding-top: 10px;
  }

  .cardBody {
    flex: 1;
  }
`
