import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow-x: hidden;

  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  width: 100%;
  height: 780px;

  padding: 10px;

  background-color: #e8e8e8;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #f89c32;
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 8px;

    margin: 10px 0 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #f89c32;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #364150;
  }
`
