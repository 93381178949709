/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'

import RouteTable from './components/RouteTable'

import {GetRoutes, GetRotasFilters, GetRouteQuantitatives} from 'src/app/repositories/Route'

import {useAlert} from 'src/app/components/Alert/Alert'
import {useRoute} from 'src/app/hooks/useRoute'
import { Metric, OptionInput} from '../usuario/Usuarios'
import {GetMunicipiosPorEstado, GetUFs} from 'src/app/repositories/Location'
import CardsWidget from 'src/app/components/CardsWidget'
import {useSearchParams} from 'react-router-dom'
import { IFilter } from 'src/app/model/Generic/Type'

const empytFilters = {
  agendaDaSemana: {
    segunda: false,
    terca: false,
    quarta: false,
    quinta: false,
    sexta: false,
    sabado: false,
    domingo: false,
  },
  cidade: null,
  nomesRotas: [],
  situacao: null,
  uf: null,
  pesquisadorPendente: false,
  numeroPagina: 1,
  tamanhoPagina: 5,
}

const emptyFilter = {label: 'Selecione uma opção', value: null}

const Rota = () => {
  const {routes, setRoutes} = useRoute()

  const [searchParams, setSearchParams] = useSearchParams()
  const pageSize =
    searchParams.get('pageSize') !== null ? parseInt(searchParams.get('pageSize')!) : 5
  const pageNumber =
    searchParams.get('pageNumber') !== null ? parseInt(searchParams.get('pageNumber')!) : 1
  const [pagesTotal, setPagesTotal] = useState(0)

  const [isLoading, setIsLoading] = useState(true)

  const [nomesRotasOptions, setNomeRotasOptions] = useState<IFilter[]>([])
  const [ufOptions, setUfOptions] = useState<IFilter[]>([])
  const [cidadeOptions, setCidadeOptions] = useState<IFilter[]>([])

  const [isSearchingPending, setIsSearchingPending] = useState(false)
  const [isSearchingPendingSync, setIsSearchingPendingSync] = useState(false)

  const [filters, setFilters] = useState<GetRotasFilters>(empytFilters)

  const [pendingRouteInfo, setPendingRouteInfo] = useState<Metric[]>([])

  const [nomesRotasFilter, setNomesRotasFilter] = useState<OptionInput | null>(null)
  const [agendaDaSemanaFilter, setAgendaDaSemanaFilter] = useState<OptionInput | null>(null)
  const [cidadeFilter, setCidadeFilter] = useState<OptionInput | null>(emptyFilter)
  const [situacaoFilter, setSituacaoFilter] = useState<OptionInput | null>(emptyFilter)
  const [ufFilter, setUfFilter] = useState<OptionInput | null>(emptyFilter)

  function applyFilters() {
    setFilters(() => {
      const agendaDaSemana = {
        segunda: false,
        terca: false,
        quarta: false,
        quinta: false,
        sexta: false,
        sabado: false,
        domingo: false,
      }
      //@ts-ignore
      agendaDaSemanaFilter?.map((item) => (agendaDaSemana[item.value] = true))
      //@ts-ignore
      let nomesRotas = nomesRotasFilter?.map((item) => item.value) || []

      let cidade = (cidadeFilter?.value as string) || null
      let situacao = (situacaoFilter?.value as string) || null
      let uf = (ufFilter?.value as string) || null

      return {
        agendaDaSemana,
        cidade,
        nomesRotas,
        situacao,
        uf,
        pesquisadorPendente: isSearchingPending,
        sincronizacaoPendente: isSearchingPendingSync,
        numeroPagina: pageNumber,
        tamanhoPagina: pageSize,
      }
    })

    searchParams.set('pageNumber', '1')
    setSearchParams(searchParams, {replace: false})
  }

  const cleanFilters = () => {
    setFilters(empytFilters)

    setNomesRotasFilter(null)
    setAgendaDaSemanaFilter(null)

    setCidadeFilter(emptyFilter)
    setSituacaoFilter(emptyFilter)
    setUfFilter(emptyFilter)

    setIsSearchingPending(false)
    setIsSearchingPendingSync(false)

    getData(empytFilters)
  }

  const handleGetData = async (filters: GetRotasFilters) => {
    try {
      setIsLoading(true)
      const res = await GetRoutes(filters)
      const ufs = await GetUFs()

      if (ufs) {
        setUfOptions(ufs)
      }

      if (res) {
        const {nomeRotas, routes, totalDePaginas} = res

        setPagesTotal(totalDePaginas)
        setRoutes(routes)
        setNomeRotasOptions(nomeRotas)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      setIsLoading(false)
    }
  }

  const getData = (o?: GetRotasFilters) => {
    if (o) {
      handleGetData(o)
    } else {
      const o: GetRotasFilters = filters

      handleGetData(o)
    }
  }

  const getRouteQuantitatives = async () => {
    try {
      const res = await GetRouteQuantitatives()

      if (res) {
        const {rotasConcluidas, rotasPendentesPesquisador, rotasPendentesSincronizacao} = res

        setPendingRouteInfo([
          {label: 'Concluídos', value: rotasConcluidas},
          {label: 'Pesquisador', value: rotasPendentesPesquisador},
          {label: 'Sincronizacao', value: rotasPendentesSincronizacao},
        ])
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  useEffect(() => {
    getRouteQuantitatives()
  }, [routes])

  useEffect(() => {
    getData()
  }, [filters])

  useEffect(() => {
    setFilters((oldState) => ({
      ...oldState,
      numeroPagina: pageNumber,
      tamanhoPagina: pageSize,
    }))
  }, [searchParams])

  const getMunicipiosPorEstado = async () => {
    if (ufFilter && ufFilter.value !== null) {
      try {
        const res = await GetMunicipiosPorEstado(String(ufFilter.value))

        if (res) setCidadeOptions(res)
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    }
  }

  useEffect(() => {
    getMunicipiosPorEstado()
  }, [ufFilter])

  return (
    <>
      <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
        {pendingRouteInfo.length > 0 && (
          <div className='d-flex justify-content-start gap-10'>
            <CardsWidget
              title='Rotas pendentes'
              countTotal={pendingRouteInfo[1].value}
              itens={pendingRouteInfo}
              bgColor='light-danger'
              textColor='danger'
              colors={['#008FFB', '#f1416c', '#F2A65E']}
            />
          </div>
        )}
      </div>
      <div className={'card'}>
        <div className='card-body py-3'>
          <RouteTable
            pagesTotal={pagesTotal}
            isLoading={isLoading}
            ufOptions={ufOptions}
            cleanFilters={cleanFilters}
            applyFilters={applyFilters}
            filters={[
              {
                label: 'Rota',
                options: nomesRotasOptions,
                value: nomesRotasFilter,
                setSelectedOption: setNomesRotasFilter,
                isMulti: true,
              },
              {
                label: 'UF',
                options: ufOptions,
                value: ufFilter,
                setSelectedOption: setUfFilter,
              },
              {
                label: 'Cidade',
                options: cidadeOptions,
                value: cidadeFilter,
                setSelectedOption: setCidadeFilter,
                disabled: ufFilter?.label === 'Selecione uma opção',
                disabledLabel: 'Selecione uma UF',
              },
              {
                label: 'Situação',
                options: [
                  {label: 'Ativo', value: 'ATIVO'},
                  {label: 'Inativo', value: 'INATIVO'},
                ],
                value: situacaoFilter,
                setSelectedOption: setSituacaoFilter,
              },
              {
                label: 'Agenda da semana', 
                options: [
                  {label: 'Dom', value: 'domingo'},
                  {label: 'Seg', value: 'segunda'},
                  {label: 'Ter', value: 'terca'},
                  {label: 'Qua', value: 'quarta'},
                  {label: 'Qui', value: 'quinta'},
                  {label: 'Sex', value: 'sexta'},
                  {label: 'Sab', value: 'sabado'},
                ],
                value: agendaDaSemanaFilter,
                setSelectedOption: setAgendaDaSemanaFilter,
                isMulti: true,
              },
              {
                type: 'switch',
                label: 'Rotas pendentes de pesquisador',
                value: isSearchingPending,
                setSelectedOption: setIsSearchingPending,
              },
              {
                type: 'switch',
                label: 'Rotas pendentes de sincronização',
                value: isSearchingPendingSync,
                setSelectedOption: setIsSearchingPendingSync,
              },
            ]}
            getRoutes={getData}
            routes={routes}
          />
        </div>
      </div>
    </>
  )
}

export default Rota
