import { FormEventHandler } from "react";
import Button from "./Button";

/* eslint-disable react-hooks/exhaustive-deps */
export default function Filtro(props: Props){
  return (
  <>
    <Button 
      icon="filter"  
      tooltip="Filtros Avançados" 
      color="primary"
      fill={false}
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasExample" 
      aria-controls="offcanvasExample"
      onClick={props.onClick}
    />
    <div className="offcanvas offcanvas-end" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasExampleLabel">Filtros Avançados</h5>
      </div>
      <div className="separator my-2 border border border-secondary"></div>
      <div className="offcanvas-body b-4">
          {props.filtrosAvançados}
      </div>
    </div>
  </>
  )
}
interface Props{
  filtrosAvançados?: JSX.Element,
  onClick?: FormEventHandler<HTMLButtonElement> | undefined
}


