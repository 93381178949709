/**
 * PARÂMETROS:
 * - title obrigatório|String = Texto exibição alerta
 * - type obrigatório|Enum TypeAlert = Estilo do alerta
 * - show obrigatório|Booleano = Variável de estado hide/show
 * - setShow obrigatório|Function = Função de estado hide/show
 * - customSize opcional|String = Alteração width padrão
 * - timeSecond opcional|Numerico = Tempo exibição segundo
 **/
import {CSSProperties} from 'react'
import {ToastContainer, toast, CloseButtonProps} from 'react-toastify'

import Icon from '../Icon'
import Button from '../Button'

import {PropTypeAlert, Props, TypeAlert} from './Alert.d'
import 'react-toastify/dist/ReactToastify.css'
import './Alert.css'

const getPropType = {
  [TypeAlert.INFO]: (): PropTypeAlert => {
    return new PropTypeAlert(
      toast.TYPE.INFO,
      'information-2',
      'var(--toastify-background-color-info)',
      'var(--toastify-text-color-info)'
    )
  },
  [TypeAlert.SUCCESS]: (): PropTypeAlert => {
    return new PropTypeAlert(
      toast.TYPE.SUCCESS,
      'check-circle',
      'var(--toastify-background-color-success)',
      'var(--toastify-text-color-success)'
    )
  },
  [TypeAlert.WARNING]: (): PropTypeAlert => {
    return new PropTypeAlert(
      toast.TYPE.WARNING,
      'information',
      'var(--toastify-background-color-warning)',
      'var(--toastify-text-color-warning)'
    )
  },
  [TypeAlert.ERROR]: (): PropTypeAlert => {
    return new PropTypeAlert(
      toast.TYPE.ERROR,
      'shield-cross',
      'var(--toastify-background-color-error)',
      'var(--toastify-text-color-error)'
    )
  },
  [TypeAlert.DEFAULT]: (): PropTypeAlert => {
    return new PropTypeAlert(
      toast.TYPE.DEFAULT,
      'abstract-26',
      'var(--toastify-background-color-default)',
      'var(--toastify-text-color-default)'
    )
  },
}

export const useAlert = (
  title: string,
  type: 'Info' | 'Success' | 'Warning' | 'Error' | 'Default' = 'Default',
  onClose = () => {},
  timeSecond = 3,
  customId?: string
) => {
  const getType = () => {
    switch (type) {
      case 'Info':
        return TypeAlert.INFO
      case 'Error':
        return TypeAlert.ERROR
      case 'Success':
        return TypeAlert.SUCCESS
      case 'Warning':
        return TypeAlert.WARNING
      default:
        return TypeAlert.DEFAULT
    }
  }

  const timeMilliseconds = timeSecond * 1000
  const propTypeBuild: () => PropTypeAlert = getPropType[getType()]
  const propType = propTypeBuild()

  const msgStyle: CSSProperties = {
    backgroundColor: propType.backgroundColor,
    display: 'flex',
    alignItems: 'center',
    color: propType.textColor,
    fontWeight: '600',
  }

  toast(title, {
    onClose: onClose,
    type: propType.toastTYPE,
    style: msgStyle,
    icon: () => <Icon icon={propType.icon} iconColor={type.toLocaleLowerCase()} />,
    closeButton: ({closeToast}: CloseButtonProps) => (
      <Button icon='cross' color={type.toLocaleLowerCase()} fill={false} onClick={closeToast} />
    ),
    autoClose: timeMilliseconds,
    toastId: customId,
  })
}

export default function Alert({customSize = '35vw'}: Props) {
  return (
    <ToastContainer
      position={toast.POSITION.TOP_CENTER}
      style={{width: customSize ?? 'fit-content'}}
    />
  )
}
