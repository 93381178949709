import axios from 'axios'

import {getAuth, removeAuth} from '../modules/auth'

import {useAlert} from '../components/Alert/Alert'

const URL_BACKEND = window.location.hostname.includes('localhost')
  ? 'http://localhost:8080/triad-admin/'
  : 'https://triadadmin.triadpesquisa.com.br/triad-admin/'

const api = axios.create({
  baseURL: URL_BACKEND,
})

api.interceptors.request.use((config) => {
  const token = getAuth()

  if (token) {
    config.headers!.Authorization = `Bearer ${token.auth}`
  }

  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      useAlert(
        'Acesso expirado',
        'Warning',
        () => {
          removeAuth()
          window.location.reload()
        },
        4,
        'token'
      )
    }

    return Promise.reject(error)
  }
)

export default api
