import AnimatedModal from 'src/app/components/AnimatedModal'
import {ModalHeader} from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/CardHeader/components/ChangeModal/styles'
import ContainerCard from './components/ContainerCard'
import {ICostCenterResponse} from 'src/app/repositories/CenterCost'

import {ClipboardListIcon, CloseIcon} from 'src/app/utils/Icons'

import {Container} from './styles'

const CostCenterViewInfoModal = ({onClose, centerCostInfo}: Props) => {
  const Cadastrodata = [
    {value: centerCostInfo.centroDeCusto.nomeFantasia || 'Não preenchido', label: 'Nome Fantasia'},
    {value: centerCostInfo.centroDeCusto.razaoSocial || 'Não preenchido', label: 'Razão Social'},
    {
      value: centerCostInfo.centroDeCusto.nomeProprietario || 'Não preenchido',
      label: 'Proprietário',
    },
    {value: centerCostInfo.centroDeCusto.cpfOuCnpj || 'Não preenchido', label: 'CPF/CNPJ'},
    {value: centerCostInfo.centroDeCusto.email || 'Não preenchido', label: 'Email'},
    {value: centerCostInfo.centroDeCusto.telefone || 'Não preenchido', label: 'Telefone'},
  ]

  const Bankdata = [
    {value: centerCostInfo.dadoBancario.banco?.nome || 'Não preenchido', label: 'Banco'},
    {value: centerCostInfo.dadoBancario.tipoConta?.nome || 'Não preenchido', label: 'Tipo Conta'},
    {
      value: centerCostInfo.dadoBancario.agencia || 'Não preenchido',
      label: 'Agência',
    },
    {value: centerCostInfo.dadoBancario.conta || 'Não preenchido', label: 'Conta'},
    {value: centerCostInfo.dadoBancario.chavePix || 'Não preenchido', label: 'Chave pix'},
  ]

  const Header = ({onClick}: any) => {
    return (
      <ModalHeader>
        <ClipboardListIcon size={25} />
        <span>Dados Centro de Custo</span>
        <button onClick={onClick}>
          <CloseIcon size={20} fill='#666666' />
        </button>
      </ModalHeader>
    )
  }

  return (
    <AnimatedModal
      containerStyle={{padding: 32, width: 600}}
      header={<Header />}
      onSubmit={onClose}
      onClose={onClose}
    >
      <Container>
        <ContainerCard title='Cadastro' data={Cadastrodata} />
        <ContainerCard title='Dados Bancários' data={Bankdata} isBlur />
        <ContainerCard title='Pesquisadores' table={centerCostInfo.pesquisadores} />
      </Container>
    </AnimatedModal>
  )
}

type Props = {
  onClose: () => void
  centerCostInfo: ICostCenterResponse
}

export default CostCenterViewInfoModal
