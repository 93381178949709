import clsx from 'clsx'
import { AnchorHTMLAttributes, FC } from 'react'
import Icon from './Icon'

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
 title: string
 icon?: string
 iconSize?: string,
 iconType?: 'duotone' | 'solid' | 'outline'
}

const MenuItem: FC<Props> = ({ title, icon, iconSize, iconType, ...rest }) => {

 return (
  <div className='menu-item me-lg-1'>
   <a
    className={clsx('menu-link py-3')}
    {...rest}
   >
    {icon && (
     <span className='menu-icon'>
      <Icon icon={icon} iconSize={iconSize} iconType={iconType}/>
     </span>
    )}
    <span className='menu-title'>{title}</span>
   </a>

  </div>
 )
}

export { MenuItem }

