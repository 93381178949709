import {Container} from './styles'
import InputText from 'src/app/components/Form/InputText'
import {Schedule} from '../..'
import {Dispatch, SetStateAction} from 'react'
import SelectDays, {SelectDay} from '../SelectDays'
import {DetailRouteResponse} from 'src/app/repositories/Route'
import {Convert_DDMMYYYY_to_YYYYMMDD, Convert_YYYYMMDD_to_DDMMYYYY} from 'src/app/utils/Date'

const ScheduleTabs = ({
  handleUpdateSchedule,
  schedule,
  routeDetail,
  isExpiration,
  setIsExpiration,
}: Props) => {
  return (
    <Container>
      <div className='footer'>
        <div className='footerRow'>
          <span>Data Início:</span>
          <InputText
            className='w-25'
            type='date'
            placeholder='Data Inicio'
            value={Convert_DDMMYYYY_to_YYYYMMDD(schedule['dataInicial']) || ''}
            onChange={(e) => {
              handleUpdateSchedule('dataInicial', Convert_YYYYMMDD_to_DDMMYYYY(e.target.value))
            }}
            height={40}
          />
          <span className='w-20'>Data Fim:</span>
          <InputText
            disabled={!isExpiration}
            className='w-25 '
            type='date'
            placeholder={isExpiration ? 'Data Fim' : 'Sem Expiração'}
            value={Convert_DDMMYYYY_to_YYYYMMDD(schedule['dataFinal']) || ''}
            onChange={(e) => {
              handleUpdateSchedule('dataFinal', Convert_YYYYMMDD_to_DDMMYYYY(e.target.value))
            }}
            height={40}
          />
          <SelectDay
            className='ml'
            onClick={() => setIsExpiration((oldState) => !oldState)}
            day='Ativar Expiração'
            //@ts-ignore
            isSelected={isExpiration}
          />
        </div>
        <div className='footerRow'>
          <span>Agenda Da Semana: </span>
          <SelectDays
            semana={routeDetail?.planejamento}
            handleUpdateSchedule={handleUpdateSchedule}
          />
        </div>
        <div className='footerRow '>
          <span>Frequência:</span>
          <InputText
            type='number'
            max={6}
            min={0}
            className='w-25'
            value={schedule['frequencia'] as number | string}
            onChange={(e) => {
              handleUpdateSchedule('frequencia', e.target.value)
            }}
            height={40}
            disabled
          />
          <span>Intervalo:</span>
          <InputText
            type='number'
            min={0}
            className='w-25'
            value={schedule['intervalo'] as number | string}
            onChange={(e) => {
              handleUpdateSchedule('intervalo', e.target.value)
            }}
            height={40}
          />
          <span>Antecedência:</span>
          <InputText
            type='number'
            max={6}
            min={0}
            className='w-25'
            value={schedule['antecedencia'] as number | string}
            onChange={(e) => {
              handleUpdateSchedule('antecedencia', e.target.value)
            }}
            height={40}
          />
          <span>Pendência:</span>
          <InputText
            type='number'
            max={6}
            min={0}
            className='w-25'
            value={schedule['pendencia'] as number | string}
            onChange={(e) => {
              handleUpdateSchedule('pendencia', e.target.value)
            }}
            height={40}
          />
        </div>
        <div className='footerRow'>
          <span className='normal'>
            <b>Frequência</b>
            {` ${schedule['frequencia']}x na semana`}
          </span>
          <span className='normal'>
            com <b>Intervalo</b>
            {` de ${schedule['intervalo']} semana(s)`}
          </span>
          <span className='normal'>
            e <b>Antecedência</b> {` ${schedule['antecedencia']}`}
          </span>
          <span className='normal'>
            e <b>Pendência</b>
            {` ${schedule['pendencia']} dia(s).`}
          </span>
        </div>
      </div>
    </Container>
  )
}

type Props = {
  handleUpdateSchedule: (key: keyof Schedule, value: string | number) => void
  schedule: Schedule
  routeDetail: DetailRouteResponse
  isExpiration: boolean
  setIsExpiration: Dispatch<SetStateAction<boolean>>
}

export default ScheduleTabs
