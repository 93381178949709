import styled from 'styled-components'
import {animated} from '@react-spring/web'

export const Wrapper = styled(animated.div)`
  z-index: 999;

  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.6);

  display: flex;

  justify-content: center;
  align-items: center;

  padding: 1%;
`

export const Container = styled(animated.div)`
  display: flex;
  flex-direction: column;

  width: 100%;

  height: 100%;

  max-width: 1200px;

  background-color: #fafafa;

  gap: 10px;

  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  overflow-y: auto;

  position: relative;

  border-radius: 5px;

  .backward,
  .forward {
    cursor: pointer;

    background-color: rgba(255, 255, 255, 0.35);

    padding: 0 !important;

    position: absolute;
    z-index: 20;
    transform: translateY(-50%);

    width: 50px;

    height: 50px;
  }

  .backward {
    top: 50%;
    left: 0;
    transform: rotate(180deg);
  }
  .forward {
    top: 53%;
    right: 0;
  }
`

export const ModalBody = styled.div`
  background-color: #fafafa;

  position: relative;

  display: flex;

  flex: 1;
`

export const Header = styled.div`
  display: flex;

  height: 40px;
  width: 100%;

  border-bottom: 1px solid #ddd;
  background-color: #fafafa;
`

export const Footer = styled.div`
  display: flex;

  height: 40px;
  width: 100%;

  border-top: 1px solid #ddd;
  background-color: #fafafa;
`
