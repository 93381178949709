import Button from 'src/app/components/Button'
import {ModalFooter} from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/CardHeader/components/ChangeModal/styles'

// import { Container } from './styles';

const Footer = ({onClick, formik, isEditing}: any) => {
  return (
    <ModalFooter>
      <div style={{padding: 16}} className='buttons'>
        <Button title='Cancelar' color='danger' onClick={onClick}>
          Cancelar
        </Button>
        <Button
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          color='primary'
          title='OK'
          onClick={formik.submitForm}
        >
          {isEditing ? 'Editar' : 'Criar'}
        </Button>
      </div>
    </ModalFooter>
  )
}

export default Footer
