import styled, {css} from 'styled-components'

type MenuButtonProps = {
  checked: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  .header {
    display: flex;
    gap: 10px;

    width: 100%;
    height: 40px;
  }

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;

    height: 100px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    font-family: Poppins;
    color: #2b2b2b;
    font-size: 14px;

    .footerRow {
      display: flex;
      align-items: center;
      /* justify-content: space-evenly; */
      gap: 5px;

      span {
        font-weight: bold;
      }

      .normal {
        font-weight: 400;
        font-size: 13px;
        font-style: italic;
      }

      span.w-20 {
        margin-left: 20px;
      }
    }
  }
`
export const MenuButton = styled.button<MenuButtonProps>`
  transition: all 0.16s;

  font-family: Poppins;

  border-radius: 5px;

  ${({checked}) =>
    checked &&
    css`
      background-color: #007bff;
      color: #fff;
    `}

  flex: 1;
`
