/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {useState, useEffect, ReactNode} from 'react'

import AnimatedModal from 'src/app/components/AnimatedModal'

import {Container} from './styles'
import {GetLastServices} from 'src/app/repositories/Atendimentos'
import {useAlert} from 'src/app/components/Alert/Alert'
import {HeaderModal} from '../../../ServiceData/components/ExclusionModal/styles'
import {CardContainer} from '../../../CardHeader/components/HomologacaoModal/styles'
import PriceScore from '../..'
import {PrecosColeta} from 'src/app/model/Validacao/Type'
import {getImageFromBucket} from 'src/app/utils/API'

export type LastService = {
  codigo: number
  data: string
  precosPesquisa: PrecosColeta[]
  fotoPP: string
}

const LastServicesModal = ({onClose, data}: any) => {
  const [lastServices, setLastServices] = useState<LastService[]>([])
  const [currentServiceData, setCurrentServiceData] = useState('')
  const [currentService, setCurrentService] = useState<LastService>({} as LastService)

  const Card = ({
    title,
    children,
    footer,
    contentContainerStyle,
    ...rest
  }: {
    title: string
    children: ReactNode
    footer?: ReactNode
    style?: React.CSSProperties
    contentContainerStyle?: React.CSSProperties
  }) => {
    return (
      <CardContainer style={{...contentContainerStyle, backgroundColor: '#fff'}}>
        <div className='cardHeader'>
          <h3 className='card-title'>{title}</h3>
        </div>
        <div className='cardBody' {...rest}>
          {children}
        </div>
        {footer && <div className='cardFooter'>{footer}</div>}
      </CardContainer>
    )
  }

  useEffect(() => {
    async function getData() {
      try {
        const o = {
          codigoAtendimento: data.codigoAtendimento,
          quantidade: 5,
        }
        const res = await GetLastServices<LastService[]>(o)

        if (res) {
          setLastServices(res)
          setCurrentServiceData(res[0].data)
        }
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    }
    getData()
  }, [])

  useEffect(() => {
    const index = lastServices.findIndex((item) => item.data === currentServiceData)

    setCurrentService(lastServices[index])
  }, [currentServiceData])

  return (
    <AnimatedModal
      headerTitle={`Análise últimos atendimentos ponto ${data.pontoColeta.cnpj}`}
      onClose={onClose}
    >
      <Container>
        <div className='esquerda'>
          <select name='' id=''>
            {lastServices.map((item) => (
              <option value={item.codigo}>{item.data}</option>
            ))}
          </select>
          <Card title='Placar de Preço'>
            <img src={getImageFromBucket(currentService?.fotoPP)} alt='' />
          </Card>
        </div>
        <div className='direita'>
          <PriceScore canEdit={false} data={data} prices={currentService?.precosPesquisa} />
        </div>
      </Container>
    </AnimatedModal>
  )
}

export default LastServicesModal
