import { formatCnpj } from "src/app/utils/Formats";
import { IExclusao } from "./Type";

export class ExclusaoModel {
    private _codigo: number
    private _codigoAtendimento: number
    private _identificadorPonto: string
    private _cnpj: string
    private _tipoExecucao: string
    private _rota: string
    private _cidade: string
    private _uf: string
    private _pesquisador: string
    private _dataSolicitacao: string
    private _solicitante: string
    private _motivoSolicitacao: string
    private _codigoExterno: string
    private _fonte: string
    private _situacaoSolicitacao: string
    private _remocaoSolicitacao: string
    private _usuarioResponsavelRemocao: string

    constructor({
        codigo,
        codigoAtendimento,
        identificadorPonto,
        cnpj,
        tipoExecucao,
        rota,
        cidade,
        uf,
        pesquisador,
        dataSolicitacao,
        solicitante,
        motivo,
        codigoExterno,
        fonte,
        situacao,
        dataRemocao,
        removidoPor
    }: IExclusao) {
        this._codigo = codigo;
        this._codigoAtendimento = codigoAtendimento;
        this._identificadorPonto = identificadorPonto;
        this._cnpj = formatCnpj(cnpj);
        this._tipoExecucao = tipoExecucao;
        this._rota = rota;
        this._cidade = cidade;
        this._uf = uf;
        this._pesquisador = pesquisador;
        this._dataSolicitacao = dataSolicitacao;
        this._solicitante = solicitante;
        this._motivoSolicitacao = motivo;
        this._codigoExterno = codigoExterno;
        this._fonte = fonte;
        this._situacaoSolicitacao = situacao;
        this._remocaoSolicitacao = dataRemocao;
        this._usuarioResponsavelRemocao = removidoPor;
    }

    get codigo() {
        return this._codigo;
    }
    
    get identificadorPonto(){
        return this._identificadorPonto;
    }
    get cnpj(){
        return this._cnpj;
    }
    get tipoExecucao(){
        return this._tipoExecucao;
    }
    get rota(){
        return this._rota;
    }
    get cidade(){
        return this._cidade;
    }
    get uf(){
        return this._uf;
    }
    get pesquisador(){
        return this._pesquisador;
    }
    get dataSolicitacao(){
        return this._dataSolicitacao;
    }
    get solicitante(){
        return this._solicitante;
    }
    get motivoSolicitacao(){
        return this._motivoSolicitacao;
    }
    get codigoExterno(){
        return this._codigoExterno;
    }
    get fonte(){
        return this._fonte;
    }
    get situacaoSolicitacao(){
        return this._situacaoSolicitacao;
    }
    get remocaoSolicitacao(){
        return this._remocaoSolicitacao;
    }
    get usuarioResponsavelRemocao(){
        return this._usuarioResponsavelRemocao;
    }
    get codigoAtendimento(){
        return this._codigoAtendimento;
    }
}