/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react'
import ApexCharts from 'apexcharts'
import {Metric} from '../pages/planejamento-execucao/usuario/Usuarios'

export default function CardsWidget({
  title,
  countTotal,
  itens,
  bgColor,
  textColor,
  colors = [],
}: Props) {
  const chartRef = useRef(null)
  const [chart, setChart] = useState<ApexCharts>()
  const [options] = useState({
    series: itens.map((item: Metric) => item.value),
    labels: itens.map((item: Metric) => item.label),
    chart: {
      width: 280,
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: true,
          },
        },
      },
    ],
    legend: {
      // show: false,
      position: 'right',
      // offsetY: 0,
      height: 230,
    },
    colors: colors,
  })
  function init() {
    const newChart = new ApexCharts(chartRef.current, options)
    setChart(newChart)
    newChart.render()
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    chart?.updateSeries(itens.map((item: Metric) => item.value))
  }, [itens])

  return (
    <div
      className={`card card-flush bg-${bgColor} bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-10 border border-muted d-flex flex-row shadow-sm`}
    >
      <div className='card-header px-6'>
        <div className='card-title d-flex flex-column pt-2'>
          <span className={`fs-2hx fw-bold text-${textColor} me-2 lh-1 ls-n2`}>{countTotal}</span>
          <span className={`text-${textColor} opacity-75 pt-1 fw-semibold fs-6`}>{title}</span>
        </div>
      </div>
      <div className='card-body d-flex flex-column align-items-end p-2 px-0'>
        <div className='d-flex align-items-center flex-column mt-3 w-100'></div>
        <div id='chart' ref={chartRef} />
      </div>
    </div>
  )
}

type Props = {
  title?: string
  countTotal?: number
  itens: Metric[]
  bgColor?: string
  textColor?: string
  colors?: string[]
}
