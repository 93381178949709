import styled, {css} from 'styled-components'

type InputProps = {
  isFocused: boolean
  isWarning: boolean
}
type InputContainerProps = {
  isTextArea: boolean
  isFocused: boolean
  isWarning: boolean
  value: string
  empty: string
  backgroundColor: string
}

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  display: flex;

  .masterinput-legend {
    position: absolute;
    left: 10px;
    top: 8px;

    transition: top 0.15s;

    display: flex;

    align-items: center;

    background-color: ${({backgroundColor}) => backgroundColor};

    padding: 1px 4px;

    font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;

    .masterinput-label {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);

      transition: all 0.15s;
    }
  }

  #security {
    font-family: text-security-disc;
    -webkit-text-security: disc;
  }

  #security::placeholder {
    font-family: 'Inter' !important;
  }

  max-height: 38px;
  flex: 1;

  border: 1px solid rgb(192, 192, 192);
  padding: 1px;

  border-radius: 5px !important;

  ${({value}) =>
    value &&
    css`
      .masterinput-legend {
        top: -12px;

        .masterinput-label {
          font-size: 12px;
        }
      }
    `}

  ${({isFocused}) =>
    isFocused
      ? css`
          border-color: #2196f3 !important;

          .masterinput-legend {
            top: -12px;
            padding: 0 3px;
            .masterinput-label {
              font-size: 12px;
              color: #2196f3;
            }
          }
        `
      : css`
          border-color: rgb(192, 192, 192);

          &:hover {
            border-color: #212121;
          }
        `}

        ${({isWarning}) =>
    isWarning &&
    css`
      border-color: #f89c32;
      color: #f89c32 !important;
    `}
        
  ${({empty}) =>
    empty &&
    css`
      border-color: #ef4836;
      border-width: 2px;

      padding: 0;

      .masterinput-legend {
        top: -12px;
        padding: 0 3px;
        .masterinput-label {
          font-size: 12px;
          color: #ef4836;
        }
      }
    `}
`

export const MasterInput = styled.input<InputProps>`
  width: ${({className}) => (className ? 'auto' : '100%')};
  max-height: 38px;

  border: 0;
  border-radius: 5px !important;
  outline: none;

  padding: 8.5px 14px;

  color: #313131 !important;
  font-weight: 400;
  font-family: 'Inter';

  &::placeholder {
    color: #d3d3d3;
    transition: all 0.15s;
  }

  @keyframes orangePulse {
    0% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(248, 156, 50, 0.7);
    }

    70% {
      /* transform: scale(1); */
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      /* transform: scale(0.95); */
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  ${({isWarning, isFocused}) =>
    isWarning &&
    !isFocused &&
    css`
      color: #f89c32 !important;
      animation: orangePulse 2s infinite;
    `}

  ${({isFocused}) =>
    isFocused
      ? css`
          &::placeholder {
            opacity: 1;
          }
        `
      : css`
          &::placeholder {
            opacity: 0;
          }
        `};
`

export const MasterTextArea = styled.textarea<InputProps>`
  width: 100%;

  border: 0;
  border-radius: 5px !important;
  outline: none;

  padding: 8.5px 14px;

  color: #313131 !important;
  font-weight: 400;
  font-family: 'Inter';

  &::placeholder {
    color: #d3d3d3;
    transition: all 0.15s;
  }

  ${({isFocused}) =>
    isFocused
      ? css`
          &::placeholder {
            opacity: 1;
          }
        `
      : css`
          &::placeholder {
            opacity: 0;
          }
        `};
`

export const IconContainer = styled.button`
  background-color: transparent;
  border: none;
  outline: none;

  padding: 2px 5px;
`

export const ErrorMessage = styled.span`
  color: #ef4836;
  font-size: 12px;
  font-family: 'Poppins';
`
