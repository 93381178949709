import React, {ReactNode, useState, useEffect} from 'react'

import {CardContainer} from './styles'
import {MoreOutlineIcon} from 'src/app/utils/Icons'
import {useSpring, useInView} from 'react-spring'
import {Actions} from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/CardHeader/styles'
import {IconType} from 'react-icons/lib'

const ModalCard = ({
  title,
  children,
  footer,
  showButton = false,
  actions,
  contentContainerStyle,
  buttonAction,
  ButtonActionIcon,
  buttonActionIconSize = 30,

  ...rest
}: Props) => {
  const [ref, isInView] = useInView({
    rootMargin: '-45% 0px -45% 0px',
  })

  const [showActions, setShowActions] = useState(isInView)
  const [runAnimation, setRunAnimation] = useState(false)

  const scaleAnimation = {
    opacity: runAnimation ? 1 : 0,
    config: {
      tension: 300,
      duration: 150,
    },
  }

  const handleCloseActions = () => {
    setRunAnimation(false)

    setTimeout(() => {
      setShowActions(false)
    }, 150)
  }

  const handleOpenActions = () => {
    setRunAnimation((oldState) => !oldState)
    setShowActions(true)
  }

  const animatedStyle = useSpring(scaleAnimation)

  function useOutside(ref: React.RefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleCloseActions()
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutside(ref)

  return (
    <CardContainer style={{backgroundColor: '#fff', ...contentContainerStyle}}>
      <div className='cardHeader'>
        <h3 className='card-title'>{title}</h3>
        {showButton && (
          <button onClick={() => (buttonAction ? buttonAction() : handleOpenActions())}>
            {ButtonActionIcon ? (
              <ButtonActionIcon size={buttonActionIconSize} />
            ) : (
              <MoreOutlineIcon size={20} />
            )}
          </button>
        )}

        {showActions && showButton && (
          <Actions orientation='left' ref={ref} style={animatedStyle}>
            {actions?.map((item) => (
              <p
                onClick={() => {
                  item.onClick()
                  handleCloseActions()
                }}
              >
                {item.label}
              </p>
            ))}
          </Actions>
        )}
      </div>
      <div className='cardBody' {...rest}>
        {children}
      </div>
      {footer && <div className='cardFooter'>{footer}</div>}
    </CardContainer>
  )
}

type Props = {
  title: string
  children: ReactNode
  footer?: ReactNode
  style?: React.CSSProperties
  contentContainerStyle?: React.CSSProperties
  showButton?: boolean
  buttonAction?: () => void
  ButtonActionIcon?: IconType
  buttonActionIconSize?: number
  actions?: {
    label: string
    onClick: () => void
  }[]
}

export default ModalCard
