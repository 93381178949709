/* eslint-disable react-hooks/exhaustive-deps */
import React, {Dispatch, FC, SetStateAction, createContext, useEffect, useState} from 'react'
import {AuthModel, UserModel} from '../modules/auth'
import * as authHelper from 'src/app/modules/auth/core/AuthHelpers'
import {WithChildren} from 'src/_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined, currentUser: UserModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

const AuthContextProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth()?.auth)
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(authHelper.getAuth()?.user)

  const saveAuth = (auth: AuthModel | undefined, currentUser: UserModel | undefined) => {
    setAuth(auth)
    setCurrentUser(currentUser)

    if (auth && currentUser) {
      authHelper.setAuth(auth, currentUser)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined, undefined)
    setCurrentUser(undefined)
  }

  useEffect(() => {
    if (currentUser) {
      setCurrentUser(currentUser)
    } else {
      logout()
    }
  }, [])

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
