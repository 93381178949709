import styled, {css} from 'styled-components'

type ContainerProps = {
  isOthers: boolean
}

type ButtonProps = {
  removeSolicitacao?: boolean
}

export const HeaderModal = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;

  span {
    color: #1b1b1b;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 300;
    font-family: 'Poppins';
    font-size: 18px;
    text-align: center;
  }

  button {
    position: absolute;
    background-color: transparent;
    top: 8px;
    right: 8px;
  }
`

export const Container = styled.div<ContainerProps>`
  padding: 16px;
  width: 100%;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;

  textarea {
    width: 100%;
    margin-top: 15px;

    border: none;
    outline: none;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;

    border-radius: 5px;

    ${({isOthers}) =>
      !isOthers &&
      css`
        cursor: not-allowed;

        background-color: #eef1f6;
      `}
  }
`

export const FooterModal = styled.div<ButtonProps>`
  padding: 10px;
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;

  ${({removeSolicitacao}) =>
    !removeSolicitacao &&
    css`
      cursor: not-allowed;
    `}
`
