import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  flex-direction: column;

  width: 100%;

  gap: 4px;

  .container-row {
    display: flex;
    gap: 0 5px;

    width: 100%;

    &.column {
      flex-direction: column;
    }
  }
`
export const FooterModal = styled.div`
  display: flex;
  gap: 20px;

  margin-left: auto;
`
