/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {useState, useEffect} from 'react'

import AnimatedModal from 'src/app/components/AnimatedModal'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'

import {Container, ModalFooter, ModalHeader} from './styles'
import {GetServicesPhotos, SwipeServicePhoto} from 'src/app/repositories/Atendimentos'
import {FotoColeta, ISigla} from 'src/app/model/Validacao/Type'
import {useAlert} from 'src/app/components/Alert/Alert'
import ItemPhoto from './components/ItemPhoto'
import Button from 'src/app/components/Button'
import {CloseIcon} from 'src/app/utils/Icons'
import {useService} from 'src/app/hooks/useService'

type Props = {
  data: ValidacaoModel
  onClose: () => void
  sigla: string
  callback?: () => void
}

export type Photo = {
  codigo: number
  siglaFoto: ISigla
  fileName: string
  latitude: number
  longitude: number
  precisao: number
  distancia: number
  descricao: string
}

type CurrentValue = {
  value: string | number
  descricao: string
  siglaFoto: string
}

const ChangeModal = ({onClose, data, sigla, callback}: Props) => {
  const [photos, setPhotos] = useState<Photo[]>([])
  const [currentValue, setCurrentValue] = useState<CurrentValue>({} as CurrentValue)
  const [currentPhoto, setCurrentPhoto] = useState<CurrentValue>({} as CurrentValue)

  const [isLoading, setIsLoading] = useState(false)

  const {setServices} = useService()

  function handleOnClick(value: string | number, descricao: string, siglaFoto: string) {
    if (value === currentValue.value) {
      setCurrentValue({value: '', descricao: '', siglaFoto: ''})
    } else {
      setCurrentValue({value, descricao, siglaFoto})
    }
  }

  const handleSwipePhoto = async () => {
    try {
      setIsLoading(true)
      const res = await SwipeServicePhoto<FotoColeta[]>(currentPhoto.value, currentValue.value)

      if (res) {
        setServices((oldState) =>
          oldState.map((item) => {
            if (item.codigoAtendimento === data.codigoAtendimento) {
              const photoOneIndex = item.fotosColeta.findIndex(
                (i) => i.siglaFoto === currentPhoto.siglaFoto
              )
              const photoTwoIndex = item.fotosColeta.findIndex(
                (i) => i.siglaFoto === currentValue.siglaFoto
              )

              const newPhotoOne = res.filter((i) => i.siglaFoto === currentPhoto.siglaFoto)[0]
              const newPhotoTwo = res.filter((i) => i.siglaFoto === currentValue.siglaFoto)[0]

              item.fotosColeta[photoOneIndex] = newPhotoTwo
              item.fotosColeta[photoTwoIndex] = newPhotoOne
            }

            return item
          })
        )
      }

      if (callback) callback()
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      onClose()
      setIsLoading(false)
    }
  }

  useEffect(() => {
    async function getData() {
      try {
        const res = await GetServicesPhotos<Photo[]>(data.codigoAtendimento)

        if (res) {
          setPhotos(res.filter((item) => item.siglaFoto !== sigla))
          const item = res.filter((item) => item.siglaFoto === sigla)[0]
          setCurrentPhoto({
            descricao: item.descricao,
            value: item.codigo,
            siglaFoto: item.siglaFoto,
          })
        }
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    }

    getData()
  }, [])

  const Header = () => {
    return (
      <ModalHeader>
        <span>
          Trocar Foto <b>{currentPhoto.descricao}</b>
          <span>por</span>
          <b>{currentValue.descricao}</b>
        </span>
        <button onClick={onClose}>
          <CloseIcon size={20} fill='#666666' />
        </button>
      </ModalHeader>
    )
  }

  const Footer = () => {
    return (
      <ModalFooter>
        <div className='buttons'>
          <Button title='Cancelar' color='danger' onClick={onClose}>
            Cancelar
          </Button>
          <Button loading={isLoading} onClick={handleSwipePhoto} color='primary' title='OK'>
            OK
          </Button>
        </div>
      </ModalFooter>
    )
  }

  return (
    <AnimatedModal
      containerStyle={{height: 'auto', padding: 16}}
      footer={<Footer />}
      header={<Header />}
      onClose={onClose}
    >
      <Container>
        {photos.map((item) => (
          <ItemPhoto handleOnClick={handleOnClick} currentValue={currentValue.value} item={item} />
        ))}
      </Container>
    </AnimatedModal>
  )
}

export default ChangeModal
