import styled, {css} from 'styled-components'

type ContainerPros = {
  isSelected: boolean
  isWarning: boolean
  isDivergence: boolean
  isApproved: boolean
  isDisapproved: boolean
}

export const Container = styled.div<ContainerPros>`
  @keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: 150%;
      opacity: 0;
      display: none;
      visibility: hidden;
    }
  }

  &.slide {
    animation: slide 1s forwards;
  }

  display: flex;
  flex-direction: column;

  position: relative;

  transition: all 0.15s, left 1.5s;

  scroll-snap-align: center;

  border: 3px solid #e8e8e8;
  background-color: #fafafa;

  border-radius: 5px;

  ${({isWarning}) =>
    isWarning &&
    css`
      border-color: #f89c32;
    `}

  ${({isDivergence}) =>
    isDivergence &&
    css`
      border-color: #f3565d;
    `}
  ${({isApproved}) =>
    isApproved &&
    css`
      border-color: #e8e8e8;
      background-color: #b9e3cc;
    `}
  ${({isDisapproved}) =>
    isDisapproved &&
    css`
      border-color: #e8e8e8;
      background-color: #ebbec0;
    `}

  ${({isSelected}) =>
    isSelected &&
    css`
      border-color: #2196f3;
      background-color: #c4dcee;
    `}

  width: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px;

    border-bottom: 1px solid #cecece;

    .buttons {
      display: flex;
      gap: 20px;
    }

    .info {
      display: flex;
      align-items: center;
      gap: 10px;

      .title {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }

  .body {
    display: flex;
    flex: 1;
  }
`
