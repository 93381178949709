import {FormikValues} from 'formik'
import {ReactNode} from 'react'

export default function Form({title, subtitle, formik, children}: Props) {
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>{title}</h1>
        <div className='text-gray-500 fw-semibold fs-6'>{subtitle}</div>
      </div>

      {formik.status && formik.status?.success && (
        <div data-testid='success' className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>{formik.status?.message}</div>
        </div>
      )}

      {formik.status && !formik.status?.success && (
        <div data-test='erro' className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status?.message}</div>
        </div>
      )}

      {children}
    </form>
  )
}

type Props = {
  formik: FormikValues
  children: ReactNode
  title?: string
  subtitle?: string
}
