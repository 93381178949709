/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'

import {Navigate} from 'react-router-dom'

import * as Yup from 'yup'
import YupPassword from 'yup-password'

import {useFormik} from 'formik'
import {useIntl} from 'react-intl'

import Form from 'src/app/components/Form'
import InputText from 'src/app/components/Form/InputText'
import Button from 'src/app/components/Button'
import {encryptMessage} from 'src/app/utils/Encrypt'
import {RedefinePassword as RedefinePasswordFunc} from 'src/app/repositories/Login'
import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {useAuth} from 'src/app/hooks/useAuth'

const initialValues = {
  password: '',
  confirmPassword: '',
}

YupPassword(Yup)

export function RedefinePassword() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser, auth, currentUser} = useAuth()

  const loginSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH'}, {name: 'Senha', min: '6'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Senha', max: '50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Senha'}))
      .minLowercase(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINLOWERCASE'}, {name: 'Senha'}))
      .minUppercase(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINUPPERCASE'}, {name: 'Senha'}))
      .minNumbers(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINNUMBERS'}, {name: 'Senha'}))
      .minSymbols(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINSYMBOLS'}, {name: 'Senha'})),
    confirmPassword: Yup.string()
      .min(6, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH'}, {name: 'Senha', min: '6'}))
      .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Senha', max: '50'}))
      .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Senha'}))
      .minLowercase(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINLOWERCASE'}, {name: 'Senha'}))
      .minUppercase(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINUPPERCASE'}, {name: 'Senha'}))
      .minNumbers(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINNUMBERS'}, {name: 'Senha'}))
      .oneOf([Yup.ref('password')], 'As senhas devem ser iguais.')
      .minSymbols(1, intl.formatMessage({id: 'AUTH.VALIDATION.MINSYMBOLS'}, {name: 'Senha'})),
  })

  let publicKey =
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCz1zqQHtHvKczHh58ePiRNgOyiHEx6lZDPlvwBTaHmkNlQyyJ06SIlMU1pmGKxILjT7n06nxG7LlFVUN5MkW/jwF39/+drkHM5B0kh+hPQygFjRq81yxvLwolt+Vq7h+CTU0Z1wkFABcTeQQldZkJlTpyx0c3+jq0o47wIFjq5fwIDAQAB'

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      // console.log(encryptMessage('123@Mudar', publicKey))
      setLoading(true)
      try {
        const encryptPassword = encryptMessage(values.password, publicKey)
        const encryptConfirmPassword = encryptMessage(values.confirmPassword, publicKey)

        const data = {
          email: currentUser!.email,
          senha: encryptPassword,
          confirmacaoSenha: encryptConfirmPassword,
        }

        const response = await RedefinePasswordFunc(data)

        const user = currentUser
        user!.primeiroAcesso = false

        setCurrentUser(user)
        saveAuth(auth, user)

        setStatus({success: true, message: response})

        // navigate(0)
      } catch (error: any) {
        setStatus({success: false, message: error.message})
        setSubmitting(false)
      } finally {
        setLoading(false)
      }
    },
  })

  if (!currentUser?.primeiroAcesso) {
    return <Navigate to={'/validacao'} />
  }

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='/' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/logo-triad-default.png')}
            className='h-60px'
          />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Form title='Redefinir Senha' formik={formik}>
            <InputText
              label={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              type='password'
              formik={{
                formikProp: 'password',
                formikValues: formik,
              }}
            />
            <InputText
              label={intl.formatMessage({id: 'AUTH.INPUT.CONFIRMPASSWORD'})}
              type='password'
              formik={{
                formikProp: 'confirmPassword',
                formikValues: formik,
              }}
            />

            <div className='d-grid mb-10 mt-10'>
              <Button
                disabled={formik.isSubmitting || !formik.isValid}
                loading={loading}
                color='primary'
              >
                {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
              </Button>
            </div>
          </Form>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}
