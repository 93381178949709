import {useState, useRef, ChangeEventHandler, ComponentProps} from 'react'

import {VisibilityIcon, VisibilityOffIcon} from '../../utils/Icons'

import {InputContainer, MasterInput, IconContainer, ErrorMessage} from './styles'
import {UseFormSetValue} from 'react-hook-form'

type AnimatedInputPros = ComponentProps<'input'> & {
  value: any
  titulo: string
  name?: string
  defaultValue?: string | number | readonly string[] | undefined
  type?: React.HTMLInputTypeAttribute | undefined
  id?: string
  placeholder?: string
  data?: string[]
  onFocus?: (
    e: React.FocusEvent<HTMLInputElement, Element> | React.FocusEvent<HTMLTextAreaElement, Element>
  ) => void
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement, Element> | React.FocusEvent<HTMLTextAreaElement, Element>
  ) => void
  isRequired?: boolean
  contentStyle?: React.CSSProperties
  dataName?: string | undefined
  isTextArea?: boolean
  isWarning?: boolean
  className?: string
  isPassword?: boolean
  empty?: string
  mask?: string
  onChange: ChangeEventHandler<HTMLInputElement>
  setValue?: UseFormSetValue<any>
  disabled?: boolean
  maxLength?: number
  backgroundColor?: string
}

function AnimatedInput({
  value,
  name = '',
  onFocus = () => {},
  onBlur = () => {},
  isRequired = false,
  titulo,
  contentStyle = {},
  data = [],
  dataName = undefined,
  isTextArea = false,
  className = '',
  id = '',
  isPassword = false,
  isWarning = false,
  empty = '',
  mask = '0,0',
  onChange,
  setValue,
  backgroundColor = '#fff',
  ...rest
}: AnimatedInputPros) {
  const [isFocused, setIsFocused] = useState(false)
  const [visibility, setVisibility] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const Focus = (
    event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    onFocus(event)
    setIsFocused(true)
  }
  const Blur = (
    event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    onBlur(event)
    setIsFocused(false)
  }

  const RenderEyeIcon = () => {
    if (visibility)
      return (
        <IconContainer
          onClick={() => {
            inputRef.current!.setAttribute('id', id)

            setVisibility(false)
          }}
        >
          <VisibilityIcon style={{color: '#2196f3'}} />
        </IconContainer>
      )

    return (
      <IconContainer
        onClick={() => {
          inputRef.current!.removeAttribute('id')
          setVisibility(true)
        }}
      >
        <VisibilityOffIcon style={{color: '#2196f3'}} />
      </IconContainer>
    )
  }

  return (
    <div style={{display: 'flex', flex: 1, padding: 0, flexDirection: 'column'}}>
      <InputContainer
        isWarning={isWarning}
        style={contentStyle}
        isTextArea={isTextArea}
        value={value}
        isFocused={isFocused}
        className={className}
        empty={empty}
        backgroundColor={backgroundColor}
      >
        {titulo && (
          <div
            onClick={() => {
              inputRef.current!.focus()
            }}
            className='masterinput-legend'
          >
            <span className='masterinput-label'>{titulo}</span>
            {isRequired && <span style={{color: '#EF4836'}}>*</span>}
          </div>
        )}

        <MasterInput
          id={id}
          isWarning={isWarning}
          ref={inputRef}
          value={value}
          data-testid={`${name}-input`}
          isFocused={isFocused}
          onFocus={Focus}
          onBlur={Blur}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e)
          }}
          {...rest}
        />

        <datalist id={dataName}>
          {data.map((item, ind) => (
            <option key={ind}>{item}</option>
          ))}
        </datalist>

        {isPassword && <RenderEyeIcon />}
      </InputContainer>
      <ErrorMessage>{empty}</ErrorMessage>
    </div>
  )
}

export default AnimatedInput
