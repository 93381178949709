import {IErroSincronization} from './type'

export default class ErrorSincronization {
  private _nomeRota: string
  private _dataSincronizacao: string
  private _detalheErro: string

  constructor({nomeRota, dataSincronizacao, detalheErro}: IErroSincronization) {
    this._nomeRota = nomeRota
    this._dataSincronizacao = dataSincronizacao
    this._detalheErro = detalheErro
  }

  get nomeRota() {
    return this._nomeRota
  }

  get dataSincronizacao() {
    return this._dataSincronizacao
  }

  get detalheErro() {
    return this._detalheErro
  }
}
