/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useMemo, useEffect} from 'react'
import {useTransition} from '@react-spring/web'
import {Container} from './styles'

import MainTree from './components/MainTree'

let id = 0

type msg = {
  success: {
    RTWS004: 'ROTA SINCRONIZADA'
    RTWS005: 'ROTA SINCRONIZADA'
    RTWS002: 'ROTA SINCRONIZADA'
    RTWS003: 'ROTA SINCRONIZADA'
    RTWS001: 'ROTA SINCRONIZADA'
    RTWS010: 'ROTA SINCRONIZADA'
    RTWS008: 'ROTA SINCRONIZADA'
    RTWS009: 'ROTA SINCRONIZADA'
    RTWS006: 'ROTA SINCRONIZADA'
    RTWS007: 'ROTA SINCRONIZADA'
  }
  error: {
    RTWS013: 'NÃO SINCRONIZADA'
    RTWS014: 'NÃO SINCRONIZADA'
    RTWS011: 'NÃO SINCRONIZADA'
    RTWS012: 'NÃO SINCRONIZADA'
  }
}

const NotificationHub = ({
  config = {tension: 125, friction: 20, precision: 0.1},
  timeout = 3000,
  children,
  onClick = () => {},
}: MessageHubProps) => {
  const refMap = useMemo(() => new WeakMap(), [])
  const cancelMap = useMemo(() => new WeakMap(), [])
  const [items, setItems] = useState<Item[]>([])

  const transitions = useTransition(items, {
    from: {opacity: 0, height: 0, life: '100%'},
    keys: (item) => item.key,
    enter: (item) => async (next, cancel) => {
      await next({opacity: 1, height: refMap.get(item).offsetHeight})
      await next({life: '0%'})
    },
    leave: [{opacity: 0}, {height: 0}],
    onRest: (result, ctrl, item) => {},
    config: (item, index, phase) => (key) =>
      phase === 'enter' && key === 'life' ? {duration: timeout} : config,
  })

  useEffect(() => {
    children((msg: msg) => {
      setItems((state) => [...state, {key: id++, msg}])
    })
  }, [])

  return (
    <Container>
      {transitions(({life, ...style}, item) => (
        <MainTree
          style={style}
          refMap={refMap}
          cancelMap={cancelMap}
          item={item}
          setItems={setItems}
          life={life}
          onClick={onClick}
        />
      ))}
    </Container>
  )
}

interface MessageHubProps {
  config?: {
    tension: number
    friction: number
    precision: number
  }
  timeout?: number
  children: (add: AddFunction) => void
  onClick: (raba: number) => void
}

export type AddFunction = (msg: msg) => void

interface Item {
  key: number
  msg: msg
}

export default NotificationHub
