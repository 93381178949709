/* eslint-disable react-hooks/rules-of-hooks */
import {Dispatch, SetStateAction, useEffect, useState} from 'react'

import {useAlert} from 'src/app/components/Alert/Alert'
import TableMaterial, {HeadCells} from 'src/app/components/TableMaterial'

import {GetPontosColeta} from 'src/app/repositories/PontoColeta'

import {IPontoColeta} from 'src/app/model/PontoColeta/Types'
import {PontosColeta} from 'src/app/repositories/Route'

const IPontoColetaToPontosColeta = (ponto: IPontoColeta) => {
  const model: PontosColeta = {
    bairro: ponto.bairro,
    cep: ponto.cep,
    cidade: ponto.cidade,
    codigo: Math.random(),
    codigoPontoColeta: ponto.codigo,
    endereco: ponto.endereco,
    identificadorPontoColeta: ponto.identificador,
    latitude: ponto.latitude,
    longitude: ponto.longitude,
    ordem: 0,
    uf: ponto.uf,
  }

  return model
}

const serializeIPontoColetaToPontosColeta = (pontos: IPontoColeta[]) => {
  const model = []

  for (const ponto of pontos) {
    model.push(IPontoColetaToPontosColeta(ponto))
  }

  return model
}

const AddPontoColetaTable = ({selectedData, setSelectedData, info, pontosColetaList}: Props) => {
  const [data, setData] = useState<PontosColeta[]>(pontosColetaList)
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)

  const [filtersCustom, setFiltersCustom] = useState<TableFilterPontoColeta>({
    identificador: '',
    endereco: '',
    bairro: '',
    nomeFantasia: '',
    uf: info.uf,
    cidade: info.cidade,
    razaoSocial: '',
    cnpj: '',
  })

  const headCells: HeadCells[] = [
    {id: 'identificador', disablePadding: false, label: 'Identificador', numeric: false},
    {id: 'endereco', disablePadding: false, label: 'Endereço', numeric: false},
    {id: 'bairro', disablePadding: false, label: 'Bairro', numeric: false},
    {id: 'nomeFantasia', disablePadding: false, label: 'Nome Fantasia', numeric: false},
    {id: 'cidade', disablePadding: false, label: 'Cidade', numeric: false},
    {id: 'uf', disablePadding: false, label: 'UF', numeric: false},
    {id: 'razaoSocial', disablePadding: false, label: 'Razão Social', numeric: false},
    {id: 'cnpj', disablePadding: false, label: 'Cnpj', numeric: false},
  ]

  const handleIsSelected = (item: IPontoColeta) => {
    return selectedData.map((ponto) => ponto.codigoPontoColeta).includes(item.codigo)
  }

  const handleSelectAllClick = (event: any, list: IPontoColeta[]) => {
    if (event.target.checked) {
      setSelectedData(serializeIPontoColetaToPontosColeta(list))
      return
    }

    setSelectedData([])
  }

  const handleClick = (item: IPontoColeta) => {
    const selectedIndex = selectedData.map((item) => item.codigoPontoColeta).indexOf(item.codigo)

    let newSelected: PontosColeta[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedData, IPontoColetaToPontosColeta(item))
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedData.slice(1))
    } else if (selectedIndex === selectedData.length - 1) {
      newSelected = newSelected.concat(selectedData.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedData.slice(0, selectedIndex),
        selectedData.slice(selectedIndex + 1)
      )
    }

    setSelectedData(newSelected)
  }

  const getData = async (filtersCustom: TableFilterPontoColeta, pageIndex: number) => {
    try {
      const {data, totalPages}: any = await GetPontosColeta(filtersCustom, pageIndex)

      if (data) {
        setData(data)
        setTotalPages(totalPages)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  useEffect(() => {
    getData(filtersCustom, pageIndex)
  }, [pageIndex, filtersCustom])

  return (
    <>
      <TableMaterial
        filledFilters={[
          {id: 'uf', value: filtersCustom.uf},
          {id: 'cidade', value: filtersCustom.cidade},
        ]}
        setCustomFilters={setFiltersCustom}
        rows={data}
        selectedRows={selectedData}
        isSelected={handleIsSelected}
        onClick={handleClick}
        onClickAll={handleSelectAllClick}
        headCells={headCells}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        totalPages={totalPages}
      />
    </>
  )
}

type Props = {
  pontosColetaList: any[]
  selectedData: PontosColeta[]
  setSelectedData: Dispatch<SetStateAction<PontosColeta[]>>
  info: {
    uf: string
    cidade: string
  }
}

export interface TableFilterPontoColeta {
  identificador: string | null
  endereco: string | null
  bairro: string | null
  nomeFantasia: string | null
  uf: string | null
  cidade: string | null
  razaoSocial: string | null
  cnpj: string | null
}

export default AddPontoColetaTable
