import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;

  flex: 1;
`
export const Footer = styled.div`
  width: 100%;

  font-family: Inter;

  font-size: 18px;

  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    padding: 0 10px;
  }
`
