import styled, {css} from 'styled-components'

type ContainerProps = {
  isCanceled: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 16px;
  flex: 1;
  gap: 8px;

  .left {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(360px, 1f) minmax(360px, 1f);
    display: grid;
    grid-gap: 5px;
    grid-template-areas:
      'one two'
      'three four';

    flex: 4;

    .one {
      grid-area: one;
    }
    .two {
      grid-area: two;
    }
    .three {
      grid-area: three;
    }
    .four {
      grid-area: four;
    }
  }
  .right {
    /* background-color: blue; */

    flex: 1;

    ${({isCanceled}) =>
      isCanceled &&
      css`
        display: flex;
        justify-content: center;
        gap: 40px;
        /* padding-top: 40px; */
      `}
  }
`
