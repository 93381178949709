/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {lazy, Suspense, useEffect, useState} from 'react'
import {useAlert} from 'src/app/components/Alert/Alert'
import {DateRangePicker} from 'rsuite'
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
import {DateRange} from 'rsuite/esm/DateRangePicker'
import {GetResumoMonitoramento} from 'src/app/repositories/Monitoramento'
import MonitoramentoTable from './components/MonitoramentoTable'
import {MonitoramentoModel} from 'src/app/model/Monitoramento/Monitoramento'

//lazy loading
const MonitoramentoChart = lazy(() => import('./components/MonitoramentoChart'))

export default function Monitoramento() {
  //data atual
  const setDefaultDate = () => new Date().toISOString().slice(0, 10)

  const [monitoramento, setMonitoramento] = useState<MonitoramentoModel[]>([])
  const [selectedFirstDate, setSelectedFirstDate] = useState<Date>()
  const [selectedSecondDate, setSelectedSecondDate] = useState<Date>()
  const [totalAtendimentos, setTotalAtendimentos] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dateFilters, setDateFilters] = useState<DateFilterMonitoramento>({
    dataInicio: setDefaultDate(),
    dataFim: setDefaultDate(),
  })

  const setCorrectDateTitle = () => {
    let message = ''

    if (selectedFirstDate && selectedSecondDate) {
      let dOne = format(selectedFirstDate, 'dd/MM', {locale: ptBR})
      let dTwo = format(selectedSecondDate, 'dd/MM', {locale: ptBR})

      if (dOne === dTwo) {
        message = `Resultados do dia ${dOne}`
      } else {
        message = `Resultados entre ${dOne} e ${dTwo}`
      }
    }

    return message
  }

  const getData = async () => {
    setIsLoading(true)
    try {
      const {listaMonitoramento, totalAtendimento} = await GetResumoMonitoramento({
        dataInicio: dateFilters.dataInicio,
        dataFim: dateFilters.dataFim,
      })

      setMonitoramento(listaMonitoramento)
      setTotalAtendimentos(totalAtendimento)
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [dateFilters])

  return (
    <>
      <div className='card mb-4'>
        <div className='card-body d-flex justify-content-between align-items-center'>
          <p className='fw-bold fs-4 m-0'>
            Total de Atendimentos Avaliados:{' '}
            <span className='text-danger'>{totalAtendimentos}</span>
          </p>
          <DateRangePicker
            editable
            showOneCalendar
            placeholder='Filtrar por período'
            placement='autoHorizontalStart'
            format='dd-MM-yyyy'
            onClean={() =>
              setDateFilters({dataInicio: setDefaultDate(), dataFim: setDefaultDate()})
            }
            renderValue={(value) => {
              if (value) {
                setSelectedFirstDate(value[0])
                setSelectedSecondDate(value[1])
                return <span>{setCorrectDateTitle()} </span>
              }
            }}
            onChange={(value: DateRange | null) => {
              if (value) {
                setDateFilters({
                  dataInicio: value[0].toISOString().slice(0, 10),
                  dataFim: value[1].toISOString().slice(0, 10),
                })
              }
            }}
          />
        </div>
      </div>
      <div className='card mb-4'>
        <div className='card-body d-flex justify-content-center'>
          <Suspense fallback={<span>Carregando Dados...</span>}>
            <MonitoramentoChart data={monitoramento!} />
          </Suspense>
        </div>
      </div>
      <div className='card'>
        <div className='card-body'>
          <MonitoramentoTable
            data={monitoramento!}
            getData={getData}
            dateFilters={dateFilters}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  )
}

export interface DateFilterMonitoramento {
  dataInicio: string
  dataFim: string
}
