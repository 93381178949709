/* eslint-disable react-hooks/rules-of-hooks */
import AnimatedModal from 'src/app/components/AnimatedModal'
import InputText from 'src/app/components/Form/InputText'
import {Select} from 'src/app/components/Select/Select'
import {Container} from './styles'
import {useIntl} from 'react-intl'

import {useFormik} from 'formik'
import * as Yup from 'yup'
import {nomeValidation, pedidoValidation} from './validations'
import {CreateClient, createClientData} from 'src/app/repositories/OperationalBase'
import {useAlert} from 'src/app/components/Alert/Alert'

const AddClientModal = ({onClose, getTableData}: Props) => {
  const intl = useIntl()

  const initialValues = {
    nome: '',
    pedido: {
      value: '',
      label: '',
    },
  }

  const validationSchema = Yup.object().shape({
    nome: nomeValidation(intl),
    pedido: pedidoValidation(intl),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const {nome, pedido} = values
      try {
        const o: createClientData = {
          nome,
          tipoPedido: pedido.value,
        }
        const res = await CreateClient(o)
        onClose()
        useAlert(res, 'Success')
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    },
  })

  return (
    <AnimatedModal
      headerTitle='Adicionar Cliente'
      onClose={onClose}
      containerStyle={{height: 350, width: 800}}
      // formik={formik}
      onSubmit={async () => {
        await formik.submitForm()
        getTableData()
      }}
    >
      <Container>
        <InputText
          label='Nome:'
          inputClass='bg-white'
          formik={{
            formikProp: 'nome',
            formikValues: formik,
          }}
        />
        <Select
          label='Tipo Pedido:'
          error={Boolean(formik.errors['pedido'])}
          options={[
            {label: 'Auto', value: 'Auto'},
            {label: 'GLP', value: 'GLP'},
          ]}
          formik={{
            formikProp: 'pedido',
            formikValues: formik,
          }}
        />
        {formik && formik.errors['pedido'] && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors['pedido'].label}</span>
          </div>
        )}
      </Container>
    </AnimatedModal>
  )
}

export default AddClientModal

type Props = {
  onClose: () => void
  getTableData: () => void
}
