import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;

  width: 100%;

  .empty {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 100%;

    text-align: center;
  }
`
