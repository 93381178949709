export default class Filtros {
  private _perfil: string | number | null
  private _situacao: string | number | null

  constructor({perfil, situacao}: IFiltros) {
    this._situacao = situacao
    this._perfil = perfil
  }

  get situacao() {
    return this._situacao
  }

  get perfil() {
    return this._perfil
  }
}

interface IFiltros {
  situacao: string | number | null
  perfil: string | number | null
}
