import IMask from 'imask'

export const cpfMask = IMask.createMask({
  mask: '000.000.000-00',
})

export const cepMask = IMask.createMask({
  mask: '00000-000',
})

export const costCenterValueMask = IMask.createMask({
  mask: '0,00',
})

export const cnpjValueMask = IMask.createMask({
  mask: '00.000.000/0000-00',
})

export const currencyFormatterBRL = (value: number | bigint) => {
  const format = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  return format.format(value)
}

export const formattedInputToBLR = (value: string) => {
  const userInput: string = value.replace(/[^0-9]/g, '')

  // Convert the input to a number and divide by 100 to get the value in BRL
  const userInputAsNumber: number = parseInt(userInput, 10) / 100

  // Format the number as BRL currency
  const formattedNumber: string = `${userInputAsNumber
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+,)/g, '$1.')}`

  return formattedNumber
}

export const formatCPF = (value: string | undefined) => {
  const cpfMask = IMask.createMask({
    mask: '000.000.000-00',
  })

  if (value) {
    cpfMask.resolve(value)

    return cpfMask.value
  }
  return ''
}

export const formatCEP = (value: string | undefined) => {
  if (value) {
    cepMask.resolve(value)

    return cepMask.value
  }
  return ''
}

export const formatCnpj = (value: string | undefined) => {
  if (value) {
    cnpjValueMask.resolve(value)

    return cnpjValueMask.value
  }
  return ''
}

export function formatPhoneNumber(value: string | undefined) {
  if (value) {
    value = value.slice(0, 2) + ')' + value.slice(2)
    value = value.slice(0, 0) + '(' + value.slice(0)
    value = value.slice(0, 9) + '-' + value.slice(9)

    return value
  }

  return ''
}

export function stringArrayToSelectOptions(array: string[]) {
  return array.map((item) => ({value: item, label: item}))
}

export function arrayToSelectOptions(array: any[], value: string, label: string) {
  return array.map((item) => ({value: item[value], label: item[label]}))
}
