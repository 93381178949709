/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'

import clsx from 'clsx'

import {CopyIcon} from 'src/app/utils/Icons'
import {useAlert} from 'src/app/components/Alert/Alert'

import {Container} from './styles'

const KeyValue = ({isBlur, label, value}: Props) => {
  const isPix = label === 'Chave Pix'
  const defaultValue = value === 'Não preenchido'

  const handleCopyPix = (isPix: boolean, value: string) => {
    if (isPix) {
      useAlert('Chave copiada', 'Info')
      navigator.clipboard.writeText(value)
    }
  }

  return (
    <Container>
      <div
        onClick={() => handleCopyPix(isPix && !defaultValue, String(value))}
        className={clsx('info', isPix && ' pix', defaultValue && 'default')}
      >
        <span className='label'>{label}</span>
        {': '}
        <span className='value'>{isBlur ? '* * * * * * * *' : value}</span>
        {isPix && <CopyIcon size={20} />}
      </div>
    </Container>
  )
}

type Props = {
  isBlur?: boolean
  label: string
  value: string | number
}

export default KeyValue
