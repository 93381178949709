import styled, {css} from 'styled-components'

type SelectDayProps = {
  isSelected: any
}

export const Container = styled.div`
  display: flex;

  gap: 10px;
`

export const SelectDayContainer = styled.div<SelectDayProps>`
  padding: 4px 8px;
  background-color: #eee;
  border-radius: 15px;

  border: 1px solid rgba(0, 0, 0, 0.3);

  cursor: pointer;

  font-family: Poppins;
  font-weight: 400;

  &.ml {
    margin-left: 10px;
  }

  transition: filter 0.2s;
  filter: brightness(120%);

  &:hover {
    filter: brightness(100%);
  }

  ${({isSelected}) =>
    isSelected &&
    css`
      background-color: #0095e8;
      color: white;
    `}
`
