import {AuthModel, UserModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const USER_LOCAL_STORAGE_KEY = 'kt-user-react-v'

const getAuth = (): {auth: AuthModel; user: UserModel} | undefined => {
  if (!localStorage) {
    return
  }

  const authValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  const userValue: string | null = localStorage.getItem(USER_LOCAL_STORAGE_KEY)

  if (!authValue) {
    return
  }
  if (!userValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(authValue) as AuthModel
    const user: UserModel = JSON.parse(userValue) as UserModel

    return {auth, user}
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel, user: UserModel) => {
  if (!localStorage) {
    return
  }

  try {
    const authValue = JSON.stringify(auth)
    const userValue = JSON.stringify(user)

    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, authValue)
    localStorage.setItem(USER_LOCAL_STORAGE_KEY, userValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
