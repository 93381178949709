import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import {CheckBoxIcon, CheckBoxOutlineBlankIcon} from 'src/app/utils/Icons'
import {withStyles} from '@mui/styles'

// import { Container } from './styles';

type SelecaoProps = {
  placeholder?: string
  opcoes: readonly any[]
  nomeLabel?: string
  value: any
  multiplo?: boolean
  titulo?: string
  setValue: (value: any) => void
  setInputValue?: (value: any) => void
  contentStyle?: React.CSSProperties
}

const styles = {
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  checkbox: {
    marginLeft: 'auto',
    padingRight: '5px',
  },
}

// @ts-ignore
const TextFieldCustomizado = withStyles({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
  },
})(TextField)

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const isObjeto = (item: string) => typeof item === 'object'
const isString = (item: string) => typeof item === 'string'

const isOptionEqualToValue = (option: any, value: any, nomeLabel: string) => {
  if (isObjeto(value)) return option[nomeLabel] === value[nomeLabel]
  return option === value
}

const handlerLabel = (option: any, nomeLabel: string) => {
  if (isString(option)) return option
  else if (isObjeto(option)) {
    return option[nomeLabel]
  }
}

const handlerValue = (value: any) => {
  if (!value) return null
  else if (isString(value)) return value ? value : ''
  else if (isObjeto(value)) {
    return value.length !== 0 ? value : []
  }
}

const Selecao = ({
  opcoes,
  nomeLabel = '',
  multiplo = false,
  titulo = '',
  placeholder,
  setValue,
  setInputValue = () => {},
  value,
  contentStyle = {},
  ...rest
}: SelecaoProps) => {
  return (
    <Autocomplete
      size={'small'}
      multiple={multiplo}
      options={opcoes}
      style={contentStyle}
      getOptionLabel={(option) => handlerLabel(option, nomeLabel)}
      // groupBy={(option) => option.group}
      isOptionEqualToValue={(option, value) => isOptionEqualToValue(option, value, nomeLabel)}
      disableCloseOnSelect={multiplo}
      value={handlerValue(value)}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        if (setInputValue) setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextFieldCustomizado
          {...params}
          label={titulo}
          placeholder={multiplo ? (placeholder = '') : (placeholder = 'Selecione')}
        />
      )}
      renderOption={(props, option, {selected}) => (
        <li {...props} key={handlerLabel(option, nomeLabel)}>
          <FormControlLabel
            control={
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={selected}
                style={styles.checkbox}
              />
            }
            label={handlerLabel(option, nomeLabel)}
            labelPlacement='start'
            style={styles.label}
          />
        </li>
      )}
      {...rest}
    />
  )
}

export default Selecao
