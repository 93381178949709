import { useMemo } from 'react'
import { Column, useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { TableColumn } from './table-painel'

export default function SubTable({ title, data: tableData, columns: tableColumns }: Props) {
 const data = useMemo(() => tableData, [])
 const columns = useMemo<Column[]>(
  () => {
   const modelColumns: Column[] = []
   for (let column of tableColumns) {
    modelColumns.push({
     Header: column.name,
     accessor: column.accessor,
     columns: serializeSubcolumns(column.subcolumns)
    })
   }
   return modelColumns
  },
  []
 )

 function serializeSubcolumns(subcolumns: TableColumn[] | undefined): Column[] | undefined {
  if (!subcolumns || subcolumns.length <= 0) return

  const modelColumn: Column[] = []
  for (let subcolumn of subcolumns) {
   modelColumn.push({
    Header: subcolumn.name,
    accessor: subcolumn.accessor,
    columns: serializeSubcolumns(subcolumn?.subcolumns)
   })
  }
  return modelColumn
 }

 const {
  headerGroups,
  page,
  getTableProps,
  getTableBodyProps,
  prepareRow,
 } = useTable(
  {
   columns,
   data,
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  useExpanded,
  usePagination
 )

 return (
  <div className='card'>
   <div className='card-header border-0 pt-5'>
    <h3 className='card-title align-items-start flex-column'>
     <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
     <span className='text-muted mt-1 fw-semibold fs-7'>Total de {data.length} itens encontrados</span>
    </h3>
   </div>
   <div className='card-body py-5'>
    <div className='table-responsive'>
     <table className='table table-bordered align-self-center' {...getTableProps()}>
      <thead>
       {headerGroups.map(headerGroup => (
        <tr className='fw-bold text-muted' {...headerGroup.getHeaderGroupProps()}>
         {headerGroup.headers.map(column => (
          <th
           {...column.getHeaderProps(column.getSortByToggleProps())}
          >
           {column.render('Header')}
          </th>
         ))}
        </tr>
       ))}

      </thead>
      <tbody {...getTableBodyProps()}>
       {page.map((row, i) => {
        prepareRow(row)
        return (
         <>
          <tr {...row.getRowProps()}
          >
           {row.cells.map(cell => {
            return (
             <>
              <td
               {...cell.getCellProps()}
              >
               {cell.render('Cell')}
              </td>
             </>
            )
           })}
          </tr >
         </>
        )
       })}
      </tbody>
     </table>
    </div>
   </div>
  </div>
 )
}

interface Props {
 title?: string,
 data: any[],
 columns: TableColumn[],
}