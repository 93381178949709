/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'

import AnimatedModal from 'src/app/components/AnimatedModal'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'

import {Container} from './styles'
import AnimatedRadio from 'src/app/components/AnimatedRadio'
import {useService} from 'src/app/hooks/useService'
import Info from './components/Info'
import {GetQADetail} from 'src/app/repositories/Atendimentos'
import {getImageFromBucket} from 'src/app/utils/API'
import DuplicateModal from '../DuplicateModal'
import ChangeModal from '../ChangeModal'
import ModalCard from 'src/app/components/ModalCard'
import ImageModal from '../../../Card/components/ImageModal'

type Props = {
  data?: ValidacaoModel
  setShowQAModal: React.Dispatch<React.SetStateAction<boolean>>
  foto: string
  setIsWarning: any
  currentSituacaoConstatada?: string | number | undefined
  handleOnClickChangeSituacaoConstatada?(value: string | number): void
}

type cadastroPosto = {
  razaoSocial: string
  cnpj: string
  endereco: string
  autorizacao: null | string
  nomeFantasia: string
  latitudePosto: number
  longitudePosto: number
}
type fotoQa = {
  codigo: number
  siglaFoto: string
  fileName: string
  latitude: number
  longitude: number
  precisao: number
  distancia: number
}

interface IGetQADetail {
  cadastroPosto: cadastroPosto
  fotoQa: fotoQa
}

const QAModal = ({
  data,
  setShowQAModal,
  currentSituacaoConstatada,
  handleOnClickChangeSituacaoConstatada,
  setIsWarning,
}: Props) => {
  const {servicesInfo} = useService()
  const [showChangeModal, setShowChangeModal] = useState(false)
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const [openModalZoom, setOpenModalZoom] = useState<boolean>(false)
  const [qaDetail, setQaDetail] = useState<IGetQADetail>({} as IGetQADetail)

  const itens = servicesInfo?.situacoesConstatadas || []

  const info = [
    {
      value: qaDetail.cadastroPosto?.autorizacao || 'Sem Autorização',
      label: 'Revendedor autorizado pela anp sob o número',
    },
    {value: qaDetail.cadastroPosto?.razaoSocial, label: 'Razão social'},
    {value: qaDetail.cadastroPosto?.nomeFantasia, label: 'Nome fantasia'},
    {value: qaDetail.cadastroPosto?.cnpj, label: 'CNPJ'},
    {value: qaDetail.cadastroPosto?.endereco, label: 'Endereço'},
  ]

  const handleOpenDuplicateModal = () => {
    setShowDuplicateModal(true)
  }

  const handleOpenChangeModal = () => {
    setShowChangeModal(true)
  }

  const handleCloseChangeModal = () => setShowChangeModal(false)

  const handleCloseDuplicateModal = () => setShowDuplicateModal(false)

  const memorizedValue = useMemo(() => getImageFromBucket(qaDetail.fotoQa?.fileName), [qaDetail])

  async function getData() {
    const {cadastroPosto, fotoQa}: IGetQADetail = await GetQADetail(data!.codigoAtendimento)

    setQaDetail({cadastroPosto, fotoQa})
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AnimatedModal
      headerTitle='Análise do quadro de aviso'
      footerTitle='Confirmar'
      onSubmit={() => setShowQAModal(false)}
      onClose={() => setShowQAModal(false)}
    >
      {showDuplicateModal && (
        <DuplicateModal
          data={data!}
          codigoFoto={qaDetail.fotoQa.codigo}
          onClose={handleCloseDuplicateModal}
          setIsWarning={setIsWarning!}
        />
      )}

      {showChangeModal && (
        <ChangeModal
          callback={getData}
          sigla={'QA'}
          data={data!}
          onClose={handleCloseChangeModal}
        />
      )}

      {openModalZoom && (
        <ImageModal
          titleFirstFoto='Foto QA (Atual)'
          firstFoto={memorizedValue}
          onclose={() => setOpenModalZoom(false)}
        />
      )}
      <Container>
        <main>
          <ModalCard
            showButton
            actions={[
              {label: 'Duplicar', onClick: handleOpenDuplicateModal},
              {label: 'Trocar', onClick: handleOpenChangeModal},
            ]}
            title='Quadro de aviso'
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              maxHeight: 500,
              backgroundColor: '#F4F6FA',
              margin: 5,
              cursor: 'zoom-in',
            }}
          >
            <img src={memorizedValue} alt='' onClick={() => setOpenModalZoom(true)} />
          </ModalCard>
        </main>

        <aside>
          <ModalCard title='Informações deste revendedor'>
            {info.map((item) => (
              <Info label={item.label} value={item.value} key={item.label} />
            ))}
          </ModalCard>
          <ModalCard title='Situação constatada'>
            {itens.map((item, index) => (
              <AnimatedRadio
                label={item.valor}
                value={item.codigo}
                currentValue={currentSituacaoConstatada!}
                onClick={handleOnClickChangeSituacaoConstatada!}
              />
            ))}
          </ModalCard>
        </aside>
      </Container>
    </AnimatedModal>
  )
}

export default QAModal
