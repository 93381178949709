/* eslint-disable react-hooks/rules-of-hooks */
import {useRef, useState} from 'react'

import AnimatedInput from 'src/app/components/AnimatedInput'
import {Tr} from '../../styles'
import {Payment} from '../..'
import {PrecosColeta} from 'src/app/model/Validacao/Type'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import {UpdateServicePrice} from 'src/app/repositories/Atendimentos'
import {useAlert} from 'src/app/components/Alert/Alert'
import {currencyFormatterBRL, formattedInputToBLR} from 'src/app/utils/Formats'

type Props = {
  index: number
  item: PrecosColeta
  showColumn: boolean
  data: ValidacaoModel
  showPayments: boolean
  canEdit: boolean
  payments: Payment[]
  sigla?: string
}

const TableRow = ({
  index,
  item,
  showColumn,
  data,
  canEdit,
  payments,
  showPayments,
  sigla,
}: Props) => {
  const [originalValue, setOriginalValue] = useState(
    String(item.preco.toFixed(2)).replace('.', ',')
  )

  const formattedOriginalValue = formattedInputToBLR(originalValue)
  const [inputValue, setInputValue] = useState(formattedOriginalValue)
  const [payment, setPayment] = useState(item.formaPagamento || 'VISTA')
  const [borderColor, setBorderColor] = useState('transparent')

  const handleChangeInputPrice = (priceValue: string) => {
    if (priceValue !== '') {
      setInputValue(formattedInputToBLR(priceValue))
    } else {
      setInputValue(formattedInputToBLR('0'))
    }
  }

  const handleOnBlurEditPrice = () => {
    handleEditPrice()
  }

  const handleOnPressEnterEditPrice = (keyPressed: string) => {
    if (keyPressed === 'Enter') {
      handleEditPrice()
    }
  }

  const handleEditPrice = async () => {
    if (originalValue !== inputValue) {
      const valor = parseFloat(inputValue.replace(',', '.'))

      const newPrecoColeta = {
        codigoAtendimento: data.codigoAtendimento,
        tipoCombustivel: item.sigla,
        tipoPagamento: payment,
        tipoFoto: sigla || 'PP',
        valor,
      }

      try {
        const res = await UpdateServicePrice<PrecosColeta>(newPrecoColeta)
        if (res) {
          setBorderColor('green')
          useAlert(`Preço ${item.sigla} alterado com sucesso`, 'Success')
          setOriginalValue(inputValue)
        }
      } catch (error: any) {
        useAlert(error.message, 'Error')
        setBorderColor('red')
        setInputValue(formattedOriginalValue)
      }
    }
  }

  return (
    <Tr borderColor={borderColor} index={index}>
      <td className='values'>{item.sigla}</td>
      {showColumn && (
        <td className='values' style={{color: item.ocorrencia ? '#f89c32' : 'black'}}>
          {currencyFormatterBRL(data.ultimoAtendimento!.precosPesquisa[index]?.preco)}
        </td>
      )}

      <td>
        <AnimatedInput
          disabled={!canEdit}
          id='PriceScore'
          type='text'
          isWarning={item.ocorrencia}
          titulo=''
          value={inputValue === '0,00' ? '-' : inputValue}
          onBlur={handleOnBlurEditPrice}
          onKeyDown={(e) => handleOnPressEnterEditPrice(e.key)}
          onChange={(e) => handleChangeInputPrice(e.target.value)}
          contentStyle={{
            borderColor: borderColor,
            fontSize: 14,
            height: 30,
          }}
          style={{
            height: 25,
            backgroundColor: index % 2 !== 0 ? '#f7fbff' : 'white',
          }}
        />
      </td>

      {showPayments && (
        <td>
          <select
            value={payment}
            onBlur={handleEditPrice}
            onChange={(e) => setPayment(e.target.value)}
          >
            {payments.map((item: Payment) => (
              <option value={item.identificador}>{item.valor}</option>
            ))}
          </select>
        </td>
      )}
    </Tr>
  )
}

export default TableRow
