import {Container} from './styles'
import {ChangeEventHandler} from 'react'
import AnimatedInput from 'src/app/components/AnimatedInput'

type Props = {
  label: string
  isTextArea?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  value?: string | number | readonly string[] | undefined
}

const ObservationItem = ({label, isTextArea = false, onChange, value}: Props) => {
  return (
    <Container>
      <AnimatedInput
        titulo={label}
        maxLength={200}
        value={value}
        onChange={onChange!}
        type='text'
        disabled={isTextArea}
        backgroundColor='#fafafa'
      />
    </Container>
  )
}

export default ObservationItem
