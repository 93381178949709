import {HTMLAttributes, useState} from 'react'

import {Container, SelectDayContainer} from './styles'
import {Planejamento} from 'src/app/repositories/Route'
import {Schedule} from '../..'

const SelectDays = ({semana, handleUpdateSchedule}: Props) => {
  const [days, setDays] = useState({
    domingo: semana.domingo || false,
    segunda: semana.segunda || false,
    terca: semana.terca || false,
    quarta: semana.quarta || false,
    quinta: semana.quinta || false,
    sexta: semana.sexta || false,
    sabado: semana.sabado || false,
  })

  const d = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']

  const handleChangeSemana = (key: string, value: boolean) => {
    const daysCopy = days

    daysCopy[key as keyof Days] = value ? false : true

    let contagemTrue = 0

    for (const dia in daysCopy) {
      //@ts-ignore
      if (daysCopy[dia] === true) {
        contagemTrue++
      }
    }

    handleUpdateSchedule('agendaDaSemana', daysCopy)
    handleUpdateSchedule('frequencia', contagemTrue)

    setDays(daysCopy)
  }

  return (
    <Container>
      {Object.entries(days).map(([key, value], index) => (
        <SelectDay
          key={key}
          onClick={() => handleChangeSemana(key, value)}
          day={d[index]}
          //@ts-ignore
          isSelected={days[key]}
        />
      ))}
    </Container>
  )
}

export const SelectDay = ({day, isSelected, ...rest}: SelectDayProps) => {
  return (
    <SelectDayContainer isSelected={isSelected} {...rest}>
      {day}
    </SelectDayContainer>
  )
}

type Days = {
  domingo: boolean
  segunda: boolean
  terca: boolean
  quarta: boolean
  quinta: boolean
  sexta: boolean
  sabado: boolean
}

type Props = {
  semana: Planejamento
  handleUpdateSchedule: (key: keyof Schedule, value: any) => void
}

type SelectDayProps = HTMLAttributes<HTMLDivElement> & {
  day: string
  isSelected: boolean
}

export default SelectDays
