import styled, {css} from 'styled-components'

type TableHeaderProps = {
  bordered: boolean
}

export const TableHeader = styled.div<TableHeaderProps>`
  display: flex;
  justify-content: space-between;

  margin-top: 20px;

  ${({bordered}) =>
    bordered &&
    css`
      margin-top: 0;
    `}

  .d-flex {
    align-items: center;
  }
`
