import api from '../config'
import PontoColetaModel from '../model/PontoColeta/PontoColeta'
import {IPontoColeta} from '../model/PontoColeta/Types'

import Erro from '../model/Erro/Erro'

import {TableFilterPontoColeta} from '../pages/planejamento-execucao/rota/components/EditRouteModal/components/AddPontoColetaTable'
import {handlerResult} from '../utils/API'

const serialize = (value: IPontoColeta) => {
  const model = new PontoColetaModel(value)
  return model
}

const serializeList = (values: IPontoColeta[]) => {
  const model = []

  for (const value of values) {
    model.push(serialize(value))
  }

  return model
}

export async function GetPontosColeta(filters: TableFilterPontoColeta, pageIndex: number) {
  try {
    const response = await api.post('pontoDeColeta/listar', convertFilters(filters, pageIndex))
    const dataResponse: PontoColetaTableResponse = await handlerResult(response.data)

    return {
      data: serializeList(dataResponse.pontosColeta),
      totalPages: dataResponse.totalDePaginas,
    }
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

function convertFilters(filters: TableFilterPontoColeta, pageIndex: number) {
  return {
    identificador: filters.identificador === '' ? null : filters.identificador,
    endereco: filters.endereco === '' ? null : filters.endereco,
    bairro: filters.bairro === '' ? null : filters.bairro,
    nomeFantasia: filters.nomeFantasia === '' ? null : filters.nomeFantasia,
    uf: filters.uf === '' ? null : filters.uf,
    cidade: filters.cidade === '' ? null : filters.cidade,
    razaoSocial: filters.razaoSocial === '' ? null : filters.razaoSocial,
    cnpj: filters.cnpj === '' ? null : filters.cnpj,
    numeroPagina: pageIndex,
  }
}

interface PontoColetaTableResponse {
  pontosColeta: IPontoColeta[]
  totalDePaginas: number
  paginaAtual: number
}
