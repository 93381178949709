import {useState} from 'react'
import {useAsyncDebounce} from 'react-table'
import InputText from '../Form/InputText'

export default function SearchInput({
  globalFilter,
  setGlobalFilter,
  height = 100,
  className,
  placeholder = 'Pesquisar...',
}: {
  globalFilter: any
  setGlobalFilter: any
  height: number
  className?: string
  placeholder?: string
}) {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <InputText
      className={className}
      height={height}
      placeholder={placeholder}
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
    />
  )
}
