import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  /* overflow-y: scroll; */

  .photo-info {
    display: flex;

    .image {
      width: 130px;
      height: 130px;

      max-width: 130px;
      max-height: 130px;

      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
  }

  .info-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`
