import React, {useState, ReactNode, useMemo} from 'react'
import {CardContainer} from '../../../CardHeader/components/HomologacaoModal/styles'
import {MoreOutlineIcon} from 'src/app/utils/Icons'
import {Actions} from '../../../CardHeader/styles'
import {AlternativePhotos} from '../AlternativePhotosModal'
import {useSpring} from '@react-spring/web'
import {Container} from '../AlternativePhotosModal/styles'
import PriceScore from '../../../PriceScore'
import {getImageFromBucket} from 'src/app/utils/API'
import DuplicateModal from '../../../CardHeader/components/DuplicateModal'
import ChangeModal from '../../../CardHeader/components/ChangeModal'
import ImageModal from '../../../Card/components/ImageModal'

const PageItem = ({
  setShowRemovePhotoModal,
  setCurrentPhoto,
  item,
  data,
  setIsWarning,
  callback,
}: any) => {
  const memorizedValue = useMemo(() => getImageFromBucket(item.fotografia.fileName), [item])

  const [showChangeModal, setShowChangeModal] = useState(false)
  const [openModalZoom, setOpenModalZoom] = useState<boolean>(false)
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)

  const [runAnimation, setRunAnimation] = useState(false)

  const [showActions, setShowActions] = useState(true)

  const scaleAnimation = {
    opacity: runAnimation ? 1 : 0,
    config: {
      tension: 300,
      duration: 150,
    },
  }

  const handleOpenRemovePhotoModal = () => {
    setShowRemovePhotoModal(true)
    handleCloseActions()
  }
  const animatedStyle = useSpring(scaleAnimation)

  const handleCloseActions = () => {
    setRunAnimation(false)

    setTimeout(() => {
      setShowActions(false)
    }, 150)
  }

  const handleOpenActions = () => {
    setRunAnimation((oldState) => !oldState)
    setShowActions(true)
  }

  const handleOpenChangeModal = () => {
    handleCloseActions()
    setShowChangeModal(true)
  }

  const handleCloseChangeModal = () => setShowChangeModal(false)

  const handleOpenDuplicateModal = () => {
    handleCloseActions()
    setShowDuplicateModal(true)
  }

  const handleCloseDuplicateModal = () => setShowDuplicateModal(false)

  const Card = ({
    title,
    children,
    footer,
    showButton = false,
    item,
    ...rest
  }: {
    title: string
    children: ReactNode
    footer?: ReactNode
    style?: React.CSSProperties
    showButton?: boolean
    item?: AlternativePhotos
  }) => {
    return (
      <CardContainer style={{backgroundColor: '#fff', height: '100%'}}>
        <div className='cardHeader'>
          <h3 className='card-title'>{title}</h3>
          {showButton && (
            <button onClick={handleOpenActions}>
              <MoreOutlineIcon size={20} />
            </button>
          )}

          {showActions && showButton && (
            <Actions orientation='left' style={animatedStyle}>
              <p onClick={handleOpenDuplicateModal}>Duplicar</p>
              <p onClick={handleOpenChangeModal}>Trocar</p>
              <p
                onClick={() => {
                  setCurrentPhoto(item)
                  handleOpenRemovePhotoModal()
                }}
              >
                Remover
              </p>
            </Actions>
          )}
        </div>
        <div className='cardBody' {...rest}>
          {children}
        </div>
        {footer && <div className='cardFooter'>{footer}</div>}
      </CardContainer>
    )
  }
  return (
    <Container>
      {showDuplicateModal && (
        <DuplicateModal
          data={data!}
          codigoFoto={item.fotografia.codigo}
          onClose={handleCloseDuplicateModal}
          setIsWarning={setIsWarning!}
          callback={callback}
        />
      )}

      {showChangeModal && (
        <ChangeModal
          sigla={item.fotografia.siglaFoto}
          data={data!}
          onClose={handleCloseChangeModal}
          callback={callback}
        />
      )}

      {openModalZoom && (
        <ImageModal
          titleFirstFoto='Foto Adicional'
          firstFoto={memorizedValue}
          onclose={() => setOpenModalZoom(false)}
        />
      )}

      <aside className='esquerda'>
        <Card
          item={item}
          showButton
          title={item.fotografia.descricao}
          style={{alignItems: 'center', display: 'flex', cursor: 'zoom-in'}}
        >
          <img src={memorizedValue} alt='Foto adicional' onClick={() => setOpenModalZoom(true)} />
        </Card>
      </aside>
      <aside className='direita'>
        <Card title='Tabela de preços'>
          <PriceScore
            showPayments
            sigla={item.fotografia.siglaFoto}
            prices={item.precos}
            data={data}
            canEdit
          />
        </Card>
      </aside>
    </Container>
  )
}

export default PageItem
