import { useContext } from "react";
import { ManutencaoSolicitacaoContext } from "../contexts/ManutencaoSolicitacaoContext";

export function useManutencaoExclusao() {
    const context = useContext(ManutencaoSolicitacaoContext);

    if (!context) {
        throw new Error("O hook useManutencaoExclusao precisa de um provider.");
    }

    return context;
}