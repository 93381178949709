import {InputHTMLAttributes, ReactNode} from 'react'

export default function Switch({className, children, ...rest}: Props) {
  return (
    <div className='form-check form-switch'>
      <input className={`form-check-input ${className}`} type='checkbox' {...rest} />
      <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>
        {children}
      </label>
    </div>
  )
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  children: ReactNode
}
