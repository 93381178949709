/* eslint-disable react-hooks/rules-of-hooks */
import {useState} from 'react'

import {animated, useSpring} from '@react-spring/web'

import Card from '../Card'

import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import {FotoColeta} from 'src/app/model/Validacao/Type'
import AnimatedRadio from 'src/app/components/AnimatedRadio'
import {useService} from 'src/app/hooks/useService'
import {UpdateOccurrencePhoto} from 'src/app/repositories/Atendimentos'
import {useAlert} from 'src/app/components/Alert/Alert'

const FotoPP = ({
  data,
  canEdit,
  foto,
  setIsWarning,
  viewOnly,
}: {
  data: ValidacaoModel
  viewOnly: boolean
  canEdit: boolean
  foto: FotoColeta
  setIsWarning: any
}) => {
  const [info]: FotoColeta[] = data.fotosColeta.filter(
    (item: FotoColeta) => item.siglaFoto === 'PP'
  )

  const [open, setOpen] = useState(viewOnly)
  const [currentValue, setCurrentValue] = useState<string | number>(info?.ocorrenciaFoto || '')

  const {servicesInfo} = useService()
  const itens = servicesInfo?.ocorrenciasFotos.filter((item) => item.siglaFoto === 'PP') || []

  async function handleOnClick(value: string | number) {
    let o: any = {
      codigoFotoAtendimento: foto.codigo,
      codigoOcorenciaSelecionada: value as number,
    }

    if (value === currentValue) {
      setCurrentValue('')
      o = {
        codigoFotoAtendimento: foto.codigo,
        codigoOcorenciaSelecionada: null,
      }
    } else {
      setCurrentValue(value)
    }

    try {
      const res = await UpdateOccurrencePhoto<string>(o)

      if (res) {
        useAlert(res, 'Success')
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const heightAnimation = {
    height: open ? 90 : 32,
    config: {
      tension: 300,
      duration: 150,
    },
  }

  const styles = useSpring(heightAnimation)

  return (
    <Card
      data={data}
      className='two'
      title={'foto pp (placar de preços)'}
      distance={info?.distancia}
      isPP
      foto={foto}
      setIsWarning={setIsWarning}
    >
      <animated.div style={styles} className='radios'>
        {itens.map((item, index) => (
          <AnimatedRadio
            // disabled={canEdit}
            label={item.valor}
            value={item.codigo}
            currentValue={currentValue}
            onClick={handleOnClick}
            renderArrow={index === 0 ? true : false}
            setOpen={setOpen}
            open={open}
          />
        ))}
      </animated.div>
    </Card>
  )
}

export default FotoPP
