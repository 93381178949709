import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  gap: 5px;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;

  .checkbox {
    display: flex;
    width: 100%;

    padding: 8px;

    gap: 8px;

    font-family: Inter;

    font-size: 16px;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  padding: 10px;

  span {
    text-transform: uppercase;
    color: #1b1b1b;
    letter-spacing: 5px;
    white-space: nowrap;
    font-weight: 300;
    font-family: 'Poppins';
    font-size: 18px;
    margin: 0 48px;
  }

  button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
  }
`

export const Footer = styled.div`
  width: 100%;
  padding: 8px;

  font-family: Inter;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    padding: 0 10px;
  }
`
