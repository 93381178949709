import styled from 'styled-components'

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  gap: 20px;

  button {
    margin-bottom: auto;
  }

  textarea {
    flex: 1;
    min-height: 80px;

    border: none;
    border-radius: 5px;
    outline: none;

    background-color: #fff;

    font-family: Poppins, sans-serif !important;

    padding: 6px;

    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;

    &:disabled {
      box-shadow: none;
    }
  }
`
