import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  flex-direction: column;

  .value {
    color: #1b1b1b;

    font-family: Poppins;

    font-weight: bold;

    width: 100%;

    padding: 2px 5px;

    background-color: #fbf9f5;

    border: 1px solid #2b2b2b;

    border-radius: 5px;

    margin-bottom: 5px;
  }

  .label {
    text-transform: uppercase;

    color: #adadad;

    font-family: Poppins;
  }
`
