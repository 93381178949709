/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useState} from 'react'
import CostCenterTable from './components/CostCenterTable'
import {GetCostCenters} from 'src/app/repositories/CenterCost'
import {useAlert} from 'src/app/components/Alert/Alert'
import {useCostCenter} from 'src/app/hooks/useCostCenter'
import {formatCPF, formatCnpj, formatPhoneNumber} from 'src/app/utils/Formats'
import {useSearchParams} from 'react-router-dom'

// import { Container } from './styles';

const CentroCusto: React.FC = () => {
  const {costCenter, setCostCenter} = useCostCenter()
  const [isLoading, setIsLoading] = useState(true)

  const [searchParams] = useSearchParams()
  const pageSize =
    searchParams.get('pageSize') !== null ? parseInt(searchParams.get('pageSize')!) : 5
  const pageNumber =
    searchParams.get('pageNumber') !== null ? parseInt(searchParams.get('pageNumber')!) : 1
  const [pagesTotal, setPagesTotal] = useState(0)

  async function getData() {
    try {
      setIsLoading(true)
      const res = await GetCostCenters({numeroPagina: pageNumber, tamanhoPagina: pageSize})
      if (res) {
        const {centrosDeCusto, bancos, contasBancarias, totalDePaginas} = res
        setPagesTotal(totalDePaginas)
        setCostCenter({
          centrosDeCusto: centrosDeCusto.map((item) => {
            if (item.cpfOuCnpj.length === 11) {
              item.cpfOuCnpj = formatCPF(item.cpfOuCnpj)
            } else {
              item.cpfOuCnpj = formatCnpj(item.cpfOuCnpj)
            }

            if (item.telefone) {
              item.telefone = formatPhoneNumber(item.telefone)
            }

            return item
          }),
          bancos: bancos.map((item) => {
            return {
              label: item.nome,
              value: item.codigo,
            }
          }),
          contasBancarias: contasBancarias.map((item) => {
            return {
              label: item.nome,
              value: item.codigo,
            }
          }),
        })
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  return (
    <div className={'card'}>
      <div className='card-body py-3'>
        <CostCenterTable
          pagesTotal={pagesTotal}
          getData={getData}
          isLoading={isLoading}
          data={costCenter}
          setData={setCostCenter}
        />
      </div>
    </div>
  )
}

export default CentroCusto
