import {useEffect, useState, memo} from 'react'

import clsx from 'clsx'

import {Container, QuickSidebarButton, BlackPanel} from './styles'
import {ExitToAppIcon} from 'src/app/utils/Icons'

type QuickSidebarProps = {
  children: React.ReactNode
  title: string
  active: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean>>
  clearFilter: () => void
  applyFilter: () => Promise<void>
}

const QuickSidebar = ({
  children,
  title,
  active,
  setActive,
  clearFilter,
  applyFilter,
}: QuickSidebarProps) => {
  const [showBlackPanel, setShowBlackPanel] = useState(active)

  const closeSideBar = () => {
    setActive(false)
    setTimeout(() => {
      setShowBlackPanel(false)
    }, 400)
  }

  useEffect(() => {
    if (active) {
      setShowBlackPanel(active)
    }
  }, [active])

  return (
    <>
      <BlackPanel onClick={closeSideBar} active={showBlackPanel} />
      <Container className={clsx({active})}>
        <ExitToAppIcon
          className='exit'
          style={{cursor: 'pointer', marginLeft: 'auto'}}
          color='#0E99FF'
          size={30}
          onClick={closeSideBar}
        />
        <div className='header'>{title}</div>

        <div className='content'>{children}</div>

        <div className='buttons'>
          <QuickSidebarButton onClick={clearFilter} color='#F89C32'>
            Remover
          </QuickSidebarButton>
          <QuickSidebarButton
            onClick={async () => {
              await applyFilter()
              closeSideBar()
            }}
            color='#384752'
          >
            Aplicar
          </QuickSidebarButton>
        </div>
      </Container>
    </>
  )
}

export default memo(QuickSidebar)
