/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect, useState} from 'react'

import {Container} from './styles'
import {ContainerBox} from '../../planejamento-execucao/CentroCusto/components/CostCenterCreationModal/styles'
import InputText from 'src/app/components/Form/InputText'
import Button from 'src/app/components/Button'

import {GetApiConfiguration} from 'src/app/repositories/ApiConfiguration'

import {IApiConfiguration} from 'src/app/model/ApiConfiguration/Type'
import {useAlert} from 'src/app/components/Alert/Alert'

export default function ColetaiApi() {
  const [data, setData] = useState<IApiConfiguration>()

  const getData = async () => {
    try {
      const res = await GetApiConfiguration()

      if (res) {
        setData(res)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Container>
      <h1>Configurações para acessar Coletaí API</h1>
      <div className='container-row'>
        <ContainerBox>
          <InputText
            placeholder='Digite sua key'
            value={data?.key}
            required
            height={80}
            label='Key'
          />
        </ContainerBox>
        <ContainerBox>
          <InputText
            placeholder='Digite seu ID único'
            value={data?.id}
            required
            height={80}
            label='ID'
          />
        </ContainerBox>
        <ContainerBox>
          <InputText
            placeholder='Digite seu e-mail de suporte'
            required
            height={80}
            label='E-mail usuário suporte'
            value={data?.email}
          />
        </ContainerBox>
      </div>
      <div className='container-row'>
        <ContainerBox>
          <InputText
            placeholder='Código perfil'
            value={data?.codigoPerfil}
            required
            height={80}
            label='Código perfil'
          />
        </ContainerBox>
        <ContainerBox>
          <InputText
            placeholder='Código projeto'
            value={data?.codigoProjeto}
            required
            height={80}
            label='Código projeto'
          />
        </ContainerBox>
      </div>
      <div className='container-row'>
        <Button color='primary'>Salvar</Button>
      </div>
    </Container>
  )
}
