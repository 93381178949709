/* eslint-disable react-hooks/rules-of-hooks */
// import { Container } from './styles';

import AnimatedModal from 'src/app/components/AnimatedModal'
import {ContainerBox} from '../../../CentroCusto/components/CostCenterCreationModal/styles'
import {Table} from '../../../CentroCusto/components/CostCenterBase/styles'
import AnimatedInput from 'src/app/components/AnimatedInput'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import TableRow from '../TableRow'
import {IModalAlert} from '../../../usuario/Usuarios'
import {Select} from 'src/app/components/Select/Select'
import {useFormik} from 'formik'
import InputText from 'src/app/components/Form/InputText'
import {CircleCheckIcon, PlusIcon} from 'src/app/utils/Icons'
import {Container} from './styles'
import {optionsValue} from '../../../usuario/components/UserCreationModal/validations'
import ModalAlert from 'src/app/components/ModalAlert'
import {
  GetClients,
  GetOrdersResponse,
  UpdateOrders,
  UpdateOrdersData,
} from 'src/app/repositories/OperationalBase'
import {useAlert} from 'src/app/components/Alert/Alert'

const OperationalEditModal = ({onClose, orders, setOrders, rowDetail, updateData}: Props) => {
  const [searchInput, setSearchInput] = useState('')
  const [modalAlert, setModalAlert] = useState<IModalAlert | undefined>(undefined)

  const [clients, setClients] = useState([])

  const [order, setOrder] = useState<GetOrdersResponse>({} as GetOrdersResponse)

  const [isFilled, setIsFilled] = useState(false)

  const filteredUserCostCenters = () => {
    if (orders.length > 0) {
      const filtroLowerCase = searchInput?.toLowerCase()
      const resultadosFiltrados = orders?.filter(
        (item) =>
          String(item.codigoCliente).includes(filtroLowerCase) ||
          item.codigoExterno.toLowerCase().includes(filtroLowerCase) ||
          item.status?.toLowerCase().includes(filtroLowerCase)
      )
      return resultadosFiltrados
    }

    return []
  }

  const handleFillOrders = async (item: GetOrdersResponse) => {
    setIsFilled(true)

    setOrder(item)

    await formik.setFieldValue('codigoExterno', item.codigoExterno)
    await formik.setFieldValue('status', item.status)
    await formik.setFieldValue('cliente', {
      value: item.codigoCliente,
      label: item.nomeCliente,
    })

    await formik.validateForm()
  }

  const initialValues = {
    codigoExterno: '',
    status: '',
    cliente: {
      label: '',
      value: '',
    },
  }

  const resetFields = async () => {
    await formik.setFieldValue('cliente', optionsValue)
    await formik.setFieldValue('status', '')
    await formik.setFieldValue('codigoExterno', '')
  }

  const handleAddOrder = () => {
    if (order.codigoCliente) {
      const copy = order

      setOrders((oldState) => [...oldState, copy])

      resetFields()

      setOrder({} as GetOrdersResponse)

      formik.validateForm()
    }
  }

  const handleUpdateOrder = () => {
    setOrders((oldState) => {
      return oldState.map((item) => {
        if (item.codigoCliente === order.codigoCliente) {
          item = order
        }

        return item
      })
    })

    resetFields()

    setOrder({} as GetOrdersResponse)

    setIsFilled(false)
  }

  const updateOrder = (key: keyof GetOrdersResponse, value: string) => {
    setOrder((oldState) => ({...oldState, [key]: value}))
  }

  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      const o: UpdateOrdersData = {
        codigoPontoColeta: rowDetail.codigo,
        pedidos: orders,
      }

      try {
        await UpdateOrders(o)
        useAlert('Pedidos atualizados com sucesso.', 'Success')
        updateData()
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    },
  })

  const getData = async () => {
    try {
      const res = await GetClients()

      if (res) setClients(res)
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const filteredClients = () => {
    const resultado = clients.filter(
      (client: any) => !orders.some((order) => order.nomeCliente === client.label)
    )

    return resultado
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AnimatedModal
      headerTitle='Editar Pedidos'
      formik={formik}
      onClose={onClose}
      containerStyle={{width: 800}}
    >
      <Container>
        <div className=' column'>
          <AnimatedInput
            titulo='Buscar'
            value={searchInput}
            placeholder='Código, Status ou Cliente'
            onChange={(e) => {
              setSearchInput(e.target.value)
            }}
            type='text'
          />
          <div className='table-container bg-white'>
            <Table width={25}>
              <tr>
                <th>Cliente</th>
                <th>Status</th>
                <th style={{width: 160}}>Código Cliente</th>
                <th style={{width: 40}}>Ação</th>
              </tr>
              {filteredUserCostCenters().map((item) => (
                <TableRow
                  item={item}
                  setModalAlert={setModalAlert}
                  setData={setOrders}
                  data={orders}
                  handleFillOrders={handleFillOrders}
                />
              ))}
            </Table>
          </div>
        </div>
        <div className='container-row'>
          <ContainerBox flex={5}>
            <Select
              label='Cliente'
              isDisabled={isFilled}
              // error={Boolean(formik.errors['cidadeUf'])}
              options={filteredClients()}
              formik={{
                formikProp: 'cliente',
                formikValues: formik,
              }}
              onChange={async (newValue: any) => {
                await formik.setFieldValue('cliente', newValue)

                updateOrder('codigoCliente', newValue.value)
                updateOrder('nomeCliente', newValue.label)
              }}
            />
            {formik && formik.touched['cliente'] && formik.errors['cliente'] && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors['cliente'].label}</span>
              </div>
            )}
          </ContainerBox>
          <ContainerBox flex={5}>
            <InputText
              height={80}
              inputClass='bg-white'
              placeholder='Digite o status'
              label='Status'
              formik={{
                formikProp: 'status',
                formikValues: formik,
              }}
              onChange={async (e) => {
                await formik.setFieldValue('status', e.target.value)
                updateOrder('status', e.target.value)
              }}
            />
          </ContainerBox>
          <ContainerBox flex={5}>
            <InputText
              height={80}
              inputClass='bg-white'
              placeholder='Digite o código'
              label='Código Cliente'
              formik={{
                formikProp: 'codigoExterno',
                formikValues: formik,
              }}
              onChange={async (e) => {
                await formik.setFieldValue('codigoExterno', e.target.value)
                updateOrder('codigoExterno', e.target.value)
              }}
            />
            {formik && formik.touched['codigoExterno'] && formik.errors['codigoExterno'] && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors['codigoExterno']}</span>
              </div>
            )}
          </ContainerBox>
          <ContainerBox style={{justifyContent: 'center'}}>
            <button className='add' onClick={isFilled ? handleUpdateOrder : handleAddOrder}>
              {isFilled ? <CircleCheckIcon color='#00E396' size={30} /> : <PlusIcon size={30} />}
            </button>
          </ContainerBox>
        </div>
      </Container>

      <ModalAlert
        isLoading={false}
        title={modalAlert?.title}
        content={modalAlert?.content}
        onConfirm={modalAlert?.action}
        onShow={Boolean(modalAlert)}
        onHide={() => setModalAlert(undefined)}
      />
    </AnimatedModal>
  )
}

export default OperationalEditModal

type Props = {
  rowDetail: any
  onClose: () => void
  orders: GetOrdersResponse[]
  setOrders: Dispatch<SetStateAction<GetOrdersResponse[]>>
  updateData: () => void
}
