/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react'

import Carousel from 'framer-motion-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import {CarouselRef} from 'framer-motion-carousel/dist/types/types'
import {useAlert} from 'src/app/components/Alert/Alert'
import AnimatedModal from 'src/app/components/AnimatedModal'
import {ISigla, PrecosColeta} from 'src/app/model/Validacao/Type'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import {
  GetAlternativePhotos,
  RemoveAlternativeServicePhoto,
} from 'src/app/repositories/Atendimentos'
import {ChevronRightIcon, CloseIcon} from 'src/app/utils/Icons'
import PageItem from '../PageItem'
import RemovePhotoModal from '../RemovePhotoModal'
import {ModalHeader} from './styles'

export type AlternativePhotos = {
  fotografia: {
    codigo: number
    siglaFoto: ISigla
    fileName: string
    latitude: number
    longitude: number
    precisao: number
    distancia: number
    descricao: string
  }
  precos: PrecosColeta[]
}

type Props = {
  onClose: () => void
  data: ValidacaoModel
  setIsWarning: any
}

const AlternativePhotosModal = ({onClose, data, setIsWarning}: Props) => {
  const [isLoading, setIsloading] = useState(true)
  const [showRemovePhotoModal, setShowRemovePhotoModal] = useState(false)

  const carouselRef = useRef<CarouselRef>(null)
  const [currentPhoto, setCurrentPhoto] = useState<AlternativePhotos>()

  const [pages, setPages] = useState<AlternativePhotos[]>([])

  const showButtons = pages.length > 1

  const goFoward = () => {
    carouselRef.current!.handleNext()
  }

  const goBackward = () => {
    carouselRef.current!.handlePrev()
  }

  const onKeyPressed = async (e: any) => {
    if (showButtons) {
      if (e.key === 'ArrowLeft' || e.key === 'a') {
        goBackward()
      }
      if (e.key === 'ArrowRight' || e.key === 'd') {
        goFoward()
      }
    }
  }

  async function getData() {
    try {
      setIsloading(true)
      const res = await GetAlternativePhotos<AlternativePhotos[]>(data.codigoAtendimento)

      if (res!.length <= 0) {
        setIsWarning(false)
        onClose()
      }

      if (res) {
        setPages(res)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      setIsloading(false)

      document.addEventListener('keydown', onKeyPressed)
    }
  }

  const handleCloseRemovePhotoModal = () => setShowRemovePhotoModal(false)

  const handleRemovePhoto = async (motivo: string) => {
    const o = {
      codigoFotoAtendimento: currentPhoto!.fotografia.codigo,
      motivo,
    }
    try {
      const res = await RemoveAlternativeServicePhoto<string>(o)

      if (res) {
        useAlert(res, 'Success')
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    } finally {
      getData()
      handleCloseRemovePhotoModal()
    }
  }

  useEffect(() => {
    getData()

    return () => {
      document.removeEventListener('keydown', onKeyPressed)
    }
  }, [])

  if (isLoading) return <></>

  return (
    <AnimatedModal
      headerTitle='Análise fotos adicionais'
      footerTitle='OK'
      onSubmit={onClose}
      onKeyDown={onKeyPressed}
      containerStyle={{width: '98%', maxWidth: 1500}}
      onClose={onClose}
    >
      {showRemovePhotoModal && (
        <RemovePhotoModal
          handleRemovePhoto={handleRemovePhoto}
          onClose={handleCloseRemovePhotoModal}
        />
      )}

      <button onClick={goBackward} className='backward'>
        <ChevronRightIcon size={30} />
      </button>
      <Carousel
        ref={carouselRef}
        autoPlay={false}
        interval={5000}
        loop={false}
        renderDots={() => <></>}
        renderArrowLeft={() => <></>}
        renderArrowRight={() => <></>}
      >
        {pages.map((item) => {
          return (
            <PageItem
              setShowRemovePhotoModal={setShowRemovePhotoModal}
              setCurrentPhoto={setCurrentPhoto}
              item={item}
              data={data}
              setIsWarning={setIsWarning}
              callback={getData}
            />
          )
        })}
      </Carousel>
      <button onClick={goFoward} className='forward'>
        <ChevronRightIcon size={30} />
      </button>
    </AnimatedModal>
  )
}

export default AlternativePhotosModal
