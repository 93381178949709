/* eslint-disable jsx-a11y/anchor-is-valid */
import {MouseEvent} from 'react'

import clsx from 'clsx'
import {FormikValues} from 'formik'
import {InputHTMLAttributes} from 'react'
import Label from '../Label'
import {ContainerLabel} from './styles'

//COLOCAR UM PLACEHOLDER FLUTUANTE NO INPUT PRA DESCREVER O QUE ELE FAZ Iniciar ciclo a cada 6 semana(s).

export default function InputText({
  label,
  formik,
  height = 100,
  required,
  link,
  className = 'w-100',
  inputClass = '',
  ...rest
}: Props) {
  const handleClickOnLink = (e: MouseEvent, callBack: () => void) => {
    e.preventDefault()
    callBack()
  }
  return (
    <>
      <div style={{height}} className={`fv-row mb-3 ${className}`}>
        <ContainerLabel>
          {label && <Label required={required}>{label}</Label>}
          {link && (
            <a href='/' onClick={(e) => handleClickOnLink(e, link.action)}>
              {link.text}
            </a>
          )}
        </ContainerLabel>
        <input
          {...formik?.formikValues.getFieldProps(formik.formikProp)}
          className={clsx(`form-control bg-transparent ${inputClass}`, {
            'is-invalid': Boolean(formik?.formikValues.errors[formik.formikProp]),
          })}
          required={required}
          {...rest}
        />
        {formik && formik.formikValues.errors[formik.formikProp] && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.formikValues.errors[formik.formikProp]}</span>
          </div>
        )}
      </div>
    </>
  )
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  inputClass?: string
  formik?: {
    formikProp: string
    formikValues: FormikValues
  }
  link?: {
    text: string
    action: () => void
  }
}
