/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {useState, useRef, useEffect, Dispatch, SetStateAction} from 'react'

import {Loader} from '@googlemaps/js-api-loader'

import AnimatedModal from 'src/app/components/AnimatedModal'

import {Container} from './styles'
import ContainerBox from './components/ContainerBox'
import IconButton from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/ServiceData/components/IconButton'
import {
  ThumbUpIcon,
  CloudDownloadIcon,
  CloudUploadIcon,
  EditLocationIcon,
  LocationIcon,
  AltRouteIcon,
  ThumbDownIcon,
  SyncCircleIcon,
} from 'src/app/utils/Icons'
import {Select} from 'src/app/components/Select/Select'

import './main.css'

import {
  PontosColeta,
  SyncRouteWithColetaiApi,
  UpdateRotaData,
  UpdateRoute,
  UpdateRouteStatus,
  UpdateRouteStatusData,
  ValidacaoImportFileRoute,
  ValidacaoImportFileRouteExcelError,
  ValidacaoImportFileRouteHeadersError,
} from 'src/app/repositories/Route'
import ScheduleTabs from './components/ScheduleTabs'
import {useFormik} from 'formik'
import {useAlert} from 'src/app/components/Alert/Alert'
import {GetResearcherByUFCity} from 'src/app/repositories/Researcher'
import AddPontoColetaModal from './components/AddPontoColetaModal'
import {useRoute} from 'src/app/hooks/useRoute'
import DraggableList from 'react-draggable-list'
import EditUFCityModal from './components/EditUFCityModal'
import {IModalAlert} from '../../../usuario/Usuarios'
import ImportModal, {errorMessage} from 'src/app/components/ImportModal'
import RotaModel from 'src/app/model/Rota/Rota'
import {Map} from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/CardHeader/components/HomologacaoModal/styles'
import {IRoute} from 'src/app/model/Rota/Types'
import PontoColetaItem from './components/PontoColetaItem'
import { IFilter } from 'src/app/model/Generic/Type'

const empytFilters = {
  label: 'Selecione um pesquisador',
  value: null,
}

const IMPORT_EXCEL_MODEL = [
  {
    sheetLabel: 'Rotas',
    sheetName: 'rota',
    values: {
      CIDADE: '*****',
      'CPF PESQUISADOR': '000.000.000-00',
      DOMINGO: 'x',
      SEGUNDA: 'x',
      TERÇA: 'x',
      QUARTA: 'x',
      QUINTA: 'x',
      SEXTA: 'x',
      SÁBADO: 'x',
      'DATA INICIO': '00/00/0000',
      'DATA EXPIRACAO': '',
      INTERVALO: 0,
      ANTECEDENCIA: 0,
      PENDENCIA: 0,
    },
  },
  {
    sheetLabel: 'Pontos',
    sheetName: 'pontos_de_coleta',
    values: {
      'IDENTIFICADOR PONTO': '******',
    },
  },
]

const EditRouteModal = ({
  onClose,
  ufOptions,
  handleDownloadRouteXlsx,
  setIsLoading,
  setModalAlert,
}: Props) => {
  const {setRoutes, routeDetail, updateRoutes, setRouteDetail} = useRoute()
  const [isExpiration, setIsExpiration] = useState(Boolean(routeDetail.planejamento.dataExpiracao))
  const [showAddPontoColetaModal, setShowAddPontoColetaModal] = useState(false)
  const [showEditUFCityModal, setShowEditUFCityModal] = useState(false)
  const [showModalIndividualImport, setShowModalIndividualImport] = useState(false)
  const [selectedMapMarker, setSelectedMapMarker] = useState(-1)

  const myRef = useRef<any>(null)
  const containerRef = useRef<any>(null)

  const agendaDaSemana = {
    domingo: routeDetail.planejamento.domingo || false,
    segunda: routeDetail.planejamento.segunda || false,
    terca: routeDetail.planejamento.terca || false,
    quarta: routeDetail.planejamento.quarta || false,
    quinta: routeDetail.planejamento.quinta || false,
    sexta: routeDetail.planejamento.sexta || false,
    sabado: routeDetail.planejamento.sabado || false,
  }

  let contagemTrue = 0

  for (const dia in agendaDaSemana) {
    //@ts-ignore
    if (agendaDaSemana[dia] === true) {
      contagemTrue++
    }
  }

  const emptySchedule = {
    frequencia: routeDetail.planejamento.frequencia || contagemTrue,
    intervalo: routeDetail.planejamento.intervalo || 0,
    antecedencia: routeDetail.planejamento.antecedencia || 0,
    pendencia: routeDetail.planejamento.pendencia || 0,

    dataInicial: routeDetail.planejamento.dataInicio || '',
    dataFinal: routeDetail.planejamento.dataExpiracao || '',

    agendaDaSemana,
  }

  const [researchersOptions, setResearchersOptions] = useState<Options[]>([])

  const [schedule, setSchedule] = useState<Schedule>(emptySchedule)

  const [pontosColetaList, setPontosColetaList] = useState<PontosColeta[]>(
    routeDetail.pontosColeta || []
  )

  const fixItemListOrder = (array: PontosColeta[]) =>
    array.map((item, index) => {
      item.ordem = index + 1
      return item
    })

  const onListChange = (newList: PontosColeta[]) => {
    setPontosColetaList(fixItemListOrder(newList))
  }

  const handleRemoveFromList = (codigo: number) => {
    setPontosColetaList((oldState) =>
      fixItemListOrder(oldState.filter((item) => item.codigo !== codigo))
    )
  }

  const handleOpenModalIndividualImport = () => setShowModalIndividualImport(true)
  const handleCloseModalIndividualImport = () => setShowModalIndividualImport(false)

  const handleAddSelectedPontosColeta = (newValues: PontosColeta[]) => {
    setPontosColetaList(
      newValues.map((item, index) => {
        item.ordem = index + 1
        return item
      })
    )
  }

  const handleUpdateSchedule = (key: keyof Schedule, value: string | number) => {
    setSchedule((oldState) => ({...oldState, [key]: value}))
  }

  const initialValues = {
    researcher: routeDetail.pesquisador || empytFilters,
    codigo: '',
  }

  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      const {researcher} = values

      const o: UpdateRotaData = {
        antecedencia: parseInt(schedule.antecedencia as string),
        intervalo: parseInt(schedule.intervalo as string),
        frequencia: parseInt(schedule.frequencia as string),
        pendencia: parseInt(schedule.pendencia as string),

        dataExpiracao: !isExpiration ? null : schedule.dataFinal || null,
        dataInicio: schedule.dataInicial || null,

        codigo: routeDetail.codigo,
        codigoPesquisador: researcher.value || null,
        codigosPontosDeColeta: pontosColetaList.map((item) => item.codigoPontoColeta),

        domingo: schedule.agendaDaSemana.domingo,
        segunda: schedule.agendaDaSemana.segunda,
        terca: schedule.agendaDaSemana.terca,
        quarta: schedule.agendaDaSemana.quarta,
        quinta: schedule.agendaDaSemana.quinta,
        sexta: schedule.agendaDaSemana.sexta,
        sabado: schedule.agendaDaSemana.sabado,
      }

      try {
        const res = await UpdateRoute(o)

        useAlert('Rota editada com sucesso.', 'Success')

        if (res) {
          syncRouteWithColetai(res.codigo)
          setRoutes((oldState) => {
            return oldState.map((item) => {
              if (item.codigo === res.codigo) {
                item = res
              }

              return item
            })
          })
        }

        onClose()
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    },
  })

  const handleOpenAddPontoColetaModal = () => setShowAddPontoColetaModal(true)

  const handleCloseAddPontoColetaModal = () => setShowAddPontoColetaModal(false)

  const handleOpenEditUFCityModal = () => setShowEditUFCityModal(true)

  const handleCloseEditUFCityModal = () => setShowEditUFCityModal(false)

  const handleUpdateRouteStatus = () => {
    const action = async () => {
      setIsLoading(true)

      if (routeDetail.ativo) await formik.setFieldValue('researcher', empytFilters)

      try {
        const o: UpdateRouteStatusData = {
          ativo: routeDetail.ativo ? false : true,
          codigo: routeDetail.codigo,
        }

        const res = await UpdateRouteStatus(o)

        if (res) {
          updateRoutes(res)

          setRouteDetail((oldState) => {
            const oldStateCopy = oldState
            oldStateCopy.ativo = res.ativo

            return oldStateCopy
          })

          useAlert(`Rota ${res.nome} atualizada com sucesso`, 'Success')
        }
      } catch (error: any) {
        useAlert(error.message, 'Error')
      } finally {
        setIsLoading(false)
        setModalAlert(undefined)
      }
    }

    let content = `Confirma a ativação da rota ${routeDetail.nome}?`

    if (routeDetail.ativo)
      content = `Confirma a inativação da rota ${routeDetail.nome}? Esta ação removerá o pesquisador da rota especificada. Se esta for a única rota no planejamento do pesquisador, ele também será inativado. Deseja continuar?`

    setModalAlert({
      title: `${routeDetail.ativo ? 'Inativar' : 'Ativar'} rota`,
      content,
      action,
    })
  }

  async function getData() {
    try {
      const res = await GetResearcherByUFCity({uf: routeDetail.uf, cidade: routeDetail.cidade})

      if (res) {
        setResearchersOptions(res)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  async function syncRouteWithColetai(codigoRota: number) {
    try {
      const res = await SyncRouteWithColetaiApi(codigoRota)
      setRouteDetail({...routeDetail, pendenteSincronizacao: false})

      if (res) {
        updateRoutes(res)
      }

      useAlert(`Rota ${res?.nome} sincronizada com o Coletaí`, 'Success')
      onClose()
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const handlerHeaderColumnErrors = (erros: ValidacaoImportFileRouteHeadersError) => {
    let err: errorMessage = []

    if (erros.rotas && erros.rotas.length > 0) {
      const preFix = 'As seguintes colunas na aba de rotas não existem '
      const columns = erros.rotas

      err.push({columns, preFix})
    }
    if (erros.pontos_de_coleta && erros.pontos_de_coleta.length > 0) {
      const preFix = 'As seguintes colunas na aba de pontos_de_coleta não existem '
      const columns = erros.pontos_de_coleta

      err.push({columns, preFix})
    }

    return err
  }

  const onImportFile = async (file: File) => {
    try {
      const res = await ValidacaoImportFileRoute(routeDetail.codigo, file)

      const {excelError: excelerror, excelSucces, headersError} = res!

      if (excelSucces) {
        syncRouteWithColetai(routeDetail.codigo)
        updateRoutes(new RotaModel(excelSucces as IRoute))
        handleCloseModalIndividualImport()
        onClose()
        useAlert('Rota atualizada com sucesso.', 'Success')
        return {
          columns: [],
          excelSuccess: {},
          errorsMessages: [],
        }
      }

      const excelError = excelerror as ValidacaoImportFileRouteExcelError

      if (excelError) {
        let rota = excelError.rota
        let pontosDeColeta = excelError.pontosDeColeta

        const rotaColumns = [
          {name: 'CIDADE', accessor: 'cidadeRota'},
          {name: 'CPF PESQUISADOR', accessor: 'cpfPesquisador'},
          {name: 'DOMINGO', accessor: 'domingo'},
          {name: 'SEGUNDA', accessor: 'segunda'},
          {name: 'TERÇA', accessor: 'terca'},
          {name: 'QUARTA', accessor: 'quarta'},
          {name: 'QUINTA', accessor: 'quinta'},
          {name: 'SEXTA', accessor: 'sexta'},
          {name: 'SÁBADO', accessor: 'sabado'},
          {name: 'DATA INICIO', accessor: 'dataInicio'},
          {name: 'DATA EXPIRACAO', accessor: 'dataExpiracao'},
          {name: 'INTERVALO', accessor: 'intervalo'},
          {name: 'ANTECEDENCIA', accessor: 'antecedencia'},
          {name: 'PENDENCIA', accessor: 'pendencia'},
        ]

        const pontoDeColetaColumns = [{name: 'IDENTIFICADOR PONTO', accessor: 'identificadorPonto'}]

        const errorsMessages = handlerHeaderColumnErrors(headersError)

        return {
          columns: [
            {
              name: 'Rotas',
              data: rotaColumns,
            },
            {
              name: 'Pontos',
              data: pontoDeColetaColumns,
            },
          ],
          excelSuccess: {Rotas: [rota], Pontos: pontosDeColeta},
          errorsMessages,
        }
      }

      const errorsMessages = handlerHeaderColumnErrors(headersError)

      return {
        columns: [],
        excelSuccess: {},
        errorsMessages,
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const scrollTo = (codigo: number) => {
    if (myRef && myRef.current) {
      let childs = myRef.current._listRef.current.children

      for (let i = 0; i < childs.length; i++) {
        const element = childs[i].children[0]

        if (parseFloat(element?.id) === codigo) {
          let topValue = i === 0 ? 0 : element.parentNode.offsetTop - 100

          containerRef.current.scrollTo({top: topValue, behavior: 'smooth'})
          // element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'})
          break
        }
      }
    }
  }

  async function initMap() {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_TOKEN!,
      version: 'weekly',
      libraries: ['places'],
      language: 'pt',
      region: 'BR',
    })

    const mapOptions = {
      center: {lat: 0, lng: 0},
      zoom: 5,
      mapId: 'TRIAD_MAP_ID',
    }

    const {Map} = (await loader.importLibrary('maps')) as google.maps.MapsLibrary

    const {AdvancedMarkerElement} = (await loader.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary

    const map = new Map(document.getElementById('map-rota') as HTMLElement, mapOptions)
    const bounds = new google.maps.LatLngBounds()

    pontosColetaList.map((item) => {
      const pos = {
        lat: item.latitude,
        lng: item.longitude,
      }

      const imgElement = document.createElement('img')
      const imageUrl = `https://mt.google.com/vt/icon?psize=16&color=ff330000&name=icons/spotlight/spotlight-waypoint-b.png&ax=44&ay=48&scale=1&text=${item.ordem}`
      imgElement.src = imageUrl

      const marker = new AdvancedMarkerElement({
        map: map,
        position: pos,
        title: item.identificadorPontoColeta,
        content: imgElement,
      })

      marker.addListener('click', () => {})

      imgElement.addEventListener('mouseover', () => {
        scrollTo(item.codigo)
        setSelectedMapMarker(item.ordem)
      })
      imgElement.addEventListener('mouseout', () => setSelectedMapMarker(-1))

      bounds.extend(pos)
    })

    map.fitBounds(bounds)
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    initMap()
  }, [pontosColetaList])

  const useContainer = true

  return (
    <AnimatedModal
      formik={formik}
      containerStyle={{maxWidth: 1600}}
      footerTitle='Editar'
      headerTitle='Edição de Rota'
      onClose={onClose}
      onSubmit={() => {}}
    >
      {showModalIndividualImport && (
        <ImportModal
          sheets={['Rotas', 'Pontos']}
          title={`upload edição rota ${routeDetail.nome}`}
          modelFileName='edicao_rota'
          onClose={handleCloseModalIndividualImport}
          onImportFile={onImportFile}
          excelModel={IMPORT_EXCEL_MODEL}
          instructions={[
            'Os campos "ROTA", "UF", "CIDADE" e "DATA INICIO" são obrigatórios',
            'Pelo menos um dia da semana deve ser estar marcado com "x"',
            'A ordem dos postos no arquivo importa, ou seja, a ordenação na rota vai depender de como os postos serão ordenados de cima para baixo',
          ]}
        />
      )}

      {showAddPontoColetaModal && (
        <AddPontoColetaModal
          handleAddSelectedPontosColeta={handleAddSelectedPontosColeta}
          onClose={handleCloseAddPontoColetaModal}
          info={{uf: routeDetail.uf, cidade: routeDetail.cidade}}
          pontosColetaList={pontosColetaList}
        />
      )}

      {showEditUFCityModal && (
        <EditUFCityModal ufOptions={ufOptions} onClose={handleCloseEditUFCityModal} />
      )}
      <Container>
        <aside>
          <header className='container'>
            <div className='info'>
              <div className='routeName'>
                <AltRouteIcon color='red' size={20} />
                <span data-test='nomeRota'>{routeDetail.nome}</span>
              </div>
              <div className='routeUF'>
                <LocationIcon color='red' size={20} />
                <span>
                  {routeDetail.uf}/{routeDetail.cidade}
                </span>
              </div>
            </div>
            <div className='buttons'>
              <IconButton
                data-test='bActiveModal'
                style={{backgroundColor: routeDetail.ativo ? '#dc3545' : '#28A745'}}
                Icon={routeDetail.ativo ? ThumbDownIcon : ThumbUpIcon}
                text={routeDetail.ativo ? 'Inativar' : 'Ativar'}
                onClick={handleUpdateRouteStatus}
              />
              <IconButton
                data-test='bDownloadModal'
                style={{backgroundColor: '#0095e8'}}
                Icon={CloudDownloadIcon}
                text='Baixar'
                onClick={async () =>
                  await handleDownloadRouteXlsx(routeDetail.nome, routeDetail.codigo)
                }
              />
              <IconButton
                data-test='bImportModal'
                style={{backgroundColor: !routeDetail.ativo ? '#4449' : '#0095e8'}}
                Icon={CloudUploadIcon}
                text='Importar'
                onClick={handleOpenModalIndividualImport}
                disabled={!routeDetail.ativo}
              />
              <IconButton
                data-test='bCityModal'
                style={{backgroundColor: !routeDetail.ativo ? '#4449' : '#0095e8'}}
                Icon={EditLocationIcon}
                text='Editar Cidade'
                onClick={handleOpenEditUFCityModal}
                disabled={!routeDetail.ativo}
              />
              <IconButton
                data-test='bCityModal'
                style={{backgroundColor: !routeDetail.pendenteSincronizacao ? '#4449' : '#0095e8'}}
                Icon={SyncCircleIcon}
                text='Sincronizar'
                onClick={() => syncRouteWithColetai(routeDetail.codigo)}
                disabled={!routeDetail.pendenteSincronizacao}
              />
            </div>
          </header>
          <ContainerBox title='Pesquisador'>
            <Select
              dataTest='oResearcher'
              isDisabled={!routeDetail.ativo}
              placeholder='Selecione um pesquisador'
              options={researchersOptions}
              formik={{
                formikProp: 'researcher',
                formikValues: formik,
              }}
            />
          </ContainerBox>
          <ContainerBox
            containerRef={containerRef}
            title='Pontos de Coleta'
            onClick={handleOpenAddPontoColetaModal}
            hasButton
            style={{
              overflow: useContainer ? 'auto' : '',
              maxHeight: useContainer ? '270px' : '',
              border: useContainer ? '1px solid gray' : '',
            }}
          >
            <DraggableList<
              PontosColeta,
              {
                handleRemoveFromList: (codigo: number) => void
                selectedMapMarker: number
                pontosColetaList: PontosColeta[]
                onListChange: (newList: PontosColeta[]) => void
              },
              any
            >
              itemKey={(item) => String(item.codigo)}
              //@ts-ignore
              template={PontoColetaItem}
              list={pontosColetaList}
              onMoveEnd={(newList: any) => onListChange(newList)}
              container={() => (useContainer ? containerRef.current! : document.body)}
              commonProps={{
                handleRemoveFromList,
                selectedMapMarker,
                pontosColetaList,
                onListChange,
              }}
              ref={myRef}
            />
          </ContainerBox>
          <ContainerBox title='Agendamento'>
            <ScheduleTabs
              isExpiration={isExpiration}
              setIsExpiration={setIsExpiration}
              routeDetail={routeDetail}
              schedule={schedule}
              handleUpdateSchedule={handleUpdateSchedule}
            />
          </ContainerBox>
          <div className='container'></div>
        </aside>
        <div className='map'>
          <Map />
        </div>
      </Container>
    </AnimatedModal>
  )
}

type Options = {
  value: any
  label: any
}

export type Schedule = {
  frequencia: number | string
  intervalo: number | string
  antecedencia: number | string
  pendencia: number | string
  dataInicial: string
  dataFinal: string
  agendaDaSemana: {
    segunda: boolean
    terca: boolean
    quarta: boolean
    quinta: boolean
    sexta: boolean
    sabado: boolean
    domingo: boolean
  }
}

type Props = {
  handleDownloadRouteXlsx: (nome: string, codigo: number) => Promise<void>
  onClose: () => void
  ufOptions: IFilter[]
  setIsLoading: Dispatch<SetStateAction<boolean>>
  setModalAlert: Dispatch<SetStateAction<IModalAlert | undefined>>
}

export default EditRouteModal
