import {MouseEvent, useState} from 'react'
import {Button, Content, Message} from './styles'
import {CloseIcon} from 'src/app/utils/Icons'
import {Tree} from '../Tree'
import {useSpring} from 'react-spring'

const MainTree = ({style, item, refMap, setItems, cancelMap, life, onClick}: any) => {
  const [isOpen, setOpen] = useState(false)

  const success = Object.entries(item.msg.success)
  const error = Object.entries(item.msg.error)

  const {height, opacity} = useSpring({
    from: {height: 0, opacity: 0},
    to: {
      height: isOpen ? style.height.animation.to : 0,
      opacity: isOpen ? 1 : 0,
    },
  })

  return (
    <Message
      style={{
        opacity: style.opacity,
        height: isOpen ? 'auto' : style.height,
      }}
    >
      <div
        className='header'
        onClick={() => {
          setOpen(!isOpen)
        }}
        ref={(ref: HTMLDivElement) => {
          ref && refMap.set(item, ref)
        }}
      >
        <span>{success.length + error.length} rotas sincronizadas</span>
        <Button
          onClick={(e: MouseEvent) => {
            e.stopPropagation()

            setItems((state: any) =>
              state.filter((i: any) => {
                return i.key !== item.key
              })
            )

            if (cancelMap.has(item) && life.get() !== '0%') {
              cancelMap.get(item)()
            }
          }}
        >
          <CloseIcon size={18} fill='#666666' />
        </Button>
      </div>
      <Content
        style={{
          opacity,
          height: isOpen ? 'auto' : height,
        }}
      >
        <Tree name='Sucesso'>
          {success.map((item) => (
            <Tree onClick={() => onClick(parseInt(item[0] as string))} name={String(item[1])} />
          ))}
        </Tree>
        <Tree name='Falha'>
          {error.map((item) => (
            <Tree onClick={() => onClick(parseInt(item[0] as string))} name={String(item[1])} />
          ))}
        </Tree>
      </Content>
    </Message>
  )
}

export default MainTree
