/* eslint-disable react-hooks/rules-of-hooks */
import {useState} from 'react'
import TablePainel from 'src/app/components/TablePainel'
import {
  TableColumn,
  TableRowAction,
  TableExportProps,
} from 'src/app/components/TablePainel/table-painel'
import CostCenterCreationModal from '../CostCenterCreationModal'
import {ICenterCost} from 'src/app/model/CenterCost/type'
import {GetCostCenterDetail, ICostCenterResponse} from 'src/app/repositories/CenterCost'
import {useAlert} from 'src/app/components/Alert/Alert'
import CostCenterViewInfoModal from '../CostCenterViewInfoModal'
import {CentroCustoData} from 'src/app/contexts/CostCenterContext'

const CostCenterTable = ({data, getData, setData, isLoading, pagesTotal}: Props) => {
  const [centerCostInfo, setCenterCostInfo] = useState<ICostCenterResponse>(
    {} as ICostCenterResponse
  )
  const [isEditing, setIsEdit] = useState(false)
  const [showCostCenterCreationModal, setShowCostCenterCreationModal] = useState(false)
  const [showCostCenterViewInfoModal, setShowCostCenterViewInfoModal] = useState(false)

  const handleCloseShowCostCenterCreationModal = () => {
    setShowCostCenterCreationModal(false)
    setIsEdit(false)
    setCenterCostInfo({} as ICostCenterResponse)
  }

  const handleCloseShowCostCenterViewInfoModal = () => {
    setShowCostCenterViewInfoModal(false)
    setCenterCostInfo({} as ICostCenterResponse)
  }

  const handleOpenShowCostCenterCreationModal = () => setShowCostCenterCreationModal(true)

  const handleOpenShowCostCenterViewInfoModal = () => setShowCostCenterViewInfoModal(true)

  const columns: TableColumn[] = [
    {
      name: 'Razão social',
      accessor: 'razaoSocial',
    },
    {
      name: 'Nome fantasia',
      accessor: 'nomeFantasia',
    },
    {
      name: 'Nome do proprietário',
      accessor: 'nomeProprietario',
    },
    {
      name: 'Email',
      accessor: 'email',
    },
    {
      name: 'Telefone',
      accessor: 'telefone',
    },
    {
      name: 'Cpf Ou Cnpj',
      accessor: 'cpfOuCnpj',
    },
    {
      name: 'Total pesquisadores',
      accessor: 'totalPesquisadores',
    },
  ]

  const rowActions: TableRowAction[] = [
    {
      label: 'Editar',
      icon: 'pencil',
      onClick: async (row: ICenterCost) => {
        try {
          const res = await GetCostCenterDetail(row.codigo)

          setCenterCostInfo(res!)
        } catch (error: any) {
          useAlert(error.message, 'Error')
        }

        setIsEdit(true)
        handleOpenShowCostCenterCreationModal()
      },
      condition: (row: ICenterCost) => true,
    },
    {
      label: 'Visualizar',
      icon: 'eye',
      onClick: async (row: ICenterCost) => {
        try {
          const res = await GetCostCenterDetail(row.codigo)

          setCenterCostInfo(res!)
        } catch (error: any) {
          useAlert(error.message, 'Error')
        }
        handleOpenShowCostCenterViewInfoModal()
      },
      condition: (row: ICenterCost) => true,
    },
  ]

  const tableExportProps: TableExportProps = {
    pdf: {
      fileName: 'centro_de_custo',
    },
    excel: {
      fileName: 'centro_de_custo',
      sheetName: 'centro_de_custo',
    },
    csv: {
      fileName: 'centro_de_custo',
    },
  }

  return (
    <>
      {showCostCenterCreationModal && (
        <CostCenterCreationModal
          isEditing={isEditing}
          onClose={handleCloseShowCostCenterCreationModal}
          setData={setData}
          getData={getData}
          centerCostInfo={centerCostInfo}
          data={data}
        />
      )}
      {showCostCenterViewInfoModal && (
        <CostCenterViewInfoModal
          centerCostInfo={centerCostInfo}
          onClose={handleCloseShowCostCenterViewInfoModal}
        />
      )}
      <TablePainel
        pagesTotal={pagesTotal}
        isLoading={isLoading}
        tableTitle='Tabela de Centro de Custo'
        columns={columns}
        data={data.centrosDeCusto || []}
        rowActions={rowActions}
        tableExportProps={tableExportProps}
        onCreateData={handleOpenShowCostCenterCreationModal}
        importDataButton={false}
        getData={getData}
      />
    </>
  )
}

type Props = {
  isLoading: boolean
  data: CentroCustoData
  getData: () => void
  setData: React.Dispatch<React.SetStateAction<CentroCustoData>>
  pagesTotal: number
}

export default CostCenterTable
