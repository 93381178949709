import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  gap: 10px;

  width: 100%;
  height: 100%;

  aside {
    header.container {
      padding: 0 !important;

      .buttons {
        display: flex;
        gap: 20px;
      }

      .info {
        display: flex;
        gap: 20px;
        padding: 8px 0;

        span {
          font-family: Poppins;
          font-size: 16px;

          margin-left: 5px;
        }
      }
    }
  }

  aside,
  .map {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;

    padding: 16px;

    border-radius: 5px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .map {
  }
`
