import api from '../config'
import Erro from '../model/Erro/Erro'
import {handlerResult} from '../utils/API'

export async function DownloadExportacoes(dataInicio: string, dataFim: string) {
  try {
    const response = await api.post('liberacoes', {dataInicio, dataFim})
    const data = handlerResult(response.data)
    return data as IExportacao[]
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export interface IExportacao {
  identificadorPonto: string
  cnpj: string
  codigoExterno: string
  fonte: string
  pesquisador: string
  dataColeta: string
  formulario: string
  status: string
  dataLiberacao: string
  liberadoPor: string
  alteradaNaValidacao: boolean
  pagamentoAprovado: boolean
  observacaoPagamento: string
}
