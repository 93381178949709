import {Dispatch, SetStateAction, useEffect, useState} from 'react'

import {useSpring, useInView} from '@react-spring/web'

import {IModalAlert} from '../../Usuarios'
import {ICidadesValor} from 'src/app/model/Usuario/Type'
import {currencyFormatterBRL} from 'src/app/utils/Formats'
import {AnimatedTableRow} from '../../../CentroCusto/components/CostCenterBase/components/TableRow/styles'

import {DeleteIcon, PencilIcon} from 'src/app/utils/Icons'

// import { Container } from './styles';

const TableRow = ({item, setData, handleFillUserCostCenter, setModalAlert}: Props) => {
  const [show, setShow] = useState(false)

  const [ref, inView] = useInView()

  const fadeAnimation = {
    opacity: show ? 1 : 0,
    scale: show ? 1 : 0,
    // borderWidth: show ? 1 : 0,
    config: {
      tension: 300,
      duration: 250,
    },
  }

  const styles = useSpring(fadeAnimation)

  useEffect(() => {
    if (inView) {
      setShow(true)
    }
  }, [inView])

  const handleDeleteUserCostCenter = (codigo: number | string) => {
    const action = () => {
      setData((oldState) => {
        return oldState.filter((item) => item.codigo !== codigo)
      })

      setModalAlert(undefined)
    }

    setModalAlert({
      title: 'Aviso!',
      content: `Deseja realmente excluir esse centro de custo? `,
      action,
    })
  }

  return (
    <AnimatedTableRow style={styles} ref={ref} key={item.codigo}>
      <td>
        {item.cidade}/{item.uf}
      </td>
      <td>{item.nomeCentroDeCusto}</td>
      <td> {currencyFormatterBRL(item.valor)}</td>
      <td>
        <div className='buttons'>
          <button onClick={() => handleDeleteUserCostCenter(item.codigo!)}>
            <DeleteIcon size={20} color='#FF4560' />
          </button>
          <button onClick={() => handleFillUserCostCenter(item)}>
            <PencilIcon size={20} color='#009EF7' />
          </button>
        </div>
      </td>
    </AnimatedTableRow>
  )
}

type Props = {
  setData: Dispatch<SetStateAction<ICidadesValor[]>>
  handleFillUserCostCenter: (item: ICidadesValor) => void
  item: ICidadesValor
  setModalAlert: Dispatch<SetStateAction<IModalAlert | undefined>>
}

export default TableRow
