import AWS from 'aws-sdk'

import Erro from '../model/Erro/Erro'

export function getImageFromBucket(fileName: string | null | undefined) {
  if (fileName) {
    const myKey = fileName.split('/')
    const myBucket = myKey[0]
    const signedUrlExpireSeconds = 3000

    myKey.shift()
    let n = myKey.toString()

    n = n.replaceAll(',', '/')

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
      region: process.env.REACT_APP_AWS_S3_REGION,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
    })

    try {
      const url = s3.getSignedUrl('getObject', {
        Bucket: myBucket,
        Key: n,
        Expires: signedUrlExpireSeconds,
      })

      return url
    } catch (error) {
      throw new Error('Erro ao carregar foto.')
    }
  }

  return 'https://www.russorizio.com/wp-content/uploads/2016/07/ef3-placeholder-image.jpg'
}

export function handlerResult(response: Response) {
  if (!response.onSuccess) {
    throw new Erro(response.data)
  }

  return response.data
}

type Response = {
  onSuccess: boolean
  data: any
}
