/* eslint-disable react-hooks/exhaustive-deps */
import {CSSProperties, useState, useEffect, Dispatch, SetStateAction, memo, useRef} from 'react'

import Lottie from 'react-lottie'

import {Container} from 'react-bootstrap'

import HeaderContainer from './HeaderContainer/HeaderContainer'
import SubHeaderContainer from './SubHeaderContainer'
import ServicesList from './ServicesList'
import QuickSidebar from 'src/app/components/QuickSidebar'
import Selecao from 'src/app/components/Selecao'
import {useService} from 'src/app/hooks/useService'

const styles: {[key: string]: CSSProperties} = {
  container: {
    maxWidth: '1870px',
    margin: 'auto',
    marginTop: 6,
    padding: 0,
    backgroundColor: '#E8E8E8',
  },
}

function Validacao() {
  const {
    isLoading,
    setGroups,
    setStates,
    setResearchers,
    setClients,
    setForms,
    setStatusPayment,
    clients,
    clientsFilter,
    dateRange,
    forms,
    statusPayment,
    formsFilter,
    groups,
    groupsFilter,
    researchers,
    researchersFilter,
    searchFilter,
    states,
    statesFilter,
    currentSituation,
    getData,
  } = useService()

  const [activeFilter, setActiveFilter] = useState(false)

  const [canShowButtons, setCanShowButtons] = useState(false)

  const handlerOnChangeMultiSelect = (values: any, setValue: Dispatch<SetStateAction<never[]>>) => {
    const array = values.map((item: any) => item)

    setValue(array)
  }

  const clearFilter = async () => {
    setGroups([])
    setStates([])
    setResearchers([])
    setClients([])
    setForms([])
    setStatusPayment(null)
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('../../../assets/animations/search.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    getData()
  }, [dateRange, currentSituation])

  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      const delayDebounceFn = setTimeout(() => {
        getData()
      }, 1500)

      return () => clearTimeout(delayDebounceFn)
    } else {
      didMount.current = true
    }
  }, [searchFilter])

  return (
    <>
      <Container style={styles.container}>
        <HeaderContainer setActiveFilter={setActiveFilter} setCanShowButtons={setCanShowButtons} />

        {isLoading ? (
          <>
            <Lottie options={defaultOptions} height={400} width={400} />
          </>
        ) : (
          <>
            <ServicesList setCanShowButtons={setCanShowButtons} />
            <SubHeaderContainer
              setCanShowButtons={setCanShowButtons}
              canShowButtons={canShowButtons}
            />
          </>
        )}

        <QuickSidebar
          applyFilter={getData}
          active={activeFilter}
          setActive={setActiveFilter}
          title='Filtros De Validação'
          clearFilter={clearFilter}
        >
          <Selecao
            contentStyle={{width: '100%'}}
            titulo={'UF'}
            multiplo
            opcoes={statesFilter}
            setValue={(value) => setStates(value)}
            value={states}
          />
          <Selecao
            contentStyle={{width: '100%'}}
            opcoes={groupsFilter}
            nomeLabel={'nome'}
            titulo={'Grupos'}
            multiplo={true}
            value={groups}
            setValue={(value) => handlerOnChangeMultiSelect(value, setGroups)}
          />
          <Selecao
            contentStyle={{width: '100%'}}
            opcoes={researchersFilter}
            nomeLabel={'nome'}
            titulo={'Pesquisadores'}
            multiplo={true}
            value={researchers}
            setValue={(value) => handlerOnChangeMultiSelect(value, setResearchers)}
          />
          <Selecao
            contentStyle={{width: '100%'}}
            opcoes={clientsFilter}
            nomeLabel={'nome'}
            titulo={'Clientes'}
            multiplo={true}
            value={clients}
            setValue={(value) => handlerOnChangeMultiSelect(value, setClients)}
          />
          <Selecao
            contentStyle={{width: '100%'}}
            opcoes={formsFilter}
            nomeLabel={'nome'}
            titulo={'Formulários'}
            multiplo={true}
            value={forms}
            setValue={(value) => handlerOnChangeMultiSelect(value, setForms)}
          />
          <Selecao
            contentStyle={{width: '100%'}}
            nomeLabel={'nome'}
            titulo={'Status Pagamento'}
            multiplo={false}
            opcoes={['Aprovado', 'Reprovado']}
            value={statusPayment}
            setValue={setStatusPayment}
          />
        </QuickSidebar>
      </Container>
    </>
  )
}

export default memo(Validacao)
