/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {useEffect, useMemo, useState} from 'react'

import {Tooltip as ReactTooltip} from 'react-tooltip'

import {useSearchParams} from 'react-router-dom'
import {
  Column,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import UserFilter from 'src/app/pages/planejamento-execucao/usuario/components/UserFilters'
import {SheetData, saveCSVFile, saveExcelFile, savePDFFile} from '../../utils/Files'
import Button from '../Button'
import Filtro from '../Filtro'
import Switch from '../Form/Switch'
import Icon from '../Icon'
import Menu from '../Menu'
import {MenuItem} from '../MenuItem'
import Pagination from '../Pagination/Pagination'
import {Select} from '../Select/Select'
import SearchInput from './SearchInput'
import SubTable from './SubTable'
import ShowHideComponent from './components/ShowHideComponent'
import {TableHeader} from './styles'
import {TableColumn, TablePainelProps} from './table-painel'
import IndeterminateCheckbox from './components/Checkbox'

export default function TablePainel({
  data: tableData,
  columns: tableColumns,
  rowActions,
  rowStyleConditions,
  subtable,
  createDataButton = true,
  onCreateData,
  importDataButton = false,
  onImportData,
  exportButton = true,
  hideColumnsButton = true,
  searchable = true,
  paginate = true,
  tableExportProps,
  getData,
  cleanFilters,
  applyFilters,
  filters,
  tableTitle = 'Tabela',
  customTitle,
  importPostButton = false,
  importPlanningButton = false,
  bordered = false,
  onImportPosts = () => {},
  onImportPlanning = () => {},
  switchFilter,
  searchPlaceholder = 'Pesquisar...',
  buttons = [],
  isLoading = false,
  pagesTotal,
  isCheckbox,
  rowSelect,
  setRowSelect,
  conditionDisabledSelect
}: TablePainelProps) {
  const data = useMemo(() => tableData, [tableData])
  const [columnStyle, setColumnStyle] = useState<any>({})

  const [searchParams, setSearchParams] = useSearchParams()
  const pageNumber =
    searchParams.get('pageNumber') !== null ? parseInt(searchParams.get('pageNumber')!) : 1

  const columns = useMemo<IColumn[]>(() => {
    const modelColumns: IColumn[] = []
    if (rowActions && rowActions.length > 0) {
      modelColumns.push({
        id: 'action',
        Header: '',
        Cell: ({row}) => {
          return (
            <Menu buttonProps={{icon: 'category', color: 'primary', fill: false}}>
              {rowActions
                .filter((action) => !action.condition || action.condition(row.values))
                .map((action, index) => (
                  <MenuItem
                    key={index}
                    title={action.label}
                    icon={action.icon}
                    onClick={() => (action.onClick ? action.onClick(row.original)! : undefined)}
                  />
                ))}
            </Menu>
          )
        },
        hidden: false,
      })
    }

    for (let column of tableColumns) {
      setColumnStyle((prev: any) => ({...prev, [column.name]: column.color}))
      modelColumns.push({
        Header: column.name,
        Cell: ({value}: any) => <>{value?.component ?? value}</>,
        accessor: column.accessor,
        columns: serializeSubcolumns(column.subcolumns),
        hidden: column.hidden || false,
        //@ts-ignore
        color: column.color,
      })
    }
    return modelColumns
  }, [tableColumns])

  const [exportIgnoreColumns, setExportIgnoreColumns] = useState<string[]>([])

  function serializeSubcolumns(subcolumns: TableColumn[] | undefined): Column[] | undefined {
    if (!subcolumns || subcolumns.length <= 0) return
    const modelColumn: Column[] = []
    for (let subcolumn of subcolumns) {
      setColumnStyle((prev: any) => ({...prev, [subcolumn.name]: subcolumn.color}))
      modelColumn.push({
        Header: subcolumn.name,
        accessor: subcolumn.accessor,
        columns: serializeSubcolumns(subcolumn?.subcolumns)!,
      })
    }
    return modelColumn
  }

  const hiddenColumns: string[] = columns.map((column) => {
    if (column.hidden) return String(column.accessor || column!.id)
    return ''
  })

  const {
    headerGroups,
    visibleColumns,
    flatRows,
    state,
    page,
    allColumns,
    canPreviousPage,
    canNextPage,
    pageCount,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setGlobalFilter,
    gotoPage,
    setPageSize,
    setHiddenColumns,
    state: {pageIndex},
    toggleHideAllColumns,
    getToggleHideAllColumnsProps,
    allColumnsHidden,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        hiddenColumns: hiddenColumns,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns,
    useRowSelect
  )

  function processConditionsStyle(row: any) {
    const conditionStyle = rowStyleConditions?.filter((r) => r.condition(row)).pop()
    return Boolean(conditionStyle) ? `bg-${conditionStyle?.style.backgroundColor}` : ''
  }

  function processTDConditionsStyle(name: string, row: any) {
    const conditionStyle = rowStyleConditions?.filter((r) => r.condition(name, row)).pop()
    return Boolean(conditionStyle) ? `bg-${conditionStyle?.style.backgroundColor}` : ''
  }

  function downloadPDF() {
    try {
      const validColumns: Column[] = getValidColumnsExport()
      const validData: string[][] = getValidDataExport(validColumns)
      savePDFFile(
        [validColumns.map((c) => c.Header?.toString()!)],
        validData,
        tableExportProps?.pdf?.fileName ?? 'base_dados'
      )
      if (tableExportProps?.pdf?.onSuccess) tableExportProps.pdf.onSuccess()
    } catch (error) {
      if (tableExportProps?.pdf?.onFailure) tableExportProps.pdf.onFailure()
    }
  }

  function copyToClipboard() {
    try {
      const validColumns: Column[] = getValidColumnsExport()
      const validData: string[][] = getValidDataExport(validColumns)
      const csvContent =
        validColumns.map((c) => c.Header?.toString()).join('\t') +
        '\n' +
        validData.map((d) => d.join('\t')).join('\n')
      navigator.clipboard.writeText(csvContent)
      if (tableExportProps?.copy?.onSuccess) tableExportProps.copy.onSuccess()
    } catch (error) {
      if (tableExportProps?.copy?.onFailure) tableExportProps.copy.onFailure()
    }
  }

  function downloadCSV() {
    try {
      const validColumns: Column[] = getValidColumnsExport()
      const validData: string[][] = getValidDataExport(validColumns)
      saveCSVFile(
        validColumns.map((c) => c.Header?.toString()!),
        validData,
        tableExportProps?.csv?.fileName ?? 'base_dados'
      )
      if (tableExportProps?.csv?.onSuccess) tableExportProps.csv.onSuccess()
    } catch (error) {
      if (tableExportProps?.csv?.onFailure) tableExportProps.csv.onFailure()
    }
  }

  function downloadExcel() {
    try {
      const validColumns: Column[] = getValidColumnsExport()
      const validData: string[][] = getValidDataExport(validColumns)

      const headers = validColumns.map((item) => item.Header!.toString())
      const data: {}[] = []

      validData.map((item, i) => {
        const dataObject: any = {}

        const values = item.map((item) => item)

        values.map((item, index) => {
          dataObject[headers[index]] = item
        })

        data.push(dataObject)
      })

      const sheetData: SheetData[] = [
        {
          sheetName: tableExportProps?.excel?.sheetName ?? 'base_dados',
          data,
        },
      ]

      saveExcelFile(sheetData, `${tableExportProps?.excel?.fileName ?? 'base_dados'}.xlsx`)
      if (tableExportProps?.csv?.onSuccess) tableExportProps.csv.onSuccess()
    } catch (error) {
      if (tableExportProps?.excel?.onFailure) tableExportProps.excel.onFailure()
    }
  }

  function getValidColumnsExport() {
    return visibleColumns.filter(
      (c) => c.id !== 'action' && exportIgnoreColumns.indexOf(c.id) < 0 && c.Header !== 'Foto'
    )
  }

  function getValidDataExport(validColumns: Column[]) {
    const validData: string[][] = []
    for (let row of flatRows.filter((r) => r.depth === 0).map((r) => r.values)) {
      validData.push(validColumns.map((c) => row[c.id ?? '']?.value ?? row[c.id ?? '']))
    }
    return validData
  }

  function getOnlyFlatColumns(columns: TableColumn[], flatColumns: TableColumn[]): TableColumn[] {
    for (let column of columns) {
      if (!column.subcolumns) {
        flatColumns.push(column)
      } else {
        getOnlyFlatColumns(column.subcolumns, flatColumns)
      }
    }
    return flatColumns
  }

  function build() {
    const hiddenColumns: string[] = []
    const ignoreExport: string[] = []
    const flatColumns = getOnlyFlatColumns(tableColumns, [])
    for (let column of flatColumns) {
      if (column.hidden) hiddenColumns.push(column.accessor!)
      if (column.exportIgnore) ignoreExport.push(column.accessor!)
    }

    setHiddenColumns(hiddenColumns)
    setExportIgnoreColumns(ignoreExport)
  }

  const getColorTableRow = (row: any) => {
    let color = '#fff'

    // if (row.temPendencia && row.temPendenciaSincronizacao) {
    //   color = 'linear-gradient(90deg, rgba(242,166,94,.18) 33%, rgba(241, 65, 108, .18) 66%)'
    // } else if (row.temPendencia) {
    //   color = 'rgba(241, 65, 108, 0.18)'
    // } else if (row.temPendenciaSincronizacao) {
    //   color = 'rgba(242,166,94,0.18)'
    // }

    return color
  }

  const getToggleSelectedHandler = (id: string, isSelect: boolean) => {
    if ( !setRowSelect ) return;

    if ( isSelect ) {
      setRowSelect( (prev: any) => [...prev, id] );
    }
    else {
      setRowSelect( (prev: any) => prev.filter((codigo: string) => codigo != id) );
    }
  } 

  useEffect(() => {
    build()
  }, [])

  return (
    <div data-test='tableData' className={clsx(bordered && 'border border-secondary p-4 mt-4')}>
      <TableHeader bordered={bordered}>
        <h3 className='card-title align-items-start flex-column'>
          {customTitle ? (
            customTitle
          ) : (
            <span className='card-label fw-bold fs-3 '>{tableTitle}</span>
          )}
        </h3>
        <div className='d-flex'>
          {switchFilter && (
            <div style={{marginRight: 10}}>
              <Switch checked={switchFilter.value} onChange={(e) => switchFilter.onChange(e)}>
                {switchFilter.label}
              </Switch>
            </div>
          )}

          {searchable && (
            <SearchInput
              className=''
              height={43}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder={searchPlaceholder}
            />
          )}
          {hideColumnsButton && (
            <Menu
              title='Colunas exibidas'
              buttonProps={{icon: 'eye', fill: false, color: 'primary', tooltip: 'Colunas'}}
            >
              <ShowHideComponent
                allColumns={allColumns}
                setHiddenColumns={setHiddenColumns}
                getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                hiddenColumns={hiddenColumns}
              />
            </Menu>
          )}
          {exportButton && (
            <Menu
              title='Exportar tabela'
              buttonProps={{icon: 'file-up', fill: false, color: 'primary', tooltip: 'Exportar'}}
            >
              <MenuItem onClick={downloadPDF} icon='document' title='PDF' />
              <MenuItem onClick={copyToClipboard} icon='fonticon-stats' title='Copiar' />
              <MenuItem onClick={downloadCSV} icon='chart-simple' title='CSV' />
              <MenuItem onClick={downloadExcel} icon='copy' title='Excel' />
            </Menu>
          )}

          {getData && (
            <div className='card-toolbar'>
              <Button
                tooltip='Recarregar'
                icon='arrows-circle'
                fill={false}
                color='primary'
                onClick={() => getData()}
              />
            </div>
          )}

          {filters && (
            <div className='card-toolbar'>
              <Filtro
                filtrosAvançados={
                  <UserFilter
                    cleanFilters={cleanFilters!}
                    applyFilters={applyFilters!}
                    filter={filters}
                  />
                }
              />
            </div>
          )}
        </div>
      </TableHeader>

      <div className='d-flex flex-stack mb-4'>
        <div className='d-flex flex-stack gap-3'>
          {createDataButton && (
            <div className='card-toolbar'>
              <Button icon='plus' data-test='bTableNew' onClick={onCreateData}>
                Novo
              </Button>
            </div>
          )}
          {importDataButton && (
            <div className='card-toolbar'>
              <Button icon='file-down' onClick={onImportData}>
                Importar
              </Button>
            </div>
          )}
          {importPlanningButton && (
            <div className='card-toolbar'>
              <Button icon='file-down' onClick={onImportPlanning}>
                Importar Planejamentos
              </Button>
            </div>
          )}
          {importPostButton && (
            <div className='card-toolbar'>
              <Button icon='file-down' onClick={onImportPosts}>
                Importar Postos
              </Button>
            </div>
          )}

          {buttons.map((item) => (
            <div className='card-toolbar'>
              <Button icon={item.icon} onClick={item.onClick}>
                {item.label}
              </Button>
            </div>
          ))}
        </div>
        <div className='d-flex flex-stack gap-3'></div>
      </div>
      {/* table-responsive */}
      <div style={{minHeight: 200}} className='table-responsive'>
        {isLoading ? (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
              top: 0,
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span className='indicator-progress' style={{display: 'block'}}>
              <span className='spinner-border spinner-border-xl align-middle ms-2'></span>
            </span>
          </div>
        ) : (
          <table
            {...getTableProps({
              className: clsx('table table-bordered align-self-center', {
                'table-hover': Boolean(subtable),
              }),
            })}
          >
            <thead>
              {headerGroups.map((headerGroup) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps({className: `fw-bold `})}>
                    {isCheckbox &&
                      <th
                        scope='col'
                        className={`text-nowrap`}
                        style={{backgroundColor: '#eee'}}
                      >

                      </th>
                    }
                    {headerGroup.headers.map((column) => {
                      const color =
                        columns.filter(
                          (item) =>
                            item.Header?.toString().toLocaleLowerCase() ===
                            column.Header?.toString().toLocaleLowerCase()
                          //@ts-ignore
                        )[0]?.color || false

                      return (
                        <th
                          scope='col'
                          {...column.getHeaderProps({
                            ...column.getSortByToggleProps(),
                            className: `text-nowrap bg-${columnStyle[column.Header?.toString()!]}`,
                          })}
                          style={{backgroundColor: color ? '#eee' : ''}}
                        >
                          <div className='d-flex align-items-center justify-content-center'>
                            {column.render('Header') as any}
                            <Icon
                              className={clsx({'d-none': !column.isSorted})}
                              icon={column.isSortedDesc ? 'arrow-down' : 'arrow-up'}
                            />
                          </div>
                          {!Boolean(column.columns) && (
                            <div
                              {...column.getResizerProps()}
                              // className={`resizer p-2 bg-primary ${column.isResizing ? 'isResizing' : ''}`}
                              // style={{ display: 'inline-block', width: '10px', height: '100%', position: 'absolute', right: '0', top: '0', transform: 'translateX(50%)', zIndex: 1, touchAction: 'none' }}
                            />
                          )}
                        </th>
                      )
                    })}
                  </tr>
                )
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page
                .filter((r) => r.depth === 0)
                .map((row, i) => {
                  let codigo = row.original ? (row.original as ColumnGeneric).codigo : String(i);
                  prepareRow(row)
                  return (
                    <>
                      <tr
                        {...row.getRowProps({className: processConditionsStyle(row)})}
                        style={{
                          background: getColorTableRow(page[i].original),
                        }}
                      >
                        {isCheckbox && (
                          <td
                            data-tooltip-id='my-tooltip-1'
                            className='align-middle text-center'
                          >
                            <IndeterminateCheckbox 
                              onChange={getToggleSelectedHandler}
                              codigo={codigo}
                              disable={conditionDisabledSelect ? conditionDisabledSelect(codigo) : false}
                            />
                          </td>
                        )}

                        {row.cells.map((cell) => {
                          //@ts-ignore
                          const errors = page[i]?.original?.errors

                          function contemPalavra(object: object = {}, palavra: string) {
                            const array = Object.keys(object)
                            const expressaoRegular = new RegExp(`\\b${palavra}\\b`, 'i')

                            for (let i = 0; i < array.length; i++) {
                              if (expressaoRegular.test(array[i])) {
                                return true
                              }
                            }
                            return false
                          }

                          return (
                            <>
                              <td
                                data-tooltip-id='my-tooltip-1'
                                data-tooltip-content={
                                  //@ts-ignore
                                  errors
                                    ? //@ts-ignore
                                      errors[cell.column.id]
                                    : ''
                                }
                                {...cell.getCellProps({
                                  className: `align-middle ${
                                    contemPalavra(
                                      //@ts-ignore
                                      errors,
                                      cell.column.id
                                    )
                                      ? 'bg-danger text-white font-weight-bold text-center'
                                      : 'text-center'
                                  }`,
                                })}
                                onClick={() =>
                                  cell.column.id !== 'action' && row.toggleRowExpanded()
                                }
                              >
                                {/*  @ts-ignore */}
                                {cell.render('Cell')}
                              </td>
                            </>
                          )
                        })}
                      </tr>
                      {Boolean(subtable) && row.isExpanded ? (
                        <tr>
                          <td colSpan={visibleColumns.length}>
                            <SubTable
                              columns={subtable!.columns}
                              data={row.subRows.map((r) => r.values)}
                              title={subtable!.title}
                            />
                          </td>
                        </tr>
                      ) : null}
                    </>
                  )
                })}
            </tbody>
          </table>
        )}
      </div>
      {paginate && (
        <div className='d-flex flex-stack mt-4'>
          <div className='d-flex flex-stack gap-4'>
            <Select
              defaultValue={{label: 'Mostrar 5', value: 5}}
              hideExclusion
              setSelectedOption={(newOption: any) => {
                setPageSize(newOption.value)

                if (pagesTotal) {
                  searchParams.set('pageSize', newOption.value)
                  searchParams.set('pageNumber', '1')

                  setSearchParams(searchParams)
                }
              }}
              isSearchable={false}
              options={[
                {
                  label: 'Mostrar 5',
                  value: 5,
                },
                {
                  label: 'Mostrar 10',
                  value: 10,
                },
                {
                  label: 'Mostrar 15',
                  value: 15,
                },
                {
                  label: 'Mostrar 20',
                  value: 20,
                },
                {
                  label: 'Mostrar 50',
                  value: 50,
                },
              ]}
            />
            <span className='text-muted fw-semibold '>
              {pagesTotal
                ? `Mostrando página ${pageNumber} de ${pagesTotal}`
                : `Mostrando página ${pageIndex + 1} de ${pageCount}`}
            </span>
          </div>
          <Pagination
            canNext={pagesTotal ? pageNumber < pagesTotal : canNextPage}
            canPrev={pagesTotal ? pageNumber > 1 : canPreviousPage}
            currentPage={pagesTotal ? pageNumber : pageIndex + 1}
            pageCount={pagesTotal ? pagesTotal : pageCount}
            onGoPage={(page) => {
              gotoPage(page - 1)

              if (pagesTotal) {
                searchParams.set('pageNumber', page.toString())
                setSearchParams(searchParams, {replace: false})
              }
            }}
          />
        </div>
      )}
      <ReactTooltip id='my-tooltip-1' place='top' />
    </div>
  )
}

export interface IGetToggleHiddenProps {
  checked: boolean
  title: string
  style: {
    cursor: string
  }
}

type IColumn = Column & {
  hidden: boolean
}

interface ColumnGeneric {
  codigo: string
}
