/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {ReactNode, useState, useEffect} from 'react'

import Button from 'src/app/components/Button'

import {
  DataHomologationResponse,
  GetHomologation,
  UpdateHomologation,
} from 'src/app/repositories/Atendimentos'

import ValidacaoModel from 'src/app/model/Validacao/Validacao'

import {Buttons, CardBody, CardContainer, Container, LinkStyled, Map} from './styles'
import {useAlert} from 'src/app/components/Alert/Alert'
import AnimatedModal from 'src/app/components/AnimatedModal'
import {Loader} from '@googlemaps/js-api-loader'
import {getImageFromBucket} from 'src/app/utils/API'
import {useService} from 'src/app/hooks/useService'
import {formatCnpj} from 'src/app/utils/Formats'
import ImageModal from '../../../Card/components/ImageModal'

type Props = {
  data?: ValidacaoModel
  setShowHomologacaoModal: React.Dispatch<React.SetStateAction<boolean>>
}

const Card = ({
  title,
  children,
  footer,
}: {
  title: string
  children: ReactNode
  footer?: ReactNode
}) => {
  return (
    <CardContainer>
      <div className='cardHeader'>
        <h3 className='card-title'>{title}</h3>
      </div>
      <div className='cardBody'>{children}</div>
      {footer && <div className='cardFooter'>{footer}</div>}
    </CardContainer>
  )
}

const HomologacaoModal = ({setShowHomologacaoModal, data}: Props) => {
  const [info, setInfo] = useState<DataHomologationResponse | null>(null)

  const {setServices} = useService()

  const [openZoomFotoPR, setOpenZoomFotoPR] = useState<boolean>(false)
  const [openZoomFotoQA, setOpenZoomFotoQA] = useState<boolean>(false)

  const homoData = {
    codigoAtendimento: data?.codigoAtendimento!,
    cnpj: data?.pontoColeta.cnpj!,
  }

  const handleCloseModal = () => {
    setShowHomologacaoModal(false)
  }

  const handleUpdate = async () => {
    try {
      const res = await UpdateHomologation(homoData)

      if (res) {
        useAlert('Ponto Homologado com sucesso', 'Success')
        handleCloseModal()

        setServices((oldState) => {
          const newState = oldState.map((item) => {
            if (item.codigoAtendimento === data?.codigoAtendimento) {
              const index = item.fotosColeta.findIndex((item) => item.siglaFoto === 'PR')

              item.fotosColeta[index].fileNameHomologada = res.atendimentoHomologado.fotoPR
            }
            return item
          })

          return newState
        })
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  async function initMap() {
    if (info) {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_TOKEN!,
        version: 'weekly',
        libraries: ['places'],
        language: 'pt',
        region: 'BR',
      })

      const mapOptions = {
        center: {lat: 0, lng: 0},
        zoom: 5,
        mapId: 'TRIAD_MAP_ID',
      }

      const {Map} = (await loader.importLibrary('maps')) as google.maps.MapsLibrary

      const {AdvancedMarkerElement} = (await loader.importLibrary(
        'marker'
      )) as google.maps.MarkerLibrary

      const positionPosto = {
        lat: info.cadastroPosto.latitudePosto,
        lng: info.cadastroPosto.longitudePosto,
      }

      const positionService = {
        lat: info.atendimentoAtual.latitudeQA,
        lng: info.atendimentoAtual.longitudeQA,
      }

      const parser = new DOMParser()

      const pinSvgString = (color: string) =>
        `<svg height="32px" width="32px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 293.334 293.334" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="5.280012"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path style="fill:#${color};" d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878 c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212 c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084 c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358 c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307 C198.98,120.938,175.559,144.358,146.667,144.358z"></path> <circle style="fill:#${color};" cx="146.667" cy="90.196" r="21.756"></circle> </g> </g> </g></svg>`

      const map = new Map(document.getElementById('map-rota') as HTMLElement, mapOptions)
      const bounds = new google.maps.LatLngBounds()

      if (
        positionPosto.lat &&
        positionPosto.lat !== 0 &&
        positionPosto.lng &&
        positionPosto.lng !== 0
      ) {
        new AdvancedMarkerElement({
          map: map,
          position: positionPosto,
          title: 'Posto',
          content: parser.parseFromString(pinSvgString('EA4335'), 'image/svg+xml').documentElement,
        })

        bounds.extend(positionPosto)
      }

      if (
        positionService.lat &&
        positionService.lat !== 0 &&
        positionService.lng &&
        positionService.lng !== 0
      ) {
        new AdvancedMarkerElement({
          map: map,
          position: positionService,
          title: 'Atendimento',
          content: parser.parseFromString(pinSvgString('0747A6'), 'image/svg+xml').documentElement,
        })

        bounds.extend(positionService)
      }

      map.fitBounds(bounds)
    }
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await GetHomologation(homoData)

        setInfo(response!)
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    }

    getData()
  }, [])

  useEffect(() => {
    if (info) initMap()
  }, [info])

  return (
    <AnimatedModal
      onClose={() => setShowHomologacaoModal(false)}
      headerTitle='Análise do posto homologado'
      footer={
        <Buttons>
          <Button onClick={handleCloseModal} color='danger' title='Cancelar'>
            Cancelar
          </Button>
          <Button onClick={handleUpdate} color='primary' title='Atualizar'>
            Atualizar
          </Button>
        </Buttons>
      }
    >
      <Container>
        <aside>
          {info && (
            <Card title='Ponto Homologado'>
              <CardBody>
                <div className='image'>
                  <p className='image-title'>Panorâmica</p>
                  <img
                    src={getImageFromBucket(info?.atendimentoHomologado?.fotoPR)}
                    onClick={() => setOpenZoomFotoPR(true)}
                  />
                </div>
                <div className='image'>
                  <p className='image-title'>Quadro de aviso</p>
                  <img
                    src={getImageFromBucket(info?.atendimentoHomologado?.fotoQA)}
                    onClick={() => setOpenZoomFotoQA(true)}
                  />
                </div>
              </CardBody>
            </Card>
          )}

          <Card title='Mapa'>
            <Map />
          </Card>
        </aside>
        <aside>
          {info && (
            <Card
              title='Pesquisa Atual'
              footer={
                <span>
                  <b>Acurácia Mínima:</b>{' '}
                  {info && info.atendimentoAtual.distanciaMinima?.toFixed(0)} M
                </span>
              }
            >
              <CardBody>
                <div className='image'>
                  <p className='image-title'>Panorâmica</p>
                  <img
                    src={getImageFromBucket(info.atendimentoAtual.fotoPR)}
                    onClick={() => setOpenZoomFotoPR(true)}
                  />
                </div>
                <div className='image'>
                  <p className='image-title'>Quadro de aviso</p>
                  <img
                    src={getImageFromBucket(info.atendimentoAtual.fotoQA)}
                    onClick={() => setOpenZoomFotoQA(true)}
                  />
                </div>
              </CardBody>
            </Card>
          )}

          <Card title='Dados cadastrais'>
            {info && (
              <>
                <p>
                  <b>Razão Social:</b> {info.cadastroPosto.razaoSocial}
                </p>
                <p>
                  <b>Cnpj: </b>
                  {formatCnpj(info.cadastroPosto.cnpj)}
                </p>
                <p>
                  <b>Bandeira: </b>
                  {info.cadastroPosto.bandeira}
                </p>
                <p>
                  <b>Endereço: </b>
                  {info.cadastroPosto.endereco}
                </p>
                <p>
                  <b>Cidade: </b>
                  {info.cadastroPosto.cidade}
                </p>
                <p>
                  <b>UF: </b>
                  {info.cadastroPosto.uf}
                </p>
                <p>
                  <b>Bairro: </b>
                  {info.cadastroPosto.bairro}
                </p>
                <p>
                  <b>Autorização:</b> {info.cadastroPosto.autorizacao}
                </p>
                <p>
                  <b>Lat / Long: </b>
                  <LinkStyled
                    rel='noreferrer'
                    target='_blank'
                    href={`https://www.google.com/maps?q=${info.cadastroPosto.latitudePosto},${info.cadastroPosto.longitudePosto}`}
                  >
                    {info.cadastroPosto.latitudePosto}, {info.cadastroPosto.longitudePosto}
                  </LinkStyled>
                </p>
              </>
            )}
          </Card>
        </aside>
        {openZoomFotoPR && (
          <ImageModal
            titleFirstFoto='Foto PR (Atual)'
            firstFoto={getImageFromBucket(info?.atendimentoAtual.fotoPR)}
            titleSecondFoto='Foto PR (Homologada)'
            secondFoto={getImageFromBucket(info?.atendimentoHomologado?.fotoPR)}
            onclose={() => setOpenZoomFotoPR(false)}
          />
        )}
        {openZoomFotoQA && (
          <ImageModal
            titleFirstFoto='Foto QA (Atual)'
            firstFoto={getImageFromBucket(info?.atendimentoAtual.fotoQA)}
            titleSecondFoto='Foto QA (Homologada)'
            secondFoto={getImageFromBucket(info?.atendimentoHomologado?.fotoQA)}
            onclose={() => setOpenZoomFotoQA(false)}
          />
        )}
      </Container>
    </AnimatedModal>
  )
}

export default HomologacaoModal
