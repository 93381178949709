import * as Yup from 'yup'

import {IntlShape} from 'react-intl'

export const cidadeValidation = (intl: IntlShape) =>
  Yup.object().when(['uf'], ([uf], field) => {
    if (uf.value === null) {
      return field.shape({
        label: Yup.string().required('Selecione uma UF'),
        value: Yup.string().required('Selecione uma UF'),
      })
    }

    return field
  })
