import styled from 'styled-components'

import {animated} from '@react-spring/web'

export const Container = styled.div`
  display: flex;

  align-items: center;

  cursor: pointer;

  height: 25px;

  .click-wrapper {
    display: flex;

    align-items: center;

    gap: 5px;
  }

  span {
    font-weight: 600;
    font-family: Inter;
  }

  button {
    background-color: transparent;

    margin-left: auto;
  }
`

export const Radio = styled(animated.div)`
  width: 16px;
  height: 16px;

  border-radius: 50%;

  border: 1px solid black;

  padding: 3px;

  display: flex;
`
export const Check = styled(animated.div)`
  flex: 1;

  background-color: #2196f3;

  border-radius: 50%;
`
