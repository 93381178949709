import {HTMLAttributes} from 'react'
import {Container} from './styles'

const ContainerBox = ({
  children,
  title,
  onClick = () => {},
  hasButton = false,
  containerRef,
  ...rest
}: Props) => {
  return (
    <Container>
      <header>
        {title}
        {hasButton && (
          <button data-test='bAdicionarPonto' onClick={onClick}>
            Adicionar pontos de coleta
          </button>
        )}
      </header>
      <div {...rest} className='main' ref={containerRef}>
        {children}
      </div>
    </Container>
  )
}

type Props = HTMLAttributes<HTMLDivElement> & {
  onClick?: () => void
  hasButton?: boolean
  containerRef?: any
}

export default ContainerBox
