import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  width: 100%;

  gap: 10px;
  padding: 10px;

  select {
    padding: 10px;

    width: 100%;

    margin-bottom: 20px;

    outline: none;

    border: 1px solid black;

    border-radius: 5px;
  }

  .esquerda,
  .direta {
    flex: 1;
  }
`
