import * as Yup from 'yup'
import {IntlShape} from 'react-intl'

import {cpf} from 'cpf-cnpj-validator'

export const optionsValue = {label: '', value: ''}

export const nomeValidation = (intl: IntlShape) =>
  Yup.string().required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Nome'}))

export const agenciaValidation = (intl: IntlShape) =>
  Yup.string()
    .max(6, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Agência', max: '6'}))
    .when(['banco', 'conta'], ([banco, conta], field) => {
      if ((banco && banco.value !== undefined) || (conta && conta !== undefined)) {
        return field.required(
          intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Agência'})
        )
      }

      return field
    })

export const contaValidation = (intl: IntlShape) =>
  Yup.string().max(
    12,
    intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Conta', max: '12'})
  )

export const emailValidation = (intl: IntlShape) =>
  Yup.string()
    .email(intl.formatMessage({id: 'AUTH.VALIDATION.EMAIL'}))
    .min(3, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH'}, {name: 'Email', min: '3'}))
    .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Email', max: '50'}))
    .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Email'}))

export const rgValidation = (name: string, intl: IntlShape) =>
  Yup.string().when('rg', (rg, field) => {
    if (rg[0] !== undefined) {
      return field.required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name}))
    }
    return field
  })

export const cpfValidation = (intl: IntlShape) =>
  Yup.string()
    .test('cpf é válido', 'CPF inválido', (value) => {
      if (value !== undefined) {
        return cpf.isValid(value!)
      } else {
        return true
      }
    })
    .when('grupoAcesso', (grupoAcesso, field) => {
      if (grupoAcesso[0].value === 'PESQUISADOR') {
        return field.required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'CPF'}))
      }
      return field
    })

export const cidadeValidation = (intl?: IntlShape) =>
  Yup.object().when(['cidadeUf', 'grupoAcesso'], ([cidadeUf, grupoAcesso], field) => {
    if (cidadeUf.value === undefined && grupoAcesso.value === 'PESQUISADOR') {
      return field.shape({
        label: Yup.string().required('Selecione uma UF'),
        value: Yup.string().required('Selecione uma UF'),
      })
    }
    return field
  })

export const grupoAcessoValidation = (intl: IntlShape) =>
  Yup.object().shape({
    label: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Grupo de acesso'})
    ),
    value: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Grupo de acesso'})
    ),
  })
