import styled from 'styled-components'

type TableProps = {
  width?: number
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;

  flex: 1;

  .lists {
    display: flex;

    overflow-y: auto;

    border: 1px solid #1b1b1b;
  }

  .list-container {
    display: flex;
    flex: 2;
    flex-direction: column;
  }

  .list-container.right {
    border-left: 1px solid #1b1b1b;
  }

  .list-name {
    padding: 10px;

    font-weight: bold;

    border-bottom: 1px solid #1b1b1b;
  }

  .list {
    padding: 10px;

    display: flex;
    justify-content: center;

    border-bottom: 1px solid #1b1b1b;
  }
`

export const Table = styled.table<TableProps>`
  width: 100%;

  th,
  td {
    padding: 8px;
    border: 1px solid #1b1b1b;
  }

  .input {
    padding: 12px;
  }

  th {
    /* width: ${({width = 50}) => width}%; */
  }

  td {
    /* cursor: pointer; */
  }

  tr {
    transition: all 0.48s;
    width: 100%;
  }

  input {
    width: 100%;
  }

  .buttons {
    display: flex;
    gap: 8px;

    button {
      background-color: transparent;
      padding: 5px;
    }
  }
`
