import styled, {css} from 'styled-components'

type trprops = {index: number; borderColor: string}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 5px;

  button {
    background-color: transparent;
    display: flex;
    align-items: center;
    text-decoration: underline;
    gap: 3px;
    font-size: 14px;
    font-family: Inter;
  }
`

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;

  border-radius: 5px;

  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

  tr {
    border-bottom: 1px solid rgba(22, 22, 22, 0.4);
  }

  tr:last-child {
    border: 0;
    border-radius: 5px;
  }

  th,
  td {
    padding: 5px;
    flex: 1;
    /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .title {
    background-color: #364150;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  th {
    color: white;
    border-color: white;
  }

  tr {
    display: flex;
    flex: 1;
  }

  td {
    padding: 3px;
  }
`

export const Tr = styled.tr<trprops>`
  background-color: white;
  ${({index}) =>
    index % 2 === 0 &&
    css`
      background-color: #f7fbff;
    `};
  td:last-child {
    border: 0;
  }

  input {
    font-size: 18px !important;
  }

  select {
    flex: 1;

    height: 100%;

    border-radius: 5px;

    border-color: ${({borderColor}) => borderColor};
  }

  .values {
    font-weight: bold;
    font-family: Roboto, sans-serif;
  }
`
