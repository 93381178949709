import {CSSProperties} from 'react'

import PhoneInput, {PhoneInputProps} from 'react-phone-input-2'

import Label from '../Label'
import {FormikProps} from 'formik'
import {IinitialValues} from 'src/app/pages/planejamento-execucao/usuario/components/UserCreationModal'

const InputPhone = ({
  formik,
  fieldName = 'telefone',
  label = 'Telefone',
  inputStyle,
  ...rest
}: Props) => {
  return (
    <>
      <Label>{label}</Label>
      <PhoneInput
        disableCountryCode
        country={'br'}
        specialLabel=''
        placeholder='99999-9999'
        inputClass='bordered'
        inputStyle={{height: 43.56, width: '100%', ...inputStyle}}
        {...formik.getFieldProps(fieldName)}
        onChange={(value: any) => {
          formik.setFieldValue(fieldName, value)
        }}
        {...rest}
      />
      {formik && formik.touched[fieldName] && formik.errors[fieldName] && (
        <div className='fv-plugins-message-container'>
          {/* @ts-ignore */}
          <span role='alert'>{formik.errors[fieldName]}</span>
        </div>
      )}
    </>
  )
}

type Props = PhoneInputProps & {
  formik: FormikProps<IinitialValues>
  fieldName?: IFieldname
  label?: string
  inputStyle?: CSSProperties | undefined
}

type IFieldname = keyof IinitialValues

export default InputPhone
