import { ReactNode } from 'react'
import { Link as ReactLink } from 'react-router-dom'

export default function Link({ navigateTo, color, children }: Props) {
 return <>
  <ReactLink to={navigateTo} className={`link-${color}`}>
   {children}
  </ReactLink>
 </>
}

type Props = {
 navigateTo: string,
 color: string,
 children: ReactNode
}