export default function Label({children, required = false}: Props) {
  return (
    <label className='form-label fs-6 fw-bolder text-dark'>
      {children} {required && <span style={{color: 'red'}}>*</span>}
    </label>
  )
}

interface Props {
  children: string
  required?: boolean
}
