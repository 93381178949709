import styled, {css} from 'styled-components'

type Props = {
  selected: boolean
}

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  justify-content: center;
  align-items: center;

  width: 100%;

  gap: 10px;

  padding: 20px;

  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
`

export const ItemPhotoContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;

  border: 3px solid #2b2b2b;

  border-radius: 5px;

  cursor: zoom-in;

  width: 250px;

  span {
    font-weight: Inter;
    font-weight: bold;
    font-size: 16px;
  }

  img {
    object-fit: contain;
    width: 100%;

    transition: all 0.15s;

    border-bottom: 3px solid #2b2b2b;
  }

  transition: all 0.15s;

  ${({selected}) =>
    selected
      ? css`
          border-color: #0e99ff;

          img {
            border-color: #0e99ff;
          }
        `
      : css`
          border-color: #2b2b2b;

          img {
            border-color: #2b2b2b;
          }
        `}
`

export const ModalFooter = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;

  margin-top: auto;

  .buttons {
    display: flex;
    gap: 20px;
  }
`

export const ModalHeader = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  button {
    position: absolute;
    right: 4px;
    top: 0;
    background-color: transparent;
  }

  span {
    color: #1b1b1b;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 300;
    font-family: 'Poppins';
    font-size: 18px;
    text-align: center;

    margin: 0 8px;
  }
`
