import styled from 'styled-components'

type Props = {
  hasTwoColumns?: boolean | undefined
}

export const Container = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({hasTwoColumns}) => (hasTwoColumns ? '1fr 1fr' : 'auto')};
  grid-gap: 32px;
`

export const CardFoto = styled.div`
  position: 'relative';
  width: 100%;
  height: 100%;
  overflow: 'hidden';
  background-color: #f1f1f1;
  padding: 8px 0;

  h1 {
    text-align: center;
  }
`

export const Tools = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #f1f1f1;
  padding: 4px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  button {
    background-color: transparent;
    border-radius: 4px;

    &:hover {
      background-color: #d3d3d3;
    }
  }
`
export const ContainerFoto = styled.div`
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`
