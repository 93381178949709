/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {useState, KeyboardEvent, SetStateAction, Dispatch} from 'react'

import ModalAlert from './ModalAlert'

import {DataUpdateNote} from '../repositories/Anotacao'

import AnotacaoModel from '../model/Anotacao/Anotacao'
import {useAlert} from './Alert/Alert'
import NoteItem from './NoteItem'
import Modal from './Modal'
import Lottie from 'react-lottie'

type Props = {
  setShowAnotacoes: Function
  showAnotacoes: boolean
  anotacoes: AnotacaoModel[]
  handleCreateNote: (mensagemAnotacao: string) => Promise<void>
  handleDeleteNote: () => Promise<void>
  handleUpdateNote: (DataUpdateNote: DataUpdateNote) => Promise<void>
  setCodigo: Dispatch<SetStateAction<number>>
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: require('src/app/assets/animations/note.json'),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export default function Anotacoes({
  showAnotacoes,
  setShowAnotacoes,
  anotacoes,
  handleCreateNote,
  handleDeleteNote,
  handleUpdateNote,
  setCodigo,
}: Props) {
  const [mensagemAnotacao, setMensagemAnotacao] = useState('')
  const [showRemovalAlert, setShowRemovalAlert] = useState(false)

  const clearInputMessage = () => setMensagemAnotacao('')

  const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      try {
        await handleCreateNote(mensagemAnotacao)
        clearInputMessage()
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    }
  }

  return (
    <>
      <ModalAlert
        title={'Você está prestes a deletar uma anotação!'}
        content={`Tem certeza que deseja deletar essa anotação? Essa ação não pode ser desfeita.`}
        onConfirm={async () => {
          try {
            await handleDeleteNote()
          } catch (error: any) {
            useAlert(error.message, 'Error')
          } finally {
            setShowRemovalAlert(false)
          }
        }}
        onShow={showRemovalAlert}
        onHide={() => setShowRemovalAlert(false)}
        danger
      />

      <Modal
        title={{message: 'Anotações'}}
        open={showAnotacoes}
        handleClose={() => setShowAnotacoes(false)}
        customFooter={
          <div className='input-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Digite sua anotação aqui.'
              value={mensagemAnotacao}
              onChange={(e) => setMensagemAnotacao(e.target.value)}
              onKeyDown={handleKeyDown}
            />

            <div className='input-group-prepend'>
              <button
                className='input-group-text'
                onClick={async () => {
                  try {
                    await handleCreateNote(mensagemAnotacao)
                    clearInputMessage()
                  } catch (error: any) {
                    useAlert(error.message, 'Error')
                  }
                }}
              >
                <i className='bi bi-send fs-1'></i>
              </button>
            </div>
          </div>
        }
      >
        {anotacoes.length === 0 && (
          <>
            <p
              style={{
                fontFamily: 'Roboto',
                textAlign: 'center',
                fontSize: 18,
                margin: 0,
                marginBottom: -50,
              }}
            >
              Adicione a primeira anotação
            </p>
            <Lottie options={defaultOptions} height={220} width={220} />
          </>
        )}
        {anotacoes.map((anotacao) => {
          return (
            <NoteItem
              anotacao={anotacao}
              setShowRemovalAlert={setShowRemovalAlert}
              setCodigo={setCodigo}
              handleUpdateNote={handleUpdateNote}
            />
          )
        })}
      </Modal>
    </>
  )
}
