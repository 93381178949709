import styled, {css} from 'styled-components'
import {animated} from '@react-spring/web'

type ContainerProps = {
  isWarning: boolean
}

export const Container = styled.div<ContainerProps>`
  .header {
    display: flex;
    border: none;
    padding: 0 !important;

    .header-row span {
      display: flex;
      flex: 1;

      justify-content: center;

      border-top-left-radius: 5px;

      background-color: #384752;
      color: white;

      padding: 5px;

      font-family: 'Inter';
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .header-row {
    display: flex;
    align-items: center;

    width: 100%;

    svg {
      margin: 0 10px;
      cursor: pointer;
    }
  }

  .pulse {
    animation: redPulse 2s infinite;
  }
  .red {
    background-color: #f3565d !important;
    color: white !important;
  }

  .between {
    justify-content: space-between;
    z-index: 9999999;

    .distance {
      font-weight: bold;
      font-size: 16px;
    }

    ${({isWarning}) =>
      isWarning &&
      css`
        background-color: #f89c32;
        animation: orangePulse 2s infinite;
        color: white;
      `}

    padding: 0 10px;
    margin: 1px 0;

    font-family: 'Inter';
    font-size: 14px;
  }
`

type ActionsProps = {
  orientation: 'left' | 'right'
}

export const Actions = styled(animated.div)<ActionsProps>`
  position: absolute;

  display: flex;
  flex-direction: column;

  background-color: #fbfbfb;

  border-radius: 5px;

  padding: 4px;

  width: 100px;

  ${({orientation}) =>
    orientation === 'left'
      ? css`
          right: 40px;
          top: -5px;
        `
      : css`
          right: -110px;
          top: 0;
        `}

  z-index: 700;

  border: 1px solid #000;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &::before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg viewBox='-2.4 -2.4 28.80 28.80' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='%23000000' stroke-width='0.288'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round' stroke='%23CCCCCC' stroke-width='2.544'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M13.9783 5.31877L10.7683 8.52877L8.79828 10.4888C7.96828 11.3188 7.96828 12.6688 8.79828 13.4988L13.9783 18.6788C14.6583 19.3588 15.8183 18.8688 15.8183 17.9188V12.3088V6.07877C15.8183 5.11877 14.6583 4.63877 13.9783 5.31877Z' fill='%23F4F6FA'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    position: absolute;

    width: 50px;
    height: 50px;

    ${({orientation}) =>
      orientation === 'left'
        ? css`
            transform: rotate(180deg);
            right: -32px;
            top: -10px;
          `
        : css`
            left: -32px;
            top: -10px;
          `}

    pointer-events: none;
  }

  p {
    padding: 10px;

    margin: 0;

    cursor: pointer;

    transition: all 0.16s;

    color: #636a77;

    border-radius: 5px;
  }

  p:hover {
    background-color: #f1f2f4;
    color: #1b1b1b;
  }
`
