import styled from 'styled-components'

export const Container = styled.div`
  z-index: 1000;

  position: fixed;
  bottom: 30px;
  left: 30px;
  right: 30px;

  width: 100%;
  max-width: 350px;
  max-height: calc(100vh - 50px);

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin-left: auto;

  @media (max-width: 680px) {
    align-items: center;
  }
`
