/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react'

import {useAlert} from 'src/app/components/Alert/Alert'

import {PontosColeta} from 'src/app/repositories/Route'

import {CloseIcon} from 'src/app/utils/Icons'

import {Container} from './styles'

interface Props {
  item: PontosColeta
  itemSelected: number

  dragHandleProps: any
  commonProps: {
    handleRemoveFromList: (codigo: number) => void
    selectedMapMarker: number
    pontosColetaList: PontosColeta[]
    onListChange: (newList: PontosColeta[]) => void
  }
}

const PontoColetaItem = ({item, itemSelected, dragHandleProps, commonProps}: Props) => {
  const currentOrder = item.ordem
  const [value, setValue] = useState(currentOrder)
  const {handleRemoveFromList, selectedMapMarker, pontosColetaList, onListChange} = commonProps

  const inputRef = useRef<HTMLInputElement>(null)

  const scale = itemSelected * 0.05 + 1
  const shadow = itemSelected * 15 + 1

  const changeInputValue = (newValue: number) => {
    setValue(newValue <= 0 ? 0 : newValue)
  }

  const handleSwipePosition = (newValue: number) => {
    // Verifica se as posições são válidas
    if (
      currentOrder < 1 ||
      newValue < 1 ||
      currentOrder > pontosColetaList.length ||
      currentOrder === newValue
    ) {
      return pontosColetaList
    } else if (newValue > pontosColetaList.length) {
      useAlert('A posição digitada é maior que a quantidade de pontos da rota.', 'Info')
      inputRef.current?.select()

      return pontosColetaList
    }

    // Clona o array para evitar modificá-lo diretamente
    const novaLista = [...pontosColetaList]

    // Obtém os objetos nas posições especificadas
    const item1 = novaLista[currentOrder - 1]
    const item2 = novaLista[newValue - 1]

    // Troca todos os atributos, exceto a ordem
    novaLista[currentOrder - 1] = {...item2, ordem: item1.ordem}
    novaLista[newValue - 1] = {...item1, ordem: item2.ordem}

    onListChange(novaLista)
  }

  useEffect(() => {
    changeInputValue(currentOrder)
  }, [pontosColetaList])

  return (
    <div
      id={String(item.codigo)}
      style={{
        transform: `scale(${scale})`,
        boxShadow: `rgba(47, 47, 47, 0.3) 0px ${shadow}px ${2 * shadow}px 0px`,
      }}
    >
      <Container selected={selectedMapMarker === currentOrder}>
        <div className='info'>
          <input
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSwipePosition(value)
            }}
            ref={inputRef}
            className='order'
            onChange={(e) => changeInputValue(parseInt(e.target?.value) || currentOrder)}
            value={value}
            onFocus={(e) => inputRef.current?.select()}
          />
          <div className='drag-container' {...dragHandleProps}>
            <p className='id'>Identificador: {item.identificadorPontoColeta}</p>
            <p>{`${item.endereco}, ${item.bairro}, ${item.cep}, ${item.cidade}/${item.uf}`}</p>
          </div>
        </div>
        <button onClick={() => handleRemoveFromList(item.codigo)}>
          <CloseIcon color='#DC3545' size={20} />
        </button>
      </Container>
    </div>
  )
}

export default PontoColetaItem
