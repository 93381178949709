import {Badge, BadgeContainer} from './style'
import {IMonitoramento, ILiberadoPorUf} from './Type'

export class MonitoramentoModel {
  private _codigoUsuario: string
  private _nomeUsuario: string
  private _totalLiberacao: number
  private _totalAprovado: number
  private _totalReprovado: number
  private _totalPorUf: ILiberadoPorUf[]

  constructor({
    codigoUsuario,
    nomeUsuario,
    totalPorUf,
    totalLiberacao,
    totalAprovado,
    totalReprovado,
  }: IMonitoramento) {
    this._codigoUsuario = codigoUsuario
    this._nomeUsuario = nomeUsuario
    this._totalPorUf = totalPorUf
    this._totalLiberacao = totalLiberacao
    this._totalAprovado = totalAprovado
    this._totalReprovado = totalReprovado
  }

  get codigoUsuario() {
    return this._codigoUsuario
  }
  get nomeUsuario() {
    return this._nomeUsuario
  }
  get totalLiberacao() {
    return this._totalLiberacao
  }
  get totalAprovado() {
    return this._totalAprovado
  }
  get totalReprovado() {
    return this._totalReprovado
  }
  get totalPorUf() {
    return (
      <BadgeContainer>
        {this._totalPorUf.map((item) => (
          <Badge>
            <span>{item.uf}</span>
            <span>{item.quantidade}</span>
          </Badge>
        ))}
      </BadgeContainer>
    )
  }
}
