import React, {RefObject} from 'react'

import {Checkbox} from '@mui/material'

import {DislikeIcon, LikeIcon, PencilIcon} from 'src/app/utils/Icons'

import {Container} from './styles'

type ColorButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string
  role:
    | 'like'
    | 'dislike'
    | 'check'
    | 'payment_approved'
    | 'payment_reproved'
    | 'default_like'
    | 'default_dislike'
  checked?: boolean
  inputRef?: RefObject<HTMLInputElement> | null
  contentContainerStyle?: React.CSSProperties
}

const ColorButton = ({
  title,
  role,
  checked = false,
  inputRef = null,
  contentContainerStyle = {},

  ...rest
}: ColorButtonProps) => {
  const getCorrectColor = () => {
    let color = ''

    if (role === 'check') color = '#2196f3'
    if (role === 'dislike') color = '#CC4166'
    if (role === 'like') color = '#32D27C'
    if (role === 'payment_approved') color = '#32D27C'
    if (role === 'payment_reproved') color = '#CC4166'
    if (role === 'default_like') color = '#808080'
    if (role === 'default_dislike') color = '#808080'

    return color
  }
  return (
    // @ts-ignore
    <Container
      className='fade-in-out'
      style={{backgroundColor: getCorrectColor(), ...contentContainerStyle}}
      {...rest}
    >
      <span>{title}</span>

      {role === 'dislike' && <DislikeIcon size={20} />}
      {role === 'like' && <LikeIcon size={20} />}
      {(role === 'payment_approved' || role === 'payment_reproved') && (
        <PencilIcon size={role === 'payment_reproved' ? 20 : 0} />
      )}
      {role === 'default_like' && <LikeIcon size={20} />}
      {role === 'default_dislike' && <DislikeIcon size={20} />}
      {role === 'check' && (
        <Checkbox size='small' inputRef={inputRef} checked={checked} style={{padding: 0}} />
      )}
    </Container>
  )
}

export default ColorButton
