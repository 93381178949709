import {Dispatch, SetStateAction} from 'react'
import {useSpring} from '@react-spring/web'

import clsx from 'clsx'

import {Check, Container, Radio} from './styles'
import {ArrowUpIcon} from 'src/app/utils/Icons'

type Props = {
  column?: boolean
  label: string | number
  value: string | number
  currentValue: string | number
  setOpen?: Dispatch<SetStateAction<boolean>>
  renderArrow?: boolean
  open?: boolean
  onClick: (value: string | number) => void
}

const AnimatedRadio = ({
  label,
  value,
  onClick,
  currentValue,
  renderArrow = false,
  setOpen,
  open,
  column = false,
}: Props) => {
  const isChecked = currentValue === value

  const scaleAnimation = {
    scale: isChecked ? 1 : 0,
    config: {
      tension: 300,
      duration: 150,
    },
  }

  const backgroundAnimation = {
    borderColor: isChecked ? '#2196f3' : '#000',
    config: {
      tension: 300,
      duration: 150,
    },
  }

  const styles = useSpring(scaleAnimation)
  const radioStyles = useSpring(backgroundAnimation)

  return (
    <Container>
      <div
        style={{flexDirection: column ? 'column' : 'row'}}
        className='click-wrapper'
        onClick={() => onClick(value)}
      >
        <Radio style={radioStyles}>
          <Check style={styles} />
        </Radio>

        <span>{label}</span>
      </div>

      {renderArrow && setOpen && (
        <button
          className={`${clsx({open: open})} accordion`}
          onClick={() => setOpen((oldState) => !oldState)}
        >
          <ArrowUpIcon size={20} />
        </button>
      )}
    </Container>
  )
}

export default AnimatedRadio
