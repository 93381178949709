import {ReactNode, useState} from 'react'

import {useSpring, animated} from '@react-spring/web'

import {Container} from './styles'

import {IconType} from 'react-icons/lib'
import {ArrowDownIcon} from 'src/app/utils/Icons'

const AnimatedArrow = animated(ArrowDownIcon)

const AccordionCollapseItem = ({title, children, initOpen = false, height, Icon}: Props) => {
  const [show, setShow] = useState(initOpen)

  const heightAnimation = {
    height: show ? height : 0,
    opacity: show ? 1 : 0,
    padding: show ? 16 : 0,

    config: {
      tension: 300,
      duration: 200,
    },
  }

  const rotateAnimation = {
    transform: show ? 'rotate(180deg)' : 'rotate(0deg)',

    config: {
      tension: 800,
      duration: 250,
    },
  }

  const styles = useSpring(heightAnimation)
  const arrowStyles = useSpring(rotateAnimation)

  return (
    <Container show={show}>
      <header onClick={() => setShow((oldState) => !oldState)}>
        <Icon size={30} />
        <p className='title'>{title}</p>
        <AnimatedArrow style={arrowStyles} className='accordion-arrow' size={20} />
      </header>

      <animated.main style={styles}>{children}</animated.main>
    </Container>
  )
}

type Props = {
  title: string
  children: ReactNode
  initOpen?: boolean
  height: number
  Icon: IconType
}

export default AccordionCollapseItem
