import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'

import {CardFoto, Container, ContainerFoto, Tools} from './styles'
import {ZoomInIcon, ZoomOutIcon, ZoomResetIcon} from 'src/app/utils/Icons'
import AnimatedModal from 'src/app/components/AnimatedModal'

type Props = {
  titleFirstFoto: string
  firstFoto: string
  titleSecondFoto?: string
  secondFoto?: string
  onclose: () => void
}

const ImageModal = ({titleFirstFoto, firstFoto, titleSecondFoto, secondFoto, onclose}: Props) => {
  return (
    <AnimatedModal
      hasFooter={false}
      headerTitle='Visualização de foto(s)'
      onClose={onclose}
      containerStyle={{padding: 8, width: 'fit-content', height: 'fit-content'}}
    >
      <Container hasTwoColumns={titleSecondFoto !== undefined && secondFoto !== undefined}>
        <TransformWrapper>
          {({zoomIn, zoomOut, resetTransform}) => (
            <CardFoto style={{order: 2}}>
              <h1>{titleFirstFoto}</h1>
              <Tools>
                <button>
                  <ZoomOutIcon size={26} onClick={() => zoomOut()} />
                </button>
                <button>
                  <ZoomResetIcon size={26} onClick={() => resetTransform()} />
                </button>
                <button>
                  <ZoomInIcon size={26} onClick={() => zoomIn()} />
                </button>
              </Tools>
              <TransformComponent>
                <ContainerFoto>
                  <img src={firstFoto} alt='' />{' '}
                </ContainerFoto>
              </TransformComponent>
            </CardFoto>
          )}
        </TransformWrapper>

        {secondFoto && (
          <TransformWrapper>
            {({zoomIn, zoomOut, resetTransform}) => (
              <CardFoto style={{order: 1}}>
                <h1>{titleSecondFoto}</h1>
                <Tools>
                  <button>
                    <ZoomOutIcon size={26} onClick={() => zoomOut()} />
                  </button>
                  <button>
                    <ZoomResetIcon size={26} onClick={() => resetTransform()} />
                  </button>
                  <button>
                    <ZoomInIcon size={26} onClick={() => zoomIn()} />
                  </button>
                </Tools>
                <TransformComponent>
                  <ContainerFoto>
                    <img src={secondFoto} alt='' />{' '}
                  </ContainerFoto>
                </TransformComponent>
              </CardFoto>
            )}
          </TransformWrapper>
        )}
      </Container>
    </AnimatedModal>
  )
}

export default ImageModal
