/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react'
import {Container, DatePickerContainer, TitleContainer} from './style'
import {DateRangePicker} from 'rsuite'
import {Button} from 'react-bootstrap'
import {useAlert} from 'src/app/components/Alert/Alert'
import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
import {DateRange} from 'rsuite/esm/DateRangePicker'
import {saveExcelFile, SheetData} from 'src/app/utils/Files'
import {DownloadExportacoes, IExportacao} from 'src/app/repositories/Exportacao'

export default function Exportacao() {
  const [selectedFirstDate, setSelectedFirstDate] = useState<Date>()
  const [selectedSecondDate, setSelectedSecondDate] = useState<Date>()
  const [dateFilters, setDateFilters] = useState({
    dataInicio: '',
    dataFim: '',
  })

  const setCorrectDateTitle = () => {
    let message = ''

    if (selectedFirstDate && selectedSecondDate) {
      let dOne = format(selectedFirstDate, 'dd/MM', {locale: ptBR})
      let dTwo = format(selectedSecondDate, 'dd/MM', {locale: ptBR})

      if (dOne === dTwo) {
        message = `Atendimentos do dia ${dOne}`
      } else {
        message = `Atendimentos entre ${dOne} e ${dTwo}`
      }
    }

    return message
  }

  const downloadData = async () => {
    try {
      if (selectedFirstDate && selectedSecondDate) {
        const res = await DownloadExportacoes(dateFilters.dataInicio, dateFilters.dataFim)

        if (res!.length > 0) {
          const dataExportacao = res!.map((item) => ({
            'IDENTIFICADOR PONTO': item.identificadorPonto,
            CNPJ: item.cnpj,
            'CODIGO EXTERNO': item.codigoExterno,
            FONTE: item.fonte,
            PESQUISADOR: item.pesquisador,
            'DATA COLETA': item.dataColeta,
            FORMULARIO: item.formulario,
            STATUS: item.status,
            'DATA LIBERACAO': item.dataLiberacao,
            'LIBERADO POR': item.liberadoPor,
            'ALTERADO NA VALIDACAO': item.alteradaNaValidacao,
            'PAGAMENTO APROVADO': item.pagamentoAprovado,
            'OBSERVAÇÃO DO PAGAMENTO': item.observacaoPagamento,
          }))

          const sheetData: SheetData[] = [
            {
              sheetName: 'atendimentos',
              data: dataExportacao,
            },
          ]
          saveExcelFile(
            sheetData,
            `atendimentos_de_${dateFilters.dataInicio}_a_${dateFilters.dataFim}.xlsx`
          )
        } else {
          useAlert('Sem atendimentos liberados durante a data escolhida', 'Info')
        }
      }
    } catch (error: any) {
      useAlert(error.getMessage, 'Error')
    }
  }

  return (
    <Container>
      <TitleContainer>
        <h1>Exportação de liberações</h1>
      </TitleContainer>

      <DatePickerContainer>
        <DateRangePicker
          placeholder='Selecione o periodo'
          onClean={() => {
            setSelectedFirstDate(undefined)
            setSelectedSecondDate(undefined)
          }}
          renderValue={(value) => {
            if (value) {
              setSelectedFirstDate(value[0])
              setSelectedSecondDate(value[1])
              return <span>{setCorrectDateTitle()}</span>
            }
          }}
          onChange={(value: DateRange | null) => {
            if (value) {
              setDateFilters({
                dataInicio: value[0].toISOString().slice(0, 10),
                dataFim: value[1].toISOString().slice(0, 10),
              })
            }
          }}
        />
        <Button
          disabled={!selectedFirstDate && !selectedSecondDate}
          color='primary'
          onClick={downloadData}
        >
          Gerar
        </Button>
      </DatePickerContainer>
    </Container>
  )
}
