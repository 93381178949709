import * as Yup from 'yup'
import {IntlShape} from 'react-intl'

export const nomeValidation = (intl: IntlShape) =>
  Yup.string().required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Nome'}))

export const pedidoValidation = (intl: IntlShape) =>
  Yup.object().shape({
    label: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Tipo Pedido'})
    ),
    value: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Tipo Pedido'})
    ),
  })
