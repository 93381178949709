import api from '../config'
import Erro from '../model/Erro/Erro'

import UsuarioModel from '../model/Usuario/Usuario'
import {IUser} from '../model/Usuario/Type'
import {creationUserData} from '../pages/planejamento-execucao/usuario/Type'
import Filtros from '../model/Usuario/Filtros'
import InitializeUsuario from '../model/Usuario/Initialize'

import {handlerResult} from '../utils/API'

export async function ImportFileUsuario(file: File, update: boolean) {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('editar', update + '')

  try {
    let response = await api.post('usuario/importarUsuariosXlsx', formData)
    const dataResponse: ValidacaoImportFileUsuarioResponse = await handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export const serializeUsersList = (users: IUser[]) => {
  const model = []

  for (const user of users) {
    model.push(new UsuarioModel(user))
  }

  return model
}

export const serializeUser = (user: InitializeUsuario) => {
  const {perfis, quantitativos, status, usuarios} = user
  const modelPesquisador = new InitializeUsuario(
    serializeUsersList(usuarios as unknown as IUser[]),
    quantitativos,
    perfis,
    status
  )

  return modelPesquisador
}

export async function getUsers(GetUsersData: GetUsersData) {
  try {
    const response = await api.post('usuario/listar', GetUsersData)
    const {
      bancos,
      estados,
      estadosCivis,
      generos,
      perfis,
      status,
      tiposContas,
      usuarios,
      quantitativos,
      centrosDeCusto,
      totalDePaginas,
    }: GetUsersResponse = await handlerResult(response.data)

    const userCreationSelectInfos = {
      bancos: bancos.map((item) => ({value: item.codigo, label: item.nome})),
      estados: estados.map((item) => ({value: item.codigo, label: item.codigo})),
      estadosCivis: estadosCivis.map((item) => ({value: item.codigo, label: item.nome})),
      generos: generos.map((item) => ({value: item.codigo, label: item.nome})),
      tiposContas: tiposContas.map((item) => ({value: item.codigo, label: item.nome})),
      perfis: perfis.map((item) => ({value: item.codigo, label: item.nome})),
    }

    return {
      usuarios: serializeUsersList(usuarios as any),
      perfis,
      status,
      userCreationSelectInfos,
      quantitativos,
      centrosDeCusto,
      totalDePaginas,
    }
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export const getUsersPerfil = async (filtros: Filtros) => {
  const response = await api.post('usuario/listar', filtros)
  const dataResponse = handlerResult(response.data)

  return dataResponse.perfis
}

export const getUserDetail = async (id: number | string) => {
  try {
    const response = await api.post('usuario/detalhes', {codigo: id})

    const dataResponse = handlerResult(response.data)

    return new UsuarioModel(dataResponse)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export const toogleUserActiveStatus = async (id: number | string, active: boolean) => {
  try {
    const response = await api.post(`usuario/atualizarSituacao`, {codigo: id, ativo: active})

    const result = response.data

    return new UsuarioModel(result)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export const CreateUser = async (creationUserData: creationUserData) => {
  try {
    const response = await api.post('usuario/add', creationUserData)

    const dataResponse = handlerResult(response.data)

    return new UsuarioModel(dataResponse)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export const updateUser = async (updateUserData: creationUserData) => {
  try {
    const response = await api.post('usuario/atualizar', updateUserData)

    const dataResponse = handlerResult(response.data)

    return new UsuarioModel(dataResponse)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export const GetUserQuantitative = async () => {
  try {
    const response = await api.get('usuario/quantitativos')

    const dataResponse: IQuantitativos = handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export type ValidacaoImportFileUsuarioResponse = {
  excelSucces: IUser | IUser[]
  headersError: HeadersError
  excelError: ExcelError
}

type ExcelError = {
  usuarios: Usuario[]
  cidadesValor: CidadesValor[]
}

type CidadesValor = {
  uf: string
  cidade: string
  valor: string
  cpfPesquisador: string
  cpfOuCnpjCentro: string
  errors: CidadesValorErrors
}

export type FormattedOptions = {
  label: string
  value: string
}

type HeadersError = {
  cidades_custo: any[]
  modelo_usuario: any[]
}

type CidadesValorErrors = {
  valor: string
}

type Usuario = {
  nome: string
  email: string
  perfil: string
  telefone: string
  cpf: string
  rg: string
  dataEmissao: string
  orgaoEmissor: string
  dataNascimento: string
  nacionalidade: string
  estadoCivil: string
  genero: string
  cep: string
  bairro: string
  cidade: string
  uf: string
  logradouro: string
  numero: string
  complemento: string
  codigoBanco: string
  tipoConta: string
  agencia: string
  conta: string
  chavePix: string
  errors: UsuarioErrors
}
type UsuarioErrors = {
  cpf: string
  email: string
}

export interface IUserCreationSelectInfos {
  bancos: FormattedOptions[]
  estados: FormattedOptions[]
  estadosCivis: FormattedOptions[]
  generos: FormattedOptions[]
  tiposContas: FormattedOptions[]
  perfis: FormattedOptions[]
}

export interface GetUsersResponse {
  usuarios: IUsers[]
  perfis: Options[]
  status: Status[]
  centrosDeCusto: ICentroDeCusto[]
  bancos: Options[]
  tiposContas: Options[]
  generos: Options[]
  estadosCivis: Options[]
  estados: Options[]
  quantitativos: IQuantitativos
  totalDePaginas: number
}

export interface IQuantitativos {
  ativos: number
  bloqueados: number
  expirados: number
  cancelados: number
  pesquisadoresPendentes: number
  pesquisadoresConcluidos: number
  total: number
}

export interface IUsers {
  codigo: string
  nome: string
  email: string
  dataCriacao: string
  dataUltimoAcesso: null | string
  telefone: null | string
  cpf: null | string
  rg: null | string
  dataEmissaoRg: null | string
  orgaoEmissorRg: null | string
  dataNascimento: null | string
  nacionalidade: null | string
  estadoCivil: null | string
  genero: null | string
  cep: null | string
  bairro: null | string
  cidade: null | string
  uf: null | string
  logradouro: null | string
  complemento: null | string
  numero: null | string
  banco: null | string
  tipoConta: null | string
  agencia: null | string
  conta: null | string
  chavePix: null | string
  primeiroAcesso: boolean
  quantidadeAcessos: number
  quantidadeTentativas: number
  imagem: null | string
  perfil: Options
  status: Status
  centroDeCusto: ICentroDeCusto | null
}

export interface ICentroDeCusto {
  codigo: number
  nomeFantasia: string
  razaoSocial: null | string
  nomeProprietario: string
  email: string
  telefone: null | string
  cpfOuCnpj: string
  totalPesquisadores: number
}

export type Options = {
  codigo: string
  nome: string
}

export type Status = Options & {
  ativo: boolean
}

export type GetUsersData = {
  situacao: string | number | null
  perfil: string | number | null
  numeroPagina: number
  tamanhoPagina: number
  pesquisadorPendente: boolean
}

export type ExcelUserResponse = {
  excelSuccess: {
    usuarios: ExcelUsuario[]
    cidadesValor: ExcelCidadeValor[]
  }

  headersError: any
}

export interface ExcelCidadeValor {
  uf: string
  cidade: string
  valor: string
  cpfPesquisador: string
  cpfOuCnpjCentro: string
  errors: string[]
}

export interface ExcelUsuario {
  nome: string
  email: string
  perfil: string
  telefone: string
  cpf: string
  rg: string
  dataEmissao: string
  orgaoEmissor: string
  dataNascimento: string
  nacionalidade: string
  estadoCivil: string
  genero: string
  cep: string
  bairro: string
  cidade: string
  uf: string
  logradouro: string
  numero: string
  complemento: string
  codigoBanco: string
  tipoConta: string
  agencia: string
  conta: string
  chavePix: string
  errors: any
}
