import styled, {css} from 'styled-components'

type QuickSidebarButtonProps = {
  color: string
}

type BlackPanelProps = {
  active: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px;

  position: fixed;
  top: 0;
  right: -300px;

  height: 100vh;

  width: 300px;

  background-color: #e4e6ef;

  z-index: 999;

  transition: all 0.4s;

  &.active {
    right: 0;
  }

  .header {
    display: flex;
    align-self: center;

    padding: 10px;

    border-bottom: 1px solid #ed8c94;

    font-size: 16px;
  }

  .content {
    display: flex;
    flex-direction: column;

    gap: 20px;

    justify-content: center;
    align-items: center;

    margin: 10px 0;
  }

  .buttons {
    width: 100%;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`

export const QuickSidebarButton = styled.button<QuickSidebarButtonProps>`
  background-color: ${({color}) => color};

  border: none;
  border-radius: 150px;

  color: white;

  padding: 10px;
`

export const BlackPanel = styled.div<BlackPanelProps>`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 998;

  transition: all 1s;

  display: none;
  visibility: hidden;

  ${({active}) =>
    active &&
    css`
      display: initial;
      visibility: visible;
    `}

  background-color: rgba(0, 0, 0, 0.4);
`
