import styled from 'styled-components'

export const Container = styled.div`
  background-color: #fff;

  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 4px;

  width: 100%;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 8px;

  .container-row {
    display: flex;
    gap: 0 5px;

    width: 100%;

    &.column {
      flex-direction: column;
    }
  }
`
