import {formatCnpj} from 'src/app/utils/Formats'
import {IPontoColeta} from './Types'

export default class PontoColetaModel {
  private _codigo: string
  private _identificador: string
  private _bairro: string
  private _bandeiraAuto: string
  private _bandeiraGlp: string
  private _cep: string
  private _uf: string
  private _cidade: string
  private _cnpj: string
  private _complemento: string
  private _endereco: string
  private _latitude: number
  private _longitude: number
  private _nomeFantasia: string
  private _numeroAnpAuto: string
  private _numeroAnpGlp: string
  private _precisao: number
  private _razaoSocial: string
  private _tipoExecucao: string

  constructor({
    codigo,
    identificador,
    bairro,
    bandeiraAuto,
    bandeiraGlp,
    cep,
    uf,
    cidade,
    cnpj,
    complemento,
    endereco,
    latitude,
    longitude,
    nomeFantasia,
    numeroAnpAuto,
    numeroAnpGlp,
    precisao,
    razaoSocial,
    tipoExecucao,
  }: IPontoColeta) {
    this._codigo = codigo
    this._identificador = identificador
    this._bairro = bairro

    this._bandeiraAuto = bandeiraAuto
    this._bandeiraGlp = bandeiraGlp

    this._cep = cep

    this._uf = uf
    this._cidade = cidade
    this._cnpj = cnpj
    this._complemento = complemento
    this._endereco = endereco
    this._latitude = latitude
    this._longitude = longitude
    this._nomeFantasia = nomeFantasia
    this._numeroAnpAuto = numeroAnpAuto
    this._numeroAnpGlp = numeroAnpGlp
    this._precisao = precisao
    this._razaoSocial = razaoSocial
    this._tipoExecucao = tipoExecucao
  }

  get codigo(): string {
    return this._codigo
  }

  set codigo(value: string) {
    this._codigo = value
  }

  get identificador(): string {
    return this._identificador
  }

  set identificador(value: string) {
    this._identificador = value
  }

  get bairro(): string {
    return this._bairro
  }

  set bairro(value: string) {
    this._bairro = value
  }

  get bandeiraAuto(): string {
    return this._bandeiraAuto
  }

  set bandeiraAuto(value: string) {
    this._bandeiraAuto = value
  }

  get bandeiraGlp(): string {
    return this._bandeiraGlp
  }

  set bandeiraGlp(value: string) {
    this._bandeiraGlp = value
  }

  get cep(): string {
    return this._cep
  }

  set cep(value: string) {
    this._cep = value
  }

  get uf(): string {
    return this._uf
  }

  set uf(value: string) {
    this._uf = value
  }

  get cnpj(): string {
    return formatCnpj(this._cnpj)
  }

  set cnpj(value: string) {
    this._cnpj = value
  }

  get complemento(): string {
    return this._complemento
  }

  set complemento(value: string) {
    this._complemento = value
  }

  get endereco(): string {
    return this._endereco
  }

  set endereco(value: string) {
    this._endereco = value
  }

  get latitude(): number {
    return this._latitude
  }

  set latitude(value: number) {
    this._latitude = value
  }

  get longitude(): number {
    return this._longitude
  }

  set longitude(value: number) {
    this._longitude = value
  }

  get nomeFantasia(): string {
    return this._nomeFantasia
  }

  set nomeFantasia(value: string) {
    this._nomeFantasia = value
  }

  get numeroAnpAuto(): string {
    return this._numeroAnpAuto
  }

  set numeroAnpAuto(value: string) {
    this._numeroAnpAuto = value
  }

  get numeroAnpGlp(): string {
    return this._numeroAnpGlp
  }

  set numeroAnpGlp(value: string) {
    this._numeroAnpGlp = value
  }

  get precisao(): number {
    return this._precisao
  }

  set precisao(value: number) {
    this._precisao = value
  }

  get razaoSocial(): string {
    return this._razaoSocial
  }

  set razaoSocial(value: string) {
    this._razaoSocial = value
  }

  get tipoExecucao(): string {
    return this._tipoExecucao
  }

  set tipoExecucao(value: string) {
    this._tipoExecucao = value
  }

  get cidade() {
    return this._cidade
  }

  set cidade(value: string) {
    this._cidade = value
  }
}
