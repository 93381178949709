import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  span {
    font-family: Poppins;
    color: #1b1b1b;

    font-size: 16px;
  }

  .info {
    padding: 5px;
  }

  .pix {
    border: 1px solid #1b1b1b;

    border-radius: 5px;

    margin-right: auto;

    cursor: pointer;
  }

  .value {
    margin-right: 8px;
  }

  .label {
    font-weight: bold;
  }

  .default {
    .value {
      font-style: italic;
      color: #888;
      font-size: 14px;
    }
  }
`
