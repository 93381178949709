import {Container} from './styles'

type Props = {
  label: string
  value: string | null | number
}

const Info = ({label, value}: Props) => {
  return (
    <Container>
      <span className='label'>{label}</span>
      <span className='value'>{value}</span>
    </Container>
  )
}

export default Info
