import {saveAs} from 'file-saver'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import Papa from 'papaparse'
import * as XLSX from 'xlsx'

export interface SheetData {
  sheetName: string
  data: {}[]
}

export async function saveExcelFile(sheetData: SheetData[], fileName: string) {
  const workbook = XLSX.utils.book_new()

  sheetData.forEach((sheet) => {
    const worksheet = XLSX.utils.json_to_sheet(sheet.data)
    XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName)

    const fields = Object.keys(sheet.data[0])

    worksheet['!cols'] = fields.map((item) => ({wch: Math.max(item.length + 10, 15)}))
  })

  const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})

  const excelBlob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  saveAs(excelBlob, fileName)
}

export function savePDFFile(head: string[][], body: string[][], fileName: string) {
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: [420, 297], // FOLHA A3
  })

  autoTable(doc, {
    theme: 'striped',
    head: head,
    body: body,
  })

  doc.save(`${fileName}.pdf`)
}

export function saveCSVFile(head: string[], body: string[][], fileName: string) {
  const csvContent = Papa.unparse([head, ...body], {
    delimiter: ';',
  })
  const blob = new Blob(['\ufeff' + csvContent], {type: 'text/csv;charset=utf-8;'})
  saveAs(blob, `${fileName}.csv`)
}
