/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useState} from 'react'

import {WithChildren} from 'src/_metronic/helpers'

import RotaModel from 'src/app/model/Rota/Rota'
import {DetailRouteResponse} from '../repositories/Route'

export const RouteContext = createContext<RouteContextProps>({} as RouteContextProps)

const RouteContextProvider: FC<WithChildren> = ({children}) => {
  const [routes, setRoutes] = useState<RotaModel[]>([])
  const [routeDetail, setRouteDetail] = useState<DetailRouteResponse>({} as DetailRouteResponse)

  const updateRoutes = (route: RotaModel) => {
    setRoutes((oldState) => {
      return oldState.map((item) => {
        if (item.codigo === route.codigo) {
          item = route
        }

        return item
      })
    })
  }

  const updateMultipleRoutes = (route: RotaModel[]) => {
    setRoutes((oldState) => {
      return oldState.map((item) => {
        const objNovo = route.find((obj) => obj.codigo === item.codigo)

        const res = objNovo ? objNovo : item

        return res
      })
    })
  }

  return (
    <RouteContext.Provider
      value={{routes, setRoutes, updateRoutes, routeDetail, setRouteDetail, updateMultipleRoutes}}
    >
      {children}
    </RouteContext.Provider>
  )
}

type RouteContextProps = {
  routes: RotaModel[]
  setRoutes: React.Dispatch<React.SetStateAction<RotaModel[]>>
  updateRoutes: (route: RotaModel) => void
  updateMultipleRoutes: (routes: RotaModel[]) => void
  routeDetail: DetailRouteResponse
  setRouteDetail: React.Dispatch<React.SetStateAction<DetailRouteResponse>>
}

export default RouteContextProvider
