import {Modal as BootstrapModal} from 'react-bootstrap'
import {ReactNode, useEffect, useState} from 'react'
import clsx from 'clsx'
import Button from '../Button'
import {MsgContainer} from './styles'
import {CloseIcon} from 'src/app/utils/Icons'

export default function Modal({
  open,
  title = {message: '', color: '#1b1b1b'},
  size,
  confirmText = 'Confirmar',
  backText = 'Cancelar',
  backButton,
  fullscreen: full,
  handleClose,
  onBack,
  onConfirm,
  children,
  footer,
  customFooter,
}: Props) {
  const [fullscreen, setFullscreen] = useState<string | true | undefined>()

  useEffect(() => {
    full ? setFullscreen(full) : setFullscreen(undefined)
  }, [full])

  return (
    <BootstrapModal
      tabIndex={-1}
      dialogClassName={clsx('modal-dialog modal-dialog-scrollable modal-dialog-centered', {
        [`mw-${size}px`]: Boolean(size),
      })}
      show={open}
      onHide={handleClose}
      fullscreen={fullscreen}
    >
      <MsgContainer color={title.color}>
        <div className='msg-header'>
          <span>{title.message}</span>
          <button onClick={handleClose}>
            <CloseIcon fill='#666666' size={20} />
          </button>
        </div>
        <div className='msg-info'>{children}</div>

        {footer && (
          <div className='msg-footer'>
            {backButton && (
              <Button color='primary' fill={false} onClick={onBack}>
                {backText}
              </Button>
            )}
            <Button color='primary' onClick={onConfirm}>
              {confirmText}
            </Button>
          </div>
        )}
        {!footer && customFooter && customFooter}
      </MsgContainer>
    </BootstrapModal>
  )
}

interface Props {
  open: boolean
  title: {
    message: string
    color?: string
  }
  size?: number
  fullscreen?: boolean
  confirmText?: string
  backButton?: boolean
  backText?: string
  children: ReactNode
  footer?: boolean
  customFooter?: ReactNode
  handleClose: () => void
  onConfirm?: () => void
  onBack?: () => void
}
