import ReactSelect from './ReactSelect'
import {ActionMeta, Props, DropdownIndicatorProps, components} from 'react-select'
import {KTIcon} from '../../../_metronic/helpers/components/KTIcon'
import Label from '../Label'
import {FormikValues} from 'formik'

export function Select(props: PropType) {
  const placeholder = props.placeholder || 'Selecione uma opção'
  const hideExclusion = props.hideExclusion || false
  const dataTest = props.dataTest

  const handleSelectAll = () => {
    const options: any = props.options
    if (options) {
      var allOptions: OptionType[] = []

      if (options.length > 0 && 'options' in options[0]) {
        options.forEach((option: GroupedOption) => {
          allOptions = allOptions.concat(option.options)
        })
      } else {
        allOptions = options
      }

      if (props.setSelectedOption) props.setSelectedOption(allOptions)
    }
  }

  const selectAllButton = (
    <>
      {props.isMulti ? (
        <button
          onClick={handleSelectAll}
          type='button'
          className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary d-flex justify-content-center align-items-center'
        >
          <KTIcon iconName='plus-square' className='fs-1 primary' />
        </button>
      ) : (
        !hideExclusion && (
          <button
            onClick={() => {
              if (props.formik)
                props.formik!.formikValues.setFieldValue(props.formik!.formikProp, {
                  value: null,
                  label: placeholder,
                })
              if (props.setSelectedOption)
                props.setSelectedOption({value: null, label: placeholder})
            }}
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary d-flex justify-content-center align-items-center'
          >
            <KTIcon iconName='cross-square' className='fs-1 primary' />
          </button>
        )
      )}
      <button
        type='button'
        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary d-flex justify-content-center align-items-center'
      >
        <KTIcon iconName='down' className='fs-1 primary' />
      </button>
    </>
  )

  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props} className='p-0'>
        {selectAllButton}
      </components.DropdownIndicator>
    )
  }

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: '6px',
      borderColor: props.error ? '#f1416c' : '#e1e3ea',
      minHeight: '43.56px',
      flex: 1,
      fontFamily: 'Poppins',
      color: '#5e6278',
      fontSize: '1.1rem',
      lineHeight: 1.5,
      zIndex: 0,
    }),
    option: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
      return {
        ...styles,
        color: isSelected ? '#fff' : '#5e6278',
        fontFamily: 'Poppins',
        fontSize: '1.1rem',
        lineHeight: 1.5,
      }
    },
    singleValue: (styles: any, {data}: any) => ({
      ...styles,
      color: '#5e6278',
      fontFamily: 'Poppins',
      fontSize: '1.1rem',
      lineHeight: 1.5,
    }),
  }

  if (props.formik) {
    return (
      <>
        {props.label && <Label required={props.required}>{props.label}</Label>}
        <ReactSelect
          id={dataTest}
          closeMenuOnSelect={!props.isMulti}
          placeholder={placeholder ? placeholder : 'Selecione uma opção'}
          styles={customStyles}
          components={{DropdownIndicator}}
          {...props.formik.formikValues.getFieldProps(props.formik.formikProp)}
          onChange={(newValue: unknown, actionMeta: ActionMeta<unknown>) => {
            props.formik!.formikValues.setFieldValue(props.formik!.formikProp, newValue)
          }}
          {...props}
        />
      </>
    )
  }

  return (
    <>
      {props.label && <Label>{props.label}</Label>}
      <ReactSelect
        id={dataTest}
        closeMenuOnSelect={!props.isMulti}
        placeholder={placeholder ? placeholder : 'Selecione uma opção'}
        styles={customStyles}
        components={{DropdownIndicator}}
        onChange={(newValue: unknown, actionMeta: ActionMeta<unknown>) => {
          if (props.setSelectedOption) props.setSelectedOption(newValue)
        }}
        {...props}
      />
    </>
  )
}

interface PropType extends Props {
  label?: string
  dataTest?: string
  hideExclusion?: boolean
  placeholder?: string
  setSelectedOption?: Function
  formik?: {
    formikProp: string
    formikValues: FormikValues
  }
  error?: boolean
}

export interface OptionType {
  value: string
  label: string
}

export interface GroupedOption {
  readonly label: string
  readonly options: readonly OptionType[]
}
