/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
// import { Container } from './styles';

import TablePainel from 'src/app/components/TablePainel'

import { useSearchParams } from 'react-router-dom'

import {
  TableColumn,
  TableExportProps,
  TableFilters,
  TableRowAction,
} from 'src/app/components/TablePainel/table-painel'
import { Dispatch, SetStateAction, useState } from 'react'
import Anotacoes from 'src/app/components/Anotacoes'
import {
  CreateNote,
  DataUpdateNote,
  DeleteNote,
  GetNotes,
  UpdateNote,
} from 'src/app/repositories/Anotacao'
import AnotacaoModel from 'src/app/model/Anotacao/Anotacao'
import OperationalEditModal from '../OperationalEditModal'
import HomologatedModal from '../HomologatedModal'
import AddClientModal from '../AddClientModal'
import { IModalAlert } from '../../../usuario/Usuarios'
import ModalAlert from 'src/app/components/ModalAlert'
import RemoveClientModal from '../RemoveClientModal'
import ImportModal, { errorMessage, excelModel } from 'src/app/components/ImportModal'
import { useAlert } from 'src/app/components/Alert/Alert'
import {
  DeleteFromBase,
  GetOrders,
  GetOrdersResponse,
  ValidacaoImportFileBaseOperacional,
  ValidacaoImportFileBaseOperacionalHeadersError,
} from 'src/app/repositories/OperationalBase'
import LastServiceModal from '../LastServiceModal'

const OperationalTable = ({
  cleanFilters,
  applyFilters,
  data,
  getData,
  filters,
  setData,
  columns,
  isLoading,
  pagesTotal,
}: Props) => {
  const [showNotesModal, setShowNotesModal] = useState(false)

  const [rowDetail, setRowDetail] = useState<any>({})

  const [showModalImport, setShowModalImport] = useState(false)

  const [orders, setOrders] = useState<GetOrdersResponse[]>([])

  const [modalAlert, setModalAlert] = useState<IModalAlert | undefined>(undefined)

  const [showOperationalEditModal, setShowOperationalEditModal] = useState(false)
  const [showHomologatedModal, setShowHomologatedModal] = useState(false)
  const [showAddClientModal, setShowAddClientModal] = useState(false)
  const [showRemoveClientModal, setShowRemoveClientModal] = useState(false)

  const [showLastServiceModal, setShowLastServiceModal] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const noRoute = searchParams.get('noRoute') === 'ativo' ? true : false

  const tableName = 'tabela_base_operacional'

  const IMPORT_EXCEL_MODEL: excelModel[] = [
    {
      sheetLabel: 'Base',
      sheetName: 'modelo_base_operacional',
      values: {
        CNPJ: '99.999.999/9999-99',
        CLIENTE: '*****',
        PEDIDO: '0/1',
        'CODIGO EXTERNO': '',
        STATUS: '',
      },
    },
  ]

  const handleRemoveData = (codigo: string) => {
    const action = async () => {
      try {
        const res = await DeleteFromBase({ codigoPontoColeta: codigo })

        if (res) {
          setOrders(res)
          setData((oldState) => oldState.filter((item) => item.codigo !== codigo))
          setModalAlert(undefined)
          useAlert(res, 'Success')
        }
      } catch (error: any) {
        useAlert(error.message)
      }
    }

    setModalAlert({
      title: 'Aviso!',
      content: `Ao confirmar o ponto de coleta será removido de toda execução e essa ação é irreversível. Deseja confirmar?`,
      action,
    })
  }

  const getOrders = async (codigo: string) => {
    try {
      const res = await GetOrders({ codigoPontoColeta: codigo })

      if (res) {
        setOrders(res)
      }
    } catch (error: any) {
      useAlert(error.message)
    }
  }

  const throwTableAction = async (
    action:
      | 'Anotar'
      | 'Exibir Foto Homologada'
      | 'Análisar últimos atendimentos'
      | 'Editar Pedidos'
      | 'Remover',
    row: any
  ) => {
    setRowDetail(row)
    switch (action) {
      case 'Análisar últimos atendimentos':
        handleOpenLastServiceModal()
        break
      case 'Anotar':
        setShowNotesModal(true)
        getAnotacoes(row.codigo)
        break
      case 'Exibir Foto Homologada':
        handleOpenHomologatedModal()

        break
      case 'Editar Pedidos':
        getOrders(row.codigo)
        setShowOperationalEditModal(true)
        break
      case 'Remover':
        handleRemoveData(row.codigo)
        break
      default:
        break
    }
  }

  const actions: TableRowAction[] = [
    {
      label: 'Anotar',
      condition: (row: any) => true,
      icon: 'notepad-edit',
      onClick: (row: any) => {
        throwTableAction('Anotar', row)
      },
    },
    {
      label: 'Exibir Foto Homologada',
      icon: 'picture',
      onClick: (row: any) => throwTableAction('Exibir Foto Homologada', row),
      condition: (row: any) => true,
    },
    {
      label: 'Análisar últimos atendimentos',
      icon: 'picture',
      onClick: (row: any) => throwTableAction('Análisar últimos atendimentos', row),
      condition: (row: any) => {
        const item = data.filter((item) => item.identificador === row.identificador)[0]
        return item.temUltimosAtendimentos
      },
    },
    {
      label: 'Editar Pedidos',
      icon: 'pencil',
      onClick: (row: any) => throwTableAction('Editar Pedidos', row),
      condition: (row: any) => true,
    },
    {
      label: 'Remover',
      icon: 'trash',
      onClick: (row: any) => throwTableAction('Remover', row),
      condition: (row: any) => true,
    },
  ]

  const tableExportProps: TableExportProps = {
    pdf: {
      fileName: tableName,
    },
    excel: {
      fileName: tableName,
      sheetName: tableName,
    },
    csv: {
      fileName: tableName,
    },
  }

  const handleCloseOperationalEditModal = () => setShowOperationalEditModal(false)

  const handleOpenHomologatedModal = () => setShowHomologatedModal(true)
  const handleCloseHomologatedModal = () => setShowHomologatedModal(false)

  const handleOpenAddClientModal = () => setShowAddClientModal(true)
  const handleCloseAddClientModal = () => setShowAddClientModal(false)

  const handleOpenRemoveClientModal = () => setShowRemoveClientModal(true)
  const handleCloseRemoveClientModal = () => setShowRemoveClientModal(false)

  const handleOpenModalImport = () => setShowModalImport(true)
  const handleCloseModalImport = () => setShowModalImport(false)

  const handleOpenLastServiceModal = () => setShowLastServiceModal(true)
  const handleCloseLastServiceModal = () => setShowLastServiceModal(false)

  const [codigo, setCodigo] = useState(0)
  const [anotacoes, setAnotacoes] = useState<AnotacaoModel[]>([])

  const handleCreateNote = async (mensagemAnotacao: string) => {
    try {
      const response = await CreateNote({
        codigoPontoColeta: rowDetail.codigo,
        descricao: mensagemAnotacao,
      })
      setAnotacoes((oldState) => {
        return [...oldState, response]
      })
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const handleDeleteNote = async () => {
    const index = anotacoes.findIndex((item) => item.codigo === codigo)
    const currentItem = anotacoes[index]

    try {
      setAnotacoes(anotacoes.filter((item) => item.codigo !== currentItem.codigo))

      await DeleteNote(codigo)

      useAlert('Anotação excluida com sucesso', 'Success')
    } catch (error: any) {
      useAlert(error.message, 'Error')

      setAnotacoes((oldState) => [...oldState, currentItem])
    }
  }

  const handleUpdateNote = async (DataUpdateNote: DataUpdateNote) => {
    try {
      let editAnotacao = await UpdateNote(DataUpdateNote)

      const updateList = anotacoes.map((anotacao: AnotacaoModel) =>
        anotacao.codigo === editAnotacao.codigo ? editAnotacao : anotacao
      )
      setAnotacoes(updateList)

      useAlert('Anotação atualizada com sucesso', 'Success')
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const getAnotacoes = async (codigo: string) => {
    try {
      let anotacoes = await GetNotes(codigo)
      setAnotacoes(anotacoes)
    } catch (error) {
      useAlert('Não foi possível listar as anotações.', 'Error')
    }
  }

  const handlerHeaderColumnErrors = (
    erros: ValidacaoImportFileBaseOperacionalHeadersError,
    name: string = 'pontos_de_coleta'
  ) => {
    let err: errorMessage = []

    if (erros.pedidos.length > 0) {
      const preFix = 'As seguintes colunas na aba de rotas não existem '
      const columns = erros.pedidos

      err.push({ columns, preFix })
    }

    return err
  }

  const onImportFile = async (file: File) => {
    try {
      const res = await ValidacaoImportFileBaseOperacional(file)

      const { excelError: excelerror, excelSucces, headersError } = res!

      const excel = excelSucces
      const excelError = excelerror

      if (excel.length > 0) {
        getData()
        useAlert(`${excel.length} Pedidos alterados com sucesso.`, 'Success')
      }

      if (excel.length > 0 && excelError.length === 0) {
        handleCloseModalImport()
      }

      const errorsMessages = handlerHeaderColumnErrors(headersError)

      if (excelError.length > 0) {
        let base = excelError

        useAlert(`Houve erro em ${excelError.length} planejamentos`, 'Error')

        const data = [
          { name: 'CNPJ', accessor: 'cnpj' },
          { name: 'CLIENTE', accessor: 'cliente' },
          { name: 'PEDIDO', accessor: 'pedido' },
          { name: 'CODIGO EXTERNO', accessor: 'codigoExterno' },
          { name: 'STATUS', accessor: 'status' },
        ]

        return {
          columns: [
            {
              name: 'Base',
              data,
            },
          ],
          excelSuccess: { Base: base },
          errorsMessages,
        }
      }

      return {
        columns: [],
        excelSuccess: {},
        errorsMessages,
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const filteredData = () => {
    const resultadosFiltrados = data.filter((item) => item.rotas === '')
    return noRoute ? resultadosFiltrados : data
  }

  return (
    <>
      {showNotesModal && (
        <Anotacoes
          showAnotacoes={showNotesModal}
          setShowAnotacoes={setShowNotesModal}
          handleCreateNote={handleCreateNote}
          handleDeleteNote={handleDeleteNote}
          setCodigo={setCodigo}
          handleUpdateNote={handleUpdateNote}
          anotacoes={anotacoes}
        />
      )}

      {showOperationalEditModal && (
        <OperationalEditModal
          rowDetail={rowDetail}
          orders={orders}
          setOrders={setOrders}
          updateData={getData}
          onClose={handleCloseOperationalEditModal}
        />
      )}

      {showHomologatedModal && (
        <HomologatedModal rowDetail={rowDetail} onClose={handleCloseHomologatedModal} />
      )}

      {showAddClientModal && (
        <AddClientModal onClose={handleCloseAddClientModal} getTableData={getData} />
      )}

      {showRemoveClientModal && (
        <RemoveClientModal onClose={handleCloseRemoveClientModal} getTableData={getData} />
      )}

      {showLastServiceModal && (
        <LastServiceModal rowDetail={rowDetail} onClose={handleCloseLastServiceModal} />
      )}

      {showModalImport && (
        <ImportModal
          title='importação múltipla de pedidos'
          modelFileName='modelo_base_operacional'
          excelModel={IMPORT_EXCEL_MODEL}
          onClose={handleCloseModalImport}
          onImportFile={onImportFile}
          sheets={['Base']}
          instructions={[
            'Os campos “CNPJ”, “CLIENTE” e “PEDIDO”  são obrigatórios;',
            'CNPJ formatado com os seus 14 dígitos (os caracteres são opcionais)',
          ]}
        />
      )}

      <TablePainel
        pagesTotal={pagesTotal}
        tableTitle='Tabela de Base Operacional'
        columns={columns}
        isLoading={isLoading}
        data={filteredData()}
        rowActions={actions}
        tableExportProps={tableExportProps}
        onImportData={handleOpenModalImport}
        importDataButton
        createDataButton={false}
        getData={getData}
        filters={filters}
        buttons={[
          {
            icon: 'add-item',
            label: 'Adição cliente',
            onClick: handleOpenAddClientModal,
          },
          {
            icon: 'eraser',
            label: 'Remoção cliente',
            onClick: handleOpenRemoveClientModal,
          },
        ]}
        applyFilters={applyFilters}
        cleanFilters={cleanFilters}
        searchPlaceholder='CNPJ'
        switchFilter={{
          label: 'Sem Rota',
          value: noRoute,
          onChange: () => {
            if (noRoute) {
              setSearchParams({ noRoute: 'falso' })
            } else {
              setSearchParams({ noRoute: 'ativo' })
            }
          },
        }}
      />

      <ModalAlert
        isLoading={false}
        title={modalAlert?.title}
        content={modalAlert?.content}
        onConfirm={modalAlert?.action}
        onShow={Boolean(modalAlert)}
        onHide={() => setModalAlert(undefined)}
      />
    </>
  )
}

export default OperationalTable

type Props = {
  data: any[]
  setData: Dispatch<SetStateAction<any[]>>
  getData: () => void
  cleanFilters: () => void
  applyFilters: () => void
  filters: TableFilters[]
  columns: TableColumn[]
  isLoading: boolean
  pagesTotal: number
}
