import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import 'rsuite/dist/rsuite-no-reset.min.css'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'

import {CustomProvider} from 'rsuite'
import zhCN from 'rsuite/locales/pt_BR'
import Alert from './components/Alert/Alert'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <CustomProvider locale={zhCN}>
        <I18nProvider>
          <LayoutProvider>
            <Outlet />
            <Alert />
            <MasterInit />
          </LayoutProvider>
        </I18nProvider>
      </CustomProvider>
    </Suspense>
  )
}

export {App}
