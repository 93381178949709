import {InputHTMLAttributes} from 'react'

export default function Checkbox({
  label,
  TypeSize = 'form-check-sm',
  value,
  keyHtmlFor,
  ...rest
}: Props) {
  return (
    <div className={`form-check form-check-custom form-check-solid ${TypeSize}`}>
      <input
        className='form-check-input border border-gray-700 border-2'
        value={value}
        type='checkbox'
        id={keyHtmlFor}
        {...rest}
      />
      <label className='form-check-label text-gray-900' htmlFor={keyHtmlFor}>
        {label}
      </label>
    </div>
  )
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  value: string
  keyHtmlFor: string
  TypeSize?: string
}
