import clsx from 'clsx'

export default function Pagination({onGoPage, canNext, canPrev, currentPage, pageCount}: Props) {
  return (
    <div className='d-flex flex-stack mt-4'>
      <ul className='pagination'>
        <li className={clsx('page-item previous', {disabled: !canPrev})}>
          <span role='button' className='page-link' onClick={() => onGoPage(1)}>
            <i className='previous'></i>
            <i className='previous'></i>
          </span>
        </li>
        <li className={clsx('page-item previous', {disabled: !canPrev})}>
          <span role='button' className='page-link' onClick={() => onGoPage(currentPage - 1)}>
            <i className='previous'></i>
          </span>
        </li>
        {canPrev && (
          <li className='page-item'>
            <span role='button' className='page-link' onClick={() => onGoPage(currentPage - 1)}>
              {currentPage - 1}
            </span>
          </li>
        )}
        <li className='page-item active'>
          <span role='button' className='page-link'>
            {currentPage}
          </span>
        </li>
        {canNext && (
          <li className='page-item'>
            <span role='button' className='page-link' onClick={() => onGoPage(currentPage + 1)}>
              {currentPage + 1}
            </span>
          </li>
        )}
        <li className={clsx('page-item next', {disabled: !canNext})}>
          <span role='button' className='page-link' onClick={() => onGoPage(currentPage + 1)}>
            <i className='next'></i>
          </span>
        </li>
        <li className={clsx('page-item next', {disabled: !canNext})}>
          <span role='button' className='page-link' onClick={() => onGoPage(pageCount)}>
            <i className='next'></i>
            <i className='next'></i>
          </span>
        </li>
      </ul>
    </div>
  )
}

interface Props {
  onGoPage: (page: number) => void
  pageCount: number
  currentPage: number
  canPrev: boolean
  canNext: boolean
}
