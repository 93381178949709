import api from '../config'
import {ISigla, fuelSigla} from '../model/Validacao/Type'
import Erro from '../model/Erro/Erro'
import {handlerResult} from '../utils/API'

export async function GetServicesQuantitative(dataInicio: string, dataFim: string) {
  try {
    const response = await api.post('validacao/quantitativos', {dataInicio, dataFim})
    const dataResponse: GetServicesQuantitativeResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}
export async function ImportRevalidationServices(file: File) {
  try {
    let formData = new FormData()
    formData.append('file', file)

    const response = await api.post('validacao/importarAtendimentosRevalidacaoXlsx', formData)
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetService(dataService: DataService) {
  try {
    const response = await api.post('validacao/listarAtendimentos', dataService)
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetFilters() {
  try {
    const response = await api.get('validacao/filtrosValidacao')
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateObservation(dataUpdate: DataUpdate) {
  try {
    const response = await api.post('validacao/atualizarObservacao', dataUpdate)
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function ValidateServices(dataApprove: DataApprove) {
  try {
    const response = await api.post('validacao/validarAtendimentos', dataApprove)
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdatePayment(DataUpdatePayment: DataUpdatePayment) {
  try {
    const response = await api.post('validacao/atualizarPagamento', DataUpdatePayment)
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetHomologation(DataHomologation: DataHomologation) {
  try {
    const response = await api.post('validacao/homologacao', DataHomologation)
    const dataResponse: DataHomologationResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateHomologation(DataHomologation: DataHomologation) {
  try {
    const response = await api.post('validacao/atualizarHomologacao', DataHomologation)
    const dataResponse: UpdateHomologationResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetQADetail(codigoAtendimento: number) {
  try {
    const response = await api.post('validacao/quadroAviso', {codigoAtendimento})
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetPayments() {
  try {
    const response = await api.get('validacao/listarFormaPagamento')
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetAlternativePhotos<T>(codigoAtendimento: number) {
  try {
    const response = await api.post('foto/fotografiaAlternativa', {codigoAtendimento})
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetAlternativePhotoType<T>(codigoAtendimento: number) {
  try {
    const response = await api.post('foto/buscarTipoFotoAtendimento', {codigoAtendimento})
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetServicesPhotos<T>(codigoAtendimento: number) {
  try {
    const response = await api.post('foto/buscarFotosAtendimento', {codigoAtendimento})
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateServicePrice<T>(UpdateServiceData: UpdateServiceData) {
  try {
    const response = await api.post('validacao/atualizarPreco', UpdateServiceData)
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function SwipeServicePhoto<T>(
  codigoFotoAtual: string | number,
  codigoFotoParaTroca: string | number
) {
  try {
    const response = await api.post('foto/trocarFotoAtendimento', {
      codigoFotoAtual,
      codigoFotoParaTroca,
    })
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateOccurrencePhoto<T>(
  DataUpdateOccurrencePhoto: DataUpdateOccurrencePhoto
) {
  try {
    const response = await api.post('foto/atualizarOcorrenciaFotografia', DataUpdateOccurrencePhoto)
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateSituationCPNJ<T>(DataUpdateSituationCPNJ: DataUpdateSituationCPNJ) {
  try {
    const response = await api.post('validacao/atualizarSituacaoCnpj', DataUpdateSituationCPNJ)
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateSituationExclusion<T>(
  DataUpdateSituationExclusion: DataUpdateSituationExclusion
) {
  try {
    const response = await api.post('validacao/solicitacaoExclusao', DataUpdateSituationExclusion)
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function RemoveAlternativeServicePhoto<T>(
  DataRemoveAlternativeServicePhoto: DataRemoveAlternativeServicePhoto
) {
  try {
    const response = await api.post(
      'foto/removerFotoAtendimento',
      DataRemoveAlternativeServicePhoto
    )
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetLastServices<T>(DataGetLastServices: DataGetLastServices) {
  try {
    const response = await api.post('validacao/buscarUltimosAtendimento', DataGetLastServices)
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function DuplicatePhoto<T>(DataDuplicatePhoto: DataDuplicatePhoto) {
  try {
    const response = await api.post('foto/duplicarFotoAtendimento', DataDuplicatePhoto)
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export type GetServicesQuantitativeResponse = {
  pendentes: number
  aprovados: number
  reprovados: number
  preReprovados: number
  revalidacao: number
}

export type UpdateHomologationResponse = {
  atendimentoHomologado: {
    fotoPR: string
    fotoQA: string
    latitudeQA: number
    longitudeQA: number
  }
  atendimentoAtual: {
    fotoPR: string
    fotoQA: string
    latitudeQA: number
    longitudeQA: number
    distanciaMinima: number
  }
  cadastroPosto: {
    razaoSocial: string
    cnpj: string
    endereco: string
    autorizacao: string
    nomeFantasia: string
    latitudePosto: number
    longitudePosto: number
  }
}

export type Atendimento = {
  fotoPR: string | null
  fotoQA: string | null
  latitudeQA: number
  longitudeQA: number
  distanciaMinima?: number
}

export type DataHomologationResponse = {
  atendimentoHomologado: Atendimento | null
  atendimentoAtual: Atendimento
  cadastroPosto: {
    razaoSocial: string
    cnpj: string
    endereco: string
    autorizacao: string
    bandeira: string
    bairro: string
    uf: string
    cidade: string
    latitudePosto: number
    longitudePosto: number
  }
}

export type DataDuplicatePhoto = {
  codigoFotoAtendimento: number
  tiposFotos: ISigla[]
}

export type DataGetLastServices = {
  codigoAtendimento: number
  quantidade: number
}
export type DataRemoveAlternativeServicePhoto = {
  codigoFotoAtendimento: number
  motivo: string
}
export type DataUpdateSituationExclusion = {
  codigoAtendimento: number
  motivo: string
  codigoSolicitacao: number | null
  cancelar: boolean
}

export type DataUpdateSituationCPNJ = {
  codigoAtendimento: number
  codigoSituacaoSelecionada: number
}
export type DataUpdateOccurrencePhoto = {
  codigoFotoAtendimento: number
  codigoOcorenciaSelecionada: number
}

export type DataService = {
  situacao?: string | null
  numeroPagina?: number | null
  dataInicio?: string | null
  dataFim?: string | null
  pesquisadores?: number[] | []
  formularios?: number[] | []
  clientes?: number[] | []
  ufs?: string[] | []
  grupos?: string[] | []
}

export type DataUpdate = {
  codigoAtendimento: number
  observacaoUm: string | null
  observacaoDois: string | null
}

export type DataApprove = {
  codigosAtendimentos: number[]
  observacao: string | null | undefined
  pagar: boolean
  isAprovar: boolean
}

export type DataUpdatePayment = {
  codigoAtendimento: number
  observacao: string | null | undefined
  pagar: boolean
}

export type DataHomologation = {
  codigoAtendimento: number
  cnpj: string
}

export type UpdateServiceData = {
  codigoAtendimento: number
  tipoCombustivel: fuelSigla
  tipoPagamento: string
  tipoFoto: string
  valor: number
}
