import api from '../config'
import Erro from '../model/Erro/Erro'
import {basePontosRes} from '../model/OperationalBase/Types'
import {handlerResult} from '../utils/API'

export async function GetOperationalBase(GetOperationalBaseData: GetOperationalBaseData) {
  try {
    const response = await api.post('baseOperacional/listar', GetOperationalBaseData)

    const dataResponse: basePontosRes = await handlerResult(response.data)

    const filters = {
      cidadesUf: dataResponse.filtros.cidadesUf.map((item) => ({value: item, label: item})),
      pedidosClientes: dataResponse.filtros.pedidosClientes.map((item) => ({
        value: item.codigo,
        label: item.nome,
      })),
      pesquisadores: dataResponse.filtros.pesquisadores.map((item) => ({
        value: item.codigo,
        label: item.nome,
      })),
    }

    return {
      basePontos: dataResponse.basePontos,
      dataTableColumns: dataResponse.dataTableColumns,
      filters,
      quantitativos: dataResponse.quantitativos,
      totalDePaginas: dataResponse.totalDePaginas,
    }
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetHomologatedPhoto(GetHomologatedPhotoData: GetHomologatedPhotoData) {
  try {
    const response = await api.post('baseOperacional/buscarFotoHomologada', GetHomologatedPhotoData)

    const dataResponse: GetHomologatedPhotoResponse[] = handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetOrders(GetOrdersData: GetOrdersData) {
  try {
    const response = await api.post('baseOperacional/detalhePedidos', GetOrdersData)

    const dataResponse: GetOrdersResponse[] = handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateOrders(UpdateOrdersData: UpdateOrdersData) {
  try {
    const response = await api.post('baseOperacional/atualizarPedidos', UpdateOrdersData)

    const dataResponse: GetOrdersResponse = handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetLastServices(GetLastServicesData: GetLastServicesData) {
  try {
    const response = await api.post('validacao/buscarUltimosAtendimentos', GetLastServicesData)

    const dataResponse = handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetClients() {
  try {
    const response = await api.get('baseOperacional/listarClientes')

    const dataResponse = handlerResult(response.data)

    return dataResponse.map((item: any) => ({label: item.nome, value: item.codigo}))
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function DeleteFromBase(DeleteFromBaseData: DeleteFromBaseData) {
  try {
    const response = await api.post('baseOperacional/removerPontoDaBase', DeleteFromBaseData)

    const dataResponse = handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function ValidacaoImportFileBaseOperacional(file: File) {
  let formData = new FormData()
  formData.append('file', file)

  try {
    let response = await api.post('baseOperacional/importarPedidosXlsx', formData)
    const dataResponse: ValidacaoImportFileBaseOperacionalResponse = await handlerResult(
      response.data
    )

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function CreateClient(createClientData: createClientData) {
  try {
    let response = await api.post('baseOperacional/criarCliente', createClientData)

    const dataResponse = await handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}
export async function RemoveClient(removeClientData: removeClientData) {
  try {
    let response = await api.post('baseOperacional/removerCliente', removeClientData)

    const dataResponse = await handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export type GetLastServicesData = {
  codigoPontoColeta: number
}

export type createClientData = {
  nome: string
  tipoPedido: string
}

export type removeClientData = {
  codigoCliente: number
}

export type ValidacaoImportFileBaseOperacionalResponse = {
  excelSucces: any
  headersError: ValidacaoImportFileBaseOperacionalHeadersError
  dataTableColumns: {name: string; accessor: string; hidden: boolean}[]
  excelError: {
    cnpj: string
    cliente: string
    pedido: string
    codigoExterno: string
    status: string
    errors: any
  }[]
}

export type ValidacaoImportFileBaseOperacionalHeadersError = {
  pedidos: string[]
}

export type DeleteFromBaseData = {
  codigoPontoColeta: string
}

export type GetOrdersData = {
  codigoPontoColeta: string
}

export type UpdateOrdersData = {
  codigoPontoColeta: string
  pedidos: {codigoCliente: number; codigoExterno: string; status: string}[]
}

export type GetOrdersResponse = {
  codigoCliente: number
  nomeCliente: string
  status: string
  codigoExterno: string
}

export type GetHomologatedPhotoData = {
  codigoPontoColeta: string
}

export type GetHomologatedPhotoResponse = {
  codigo: string
  tipoExecucao: 'AUTO' | 'GLP'
  fotoPR: string
  fotoQA: string
}
export type GetOperationalBaseData = {
  pedidoCliente: number | null
  codigoExterno: string | null
  cidadeUf: string | null
  pesquisador: string | null
  temAnotacao: boolean
  postosNaoHomologados: boolean
  numeroPagina: number
  tamanhoPagina: number
}
