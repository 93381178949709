import { HTMLAttributes, ReactNode } from "react";

export default function Accordion({ title, children, className, ...rest }: Props) {
  return <div className={`accordion ${className}`} {...rest}>
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button className="accordion-button bg-light-primary fw-semibold fs-6 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
          {title}
        </button>
      </h2>
      <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div className="accordion-body">
          <div className="d-flex flex-column">
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
}

interface Props extends HTMLAttributes<HTMLElement> {
  title: string
  children: ReactNode
  className?: string
}