import AnimatedModal from 'src/app/components/AnimatedModal'
import AddPontoColetaTable from '../AddPontoColetaTable'
import {useState} from 'react'
import {PontosColeta} from 'src/app/repositories/Route'

const AddPontoColetaModal = ({
  onClose,
  handleAddSelectedPontosColeta,
  info,
  pontosColetaList,
}: any) => {
  const [selectedData, setSelectedData] = useState<PontosColeta[]>(pontosColetaList)

  const handleSubmitModal = () => {
    onClose()
    handleAddSelectedPontosColeta(selectedData)
  }

  return (
    <AnimatedModal
      containerStyle={{maxWidth: 1500}}
      headerTitle='Adiconar pontos de coleta'
      footerTitle='Adicionar'
      onClose={onClose}
      onSubmit={handleSubmitModal}
    >
      <AddPontoColetaTable
        pontosColetaList={pontosColetaList}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        info={info}
      />
    </AnimatedModal>
  )
}

export default AddPontoColetaModal
