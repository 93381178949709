/* eslint-disable react-hooks/rules-of-hooks */
import clsx from 'clsx'

import {Table} from '../../../CostCenterBase/styles'
import TableRow from '../../../CostCenterBase/components/TableRow'

import {CopyIcon} from 'src/app/utils/Icons'

import {Container} from './styles'
import {useAlert} from 'src/app/components/Alert/Alert'
import {currencyFormatterBRL, formatCPF, formatCnpj, formatPhoneNumber} from 'src/app/utils/Formats'

const Values = ({data, table, isBlur = false, hidden}: any) => {
  const handleCopyPix = (isPix: boolean, value: string) => {
    if (isPix) {
      useAlert('Chave copiada', 'Info')
      navigator.clipboard.writeText(value)
    }
  }

  return (
    <Container>
      {data?.map((item: any) => {
        const isPix = item.label === 'Chave pix'
        const isCPFCNPJ = item.label === 'CPF/CNPJ'
        const defaultValue = item.value === 'Não preenchido'
        const isTelefone = item.label === 'Telefone'

        let value = item.value

        if (isTelefone) {
          value = formatPhoneNumber(value)
        }

        if (isCPFCNPJ) {
          if (value.length === 11) {
            value = formatCPF(value)
          } else {
            value = formatCnpj(value)
          }
        }

        return (
          <div
            onClick={() => handleCopyPix(isPix && !defaultValue, item.value)}
            className={clsx('info', isPix && ' pix', defaultValue && 'default')}
          >
            <span className='label'>{item.label}</span>
            {': '}
            <span className='value'>{isBlur && hidden ? '* * * * * * * *' : value}</span>
            {isPix && <CopyIcon size={20} />}
          </div>
        )
      })}

      {table && (
        <Table>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Cidade/UF</th>
            <th>Valor</th>
          </tr>

          {table.map((item: any) => {
            return (
              <TableRow item={item} onClick={() => {}}>
                <td>{item.nome}</td>
                <td>{formatCPF(item.cpf)}</td>
                <td>{item.cidadeUf}</td>
                <td>{currencyFormatterBRL(item.valor)}</td>
              </TableRow>
            )
          })}
        </Table>
      )}
    </Container>
  )
}

export default Values
