import api from '../config'
import CenterCostModel from '../model/CenterCost/CenterCost'
import {ICenterCost} from '../model/CenterCost/type'
import Erro from '../model/Erro/Erro'
import {handlerResult} from '../utils/API'

const serializeCenterCost = (value: ICenterCost) => {
  const model = new CenterCostModel(value)
  return model
}

const serializeListCenterCost = (values: ICenterCost[]) => {
  const model = []

  for (const value of values) {
    model.push(serializeCenterCost(value))
  }

  return model
}

export async function GetCostCenters(GetCostCentersData: GetCostCentersData) {
  try {
    const response = await api.post('centroDeCusto/listar', GetCostCentersData)
    const {
      centrosDeCusto,
      pesquisadores,
      bancos,
      contasBancarias,
      totalDePaginas,
    }: GetCostCentersResponse = await handlerResult(response.data)

    return {
      centrosDeCusto: serializeListCenterCost(centrosDeCusto),
      pesquisadores,
      bancos,
      contasBancarias,
      totalDePaginas,
    }
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}
export async function GetRawCostCenters() {
  try {
    const response = await api.get('centroDeCusto/resumoCentrosDeCusto')
    const result: {
      codigo: string
      nome: string
    }[] = await handlerResult(response.data)

    return result
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}
export async function GetCostCenterDetail(codigo: number) {
  try {
    const response = await api.post('centroDeCusto/detalhe', {codigo})
    const dataResponse: ICostCenterResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function CreateCostCenter<T>(CreateCostCenterData: CreateCostCenterData) {
  try {
    const response = await api.post('centroDeCusto/criar', CreateCostCenterData)
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}
export async function UpdateCostCenter<T>(UpdateCostCenterData: UpdateCostCenterData) {
  try {
    const response = await api.post('centroDeCusto/atualizar', UpdateCostCenterData)
    const dataResponse: T = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export interface IPesquisadores {
  codigo: string
  nome: string
  numeroTelefone: string
  cpf: string
  email: string
}

export type GetCostCentersResponse = {
  centrosDeCusto: ICenterCost[]
  pesquisadores: IPesquisadores
  bancos: {
    codigo: string
    nome: string
  }[]
  contasBancarias: {
    codigo: string
    nome: string
  }[]
  totalDePaginas: number
}

export type UpdateCostCenterData = {
  codigo: number
  nomeFantasia: string
  razaoSocial: string
  nomeProprietario: string
  cpfOuCnpj: string
  email: string
  telefone: string
  codigoBanco: string
  codigoTipoContaBanco: string
  agenciaBanco: string
  contaBanco: string
  chavePix: string
  pesquisadores: string[]
}
export type CreateCostCenterData = {
  codigo?: number
  nomeFantasia: string
  razaoSocial: string
  nomeProprietario: string
  cpfOuCnpj: string
  email: string
  telefone: string
  codigoBanco: string
  codigoTipoContaBanco: string
  agenciaBanco: string
  contaBanco: string
  chavePix: string
  pesquisadores: string[]
}

type GetCostCentersData = {
  numeroPagina: number

  tamanhoPagina: number
}

export interface IDataBank {
  codigo: string
  banco: {
    codigo: string
    nome: string
  }
  tipoConta: {
    codigo: string
    nome: string
  }
  agencia: string
  conta: string
  chavePix: string
}

export interface IResearchers {
  codigo: string
  nome: string
  valor: number
  cpf: string
  cidadeUf: string
}

export interface ICostCenterResponse {
  centroDeCusto: CreateCostCenterData
  dadoBancario: IDataBank
  pesquisadores: IResearchers[]
}
