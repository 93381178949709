import Service from '../Service'
import Lottie from 'react-lottie'

import {Container} from './styles'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useService} from 'src/app/hooks/useService'

type ServiceListProps = {
  setCanShowButtons: React.Dispatch<React.SetStateAction<boolean>>
}

const ServicesList = ({setCanShowButtons}: ServiceListProps) => {
  const {
    selectedAtendimentos,
    setSelectedAtendimentos,
    selectedApprovedAtendimentos,
    services: atendimentos,
    getInfiniteData,
  } = useService()

  const handleClick = (_: any, item: ValidacaoModel) => {
    const selectedIndex = selectedAtendimentos
      .map((item) => item.codigoAtendimento)
      .indexOf(item.codigoAtendimento)

    let newSelected: typeof atendimentos = []

    if (selectedIndex === -1) {
      setCanShowButtons(true)
      newSelected = newSelected.concat(selectedAtendimentos, item)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAtendimentos.slice(1))
    } else if (selectedIndex === selectedAtendimentos.length - 1) {
      newSelected = newSelected.concat(selectedAtendimentos.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAtendimentos.slice(0, selectedIndex),
        selectedAtendimentos.slice(selectedIndex + 1)
      )
    }

    setSelectedAtendimentos(newSelected)
  }

  const handleIsSelected = (codigoAtendimento: number) => {
    return selectedAtendimentos.map((item) => item.codigoAtendimento).includes(codigoAtendimento)
  }

  const handleIsApproved = (codigoAtendimento: number) => {
    return selectedApprovedAtendimentos
      .map((item) => item.codigoAtendimento)
      .includes(codigoAtendimento)
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('../../../../assets/animations/noFile.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <InfiniteScroll
      dataLength={atendimentos.length}
      next={getInfiniteData}
      hasMore={true}
      loader={<></>}
      scrollableTarget='scrollableDiv'
    >
      <Container
        style={{justifyContent: atendimentos.length === 0 ? 'center' : 'initial'}}
        id='scrollableDiv'
      >
        {atendimentos.length > 0 ? (
          atendimentos.map((item) => (
            <>
              {/* <div style={{width: '100%', minHeight: 700, background: 'red'}}></div> */}
              <Service
                onClick={handleClick}
                handleIsSelected={handleIsSelected}
                handleIsApproved={handleIsApproved}
                data={item}
              />
            </>
          ))
        ) : (
          <>
            <Lottie options={defaultOptions} height={400} width={400} />
            <h2
              style={{textAlign: 'center', fontSize: '2rem', fontFamily: 'Inter', color: '#364150'}}
            >
              Nenhum atendimento encontrado
            </h2>
          </>
        )}
      </Container>
    </InfiniteScroll>
  )
}

export default ServicesList
