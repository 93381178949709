import React, {
  ReactNode,
  useEffect,
  useRef,
  CSSProperties,
  useState,
  KeyboardEventHandler,
  ReactElement,
} from 'react'

import {useSpring, useInView} from '@react-spring/web'

import {Container, Wrapper, ModalBody} from './styles'
import {
  ModalFooter,
  ModalHeader,
} from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/CardHeader/components/ChangeModal/styles'
import {CloseIcon} from 'src/app/utils/Icons'
import Button from '../Button'

type AnimationsType = 'scale' | 'slide' | 'fade'

type Props = {
  children?: ReactNode
  onClose: () => void
  onSubmit?: () => void
  footer?: ReactElement
  header?: ReactElement
  headerTitle?: string
  footerTitle?: string
  hasHeader?: boolean
  hasFooter?: boolean
  containerStyle?: CSSProperties | undefined
  animationDuration?: number
  animationType?: AnimationsType
  onKeyDown?: KeyboardEventHandler<HTMLDivElement> | undefined
  ref?: any
  formik?: any
}

const AnimatedModal = ({
  children,
  header,
  footer,
  onClose,
  containerStyle,
  animationDuration = 250,
  animationType = 'scale',
  headerTitle,
  footerTitle = 'Salvar',
  formik,
  onSubmit,
  hasHeader = true,
  hasFooter = true,
  ...rest
}: Props) => {
  const wrapperRef = useRef(null)

  const [ref, isInView] = useInView({
    rootMargin: '-45% 0px -45% 0px',
  })

  const [show, setShow] = useState(isInView)

  function useOutside(ref: React.RefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          const text = event.target

          const getClassName = (className: string) =>
            !text?.parentElement?.className?.includes(className)

          if (
            getClassName('ki-duotone') &&
            getClassName('modal-header') &&
            getClassName('modal-content') &&
            getClassName('modal-footer') &&
            getClassName('btn') &&
            getClassName('Toastify') &&
            getClassName('modal-open') &&
            !String(event.target.textContent).includes('optimizeLegibility')
          ) {
            setShow(false)

            setTimeout(() => {
              onClose()
            }, animationDuration)
          }
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutside(wrapperRef)

  useEffect(() => {
    setShow(isInView)
  }, [isInView])

  const scaleAnimation = {
    scale: show ? 1 : 0,
    config: {
      tension: 300,
      duration: animationDuration,
    },
  }

  const fadeAnimation = {
    opacity: show ? 1 : 0,
    config: {
      tension: 300,
      duration: animationDuration,
    },
  }

  // const slideAnimation = {
  //   scale: show ? 1 : 0,
  //   config: {
  //     tension: 300,
  //     duration: animationDuration,
  //   },
  // }

  const getCorrectAnimation = () => {
    switch (animationType) {
      case 'scale':
        return scaleAnimation
      case 'slide':
        return scaleAnimation
      case 'fade':
        return fadeAnimation

      default:
        return scaleAnimation
    }
  }

  const styles = useSpring(getCorrectAnimation())

  const wrapperStyle = useSpring(fadeAnimation)

  const onClick = () => {
    setShow(false)

    setTimeout(() => {
      onClose()
    }, animationDuration)
  }

  const renderHeader = () => {
    if (header) {
      return React.Children.map(header, (child) => {
        return React.cloneElement(child, {
          onClick,
        })
      })
    }

    return (
      <ModalHeader>
        <span>{headerTitle}</span>
        <button style={{marginTop: '4px'}} onClick={onClick}>
          <CloseIcon size={20} fill='#666666' />
        </button>
      </ModalHeader>
    )
  }

  const renderFooter = () => {
    if (footer) {
      return React.Children.map(footer, (child) => {
        return React.cloneElement(child, {
          onClick,
        })
      })
    }

    return (
      <ModalFooter>
        <div style={{padding: 16, paddingBottom: 0}} className='buttons'>
          <Button title='Cancelar' color='danger' onClick={onClick}>
            Cancelar
          </Button>
          <Button
            data-test='modalConfirm'
            loading={formik?.isSubmitting || false}
            disabled={formik?.isSubmitting || false}
            color='primary'
            title='OK'
            onClick={async () => {
              await formik?.submitForm()
              onSubmit ? onSubmit() : onClick()
            }}
          >
            {footerTitle}
          </Button>
        </div>
      </ModalFooter>
    )
  }

  return (
    <Wrapper className='wrapper' style={wrapperStyle} ref={ref}>
      <Container
        id='container'
        {...rest}
        style={{padding: 8, ...containerStyle, ...styles}}
        ref={wrapperRef}
      >
        {hasHeader && renderHeader()}
        <ModalBody>{children}</ModalBody>
        {hasFooter && renderFooter()}
      </Container>
    </Wrapper>
  )
}

export default AnimatedModal
