import styled from 'styled-components'

export const TableContainer = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  tr {
    display: flex;

    border: 1px solid rgb(224, 224, 224);
    border-top: none;

    padding: 16px;
    flex: 1;
  }

  th {
    display: flex;
    flex-direction: column;

    flex: 1;

    user-select: none;

    text-align: center;
  }

  td {
    display: flex;
    flex: 1;
    min-height: 50px;

    justify-content: center;
    align-items: center;

    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Roboto';
  }

  td,
  th {
    margin: 4px;
  }
`

export const SearchInput = styled.input`
  border-radius: 0 !important;

  border: 1px solid rgb(224, 224, 224) !important;

  width: 100% !important;

  padding: 8px 16px;
  padding-right: 32px;
  height: 40px;

  margin-top: 4px;

  box-shadow: none !important;
`

export const TableFooter = styled.div`
  padding: 16px;
  border: 1px solid rgb(224, 224, 224);
  border-top: none;

  display: flex;

  .content {
    margin-left: auto;

    display: flex;
    align-items: center;
    gap: 40px;
  }
`

export const TableHeaderWrapper = styled.div`
  display: flex;

  div {
    display: flex;
    flex-direction: column;
  }
`

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 16px;

  border: 1px solid rgb(224, 224, 224);
  border-bottom: none;

  span {
    font-weight: bold;
    font-size: 18px;
  }
`
export const PaginationButtonStyled = styled.button`
  background-color: transparent;
  border: none;
  margin-right: 5px;
  cursor: pointer;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`
