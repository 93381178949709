import {useState} from 'react'

import {Container, ContainerHeader} from './styles'
import {ArrowDownIcon, OpenEyeIcon, CloseEyeIcon} from 'src/app/utils/Icons'

import Values from '../Values'

const ContainerCard = ({title, data = [], table, isBlur = false}: any) => {
  const [hidden, setHidden] = useState(true)

  return (
    <Container>
      <ContainerHeader>
        <span className='icon'>
          <ArrowDownIcon size={30} />
        </span>
        <span>{title}</span>

        {isBlur && (
          <button onClick={() => setHidden((oldState) => !oldState)}>
            {hidden ? <OpenEyeIcon size={30} /> : <CloseEyeIcon size={30} />}
          </button>
        )}
      </ContainerHeader>
      <div className='container-body'>
        <Values data={data} table={table} isBlur={isBlur} hidden={hidden} />
      </div>
    </Container>
  )
}

export default ContainerCard
