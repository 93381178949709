import {useState} from 'react'

import AnimatedModal from 'src/app/components/AnimatedModal'
import Button from 'src/app/components/Button'
import AnimatedRadio from 'src/app/components/AnimatedRadio'

import {Container, FooterModal, HeaderModal} from '../ExclusionModal/styles'

const RemovePhotoModal = ({onClose, handleRemovePhoto}: any) => {
  const items = [
    'Foto sem padrão',
    'Foto incorreta',
    'Foto idêntica',
    'Foto inserida errado',
    'Outros',
  ]

  const [currentValue, setCurrentValue] = useState<string | number>('')
  const [reason, setReason] = useState('')
  const [removeSolicitacao, setRemoveSolicitacao] = useState<boolean>(false)

  const isOthers = currentValue === 'Outros'

  const handleOnClick = (value: string | number) => {
    setCurrentValue(value)
  }

  const Footer = () => {
    return (
      <FooterModal>
        <Button color='danger' onClick={onClose}>
          Cancelar Solicitação
        </Button>
        <Button
          color='primary'
          onClick={async () => await handleRemovePhoto(isOthers ? reason : currentValue)}
        >
          Confirmar Solicitação
        </Button>
      </FooterModal>
    )
  }

  return (
    <AnimatedModal
      containerStyle={{height: 'auto', width: 400}}
      headerTitle='Motivo'
      footer={<Footer />}
      onClose={onClose}
    >
      <Container isOthers={isOthers}>
        {items.map((item) => (
          <AnimatedRadio
            label={item}
            value={item}
            currentValue={currentValue}
            onClick={handleOnClick}
          />
        ))}
        <textarea
          disabled={!isOthers}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder='Digite o motivo...'
        ></textarea>
      </Container>
    </AnimatedModal>
  )
}

export default RemovePhotoModal
