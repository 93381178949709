/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {useEffect, useState} from 'react'
import {useAlert} from 'src/app/components/Alert/Alert'
import AnimatedModal from 'src/app/components/AnimatedModal'
import {Select} from 'src/app/components/Select/Select'
import {useService} from 'src/app/hooks/useService'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import Service from 'src/app/pages/avaliacao-pesquisa/validacao/Service'
import {GetLastServices, GetLastServicesData} from 'src/app/repositories/OperationalBase'

const LastServiceModal = ({onClose, rowDetail}: Props) => {
  const [data, setData] = useState([])
  const [options, setOptions] = useState([])
  const [currentOption, setCurrentOption] = useState(options[0])
  const [atendimento, setAtendimento] = useState<ValidacaoModel | null>(null)

  const {setServicesInfo} = useService()

  const getData = async () => {
    try {
      const o: GetLastServicesData = {codigoPontoColeta: rowDetail.codigo}
      const res = await GetLastServices(o)

      setData(res.atendimentos)
      setServicesInfo(res.informacoesAtendimentos)

      const ops = res.atendimentos.map((item: {dataColeta: any; codigoAtendimento: any}) => ({
        label: item.dataColeta,
        value: item.codigoAtendimento,
      }))
      setOptions(ops)
      setCurrentOption(ops[0])
      setAtendimento(res.atendimentos[0])
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const RenderSelect = () => (
    <div>
      <Select
        options={options}
        value={currentOption}
        onChange={(e: any) => {
          setCurrentOption(e)

          setAtendimento(
            data.filter((item: {codigoAtendimento: any}) => item.codigoAtendimento === e.value)[0]
          )
        }}
      />
    </div>
  )
  return (
    <AnimatedModal
      hasHeader={false}
      hasFooter={false}
      onClose={onClose}
      containerStyle={{maxWidth: 1500}}
    >
      {data.length > 0 && atendimento && (
        <Service
          viewOnly
          onClick={() => {}}
          handleIsSelected={() => false}
          handleIsApproved={() => false}
          data={atendimento}
          RenderSelect={RenderSelect}
        />
      )}
    </AnimatedModal>
  )
}

type Props = {
  onClose: () => void
  rowDetail: any
}

export default LastServiceModal
