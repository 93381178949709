import React from 'react'

import {ServiceContextProvider} from '../contexts/ServiceContext'
import AuthContextProvider from '../contexts/AuthContex'
import CostCenterContextProvider from '../contexts/CostCenterContext'
import RouteContextProvider from '../contexts/RotaContext'
import {ManutencaoSolicitacaoContextProvider} from '../contexts/ManutencaoSolicitacaoContext'

interface ProvidersProps {
  children: React.ReactNode
}

const Providers = ({children}: ProvidersProps) => {
  return (
    <ManutencaoSolicitacaoContextProvider>
      <RouteContextProvider>
        <CostCenterContextProvider>
          <ServiceContextProvider>
            <AuthContextProvider>{children}</AuthContextProvider>
          </ServiceContextProvider>
        </CostCenterContextProvider>
      </RouteContextProvider>
    </ManutencaoSolicitacaoContextProvider>
  )
}

export default Providers
