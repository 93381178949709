/* eslint-disable react-hooks/rules-of-hooks */
import {useState} from 'react'

import {animated, useSpring} from '@react-spring/web'

import Card from '../Card'

import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import {FotoColeta} from 'src/app/model/Validacao/Type'
import AnimatedRadio from 'src/app/components/AnimatedRadio'
import {useService} from 'src/app/hooks/useService'
import {useAlert} from 'src/app/components/Alert/Alert'
import {UpdateSituationCPNJ} from 'src/app/repositories/Atendimentos'

const FotoQA = ({
  data,
  viewOnly,
  foto,
  setIsWarning,
}: {
  data: ValidacaoModel
  canEdit: boolean
  viewOnly: boolean
  foto: FotoColeta
  setIsWarning: any
}) => {
  const {servicesInfo} = useService()
  const [infoFotoAtendimento]: FotoColeta[] = data.fotosColeta.filter(
    (item: FotoColeta) => item.siglaFoto === 'QA'
  )
  const [open, setOpen] = useState(viewOnly)
  const [currentOcorrenciaFoto, setCurrentOcorrenciaFoto] = useState<string | number>(
    infoFotoAtendimento?.ocorrenciaFoto || ''
  )
  const [currentSituacaoConstatada, setCurrentSituacaoConstatada] = useState<string | number>(
    data.situacaoConstatada || ''
  )

  const situacoesConstatadas = servicesInfo?.situacoesConstatadas || []

  async function handleOnClickChangeOcorrencia(value: string | number) {
    setCurrentOcorrenciaFoto(value)
  }

  async function handleOnClickChangeSituacaoConstatada(value: string | number) {
    let o: any = {
      codigoAtendimento: data.codigoAtendimento,
      codigoSituacaoSelecionada: value as number,
    }

    if (value !== currentSituacaoConstatada) {
      setCurrentSituacaoConstatada(value)

      try {
        const res = await UpdateSituationCPNJ<string>(o)

        if (res) {
          useAlert(res, 'Success')
        }
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    }
  }

  const heightAnimation = {
    height: open ? 90 : 32,
    config: {
      tension: 300,
      duration: 150,
    },
  }

  const styles = useSpring(heightAnimation)

  return (
    <Card
      foto={foto}
      cnpj={data.pontoColeta.cnpj}
      numeroAnp={
        data.formulario === 'PG' ? data.pontoColeta.numeroAnpGlp : data.pontoColeta.numeroAnpAuto
      }
      isQA
      distance={infoFotoAtendimento?.distancia}
      className='three'
      title={'foto qa (quadro de aviso)'}
      data={data}
      setIsWarning={setIsWarning}
      currentOcorrenciaFoto={currentOcorrenciaFoto}
      handleOnClickChangeOcorrencia={handleOnClickChangeOcorrencia}
      currentSituacaoConstatada={currentSituacaoConstatada}
      handleOnClickChangeSituacaoConstatada={handleOnClickChangeSituacaoConstatada}
    >
      <animated.div style={styles} className='radios'>
        {situacoesConstatadas.map((item, index) => (
          <AnimatedRadio
            // disabled={canEdit}
            label={item.valor}
            value={item.codigo}
            currentValue={currentSituacaoConstatada}
            onClick={handleOnClickChangeSituacaoConstatada}
            renderArrow={index === 0 ? true : false}
            setOpen={setOpen}
            open={open}
          />
        ))}
      </animated.div>
    </Card>
  )
}

export default FotoQA
