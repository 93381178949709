import {ButtonHTMLAttributes} from 'react'

import {IconType} from 'react-icons/lib'

import {Container} from './styles'

type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  Icon: IconType
  text: string
  Indicator?: IconType
  empty?: boolean
  style?: React.CSSProperties
  isToggled?: boolean
  iconConfig?: {size: number; color: string}
}

const IconButton = ({
  Icon,
  text,
  Indicator,
  empty = false,
  iconConfig = {color: 'white', size: 20},
  isToggled,
  ...rest
}: IconButtonProps) => {
  return (
    <Container empty={empty} {...rest}>
      <Icon {...iconConfig} />
      <span>{text}</span>
      {Indicator && <Indicator style={{cursor: 'default', transition:'transform 0.4s', transform: isToggled ? "rotate(0deg)": "rotate(-180deg)"}} size={20} color='white' />}
    </Container>
  )
}

export default IconButton
