import api from '../config'
import { DetalheMonitoramentoModel } from '../model/Monitoramento/DetalheMonitoramento'
import { MonitoramentoModel } from '../model/Monitoramento/Monitoramento'
import { IMonitoramento } from '../model/Monitoramento/Type'
import { handlerResult } from '../utils/API'

const serialize = (item: IMonitoramento) => {
  return new MonitoramentoModel(item)
}

const serializeDetail = (item: IAtendimentoLiberado) => {
  return new DetalheMonitoramentoModel(item)
}

const serializeList = (itens: IMonitoramento[]) => {
  let model = []

  for (let item of itens) {
    model.push(serialize(item))
  }

  return model
}
const serializeListDetail = (itens: IAtendimentoLiberado[]) => {
  let model = []

  for (let item of itens) {
    model.push(serializeDetail(item))
  }

  return model
}

export async function GetResumoMonitoramento(dataPeriodo: DataPeriodo) {
  const response = await api.post('monitoracao', dataPeriodo)
  const { listaMonitoramento, totalAtendimentos } = await handlerResult(response.data)
  return {
    listaMonitoramento: serializeList(listaMonitoramento),
    totalAtendimento: totalAtendimentos,
  }
}

export async function GetDetailUsuario(dataDetailUsuario: DataDetailUsuario) {
  const response = await api.post('monitoracao/detalheAssistente', dataDetailUsuario)
  const { nomeAssistente, totalAtendimentos, atendimentosValidados } = await handlerResult(response.data)
  return {
    nomeAssistente: nomeAssistente,
    totalAtendimentos: totalAtendimentos,
    atendimentosValidados: serializeListDetail(atendimentosValidados),
  } as IDetailUsuarioMonitoramento
}
interface DataPeriodo {
  dataInicio: string
  dataFim: string
}

interface DataDetailUsuario {
  codigoUsuario: string
  periodo?: DataPeriodo
}

export interface IDetailUsuarioMonitoramento {
  nomeAssistente: string
  totalAtendimentos: number
  atendimentosValidados: DetalheMonitoramentoModel[]
}

export interface IAtendimentoLiberado {
  codigoAtendimento: number
  codigoTriad: string
  dataExecucao: string
  dataValidacao: string
  status: string
  uf: string
  fonte: string
  codigoExterno: string
}
