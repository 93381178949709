import clsx from "clsx"
import { KTIcon } from "../../_metronic/helpers"

export default function Icon({ icon, iconSize, iconType, iconColor, className }: IconProps) {
 return icon.startsWith('fonticon')
  ? <i className={clsx(`${icon} fs-2`, { [`fs-${iconSize}x`]: Boolean(iconSize) }, { [`text-${iconColor}`]: Boolean(iconColor) }, className)}></i>
  : <KTIcon iconName={icon} iconType={iconType} className={clsx(`fs-2`, { [`fs-${iconSize}x`]: Boolean(iconSize) }, { [`text-${iconColor}`]: Boolean(iconColor) }, className)} />
}

export interface IconProps {
 icon: string,
 iconSize?: string,
 iconType?: 'duotone' | 'solid' | 'outline',
 iconColor?: string,
 className?: string
}