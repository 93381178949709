import api from '../config'
import RotaModel from '../model/Rota/Rota'
import {IRoute} from '../model/Rota/Types'
import Erro from '../model/Erro/Erro'
import {handlerResult} from '../utils/API'
import {IErroSincronization} from '../model/ErrorSincronization/type'
import ErrorSincronization from '../model/ErrorSincronization/ErrorSincronization'

export const serializeRoute = (value: IRoute) => {
  const model = new RotaModel(value)
  return model
}

export const serializeErroSincronization = (value: IErroSincronization) => {
  const model = new ErrorSincronization(value)
  return model
}

export const serializeListRoute = (values: IRoute[]) => {
  const model = []

  for (const value of values) {
    model.push(serializeRoute(value))
  }

  return model
}

export const serializeListErrorSincronization = (values: IErroSincronization[]) => {
  const model = []

  for (const value of values) {
    model.push(serializeErroSincronization(value))
  }

  return model
}

export async function GetRoutes(GetRotasFilters: GetRotasFilters) {
  try {
    const response = await api.post('rota/listar', GetRotasFilters)
    const {nomeRotas, rotas, totalDePaginas}: GetRotasResponse = await handlerResult(response.data)

    return {
      routes: serializeListRoute(rotas),
      nomeRotas: nomeRotas.map((item) => ({value: item, label: item})),
      totalDePaginas,
    }
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function CreateRoute(CreateRotaData: CreateRotaData) {
  try {
    const response = await api.post('rota/criar', CreateRotaData)
    const dataResponse: IRoute = await handlerResult(response.data)

    return serializeRoute(dataResponse)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateRoute(UpdateRotaData: UpdateRotaData) {
  try {
    const response = await api.post('rota/atualizar', UpdateRotaData)
    const dataResponse: IRoute = await handlerResult(response.data)

    return serializeRoute(dataResponse)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetRouteDetail(codigo: number) {
  try {
    const response = await api.post('rota/detalhe', {codigo})
    const dataResponse = await handlerResult(response.data)

    let dataResponseCopy: DetailRouteResponse = dataResponse

    if (dataResponseCopy['pesquisador']) {
      dataResponseCopy['pesquisador'] = {
        value: dataResponse.pesquisador.codigo,
        label: dataResponse.pesquisador.nome,
      }
    }

    return dataResponseCopy
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}
export async function GetNextCodeRoute(uf: string, tipoExecucao: string) {
  try {
    const response = await api.post('rota/proximoNomeRota', {uf, tipoExecucao})
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function DownloadRouteXlsx(codigo: number) {
  try {
    const response = await api.post('rota/downloadRotaXlsx', {codigo})
    const dataResponse: DownloadRouteXlsxResponse = await handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateRouteStatus(UpdateRouteStatusData: UpdateRouteStatusData) {
  try {
    const response = await api.post('rota/atualizarStatus', UpdateRouteStatusData)
    const dataResponse: IRoute = await handlerResult(response.data)

    return serializeRoute(dataResponse)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateRouteUFCity(UpdateRouteUFCityData: UpdateRouteUFCityData) {
  try {
    const response = await api.post('rota/atualizarCidadeUf', UpdateRouteUFCityData)
    const dataResponse: IRoute = await handlerResult(response.data)

    return serializeRoute(dataResponse)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function SyncRouteWithColetaiApi(codigoRota: number) {
  try {
    const response = await api.post('api/criarEditarRota', {codigoRota})
    const dataResponse = await handlerResult(response.data)

    return serializeRoute(dataResponse)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function SyncAllRoutesWithColetaiApi() {
  try {
    const response = await api.get('api/criarRota')
    const dataResponse = await handlerResult(response.data)

    return serializeListRoute(dataResponse)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function ListErrorsFromSyncronizationRoute(codigoRota: number) {
  try {
    const response = await api.post('api/listarErrosSincronizacaoRota', {codigoRota})
    const dataResponse = await handlerResult(response.data)

    return serializeListErrorSincronization(dataResponse)
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function ValidacaoImportFileRoute(codigo: number, file: File) {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('codigoRota', codigo + '')

  try {
    let response = await api.post('rota/importarRotaPlanejamentoXlsx', formData)
    const dataResponse: ValidacaoImportFileRouteResponse = await handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function ValidacaoImportPlanejamentoRoute(file: File) {
  let formData = new FormData()
  formData.append('file', file)

  try {
    let response = await api.post('rota/importarRotasPlanejamentosXlsx', formData)
    const dataResponse: ValidacaoImportFileRouteResponse = await handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function ValidacaoImportacaoMultipla(file: File) {
  let formData = new FormData()
  formData.append('file', file)

  try {
    let response = await api.post('rota/importarPontosRotasCompletasXlsx', formData)
    const dataResponse: ValidacaoImportFileRouteResponse2 = await handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
    }
    throw new Erro(error.response.data.data.message)
  }
}

export async function GetRouteQuantitatives() {
  try {
    let response = await api.get('rota/quantitativos')
    const dataResponse: GetRouteQuantitativesData = await handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export type GetRouteQuantitativesData = {
  rotasConcluidas: number
  rotasPendentesPesquisador: number
  rotasPendentesSincronizacao: number
}

export type ValidacaoImportFileRouteResponse = {
  excelSucces: IRoute | IRoute[]
  headersError: ValidacaoImportFileRouteHeadersError
  excelError: ValidacaoImportFileRouteExcelError | RouteResponse[]
}

export type ValidacaoImportFileRouteResponse2 = {
  excelSucces: IRoute | IRoute[]
  headersError: ValidacaoImportFileRouteHeadersError
  excelError: {
    nomeRota: string
    identificadorPonto: string
    errors: any
  }[]
}

export type ValidacaoImportFileRouteHeadersError = {
  rotas: string[]
  pontos_de_coleta: string[]
}

export type RouteResponse = {
  nomeRota: string | null
  ufRota: string
  cidadeRota: string
  cpfPesquisador: string
  segunda: string
  terca: string
  quarta: string
  quinta: string
  sexta: string
  sabado: string
  domingo: string
  dataInicio: string
  dataExpiracao: string
  frequencia: string
  intervalo: string
  antecedencia: string
  pendencia: string
  identificadoresPontosDeColeta: []
  errors: any
}

export type ValidacaoImportFileRouteExcelError = {
  rota: RouteResponse
  pontosDeColeta: {
    nomeRota: string | null
    identificadorPonto: string
    errors: any
  }
}

export type UpdateRouteUFCityData = {
  codigo: number
  uf: string | null
  cidade: string | null
}

export type UpdateRouteStatusData = {
  codigo: number
  ativo: boolean
}

export type DownloadRouteXlsxResponse = {
  nomeRota: string
  ufRota: string
  cidadeRota: string
  cpfPesquisador: string
  segunda: string
  terca: string
  quarta: string
  quinta: string
  sexta: string
  sabado: string
  domingo: string
  dataInicio: string
  dataExpiracao: string
  frequencia: string
  intervalo: string
  antecedencia: string
  pendencia: string
  identificadoresPontosDeColeta: string[]
  errors: Object
}

export type UpdateRotaData = {
  codigo: number
  codigoPesquisador: string | null
  dataInicio: string | null
  dataExpiracao: string | null
  frequencia: number
  intervalo: number
  antecedencia: number
  pendencia: number
  segunda: boolean
  terca: boolean
  quarta: boolean
  quinta: boolean
  sexta: boolean
  sabado: boolean
  domingo: boolean
  codigosPontosDeColeta: string[]
}

export type GetRotasResponse = {
  rotas: IRoute[]
  nomeRotas: string[]
  totalDePaginas: number
}

export interface PontosColeta {
  codigo: number
  codigoPontoColeta: string
  identificadorPontoColeta: string
  endereco: string
  bairro: string
  cep: string
  uf: string
  cidade: string
  latitude: number
  longitude: number
  ordem: number
}

export type Planejamento = {
  dataInicio: string
  dataExpiracao: string
  segunda: boolean
  terca: boolean
  quarta: boolean
  quinta: boolean
  sexta: boolean
  sabado: boolean
  domingo: boolean
  frequencia: number
  intervalo: number
  antecedencia: number
  pendencia: number
}

export type DetailRouteResponse = {
  codigo: number
  nome: string
  uf: string
  cidade: string
  ativo: boolean
  pendenteSincronizacao: boolean
  dataCriacao: string
  pesquisador: {
    value: string
    label: string
  }
  planejamento: Planejamento
  pontosColeta: PontosColeta[]
}

export type CreateRouteResponse = {
  planejamento: Planejamento
  pontosColetaRota: PontosColeta[]
  rota: IRoute
}

export type CreateRotaData = {
  nome: string
  uf: string
  cidade: string
}

export type GetRotasFilters = {
  situacao: string | null
  uf: string | null
  cidade: string | null
  agendaDaSemana: AgendaDaSemanaBean | null
  nomesRotas: string[] | null
  pesquisadorPendente: boolean
}

type AgendaDaSemanaBean = {
  segunda: boolean
  terca: boolean
  quarta: boolean
  quinta: boolean
  sexta: boolean
  sabado: boolean
  domingo: boolean
}
