import {useState, useEffect, RefObject} from 'react'

import Switch from 'src/app/components/Form/Switch'
import Button from 'src/app/components/Button'

import {Container, Line} from './styles'

import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import Selecao from 'src/app/components/Selecao'
import Lottie from 'react-lottie'
import {useService} from 'src/app/hooks/useService'
import {CloseIcon} from 'src/app/utils/Icons'

type Props = {
  width: number
  showContent: boolean
  loading: boolean
  handleClose: () => void
  data: ValidacaoModel
  config: IServiceActionModalConfig
  containerRef: RefObject<HTMLDivElement>
  action: 'aprovar' | 'reprovar'
}

export interface IServiceActionModalConfig {
  color: string
  title: {message: string; color?: string}
  handleSuccess: (
    codigo: number,
    approvedReason: string | undefined,
    disapprovedReason: string,
    isActive: boolean,
    action: 'aprovar' | 'reprovar'
  ) => void
  hasInfo?: boolean
  active: boolean
}

export const selectOptions = [
  'Erro de localização',
  'Erro de preenchimento',
  'Erro fotográfico',
  'Erro de execução de formulário',
  'Coleta Duplicada',
  'Erro de Sincronização de data',
  'Erro de execução de horário',
  'Pesquisa Teste ',
  'Posto(s) não autorizado(s)',
]

const ServiceActionModal = ({
  width,
  showContent,
  handleClose,
  data,
  config,
  loading,
  containerRef,
  action,
}: Props) => {
  const [isActive, setIsActive] = useState(true)
  const [approvedReason, setApprovedReason] = useState<string>()
  const [disapprovedReason, setDisapprovedReason] = useState(
    'Selecione o motivo da reprovação do pagamento'
  )

  const {color, title = {message: '', color: '#1b1b1b'}, handleSuccess, hasInfo = true} = config

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('src/app/assets/animations/snake-loading.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    setIsActive(config.active)
  }, [config])

  const isDisapproved = data.statusAtendimento === 'REPROVADO'
  const {services} = useService()

  return (
    <Container height={containerRef.current?.clientHeight}>
      {[1, 2, 3, 4, 5, 6, 7].map((item, index) => (
        <Line
          style={{cursor: loading ? 'progress' : 'auto'}}
          color={color}
          width={width}
          index={index}
          textColor={title.color!}
        >
          {index === 3 && showContent && !loading && (
            <div
              style={{
                marginTop:
                  hasInfo &&
                  isDisapproved &&
                  services[0].codigoAtendimento === data.codigoAtendimento
                    ? 60
                    : 0,
              }}
              className='msg-container'
            >
              <div className='msg-header'>
                <span>{title.message}</span>
                <button onClick={handleClose}>
                  <CloseIcon size={20} fill='#666666' />
                </button>
              </div>
              <div className='msg-info'>
                {hasInfo && (
                  <>
                    <div className='msg-row'>
                      <span className='label'>cnpj</span>
                      <span className='value text-muted'>{data.pontoColeta.cnpj}</span>
                    </div>
                    <div className='msg-row'>
                      <span className='label'>razão social</span>
                      <span className='value text-muted'>{data.pontoColeta.razaoSocial}</span>
                    </div>
                    <div className='msg-row'>
                      <span className='label'>pesquisador</span>
                      <span className='value text-muted'>{data.pesquisador.nome}</span>
                    </div>
                    <div className='msg-row'>
                      <span className='label'>data coleta</span>
                      <span className='value text-muted'>{data.dataColeta}</span>
                    </div>
                  </>
                )}

                <Switch
                  className='text-success'
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                >
                  Pagamento Liberado?
                </Switch>

                {isActive ? (
                  <textarea
                    value={approvedReason}
                    onChange={(e) => setApprovedReason(e.target.value)}
                    placeholder='Observação de pagamento'
                  ></textarea>
                ) : (
                  <Selecao
                    contentStyle={{width: '100%'}}
                    titulo={'Motivo da reprovação'}
                    opcoes={selectOptions}
                    setValue={setDisapprovedReason}
                    value={disapprovedReason}
                  />
                )}

                <div className='msg-buttons'>
                  <Button
                    onClick={() => {
                      setIsActive(config.active)
                      handleClose()
                    }}
                  >
                    Não
                  </Button>
                  <Button
                    onClick={() =>
                      handleSuccess(
                        data.codigoAtendimento,
                        approvedReason,
                        disapprovedReason,
                        isActive,
                        action
                      )
                    }
                  >
                    Sim
                  </Button>
                </div>
              </div>
            </div>
          )}
          {index === 3 && loading && (
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'progress',
              }}
              className='msg-container'
            >
              <div style={{flexDirection: 'column', paddingTop: 100}} className='msg-header'>
                <span style={{fontSize: 22}}>Processando</span>
                <Lottie options={defaultOptions} height={150} width={150} />
              </div>
            </div>
          )}
        </Line>
      ))}
    </Container>
  )
}

export default ServiceActionModal
