export default class Erro extends Error {
  constructor(message: any) {
    super(message)
    this.name = 'Erro'
    this.message = message
  }

  toString() {
    return this.message
  }
}
