/* eslint-disable react-hooks/rules-of-hooks */
import {useState} from 'react'
import {useAlert} from 'src/app/components/Alert/Alert'
import TablePainel from 'src/app/components/TablePainel'
import {
  TableColumn,
  TableRowAction,
  TableExportProps,
} from 'src/app/components/TablePainel/table-painel'
import {MonitoramentoModel} from 'src/app/model/Monitoramento/Monitoramento'
import {GetDetailUsuario, IDetailUsuarioMonitoramento} from 'src/app/repositories/Monitoramento'
import DetailUserModal from '../DetailUserModal'
import {DateFilterMonitoramento} from '../..'

export default function MonitoramentoTable({data, getData, dateFilters, isLoading}: Props) {
  const [detailUsuario, setDetailUsuario] = useState<IDetailUsuarioMonitoramento>()
  const [showModal, setShowModal] = useState<boolean>(false)

  const tableColumns: TableColumn[] = [
    {
      name: 'Asssitente',
      accessor: 'nomeUsuario',
    },
    {
      name: 'Total Avaliado',
      accessor: 'totalLiberacao',
    },
    {
      name: 'Total Aprovado',
      accessor: 'totalAprovado',
    },
    {
      name: 'Total Reprovados',
      accessor: 'totalReprovado',
    },
    {
      name: "Total por UF's Aprovados",
      accessor: 'totalPorUf',
    },
  ]
  const tableRowsActions: TableRowAction[] = [
    {
      label: 'Detalhe Gestor(a)',
      icon: 'eye',
      onClick: async (row: MonitoramentoModel) => {
        await getDetailAssistente(row.codigoUsuario)
        return handleClickShowModal()
      },
    },
  ]
  const tableExports: TableExportProps = {
    pdf: {
      fileName: 'liberacao_por_assistentes',
    },
    excel: {
      fileName: 'liberacao_por_assistentes',
      sheetName: 'liberacao_por_assistentes',
    },
    csv: {
      fileName: 'liberacao_por_assistentes',
    },
  }

  const handleClickShowModal = () => {
    setShowModal(true)
  }
  const handleClickCloseModal = () => {
    setShowModal(false)
  }

  const getDetailAssistente = async (codigoUsuario: string) => {
    try {
      const res = await GetDetailUsuario({
        codigoUsuario: codigoUsuario,
        periodo: {
          dataInicio: dateFilters.dataInicio,
          dataFim: dateFilters.dataFim,
        },
      })

      if (res) {
        setDetailUsuario({
          nomeAssistente: res.nomeAssistente,
          totalAtendimentos: res.totalAtendimentos,
          atendimentosValidados: res.atendimentosValidados,
        })
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  return (
    <>
      {showModal && <DetailUserModal data={detailUsuario} closeModal={handleClickCloseModal} />}
      <TablePainel
        tableTitle='Avaliações Por Assistentes'
        data={data}
        getData={getData}
        columns={tableColumns}
        rowActions={tableRowsActions}
        tableExportProps={tableExports}
        isLoading={isLoading}
        paginate={false}
        createDataButton={false}
      />
    </>
  )
}

interface Props {
  data: MonitoramentoModel[]
  dateFilters: DateFilterMonitoramento
  getData: () => void
  isLoading?: boolean
}
