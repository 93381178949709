/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useState} from 'react'

import {WithChildren} from 'src/_metronic/helpers'
import {ICenterCost} from '../model/CenterCost/type'

export const CostCenterContext = createContext<CostCenterContextProps>({} as CostCenterContextProps)

const CostCenterContextProvider: FC<WithChildren> = ({children}) => {
  const [costCenter, setCostCenter] = useState<CentroCustoData>({} as CentroCustoData)

  return (
    <CostCenterContext.Provider value={{costCenter, setCostCenter}}>
      {children}
    </CostCenterContext.Provider>
  )
}

type CostCenterContextProps = {
  costCenter: CentroCustoData
  setCostCenter: React.Dispatch<React.SetStateAction<CentroCustoData>>
}

export type CentroCustoData = {
  centrosDeCusto: ICenterCost[]
  bancos: {
    value: string | null
    label: string
  }[]
  contasBancarias: {
    value: string | null
    label: string
  }[]
}

export default CostCenterContextProvider
