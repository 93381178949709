/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {Dispatch, SetStateAction, useEffect, useState} from 'react'

import AnimatedModal from 'src/app/components/AnimatedModal'

import {useAlert} from 'src/app/components/Alert/Alert'
import Button from 'src/app/components/Button'
import {useService} from 'src/app/hooks/useService'
import {FotoColeta} from 'src/app/model/Validacao/Type'
import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import {
  DataDuplicatePhoto,
  DuplicatePhoto,
  GetAlternativePhotoType,
} from 'src/app/repositories/Atendimentos'
import {Container, Footer} from './styles'

type Props = {
  data: ValidacaoModel
  onClose: () => void
  codigoFoto: number
  setIsWarning: Dispatch<SetStateAction<boolean>>
  callback?: () => void
}

type types = {
  codigo: string
  nome: string
}

const DuplicateModal = ({data, onClose, codigoFoto, setIsWarning, callback}: Props) => {
  const [types, setTypes] = useState<types[]>([])

  const {setServices} = useService()

  const [checkedState, setCheckedState] = useState<boolean[]>([])

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    )

    setCheckedState(updatedCheckedState)
  }

  const handleDuplicatePhoto = async () => {
    const tiposFotos: any = checkedState
      .map((item, index) => {
        if (item) {
          return types[index]
        }
      })
      .filter((item) => item !== undefined)
      .map((item) => item?.codigo)

    const o: DataDuplicatePhoto = {
      codigoFotoAtendimento: codigoFoto,
      tiposFotos,
    }

    try {
      const res = await DuplicatePhoto<FotoColeta[]>(o)

      if (res) {
        setServices((oldState) =>
          oldState.map((item) => {
            if (item.codigoAtendimento === data.codigoAtendimento) {
              item.fotosColeta = item.fotosColeta.concat(res)
            }
            return item
          })
        )

        setIsWarning(true)
        useAlert('Fotos duplicadas com sucesso', 'Success')
        onClose()
        if (callback) callback()
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  useEffect(() => {
    async function getData() {
      try {
        const res = await GetAlternativePhotoType<types[]>(data.codigoAtendimento)

        if (res) {
          setTypes(res)
          setCheckedState(new Array(res.length).fill(false))
        }
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    }

    getData()
  }, [])

  return (
    <AnimatedModal
      headerTitle='Duplicidade da foto'
      containerStyle={{height: 'auto', width: '400px'}}
      onClose={onClose}
      footer={
        <Footer>
          <Button onClick={handleDuplicatePhoto} color='primary'>
            OK
          </Button>
        </Footer>
      }
    >
      <Container>
        {types.map((item, index) => (
          <div className='checkbox'>
            <input
              checked={checkedState[index]}
              onChange={() => handleOnChange(index)}
              type='checkbox'
              id={String(index)}
              value={item.codigo}
            />
            <label htmlFor={String(index)}>{item.nome}</label>
          </div>
        ))}
      </Container>
    </AnimatedModal>
  )
}

export default DuplicateModal
