import styled from 'styled-components'

type ContainerProps = {
  schema: {color: string; background: string}
}

export const Container = styled.div<ContainerProps>`
  padding: 0.15rem 0.75rem;
  border-radius: 0.85rem;

  color: ${({schema}) => schema.color};
  background-color: ${({schema}) => schema.background};

  font-size: 0.8rem;

  height: 20px;

  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  font-weight: 500 !important;
`
