import styled from 'styled-components'
import {animated} from '@react-spring/web'

export const ContainerTree = styled('div')`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: ui-monospace, monospace;
  font-size: 14px;
  line-height: 21px;
  --webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`

export const Frame = styled('div')`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  vertical-align: middle;
  color: #fff;
  fill: #24292e;

  width: 100%;
  margin-left: auto;

  background-color: #445159;

  position: relative;

  .frame-header {
    display: flex;
    align-items: center;

    cursor: pointer;

    padding: 8px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
    background-image: linear-gradient(130deg, #00b4e6, #00f0e0);
    height: 1px;
  }
`

export const Title = styled('span')``

export const Content = styled(animated.div)`
  color: white;
  will-change: transform, opacity, height;
  overflow: auto;

  /* max-height: 750px; */

  padding-left: 20px;

  :active {
    background-image: linear-gradient(130deg, #00b4e6, #00f0e0);
  }
`

export const toggle = {
  width: '1em',
  height: '1em',
  marginRight: 10,
  cursor: 'pointer',
  verticalAlign: 'middle',
}
