/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { DateRangePicker } from 'rsuite'
import { format } from 'date-fns'
import { DateRange } from 'rsuite/esm/DateRangePicker'
import ptBR from 'date-fns/locale/pt-BR'

import { useManutencaoExclusao } from 'src/app/hooks/useManutencaoExclusao'
import { useAlert } from 'src/app/components/Alert/Alert'
import { GetFotosHomologacao, GetSolicitacaoExclusao, UncheckSolicitacaoPosto, UpdateDeletePostoBase } from 'src/app/repositories/ManutencaoExclusao'
import { Convert_YYYYMMDD_to_DDMMYYYY, ConvertDateToStr, GetDateNow } from 'src/app/utils/Date'
import { IFilter } from 'src/app/model/Generic/Type'
import { Select } from 'src/app/components/Select/Select'
import { getImageFromBucket } from 'src/app/utils/API'
import { IDetailFotografia } from 'src/app/model/ManutencaoExclusao/Type'
import ExclusaoTable from './components/ExclusaoTable'
import Button from 'src/app/components/Button'
import ImageModal from '../../avaliacao-pesquisa/validacao/Service/components/Card/components/ImageModal'
import { ExclusaoModel } from 'src/app/model/ManutencaoExclusao/ExclusaoModel'

export default function ManutencaoExclusao() {
  const DEFAULT_STATUS = {label: "PENDENTE", value: '1'};
  const {solicitacao, setSolicitacao, filters, setFilters} = useManutencaoExclusao();
  const [codigosSelect, setCodigosSelect] = useState<string[]>([]);

  const [total, setTotal] = useState<number>(0);
  const [pesquisadores, setPesquisadores] = useState<IFilter[]>([]);
  const [status, setStatus] = useState<IFilter[]>([]);

  const [inputStatus, setInputStatus] = useState<IFilter>(DEFAULT_STATUS);

  const [atendimentoHomologacao, setAtendimentoHomologacao] = useState<number>(0);

  const [fotos, setFotos] = useState<IDetailFotografia>();
  const [showModalFoto, setShowModalFoto] = useState<boolean>(false);
  const [disabledBtnExclusionAll, setDisabledBtnExclusionAll] = useState<boolean>(false);
  const [disabledBtnExclusionSelects, setDisabledBtnExclusionSelects] = useState<boolean>(true);
  const [disabledBtnExclusionUnmark, setDisabledBtnExclusionUnmark] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setCorrectDateTitle = (values: any) => {
    let msg = '';

    if ( values && values[0] && values[1] ) {
      let dOne = format(values[0], 'dd/MM/yyyy', {locale: ptBR})
      let dTwo = format(values[1], 'dd/MM/yyyy', {locale: ptBR})

      msg = dOne === dTwo ? dOne : `${dOne}-${dTwo}`;
    }

    return msg;
  }

  const onSetValuePesquisador = (newItem: any) => {
    let strValue = newItem?.value || null;
    setFilters( (prev) => ({...prev, codigoPesquisador: strValue}) );
  }

  const onSetValueStatus = (newItem: any) => {
    let strValue = newItem.value ? newItem.label : null;
    setInputStatus(newItem);
    setFilters( (prev) => ({...prev, status: strValue}) );
  }

  const pendingSelectMarkOff = () => {
    if ( codigosSelect.length > 0 ) {
      let parseCodigosNumber : number[] = codigosSelect.map((codigo : string) => Number(codigo));
      markOffPosto(parseCodigosNumber);
    }
  }

  const pendingSelectsDeletion = () => {
    if ( codigosSelect.length > 0 ) {
      let parseCodigosNumber : number[] = codigosSelect.map((codigo : string) => Number(codigo));
      deletePostoDaBase(parseCodigosNumber);
    }
  }

  const pendingTotalDeletion = () => {
    setDisabledBtnExclusionAll(true);
    let codigosSolicitacoes: number[] = solicitacao
                                        .filter((item: ExclusaoModel) => item.situacaoSolicitacao === DEFAULT_STATUS.label)
                                        .map((item: ExclusaoModel) => item.codigo);
    if (codigosSolicitacoes.length > 0) {
      deletePostoDaBase(codigosSolicitacoes);
    }
  }

  const getData = async () => {
    setIsLoading(true);
    try {
      const result = await GetSolicitacaoExclusao(filters); 
      
      setTotal(result.total);
      setSolicitacao(result.solicitacoes);
      setPesquisadores( result.pesquisadores.map(item => ( { label: item.nome, value: item.codigo } as IFilter )) );
      setStatus( result.statusExclusao.map(item => ( { label: item.nome, value: item.codigo } as IFilter )) );

      let disabledAction = result.solicitacoes.some(item => item.situacaoSolicitacao === DEFAULT_STATUS.label);
      setDisabledBtnExclusionAll(!disabledAction);
      setDisabledBtnExclusionSelects(true);
      setDisabledBtnExclusionUnmark(true);
      setCodigosSelect([]);
    } catch (error: any) {
      useAlert(error.message, 'Error');
    } finally {
      setIsLoading(false);
    }
  }

  const getHomologacao = async ( codigo: number ) => { 
    try {
      const result = await GetFotosHomologacao(codigo);
      setFotos(result);
      setShowModalFoto(true);
    } catch (error: any) {
      console.log(error);
      useAlert(error.message, 'Error');
    }
  }

  const deletePostoDaBase = async (codigos: number[]) => {
    setIsLoading(true);
    try {
      const { SolicitacoesNaoAtualizadas } =  await UpdateDeletePostoBase(codigos);
      setCodigosSelect([]);

      if ( SolicitacoesNaoAtualizadas && SolicitacoesNaoAtualizadas.length > 0 ) {
        let msg = `Os pontos de coleta ${SolicitacoesNaoAtualizadas.join(",")} não foram removidos da base operaciona.`;
        useAlert(msg, 'Error');
      }

      await getData();
    } catch (error: any) {
      console.log(error);
      useAlert(error.message, 'Error');
      setDisabledBtnExclusionAll(true);
    } finally {
      setIsLoading(false);
    }
  }

  const markOffPosto = async (codigos: number[]) => {
    setIsLoading(true);
    try {
      const { SolicitacoesNaoAtualizadas } = await UncheckSolicitacaoPosto(codigos);
      setCodigosSelect([]);
      
      if ( SolicitacoesNaoAtualizadas && SolicitacoesNaoAtualizadas.length > 0 ) {
        let msg = `Os pontos de coleta ${SolicitacoesNaoAtualizadas.join(",")} não foram desmarcados.`;
        useAlert(msg, 'Error');
      }

      await getData();
    } catch (error: any) {
      console.log(error);
      useAlert(error.message, 'Error');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    if ( atendimentoHomologacao > 0 ) {
      getHomologacao(atendimentoHomologacao);
    }
  }, [atendimentoHomologacao]);

  useEffect(() => {
    let disabledBtn = codigosSelect.length == 0;

    setDisabledBtnExclusionSelects(disabledBtn);
    setDisabledBtnExclusionUnmark(disabledBtn);
  }, [codigosSelect]);

  return (
    <>
      {(showModalFoto && fotos?.homologado) && (
        <ImageModal
          titleFirstFoto='Foto PR (Atual)'
          firstFoto={getImageFromBucket(fotos?.panoramica)}
          titleSecondFoto='Foto PR (Homologada)'
          secondFoto={getImageFromBucket(fotos?.homologado)}
          onclose={() => setShowModalFoto(false)}
        />
      )}

      {(showModalFoto && !fotos?.homologado) && (
        <ImageModal
          titleFirstFoto='Foto PR (Atual)'
          firstFoto={getImageFromBucket(fotos?.panoramica)}
          onclose={() => setShowModalFoto(false)}
        />
      )}

      <div className='card mb-4'>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center flex-wrap mb-2'>
            <div className='fw-bolder'>
              <p className='fs-4 m-0'>
                Total de solicitações: <span className='text-danger'>{total}</span>
              </p>
            </div>
            <div className='d-flex' style={{gap: 5}}>
              <Select
                // styles={{width: '13rem'}}
                placeholder={'Situação'}
                isMulti={false}
                options={status}
                setSelectedOption={(newValue: any) => onSetValueStatus(newValue)}
                value={inputStatus}
              />
              <Select
                // styles={{width: '18rem'}}
                placeholder={'Pesquisador'}
                isMulti={false}
                options={pesquisadores}
                setSelectedOption={(newValue: any) => onSetValuePesquisador(newValue)}
              />
              <DateRangePicker
                editable
                showOneCalendar
                placeholder={Convert_YYYYMMDD_to_DDMMYYYY(filters.dataInicio)}
                placement='autoHorizontalStart'
                format='dd/MM/yyyy'
                onClean={() => {
                  setFilters( (prev) => ({
                    ...prev, 
                    dataInicio: GetDateNow(),
                    dataFim: GetDateNow()
                  }));
                }}
                renderValue={(value) => {
                  let strValue = setCorrectDateTitle(value);
                  return <span>{strValue}</span>
                }}
                onChange={(value: DateRange | null) => {
                  if ( value ) {
                    setFilters((prev) => ({
                      ...prev,
                      dataInicio: ConvertDateToStr(value[0]),
                      dataFim: ConvertDateToStr(value[1])
                    }));
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <ExclusaoTable 
            data={solicitacao} 
            getData={getData} 
            isLoading={isLoading} 
            setAtendimentoHomologacao={setAtendimentoHomologacao} 
            deletePostoDaBase={deletePostoDaBase}
            markOffPosto={markOffPosto}
            rowSelect={codigosSelect}
            setRowSelect={setCodigosSelect}
          />

          <div className='card-toolbar d-flex gap-4 justify-content-end'>
            <Button icon='check' color='warning' disabled={disabledBtnExclusionUnmark} onClick={pendingSelectMarkOff}>
              Desmarcar selecionado(s)
            </Button>

            <Button icon='check' color='danger' disabled={disabledBtnExclusionSelects} onClick={pendingSelectsDeletion}>
              Excluir selecionado(s)
            </Button>

            <Button icon='cancel' color='danger' disabled={disabledBtnExclusionAll} onClick={pendingTotalDeletion}>
              Exclusão Total pendente(s)
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
