/* eslint-disable react-hooks/exhaustive-deps */
// import { Container } from './styles';

import {ReactNode, useEffect, useMemo, useState} from 'react'
import AnimatedModal from 'src/app/components/AnimatedModal'
import {
  CardBody,
  CardContainer,
} from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/CardHeader/components/HomologacaoModal/styles'
import {Container} from './styles'
import ImageModal from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/Card/components/ImageModal'
import {
  GetHomologatedPhoto,
  GetHomologatedPhotoData,
  GetHomologatedPhotoResponse,
} from 'src/app/repositories/OperationalBase'
import {getImageFromBucket} from 'src/app/utils/API'

const Card = ({
  title,
  children,
  footer,
}: {
  title: string
  children: ReactNode
  footer?: ReactNode
}) => {
  return (
    <CardContainer>
      <div className='cardHeader'>
        <h3 className='card-title'>{title}</h3>
      </div>
      <div className='cardBody'>{children}</div>
      {footer && <div className='cardFooter'>{footer}</div>}
    </CardContainer>
  )
}

const Item = ({item, handleOpenModal}: any) => {
  const fotoPr = useMemo(
    () =>
      getImageFromBucket(item.fotoPR) ||
      'https://www.russorizio.com/wp-content/uploads/2016/07/ef3-placeholder-image.jpg',
    [item]
  )

  const fotoQa = useMemo(
    () =>
      getImageFromBucket(item.fotoQA) ||
      'https://www.russorizio.com/wp-content/uploads/2016/07/ef3-placeholder-image.jpg',
    [item]
  )

  return (
    <Card title={item.tipoExecucao}>
      <CardBody>
        <div className='image' onClick={() => handleOpenModal(fotoPr)}>
          <p className='image-title'>Panorâmica</p>
          <img src={fotoPr} alt='' />
        </div>
        <div className='image' onClick={() => handleOpenModal(fotoQa)}>
          <p className='image-title'>Quadro de aviso</p>
          <img src={fotoQa} alt='' />
        </div>
      </CardBody>
    </Card>
  )
}

const HomologatedModal = ({onClose, rowDetail}: Props) => {
  const [showImageModal, setShowImageModal] = useState(false)

  const [data, setData] = useState<GetHomologatedPhotoResponse[]>([])

  const noData = data.length === 0

  const [currentImage, setCurrentImage] = useState('')

  const handleCloseModal = () => setShowImageModal(false)
  const handleOpenModal = (image: string) => {
    setCurrentImage(image)
    setShowImageModal(true)
  }

  const getData = async () => {
    const o: GetHomologatedPhotoData = {codigoPontoColeta: rowDetail.codigo}
    const res = await GetHomologatedPhoto(o)

    if (res) {
      setData(res)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AnimatedModal
      onClose={onClose}
      containerStyle={{width: noData ? 400 : 'auto', height: 'auto'}}
      hasFooter={false}
      headerTitle='Foto homologada'
    >
      <Container>
        {data.map((item, index) => (
          <Item key={String(index)} item={item} handleOpenModal={handleOpenModal} />
        ))}

        {noData && (
          <div className='empty'>
            <h2>Ponto de coleta sem homologação</h2>
          </div>
        )}
      </Container>

      {showImageModal && (
        <ImageModal
          titleFirstFoto='Foto Homologada'
          firstFoto={currentImage}
          onclose={handleCloseModal}
        />
      )}
    </AnimatedModal>
  )
}

export default HomologatedModal

type Props = {
  onClose: () => void
  rowDetail: any
}
