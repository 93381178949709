import api from '../config'
import Erro from '../model/Erro/Erro'
import {handlerResult} from '../utils/API'

export type DataCreateNote = {
  codigoPontoColeta: string
  descricao: string
}

export type DataUpdateNote = {
  codigo: number
  descricao: string
}

export async function GetNotes(codigoPontoColeta: string) {
  try {
    const response = await api.post('anotacao/listar', {codigoPontoColeta})
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function CreateNote(DataCreateNote: DataCreateNote) {
  try {
    const response = await api.post('anotacao/adicionar', DataCreateNote)
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function DeleteNote(codigo: number) {
  try {
    const response = await api.post('anotacao/remover', {codigo})
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function UpdateNote(DataUpdateNote: DataUpdateNote) {
  try {
    const response = await api.post('anotacao/atualizar', DataUpdateNote)
    const dataResponse = await handlerResult(response.data)
    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}
