import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 5px;

  padding: 4px 10px;

  main {
    gap: 5px;
  }

  main,
  aside {
    flex: 1;
  }

  aside {
    display: flex;

    gap: 5px;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    background-color: transparent;
  }
`
