/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {useState, useEffect, SetStateAction, Dispatch} from 'react'

import {useIntl} from 'react-intl'

import {useFormik} from 'formik'
import * as Yup from 'yup'

import cep from 'cep-promise'

import AnimatedModal from 'src/app/components/AnimatedModal'

import AccordionCollapseItem from '../../../CentroCusto/components/AccordionCollapseItem'
import {
  CloseIcon,
  AddressCardIcon,
  HouseIcon,
  BankIcon,
  PlusIcon,
  CircleCheckIcon,
} from 'src/app/utils/Icons'
import {ContainerBox} from '../../../CentroCusto/components/CostCenterCreationModal/styles'
import InputText from 'src/app/components/Form/InputText'
import {ModalHeader} from 'src/app/pages/avaliacao-pesquisa/validacao/Service/components/CardHeader/components/ChangeModal/styles'
import InputImage from 'src/app/components/InputImage'
import {Select} from 'src/app/components/Select/Select'
import 'react-phone-input-2/lib/style.css'
import {useAlert} from 'src/app/components/Alert/Alert'
import {GetRawCostCenters} from 'src/app/repositories/CenterCost'
import {CreateUser, IUserCreationSelectInfos, updateUser} from 'src/app/repositories/User'

import {Container} from './styles'
import {creationUserData} from '../../Type'
import {
  agenciaValidation,
  contaValidation,
  cpfValidation,
  emailValidation,
  grupoAcessoValidation,
  nomeValidation,
  optionsValue,
  rgValidation,
} from './validations'
import UsuarioModel from 'src/app/model/Usuario/Usuario'
import {Convert_DDMMYYYY_to_YYYYMMDD} from 'src/app/utils/Date'
import Footer from '../Footer'
import InputPhone from 'src/app/components/InputPhone'
import {Table} from '../../../CentroCusto/components/CostCenterBase/styles'
import AnimatedInput from 'src/app/components/AnimatedInput'
import {GetMunicipiosPorEstado} from 'src/app/repositories/Location'
import TableRow from '../TableRow'
import ModalAlert from 'src/app/components/ModalAlert'
import {IModalAlert} from '../../Usuarios'
import {ICidadesValor} from 'src/app/model/Usuario/Type'
import {cepMask, costCenterValueMask, cpfMask, formatCEP, formatCPF} from 'src/app/utils/Formats'
import {v4 as uuidv4} from 'uuid'

const UserCreationModal = ({
  onClose,
  isEditing,
  userCreationSelectInfos,
  userInfo,
  setUserInfo,
  setUsers,
  getUserQuantitative,
}: Props) => {
  const [modalAlert, setModalAlert] = useState<IModalAlert | undefined>(undefined)
  const [isFilled, setIsFilled] = useState(false)
  const [cities, setCities] = useState<OptionType[]>([])
  const [userCostCenter, setUserCostCenter] = useState<ICidadesValor>({} as ICidadesValor)
  const [userCostCenters, setUserCostCenters] = useState<ICidadesValor[]>(
    userInfo.cidadesValor || []
  )
  const [searchInput, setSearchInput] = useState('')

  const [imagem, setImagem] = useState('')
  const [centerCostRawData, setCenterCostRawData] = useState<
    {
      codigo: string
      nome: string
    }[]
  >([])

  const intl = useIntl()

  const centerCostList = centerCostRawData.map((item) => {
    return {value: item.codigo, label: `${item.nome}`}
  })

  const filteredUserCostCenters = () => {
    const filtroLowerCase = searchInput.toLowerCase()
    const resultadosFiltrados = userCostCenters.filter(
      (item) =>
        item.cidade.toLowerCase().includes(filtroLowerCase) ||
        item.uf.toLowerCase().includes(filtroLowerCase) ||
        item.nomeCentroDeCusto?.toLowerCase().includes(filtroLowerCase)
    )
    return resultadosFiltrados
  }

  const validationSchema = Yup.object().shape({
    nome: nomeValidation(intl),
    email: emailValidation(intl),
    agencia: agenciaValidation(intl),
    grupoAcesso: grupoAcessoValidation(intl),
    dataEmissaoRg: rgValidation('Data Emissão', intl),
    orgaoEmissorRg: rgValidation('Orgão Emissor', intl),
    conta: contaValidation(intl),
    cpf: cpfValidation(intl),
    // cidade: cidadeValidation(intl),
  })

  const initialValues = {
    telefone: userInfo.telefone || '',
    nome: userInfo.nome || '',
    email: userInfo.email || '',
    costCenterValue: '',
    rg: userInfo.rg || '',
    orgaoEmissorRg: userInfo.orgaoEmissorRg || '',
    nacionalidade: userInfo.nacionalidade || '',
    cep: formatCEP(userInfo.cep),
    cpf: formatCPF(userInfo.cpf),
    bairro: userInfo.bairro || '',
    cidade: optionsValue,
    centro: optionsValue,
    logradouro: userInfo.logradouro || '',
    complemento: userInfo.complemento || '',
    numero: userInfo.numero || 0,
    agencia: userInfo.agencia || '',
    conta: userInfo.conta || '',
    chavePix: userInfo.chavePix || '',
    dataEmissaoRg:
      (userInfo.dataEmissaoRg && Convert_DDMMYYYY_to_YYYYMMDD(userInfo.dataEmissaoRg)) || '',
    dataNascimento:
      (userInfo.dataNascimento && Convert_DDMMYYYY_to_YYYYMMDD(userInfo.dataNascimento)) || '',
    grupoAcesso: userInfo.perfil || optionsValue,
    estadoCivil: userInfo.estadoCivil || optionsValue,
    genero: userInfo?.genero || optionsValue,
    uf: userInfo.uf || optionsValue,
    cidadeUf: optionsValue,
    banco: userInfo.banco || optionsValue,
    tipoConta: userInfo.tipoConta || optionsValue,
    cidadeUser: userInfo.cidade || '',
  }

  const formik = useFormik<IinitialValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const {
        grupoAcesso,
        agencia,
        bairro,
        banco,
        cep,
        chavePix,
        complemento,
        conta,
        dataEmissaoRg,
        dataNascimento,
        email,
        estadoCivil,
        genero,
        logradouro,
        nacionalidade,
        nome,
        numero,
        orgaoEmissorRg,
        rg,
        telefone,
        tipoConta,
        uf,
        cpf,
        cidadeUser,
      } = values

      cpfMask.resolve(cpf)
      cepMask.resolve(cep)

      const o: creationUserData = {
        nome: nome || null,
        email: email || null,
        codigoPerfil: grupoAcesso.value! || null,
        telefone: telefone || null,
        cpf: cpfMask.unmaskedValue || null,
        rg: rg.replaceAll(',', '').replaceAll('-', '').replaceAll('.', '') || null,
        dataEmissaoRg: dataEmissaoRg || null,
        orgaoEmissorRg: orgaoEmissorRg || null,
        dataNascimento: dataNascimento || null,
        nacionalidade: nacionalidade || null,
        cep: cepMask.unmaskedValue || null,
        bairro: bairro || null,
        complemento: complemento || null,
        numero: numero || null,
        agencia: agencia || null,
        conta: conta || null,
        chavePix: chavePix || null,
        imagem: imagem,
        cidadesValor:
          userCostCenters.map((item) => {
            delete item['codigo']
            return item
          }) || null,
        cidade: cidadeUser || null,
        uf: uf.value || null,
        logradouro: logradouro || null,
        banco: banco.value || null,
        genero: genero.value || null,
        tipoConta: tipoConta.value || null,
        estadoCivil: estadoCivil.value || null,
      }

      try {
        if (isEditing) {
          o.codigo = userInfo.codigo
          const res = await updateUser(o)

          if (res) {
            setUsers((oldState) => {
              return oldState.map((item) => {
                if (item.codigo === res!.codigo) {
                  item = res!
                }
                return item
              })
            })

            useAlert(`Usuário editado com sucesso.`, 'Success')
          }
        } else {
          const res = await CreateUser(o)

          if (res) {
            setUsers((oldState) => {
              const listCopy = oldState

              listCopy.unshift(res!)

              return listCopy
            })
          }

          useAlert(
            `Usuário criado com sucesso. Um e-mail com a senha temporária foi enviado ao usuário.`,
            'Success'
          )
        }
        getUserQuantitative()
        onClose()
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    },
  })

  const isPesquisador = formik.getFieldProps('grupoAcesso').value.value === 'PESQUISADOR'
  const isRgFilled =
    formik.getFieldProps('rg').value !== undefined && formik.getFieldProps('rg').value !== ''

  centerCostList.unshift({
    label: formik.getFieldProps('nome').value,
    //@ts-ignore
    value: null,
  })

  const Header = ({onClick}: any) => {
    return (
      <ModalHeader>
        <span>{isEditing ? 'Editar Usuário' : 'Criar Usuário'}</span>
        <button onClick={onClick}>
          <CloseIcon size={20} fill='#666666' />
        </button>
      </ModalHeader>
    )
  }

  const handleUpdateUserImage = (value: string) => {
    setUserInfo((oldState) => {
      const stateCopy = oldState

      stateCopy.imagem = value

      return stateCopy
    })
  }

  const handleUpdateUserCostCenter = (key: keyof ICidadesValor, value: string) => {
    setUserCostCenter((oldState) => ({...oldState, [key]: value}))
  }

  const getData = async () => {
    try {
      const res = await GetRawCostCenters()
      if (res) {
        setCenterCostRawData(res)
      }
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  const handleFillUserCostCenter = async (item: ICidadesValor) => {
    setIsFilled(true)

    setUserCostCenter(item)

    await formik.setFieldValue('cidadeUf', {value: item.uf, label: item.uf})
    await formik.setFieldValue('cidade', {value: item.cidade, label: item.cidade})
    await formik.setFieldValue('centro', {
      value: item.nomeCentroDeCusto,
      label: item.nomeCentroDeCusto,
    })
    await formik.setFieldValue('costCenterValue', item.valor)

    await formik.validateForm()
  }

  const resetCidadeUFCustoFields = async () => {
    await formik.setFieldValue('cidadeUf', optionsValue)
    await formik.setFieldValue('cidade', optionsValue)
    await formik.setFieldValue('centro', optionsValue)
    await formik.setFieldValue('costCenterValue', '')
  }

  const handleAddCidadeUFCusto = () => {
    if (
      userCostCenter.cidade &&
      userCostCenter.uf &&
      userCostCenter.valor &&
      userCostCenter.nomeCentroDeCusto
    ) {
      const copy = userCostCenter
      copy.codigo = uuidv4()
      setUserCostCenters((oldState) => [...oldState, copy])

      resetCidadeUFCustoFields()

      setUserCostCenter({} as ICidadesValor)

      formik.validateForm()
    }
  }

  const handleUpdateCidadeUFCusto = () => {
    setUserCostCenters((oldState) => {
      return oldState.map((item) => {
        if (item.codigo === userCostCenter.codigo) {
          item = userCostCenter
        }

        return item
      })
    })

    resetCidadeUFCustoFields()

    setUserCostCenter({} as ICidadesValor)

    setIsFilled(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AnimatedModal
      header={<Header />}
      footer={<Footer isEditing={isEditing} formik={formik} />}
      containerStyle={{padding: 16}}
      onClose={onClose}
    >
      <Container>
        <AccordionCollapseItem height={440} title='Cadastro' Icon={AddressCardIcon} initOpen>
          <div className='container-row'>
            <ContainerBox>
              <InputImage
                setDataFile={handleUpdateUserImage}
                fileByte={userInfo?.imagemUrl}
                setImagem={setImagem}
              />
            </ContainerBox>

            <div className='box-container'>
              <div className='container-row'>
                <ContainerBox>
                  <InputText
                    required
                    placeholder='Digite o nome'
                    height={80}
                    label='Nome'
                    formik={{
                      formikProp: 'nome',
                      formikValues: formik,
                    }}
                  />
                </ContainerBox>
                <ContainerBox>
                  <Select
                    required
                    label='Grupo'
                    error={Boolean(formik.errors['grupoAcesso'])}
                    options={userCreationSelectInfos.perfis}
                    formik={{
                      formikProp: 'grupoAcesso',
                      formikValues: formik,
                    }}
                  />
                  {formik && formik.errors['grupoAcesso'] && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors['grupoAcesso'].label}</span>
                    </div>
                  )}
                </ContainerBox>
              </div>
              <div className='container-row'>
                <ContainerBox>
                  <InputText
                    required
                    height={80}
                    placeholder='Digite o email'
                    label='Email'
                    formik={{
                      formikProp: 'email',
                      formikValues: formik,
                    }}
                  />
                </ContainerBox>
                <ContainerBox>
                  <InputPhone formik={formik} />
                </ContainerBox>
              </div>
            </div>
          </div>

          <div className='container-row'>
            <ContainerBox>
              <InputText
                required={isPesquisador}
                height={80}
                placeholder='Somente números'
                label='CPF'
                formik={{
                  formikProp: 'cpf',
                  formikValues: formik,
                }}
                onChange={(event: any) => {
                  cpfMask.resolve(event.target.value)
                  formik.setFieldValue('cpf', cpfMask.value)
                }}
              />
            </ContainerBox>{' '}
            <ContainerBox>
              <InputText
                height={80}
                placeholder='Digite o RG'
                label='RG'
                formik={{
                  formikProp: 'rg',
                  formikValues: formik,
                }}
              />
            </ContainerBox>{' '}
            <ContainerBox>
              <InputText
                required={isRgFilled}
                height={80}
                label='Data emissão'
                type='date'
                formik={{
                  formikProp: 'dataEmissaoRg',
                  formikValues: formik,
                }}
              />
            </ContainerBox>{' '}
            <ContainerBox>
              <InputText
                required={isRgFilled}
                height={80}
                placeholder='Digite o orgão emissor'
                label='Orgão emissor'
                formik={{
                  formikProp: 'orgaoEmissorRg',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
          </div>
          <div className='container-row'>
            <ContainerBox>
              <InputText
                height={80}
                label='Data Nascimento'
                type='date'
                formik={{
                  formikProp: 'dataNascimento',
                  formikValues: formik,
                }}
              />
            </ContainerBox>{' '}
            <ContainerBox>
              <InputText
                height={80}
                placeholder='Digite a nacionalidade'
                label='Nacionalidade'
                formik={{
                  formikProp: 'nacionalidade',
                  formikValues: formik,
                }}
              />
            </ContainerBox>{' '}
            <ContainerBox>
              <Select
                label='Estado Civil'
                error={Boolean(formik.touched['estadoCivil'] && formik.errors['estadoCivil'])}
                options={userCreationSelectInfos.estadosCivis}
                formik={{
                  formikProp: 'estadoCivil',
                  formikValues: formik,
                }}
              />
              {formik && formik.touched['estadoCivil'] && formik.errors['estadoCivil'] && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors['estadoCivil'].label}</span>
                </div>
              )}
            </ContainerBox>{' '}
            <ContainerBox>
              <Select
                label='Gênero'
                error={Boolean(formik.touched['genero'] && formik.errors['genero'])}
                options={userCreationSelectInfos.generos}
                formik={{
                  formikProp: 'genero',
                  formikValues: formik,
                }}
              />
              {formik && formik.touched['genero'] && formik.errors['genero'] && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors['genero'].label}</span>
                </div>
              )}
            </ContainerBox>
          </div>
        </AccordionCollapseItem>

        {isPesquisador && (
          <AccordionCollapseItem height={520} title='Cidade UF Custo' Icon={HouseIcon}>
            <div className='container-row column'>
              <AnimatedInput
                titulo='Buscar'
                value={searchInput}
                placeholder='Cidade/Uf ou Centro de Custo'
                onChange={(e) => {
                  setSearchInput(e.target.value)
                }}
                type='text'
              />
              <div className='table-container'>
                <Table width={25}>
                  <tr>
                    <th>Cidade/Uf</th>
                    <th>Centro de Custo</th>
                    <th style={{width: 80}}>Valor</th>
                    <th style={{width: 40}}>Ação</th>
                  </tr>
                  {filteredUserCostCenters().map((item) => (
                    <TableRow
                      item={item}
                      setModalAlert={setModalAlert}
                      setData={setUserCostCenters}
                      handleFillUserCostCenter={handleFillUserCostCenter}
                    />
                  ))}
                </Table>
              </div>
            </div>
            <div className='container-row'>
              <ContainerBox flex={5}>
                <Select
                  label='UF'
                  error={Boolean(formik.errors['cidadeUf'])}
                  options={userCreationSelectInfos.estados}
                  formik={{
                    formikProp: 'cidadeUf',
                    formikValues: formik,
                  }}
                  onChange={async (newValue: any) => {
                    await formik.setFieldValue('cidadeUf', newValue)
                    await formik.setFieldValue('cidade', {value: '', label: ''})

                    handleUpdateUserCostCenter('uf', newValue.value)

                    try {
                      const res = await GetMunicipiosPorEstado(newValue.value)

                      if (res) {
                        setCities(res)
                      }
                    } catch (error) {
                      useAlert('Erro ao listar as cidade', 'Error')
                    }
                  }}
                />
                {formik && formik.touched['cidadeUf'] && formik.errors['cidadeUf'] && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors['cidadeUf'].label}</span>
                  </div>
                )}
              </ContainerBox>
              <ContainerBox flex={5}>
                <Select
                  label='Cidade'
                  isDisabled={!Boolean(formik.getFieldProps('cidadeUf').value.value)}
                  error={!Boolean(formik.getFieldProps('cidadeUf').value.value)}
                  options={cities}
                  formik={{
                    formikProp: 'cidade',
                    formikValues: formik,
                  }}
                  onChange={async (newValue: any) => {
                    await formik.setFieldValue('cidade', newValue)

                    handleUpdateUserCostCenter('cidade', newValue.value)
                  }}
                />
                {!Boolean(formik.getFieldProps('cidadeUf').value.value) && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>Selecione uma UF</span>
                  </div>
                )}
              </ContainerBox>
              <ContainerBox flex={5}>
                <InputText
                  height={80}
                  placeholder='Digite o valor'
                  label='Valor'
                  formik={{
                    formikProp: 'costCenterValue',
                    formikValues: formik,
                  }}
                  onChange={async (e) => {
                    costCenterValueMask.resolve(e.target.value)
                    formik.setFieldValue('costCenterValue', costCenterValueMask.value)
                    handleUpdateUserCostCenter('valor', e.target.value.replace(',', '.'))
                  }}
                />
              </ContainerBox>
              <ContainerBox flex={5}>
                <Select
                  label='Centro'
                  error={Boolean(formik.touched['centro'] && formik.errors['centro'])}
                  options={centerCostList}
                  formik={{
                    formikProp: 'centro',
                    formikValues: formik,
                  }}
                  onChange={async (newValue: any) => {
                    await formik.setFieldValue('centro', newValue)

                    handleUpdateUserCostCenter('nomeCentroDeCusto', newValue.label)
                    handleUpdateUserCostCenter('codigoCentroDeCusto', newValue.value)
                  }}
                />
                {formik && formik.touched['centro'] && formik.errors['centro'] && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors['centro'].label}</span>
                  </div>
                )}
              </ContainerBox>
              <ContainerBox style={{justifyContent: 'center'}}>
                <button
                  className='add'
                  onClick={isFilled ? handleUpdateCidadeUFCusto : handleAddCidadeUFCusto}
                >
                  {isFilled ? (
                    <CircleCheckIcon color='#00E396' size={30} />
                  ) : (
                    <PlusIcon size={30} />
                  )}
                </button>
              </ContainerBox>
            </div>
          </AccordionCollapseItem>
        )}

        <AccordionCollapseItem height={490} title='Dados Pessoais' Icon={BankIcon}>
          <div className='d-flex align-items-center'>
            <span className='line'></span>
            <span className='line-name'>Endereço</span>
            <span className='line'></span>
          </div>
          <div className='container-row'>
            <ContainerBox flex={2}>
              <InputText
                height={80}
                label='Cep'
                placeholder='Digite o cep'
                formik={{
                  formikProp: 'cep',
                  formikValues: formik,
                }}
                onChange={async (e) => {
                  cepMask.resolve(e.target.value)
                  formik.setFieldValue('cep', cepMask.value)
                }}
                onBlur={async (e) => {
                  try {
                    const {
                      city: cidade,
                      neighborhood: bairro,
                      state,
                      street: logradouro,
                    } = await cep(e.target.value)

                    formik.setFieldValue('cidadeUser', cidade)
                    formik.setFieldValue('bairro', bairro)
                    formik.setFieldValue('logradouro', logradouro)
                    formik.setFieldValue('uf', {label: state, value: state})
                  } catch (error: any) {
                    // useAlert('Cep incorreto', 'Error')
                  }
                }}
              />
            </ContainerBox>
            <ContainerBox flex={2}>
              <InputText
                height={80}
                label='Bairro'
                placeholder='Digite o bairro'
                formik={{
                  formikProp: 'bairro',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
            <ContainerBox flex={2}>
              <InputText
                height={80}
                label='Cidade'
                placeholder='Digite a cidade'
                formik={{
                  formikProp: 'cidadeUser',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
            <ContainerBox>
              <Select
                label='UF'
                error={Boolean(formik.touched['uf'] && formik.errors['uf'])}
                options={userCreationSelectInfos.estados}
                formik={{
                  formikProp: 'uf',
                  formikValues: formik,
                }}
              />
              {formik && formik.touched['uf'] && formik.errors['uf'] && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors['uf'].label}</span>
                </div>
              )}
            </ContainerBox>
          </div>
          <div className='container-row'>
            <ContainerBox flex={3}>
              <InputText
                height={80}
                placeholder='Digite o logradouro. ex: rua birigui'
                label='Logradouro'
                formik={{
                  formikProp: 'logradouro',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
            <ContainerBox flex={3}>
              <InputText
                height={80}
                placeholder='Digite o complemento'
                label='Complemento'
                formik={{
                  formikProp: 'complemento',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
            <ContainerBox>
              <InputText
                height={80}
                label='Número'
                type='number'
                formik={{
                  formikProp: 'numero',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
          </div>
          <div className='d-flex align-items-center'>
            <span className='line'></span>
            <span className='line-name'>Dados Bancários</span>
            <span className='line'></span>
          </div>
          <div className='container-row'>
            <ContainerBox flex={2}>
              <Select
                label='Tipo Conta'
                error={Boolean(formik.touched['tipoConta'] && formik.errors['tipoConta'])}
                options={userCreationSelectInfos.tiposContas}
                formik={{
                  formikProp: 'tipoConta',
                  formikValues: formik,
                }}
              />
              {formik && formik.touched['tipoConta'] && formik.errors['tipoConta'] && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors['tipoConta'].label}</span>
                </div>
              )}
            </ContainerBox>
            <ContainerBox flex={3}>
              <Select
                label='Banco'
                error={Boolean(formik.touched['banco'] && formik.errors['banco'])}
                options={userCreationSelectInfos.bancos}
                formik={{
                  formikProp: 'banco',
                  formikValues: formik,
                }}
              />
              {formik && formik.touched['banco'] && formik.errors['banco'] && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors['banco'].label}</span>
                </div>
              )}
            </ContainerBox>
          </div>
          <div className='container-row'>
            <ContainerBox>
              <InputText
                height={80}
                type='number'
                placeholder='Digite a agência'
                label='Agência'
                formik={{
                  formikProp: 'agencia',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
            <ContainerBox>
              <InputText
                height={80}
                type='number'
                label='Conta'
                placeholder='Digite o número da conta'
                formik={{
                  formikProp: 'conta',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
            <ContainerBox>
              <InputText
                height={80}
                label='Chave Pix'
                placeholder='Digite a chave pix'
                formik={{
                  formikProp: 'chavePix',
                  formikValues: formik,
                }}
              />
            </ContainerBox>
          </div>
        </AccordionCollapseItem>
      </Container>

      <ModalAlert
        isLoading={false}
        title={modalAlert?.title}
        content={modalAlert?.content}
        onConfirm={modalAlert?.action}
        onShow={Boolean(modalAlert)}
        onHide={() => setModalAlert(undefined)}
      />
    </AnimatedModal>
  )
}

export type Props = {
  getUserQuantitative: () => void
  onClose: () => void
  isEditing: boolean
  userCreationSelectInfos: IUserCreationSelectInfos
  userInfo: UsuarioModel
  setUserInfo: Dispatch<SetStateAction<UsuarioModel>>
  setUsers: Dispatch<SetStateAction<UsuarioModel[]>>
}

export interface IOptionsValue {
  value: string | undefined
  label: string | undefined
}

export interface IinitialValues {
  telefone: string
  nome: string
  email: string
  rg: string
  orgaoEmissorRg: string
  nacionalidade: string
  cep: string
  costCenterValue: string
  cpf: string
  bairro: string
  logradouro: string
  complemento: string
  numero: number
  agencia: string
  conta: string
  chavePix: string
  dataEmissaoRg: string
  dataNascimento: string
  cidade: IOptionsValue
  grupoAcesso: IOptionsValue
  estadoCivil: IOptionsValue
  genero: IOptionsValue
  uf: IOptionsValue
  cidadeUf: IOptionsValue
  banco: IOptionsValue
  tipoConta: IOptionsValue
  centro: IOptionsValue
  cidadeUser: string
}

export type OptionType = {
  value: string | number
  label: string
}

export type UserInfo = {
  codigo?: string
  email: string
  nome: string
  telefone: string
  cpf: string
  codigoPerfil: string
  rg: string
  dataEmissaoRg: string
  orgaoEmissorRg: string
  dataNascimento: string
  nacionalidade: string
  estadoCivil: string
  genero: string
  cep: string
  bairro: string
  cidade: string
  uf: string
  logradouro: string
  complemento: string
  numero: string
  banco: string
  tipoConta: string
  agencia: string
  conta: string
  chavePix: string
  codigoCentroDeCusto: number
  imagem: string
}

export default UserCreationModal
