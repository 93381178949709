import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {ICidadesValor, IUser, KeyValue, Situation} from './Type'
import StatusTag from 'src/app/components/StatusTag'

export default class UsuarioModel {
  private _tipoConta: KeyValue | null
  private _uf: KeyValue | null
  private _perfil: KeyValue
  private _banco: KeyValue | null
  private _genero: KeyValue | null
  private _estadoCivil: KeyValue | null
  private _conta: string
  private _codigo: string
  private _imagem: string
  private _nome: string
  private _email: string
  private _status: Situation
  private _quantidadeAcessos: number
  private _dataUltimoAcesso: string
  private _dataCriacao: string
  private _telefone: string
  private _primeiroAcesso: boolean
  private _temPendencia: boolean
  private _quantidadeTentativas: number
  private _agencia: string
  private _bairro: string
  private _cep: string
  private _chavePix: string
  private _cidade: string
  private _complemento: string
  private _cpf: string
  private _dataEmissaoRg: string
  private _dataNascimento: string
  private _logradouro: string
  private _nacionalidade: string
  private _numero: number
  private _orgaoEmissorRg: string
  private _rg: string
  private _cidadesValor: ICidadesValor[]

  constructor({
    codigo,
    imagem,
    nome,
    email,
    perfil,
    status,
    quantidadeAcessos,
    dataUltimoAcesso,
    dataCriacao,
    telefone,
    primeiroAcesso,
    quantidadeTentativas,
    agencia,
    bairro,
    banco,
    cep,
    chavePix,
    cidade,
    complemento,
    conta,
    cpf,
    dataEmissaoRg,
    dataNascimento,
    estadoCivil,
    genero,
    logradouro,
    nacionalidade,
    numero,
    orgaoEmissorRg,
    rg,
    tipoConta,
    uf,
    cidadesValor,
    temPendencia,
  }: IUser) {
    this._codigo = codigo
    this._imagem = imagem
    this._nome = nome
    this._email = email
    this._perfil = perfil
    this._status = status
    this._quantidadeAcessos = quantidadeAcessos
    this._dataUltimoAcesso = dataUltimoAcesso
    this._dataCriacao = dataCriacao
    this._telefone = telefone
    this._primeiroAcesso = primeiroAcesso
    this._quantidadeTentativas = quantidadeTentativas
    this._agencia = agencia
    this._bairro = bairro
    this._banco = banco
    this._cep = cep
    this._chavePix = chavePix
    this._cidade = cidade
    this._complemento = complemento
    this._conta = conta
    this._cpf = cpf
    this._dataEmissaoRg = dataEmissaoRg
    this._dataNascimento = dataNascimento
    this._estadoCivil = estadoCivil
    this._genero = genero
    this._logradouro = logradouro
    this._nacionalidade = nacionalidade
    this._numero = numero
    this._orgaoEmissorRg = orgaoEmissorRg
    this._rg = rg
    this._tipoConta = tipoConta
    this._uf = uf
    this._cidadesValor = cidadesValor
    this._temPendencia = temPendencia
  }

  get temPendencia() {
    return this._temPendencia
  }

  get codigo() {
    return this._codigo
  }

  get cidadesValor() {
    return this._cidadesValor
  }

  get imagemUrl() {
    if (this._imagem) {
      return `data:image/png;base64,${this._imagem}`
    }
    return toAbsoluteUrl('/media/avatars/blank.png')
  }

  get imagem() {
    if (this._imagem) {
      return (
        <img
          src={`data:image/png;base64,${this._imagem}`}
          style={{width: 32, height: 32, borderRadius: 16}}
          alt='imagem de perfil'
        ></img>
      )
    }
    return (
      <img
        src={toAbsoluteUrl('/media/avatars/blank.png')}
        style={{width: 32, height: 32, borderRadius: 16}}
        alt='imagem de perfil'
      ></img>
    )
  }

  set imagem(value: any) {
    this._imagem = value
  }

  get nome() {
    return this._nome
  }

  get email() {
    return this._email
  }

  get perfil() {
    return {
      label: this._perfil.nome,
      value: this._perfil.codigo,
    }
  }

  get status() {
    return this._status
  }

  get quantidadeAcessos(): number {
    return this._quantidadeAcessos
  }

  get dataUltimoAcesso() {
    return this._dataUltimoAcesso
  }

  get dataCriacao() {
    return this._dataCriacao
  }

  get telefone() {
    return this._telefone
  }

  get primeiroAcesso(): boolean {
    return this._primeiroAcesso
  }

  get quantidadeTentativas(): number {
    return this._quantidadeTentativas
  }

  get agencia() {
    return this._agencia
  }

  get bairro() {
    return this._bairro
  }

  get banco() {
    return {
      label: this._banco?.nome,
      value: this._banco?.codigo,
    }
  }

  get cep() {
    return this._cep
  }

  get chavePix() {
    return this._chavePix
  }

  get cidade() {
    return this._cidade
  }

  get complemento() {
    return this._complemento
  }

  get conta() {
    return this._conta
  }

  get cpf() {
    return this._cpf
  }

  get dataEmissaoRg() {
    return this._dataEmissaoRg
  }

  get dataNascimento() {
    return this._dataNascimento
  }

  get estadoCivil() {
    return {
      label: this._estadoCivil?.nome,
      value: this._estadoCivil?.codigo,
    }
  }

  get genero() {
    return {
      label: this._genero?.nome,
      value: this._genero?.codigo,
    }
  }

  get logradouro() {
    return this._logradouro
  }

  get nacionalidade() {
    return this._nacionalidade
  }

  get numero() {
    return this._numero
  }

  get orgaoEmissorRg() {
    return this._orgaoEmissorRg
  }

  get rg() {
    return this._rg
  }

  get tipoConta() {
    return {
      label: this._tipoConta?.nome,
      value: this._tipoConta?.codigo,
    }
  }

  get uf() {
    return {
      label: this._uf?.codigo,
      value: this._uf?.codigo,
    }
  }

  get statusNome() {
    return this._status.nome
  }

  set statusNome(nome: string) {
    this._status.nome = nome
  }

  get statusPendencia() {
    return (
      <div style={{display: 'flex', gap: 5, flexDirection: 'column'}}>
        {this._temPendencia && <StatusTag name='Pendente' />}
        {!this._temPendencia && <StatusTag type='info' name='Concluído' />}
      </div>
    )
  }
}
