import {HTMLAttributes} from 'react'
import {TypeOptions} from 'react-toastify'

export enum TypeAlert {
  INFO = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'danger',
  DEFAULT = 'default',
}

export interface Props extends HTMLAttributes<HTMLElement> {
  customSize?: string
}

export class PropTypeAlert {
  constructor(
    public toastTYPE: TypeOptions,
    public icon: string,
    public backgroundColor: string,
    public textColor: string
  ) {}
}
