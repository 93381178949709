/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'

import {useIntl} from 'react-intl'
import {useFormik} from 'formik'

import AnimatedModal from 'src/app/components/AnimatedModal'
import {Select} from 'src/app/components/Select/Select'
import {UpdateRouteUFCity, UpdateRouteUFCityData} from 'src/app/repositories/Route'

import {Container} from './styles'
import {cidadeValidation} from './validation'
import {ContainerBox} from 'src/app/pages/planejamento-execucao/CentroCusto/components/CostCenterCreationModal/styles'
import {GetMunicipiosPorEstado} from 'src/app/repositories/Location'
import {useAlert} from 'src/app/components/Alert/Alert'
import {useRoute} from 'src/app/hooks/useRoute'
import { IFilter } from 'src/app/model/Generic/Type'

const EditUFCityModal = ({onClose, ufOptions}: Props) => {
  const {updateRoutes, routeDetail, setRouteDetail} = useRoute()
  const [cidadeOptions, setCidadeOptions] = useState<IFilter[]>([])

  const intl = useIntl()

  const initialValues = {
    uf: {
      label: 'Selecione uma UF',
      value: null,
    },
    cidade: {
      label: 'Selecione uma Cidade',
      value: null,
    },
  }

  const validationSchema = Yup.object().shape({
    cidade: cidadeValidation(intl),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const {cidade} = values

      const o: UpdateRouteUFCityData = {
        cidade: cidade.value || null,
        uf: routeDetail.uf,
        codigo: routeDetail.codigo,
      }

      try {
        const res = await UpdateRouteUFCity(o)

        if (res) {
          updateRoutes(res)
          setRouteDetail((oldState) => {
            const oldStateCopy = oldState

            if (oldStateCopy.codigo === res.codigo) {
              oldStateCopy.uf = res.uf
              oldStateCopy.cidade = res.cidade
            }

            return oldStateCopy
          })

          useAlert('Cidade Referência trocada com sucesso.', 'Success')
          onClose()
        }
      } catch (error: any) {
        useAlert(error.message, 'Error')
      }
    },
  })

  const getMunicipiosPorEstado = async () => {
    try {
      const res = await GetMunicipiosPorEstado(routeDetail.uf)

      if (res) setCidadeOptions(res)
    } catch (error: any) {
      useAlert(error.message, 'Error')
    }
  }

  useEffect(() => {
    getMunicipiosPorEstado()
  }, [])

  return (
    <AnimatedModal
      formik={formik}
      containerStyle={{maxWidth: 700, height: 'auto', overflowY: 'initial'}}
      headerTitle={`Editar Cidade Referência: ${routeDetail.nome}`}
      footerTitle='Editar'
      onClose={onClose}
    >
      <Container>
        <ContainerBox>
          <Select
            dataTest='oCitiesModal'
            options={cidadeOptions}
            error={Boolean(formik.errors['cidade']?.value)}
            placeholder={'Selecione uma Cidade'}
            formik={{
              formikProp: 'cidade',
              formikValues: formik,
            }}
          />
          {formik.errors['cidade'] && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors['cidade'].value}</span>
            </div>
          )}
        </ContainerBox>
      </Container>
    </AnimatedModal>
  )
}

type Props = {
  onClose: () => void
  ufOptions: IFilter[]
}

export default EditUFCityModal
