/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef} from 'react'

import _uniqueId from 'lodash/uniqueId'

import {CameraIcon} from 'src/app/utils/Icons'

import {Container, ContainerButton} from './styles'

function InputImage({setDataFile, fileByte, blockInputs = false, setImagem}: any) {
  const [file, setFile] = useState(fileByte)

  const cameraRef = useRef<HTMLInputElement>(null)

  const [keyId] = useState(_uniqueId('prefix-'))

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL: any = ''
      // Make new FileReader
      let reader = new FileReader()

      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result
        resolve(baseURL)
      }
    })
  }

  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event?.target?.files!.length > 0) {
      if (event.target.files) {
        const file: any = event.target.files[0]

        setFile(URL.createObjectURL(event.target.files[0]))

        const result = await getBase64(file)

        file['base64'] = result

        setImagem(file.base64.split(',')[1])
      }
    }

    event.target.value = ''
  }

  function handlerClick(_: any) {
    setFile('')
    setDataFile([])
    setImagem('')
  }

  useEffect(() => {
    const setData = () => {
      setImagem(fileByte.split(',')[1])
    }
    if (typeof fileByte === 'object') {
      if (!fileByte) return
      if (fileByte.length === 0) return
      setDataFile(fileByte)
      setFile(URL.createObjectURL(fileByte))
    }
    if (fileByte) setData()
  }, [fileByte])

  return (
    <Container>
      <ContainerButton type='button' onClick={() => cameraRef.current?.click()}>
        {file ? (
          <img src={file} alt='foto avatar' />
        ) : (
          <>
            <CameraIcon size={100} color='#E6F1FB' />
            <span>Escolher Foto</span>
          </>
        )}

        <input
          id={keyId}
          ref={cameraRef}
          type='file'
          onChange={handleChange}
          accept='image/*'
          disabled={blockInputs}
        />
      </ContainerButton>
      {file && (
        <button className='btn-remove' onClick={handlerClick} disabled={blockInputs}>
          Remover
        </button>
      )}
    </Container>
  )
}

export default InputImage
