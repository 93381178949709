import {useState} from 'react'

import FotoPR from '../FotoPR'
import FotoPP from '../FotoPP'
import FotoQA from '../FotoQA'
import ServiceData from '../ServiceData'
import PriceScore from '../PriceScore'

import {Container} from './styles'
import FotoHomo from '../FotoHomo'

import ValidacaoModel from 'src/app/model/Validacao/Validacao'
import {ISigla} from 'src/app/model/Validacao/Type'

const AutoGLP = ({data, viewOnly}: {data: ValidacaoModel; viewOnly: boolean}) => {
  const canEdit = true

  const getCorrectImage = (sigla: ISigla) => {
    const index = data.fotosColeta.findIndex((item) => item.siglaFoto === sigla)

    return data.fotosColeta[index]
  }

  const canceled =
    getCorrectImage('PR')?.ocorrenciaFoto !== null &&
    getCorrectImage('QA') === undefined &&
    getCorrectImage('PP') === undefined

  const alternativePhotos = data.fotosColeta.filter(
    (item) =>
      item.siglaFoto === 'A1' ||
      item.siglaFoto === 'A2' ||
      item.siglaFoto === 'A3' ||
      item.siglaFoto === 'A4'
  )
  const [isWarning, setIsWarning] = useState(alternativePhotos.length > 0)

  return (
    <Container isCanceled={canceled}>
      {!canceled ? (
        <>
          <aside className='left'>
            <FotoPR
              setIsWarning={setIsWarning}
              canEdit={canEdit}
              data={data}
              foto={getCorrectImage('PR')}
              viewOnly={viewOnly}
            />
            <FotoPP
              viewOnly={viewOnly}
              setIsWarning={setIsWarning}
              canEdit={canEdit}
              data={data}
              foto={getCorrectImage('PP')}
            />
            <FotoQA
              viewOnly={false}
              setIsWarning={setIsWarning}
              canEdit={canEdit}
              data={data}
              foto={getCorrectImage('QA')}
            />
            <ServiceData
              viewOnly={viewOnly}
              isWarning={isWarning}
              setIsWarning={setIsWarning}
              canEdit={canEdit}
              data={data}
            />
          </aside>
          <aside className='right'>
            <PriceScore canEdit={canEdit} data={data} />
          </aside>
        </>
      ) : (
        <>
          <aside className='right'>
            <FotoHomo setIsWarning={setIsWarning} foto={getCorrectImage('PR')} />
            <FotoPR
              viewOnly={viewOnly}
              setIsWarning={setIsWarning}
              canEdit={canEdit}
              data={data}
              foto={getCorrectImage('PR')}
            />

            <ServiceData
              viewOnly={viewOnly}
              isWarning={isWarning}
              setIsWarning={setIsWarning}
              canEdit={canEdit}
              data={data}
            />
          </aside>
        </>
      )}
    </Container>
  )
}

export default AutoGLP
