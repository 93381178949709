import { useState } from "react";

export default function IndeterminateCheckbox({
    onChange,
    className = '',
    codigo,
    disable
  }: Props) {
  const [isChecked, setIsChecked] = useState(false);

  const onChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let targetChecked = event.currentTarget.checked;
    onChange(codigo, targetChecked);
    setIsChecked(!isChecked);
  }

  return (
    <div className="form-check form-check-custom form-check-solid">
      <input
        key={codigo}
        type="checkbox"
        className={className + ' form-check-input cursor-pointer border border-gray-500'}
        checked={isChecked}
        onChange={onChangeCheck}
        disabled={disable}
      />
    </div>
  )
}

interface Props {
  onChange: (id: string, isSelect: boolean) => void,
  className?: string,
  codigo: string,
  disable: boolean
}