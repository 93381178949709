import * as Yup from 'yup'
import {cpf, cnpj} from 'cpf-cnpj-validator'
import {IntlShape} from 'react-intl'

export const proprietarioValidation = (intl: IntlShape) =>
  Yup.string().required(
    intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Proprietário'})
  )

export const cpfCnpjValidation = (intl: IntlShape) =>
  Yup.string()
    .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'CPF/CNPJ'}))
    .test(
      'oneOfRequired',
      'CPF ou CNPJ inválido',
      (value) => cpf.isValid(value) || cnpj.isValid(value)
    )

export const razaoSocialValidation = (intl: IntlShape) =>
  Yup.string().required(
    intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Razão Social'})
  )

export const emailValidation = (intl: IntlShape) =>
  Yup.string()
    .email(intl.formatMessage({id: 'AUTH.VALIDATION.EMAIL'}))
    .min(3, intl.formatMessage({id: 'AUTH.VALIDATION.MIN_LENGTH'}, {name: 'Email', min: '3'}))
    .max(50, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Email', max: '50'}))
    .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Email'}))

export const bancoValidation = (intl: IntlShape) =>
  Yup.object().shape({
    label: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Banco'})
    ),
    value: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Banco'})
    ),
  })

export const tipoContaValidation = (intl: IntlShape) =>
  Yup.object().shape({
    label: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Tipo Conta'})
    ),
    value: Yup.string().required(
      intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Tipo Conta'})
    ),
  })

export const agenciaValidation = (intl: IntlShape) =>
  Yup.string()
    .max(6, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Agência', max: '6'}))
    .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Agência'}))

export const contaValidation = (intl: IntlShape) =>
  Yup.string()
    .max(12, intl.formatMessage({id: 'AUTH.VALIDATION.MAX_LENGTH'}, {name: 'Conta', max: '12'}))
    .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED'}, {name: 'Conta'}))
