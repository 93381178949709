import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  /* max-height: 360px; */
  width: 100%;

  margin-bottom: auto;

  background-color: #fff;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(206, 206, 206, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

  border-radius: 5px;

  transition: all 0.4s;

  position: relative;

  img {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .accordion {
    background-color: transparent;
    color: #364150;

    transition: all 0.4s;

    transform: rotate(-180deg);

    &.open {
      transform: rotate(0deg);
    }
  }

  .radios {
    display: flex;
    flex-direction: column;

    padding: 8px;

    height: 32px;

    overflow: hidden;
  }

  .img {
    display: flex;
    justify-content: center;

    position: relative;

    width: 100% !important;
    height: 230px;

    background-color: #f4f6fa;

    .image {
      width: 305px;

      .lazy-load-image-background {
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .distance {
      display: flex;
      flex-direction: column;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      padding: 5px;
      background-color: #f4f6fa;

      p {
        font-family: Poppins;
        font-size: 14px;
        color: #2b2b2b;

        margin: 0;
        padding: 0;
      }
    }

    img {
      cursor: zoom-in;
      height: 100%;
    }
  }

  .float-container {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .float-img {
    height: 100px;
    width: 100px;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    border: 3px solid #f89c32;

    img {
      height: 100%;
      width: 100%;

      object-fit: cover;

      cursor: zoom-in;
    }
  }
`

export const InfoQA = styled.div`
  border: 0;
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-color: black;
  border-style: dashed;

  .firstRowQA {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 10px;
    text-transform: uppercase;
    .key {
      font-weight: bold;
    }
  }

  .secondRowQA {
    background-color: #f1f1f1;
    padding: 3px 10px;
  }
`
export const DistanceText = styled.span<{value: number}>`
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: bold;

  margin: 0;
  padding: 0;

  color: ${(props) => (props.value > 300 ? '#F89C32' : '#2b2b2b')};
`
