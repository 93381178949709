import api from '../config'

import Erro from '../model/Erro/Erro'

import {handlerResult} from '../utils/API'

import {arrayToSelectOptions} from '../utils/Formats'

export async function GetResearcherByUFCity(GetResearcherByUFCityData: GetResearcherByUFCityData) {
  try {
    const response = await api.post('pesquisador/listarPorCidadeCusto', GetResearcherByUFCityData)
    const dataResponse: ResearchersOptions[] = await handlerResult(response.data)

    return arrayToSelectOptions(dataResponse, 'codigo', 'nome')
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export async function GetActiveResearches<T>() {
  try {
    const response = await api.get('pesquisador/listarAtivos')
    const dataResponse: T = await handlerResult(response.data)

    return dataResponse
  } catch (error: any) {
    if (error.response) {
      throw new Erro(error.response.data.data.message)
    }
  }
}

export type ResearchersOptions = {
  codigo: string
  nome: string
  numeroTelefone: string
  cpf: string
  email: string
}

type GetResearcherByUFCityData = {
  uf: string
  cidade: string
}
