import Card from '../Card'

const FotoHomo = ({foto, setIsWarning}: any) => {
  return (
    <Card foto={foto} setIsWarning={setIsWarning} showDistance={false} title='foto homologada'>
       
    </Card>
  )
}

export default FotoHomo
