import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  .table-container {
    margin: 10px 0;
  }

  .container-row {
    display: flex;

    gap: 10px;
  }
`
